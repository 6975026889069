import React from 'react'
import { Flex, Text, Button, Box } from '@chakra-ui/react'
import { SampleNote as SampleNoteIcon } from '@components/icons'
import { useSampleSessions } from '@hooks'

const SampleButton = ({ title, type }) => {
  const { isLoading, createSampleSession } = useSampleSessions()

  const handleCreate = () => {
    createSampleSession(type)
  }

  return (
    <Flex
      flexDirection="row"
      bg="#F6F7FE"
      alignItems="center"
      justifyContent="center"
      width="auto"
      padding="0px 4px"
      border="2px solid"
      borderColor="rgb(246, 247, 254)"
      borderRadius="4px"
      _hover={{
        cursor: 'pointer',
        border: '2px solid',
        borderColor: 'primary'
      }}
    >
      <Button
        id={`pendo-sessions-sample-${type}`}
        data-click-event-name="Clicked Sample Session Link"
        variant="link"
        isLoading={isLoading}
        color="primary"
        onClick={handleCreate}
        ml={0}
        mr="xxsmall"
        textDecoration="none"
        _hover={{ textDecoration: 'none' }}
      >
        <SampleNoteIcon />
        <Text ml="4px">{title}</Text>
      </Button>
    </Flex>
  )
}

export const SampleSessionsModule = ({ containerProps = {} }) => {
  const { sampleNotes } = useSampleSessions()

  return (
    <Flex alignItems="left" direction="column" width="100%" {...containerProps}>
      <Text fontWeight="bold" mb="xsmall" align="left">
        Explore sample notes
      </Text>
      <Box>
        <Flex
          flexDirection="row"
          gap="8px"
          flexWrap="wrap"
          justifyContent="left"
          mb="large"
        >
          {sampleNotes.map(({ label, identifier }) => (
            <SampleButton key={identifier} title={label} type={identifier} />
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}
