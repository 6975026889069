import React from 'react'
import {
  Box,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  FormErrorMessage,
  HStack,
  RadioGroup,
  Radio
} from '@chakra-ui/react'
import {
  Formik,
  Form,
  Field,
  FieldProps,
  FormikProps,
  useFormikContext
} from 'formik'
import InputMask from 'react-input-mask'
import { TextField } from '@blueprinthq/joy'
import { useReminderControllerSetReminder } from '~/clinician-api'
import * as Yup from 'yup'
import { DateTime } from 'luxon'

const now = DateTime.now()

const DAYS = [
  {
    key: 'Mon',
    label: 'M',
    value: 1
  },
  {
    key: 'Tue',
    label: 'T',
    value: 2
  },
  {
    key: 'Wed',
    label: 'W',
    value: 3
  },
  {
    key: 'Thu',
    label: 'Th',
    value: 4
  },
  {
    key: 'Fri',
    label: 'F',
    value: 5
  },
  {
    key: 'Sat',
    label: 'S',
    value: 6
  },
  {
    key: 'Sun',
    label: 'S',
    value: 7
  }
]

export const ClinicianReminderFieldsSchema = Yup.object().shape({
  daysOfWeek: Yup.array(Yup.string())
    .min(1, 'Please select a day')
    .default([DAYS.find(d => now.weekday === d.value)?.key]),
  timeOfDay: Yup.string()
    .required('Please enter a time')
    .default(now.toFormat('HH:mm')),
  contactMethod: Yup.string()
    .required('Required')
    .default('email'),
  email: Yup.string().when('contactMethod', {
    is: (value: string) => value === 'email' || value === 'both',
    then: y => y.email('Invalid email address').required('Required'),
    otherwise: y => y.nullable()
  }),
  phone: Yup.string().when('contactMethod', {
    is: (value: string) => value === 'sms' || value === 'both',
    then: schema =>
      schema
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          'Phone number must be in format (XXX) XXX-XXXX'
        )
        .required('Required'),
    otherwise: schema => schema.nullable()
  })
})

interface ClinicianReminderFormProps {
  user: any
  children: (props: any) => React.ReactNode
  setShowSuccess: (showSuccess: boolean) => void
}

export const ClinicianReminderForm = ({
  children,
  user,
  setShowSuccess
}: ClinicianReminderFormProps) => {
  const { mutateAsync: setReminder } = useReminderControllerSetReminder({
    mutation: {
      onSuccess: () => {
        setShowSuccess(true)
      }
    }
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        daysOfWeek: [DAYS.find(d => now.weekday === d.value)?.key],
        timeOfDay: now.toFormat('HH:mm'),
        contactMethod: 'email',
        email: user.email,
        phone: ''
      }}
      validationSchema={ClinicianReminderFieldsSchema}
      onSubmit={async values => {
        const timeArray = values.timeOfDay.split(':')

        const dateTime = getDateTime(values.daysOfWeek[0]!, {
          hours: parseInt(timeArray[0]),
          minutes: parseInt(timeArray[1])
        })

        await setReminder({
          data: {
            nextAppointmentDateTime: dateTime.toISO(),
            email: values.email,
            phoneNumber: values.phone ?? '',
            contactMethod: values.contactMethod ?? 'email'
          }
        })
      }}
    >
      {(formikProps: FormikProps<any>) => (
        <Form>{children({ formikProps })}</Form>
      )}
    </Formik>
  )
}

function getDateTime(
  selectedDay: string,
  time: { hours: number; minutes: number } | null
) {
  // Find the day value (1-7) for the selected day
  const selectedDayValue = DAYS.find(d => d.key === selectedDay)!.value

  // Get current weekday (1-7)
  const currentWeekday = now.weekday

  // Calculate days until the next occurrence of the selected day
  let daysToAdd: number

  if (selectedDayValue > currentWeekday) {
    // Selected day is later this week
    daysToAdd = selectedDayValue - currentWeekday
  } else if (selectedDayValue < currentWeekday) {
    // Selected day is next week
    daysToAdd = 7 - currentWeekday + selectedDayValue
  } else {
    // Selected day is today - check the time
    if (time) {
      const selectedTime = now.startOf('day').plus({
        hours: time.hours,
        minutes: time.minutes
      })

      if (selectedTime < now) {
        // Time has already passed today, so schedule for next week
        daysToAdd = 7
      } else {
        // Time is still upcoming today
        daysToAdd = 0
      }
    } else {
      // No time specified, default to next week
      daysToAdd = 7
    }
  }

  // Calculate the next date
  const nextDate = now.plus({ days: daysToAdd })

  // If time is specified, set it on the next date
  if (time) {
    return nextDate.startOf('day').plus({
      hours: time.hours,
      minutes: time.minutes
    })
  }

  return nextDate.startOf('day')
}

export const ClinicianReminderFields = ({ user }: { user: any }) => {
  const { values } = useFormikContext<any>()

  return (
    <Box>
      <Text>
        We'll send you a reminder 10 minutes before your next appointment so you
        can experience Blueprint's superpowers in a real session
      </Text>
      <VStack
        spacing="small"
        mt="small"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Field name="daysOfWeek">
          {({ field, form, meta }: FieldProps) => (
            <FormControl isInvalid={!!meta.error}>
              <FormLabel fontWeight="bold">My next appointment:</FormLabel>
              <DayOfWeekSelector
                selectedDays={field.value}
                onChange={selectedDays => {
                  form.setFieldValue(field.name, selectedDays)
                }}
              />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field pb="xsmall" name="timeOfDay">
          {({ field, meta }: FieldProps) => (
            <FormControl isInvalid={!!meta.error}>
              <FormLabel fontWeight="bold">Time:</FormLabel>
              <Input w="37%" {...field} type="time" />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field pb="xsmall" name="contactMethod">
          {({ field, form, meta }: FieldProps) => (
            <FormControl isInvalid={!!meta.error}>
              <FormLabel fontWeight="bold">Delivery method:</FormLabel>
              <RadioGroup
                value={field.value}
                onChange={(nextValue: string) =>
                  form.setFieldValue(field.name, nextValue)
                }
              >
                <HStack spacing={5}>
                  <Radio _focus={{ outline: 'none' }} value="email">
                    Email
                  </Radio>
                  <Radio _focus={{ outline: 'none' }} value="sms">
                    Text Message
                  </Radio>
                  <Radio _focus={{ outline: 'none' }} value="both">
                    Both
                  </Radio>
                </HStack>
              </RadioGroup>
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        {values.contactMethod !== 'sms' && (
          <Field pb="xsmall" name="email">
            {({ field, meta }: FieldProps) => (
              <FormControl isInvalid={!!meta.error}>
                <FormLabel fontWeight="bold" htmlFor="phone">
                  Email:
                </FormLabel>
                <TextField {...field} width="100%" label="" />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        )}
        {values.contactMethod !== 'email' && (
          <Field name="phone">
            {({ field, meta }: FieldProps) => {
              return (
                <Box width="100%" mb="16px">
                  <FormControl isInvalid={meta.touched && !!meta.error}>
                    <FormLabel fontWeight="bold" htmlFor="phone">
                      Mobile number:
                    </FormLabel>
                    <InputMask
                      mask="(999) 999-9999"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      {(inputProps: any) => (
                        <Input
                          {...inputProps}
                          {...field}
                          id="phone"
                          type="tel"
                          height="48px"
                          placeholder="(123) 456-7890"
                        />
                      )}
                    </InputMask>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                </Box>
              )
            }}
          </Field>
        )}
      </VStack>
    </Box>
  )
}

interface DayOfWeekSelectorProps {
  selectedDays: string[]
  showEveryDayOption?: boolean
  onChange: (selectedDays: string[]) => void
}

export const DayOfWeekSelector = ({
  selectedDays,
  onChange
}: DayOfWeekSelectorProps) => {
  const handleDaySelect = (value: string) => {
    const newSelectedDays = [value]
    // If the day is already selected, deselect it (empty array)
    if (selectedDays.length === 1 && selectedDays[0] === value) {
      onChange([])
    } else {
      // Otherwise select only this day
      onChange(newSelectedDays)
    }
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" gap="xsmall">
        {DAYS.map((day, index) => {
          const isSelected = selectedDays.includes(day.key)

          return (
            <Button
              key={index}
              variant={isSelected ? 'solid' : 'outline'}
              w="50px"
              h="50px"
              m={0}
              p={0}
              borderRadius="full"
              borderColor="pale_gray"
              onClick={() => handleDaySelect(day.key)}
              _focus={{ outline: 'none' }}
            >
              {day.label}
            </Button>
          )
        })}
      </Box>
    </Box>
  )
}
