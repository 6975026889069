import React from 'react'
import {
  Text,
  Box,
  DrawerFooter,
  Button,
  Flex,
  IconButton
} from '@chakra-ui/react'
import { ArrowLeftIcon } from '@blueprinthq/joy'
import Memories from './memories'

const MemoryManagementDrawer = ({
  onClose,
  noteType
}: {
  onClose: () => void
  noteType: string
}) => {
  return (
    <>
      <Box
        height="95px"
        backgroundColor="primary"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex w="100%" height="80px" align="center">
          <IconButton
            aria-label="Back"
            icon={<ArrowLeftIcon fill="white" />}
            variant="ghost"
            color="white"
            onClick={onClose}
            _focus={{ outline: 'none' }}
            _hover={{ bg: 'rgba(255,255,255,0.1)' }}
          />
          <Text as="span" fontWeight="bold" color="white" paddingLeft="xsmall">
            Manage Memory
          </Text>
        </Flex>
      </Box>
      <Memories noteType={noteType} />
      <DrawerFooter width="100%" px="small" py={0}>
        <Box w="100%">
          <Flex justify="space-between" width="100%" py={6}>
            <Button variant="outline" onClick={onClose} size="lg" flex={1}>
              Cancel
            </Button>
          </Flex>
        </Box>
      </DrawerFooter>
    </>
  )
}

export default MemoryManagementDrawer
