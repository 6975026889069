import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Text, Button, Flex } from '@chakra-ui/react'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'
import { FadeIn } from './animations'
import { VideoThumbnail } from './video-thumbnail'

export const VideoOfframp = ({ onPlayVideo }: { onPlayVideo: () => void }) => {
  const history = useHistory()
  const videoUrl =
    'https://res.cloudinary.com/hellojoy/video/upload/v1742925423/onboarding/Blueprint-Onboarding_with_Vivian-compile_compressed.mp4'

  useEffect(() => {
    trackEvent('Onboarding Video Offramp Viewed', {
      version: 3
    })
  }, [])

  return (
    <Step mt={{ base: '60px', md: 0 }} pb={4}>
      <FadeIn duration={0.7} maxWidth="360px" margin="0 auto">
        <VideoThumbnail
          videoUrl={videoUrl}
          onPlay={onPlayVideo}
          timestamp={12}
        />
        <Box mt="medium">
          <Text fontSize="24px" style={{ lineHeight: '44px' }}>
            Ready to go?
          </Text>
          <Text mt="small">
            It's super easy: add your client's name, hit record then place
            your device nearby. We'll handle the notes, you focus on the
            your patient.
          </Text>
        </Box>
        <Box pt="large">
          <Button
            onClick={() =>
              history.push('/start-session/new-client?context=onboarding')
            }
            isFullWidth
            m={0}
            mb="small"
            type="button"
            size="lg"
          >
            Let's do it
          </Button>
          <Button
            onClick={onPlayVideo}
            isFullWidth
            m={0}
            variant="ghost"
            size="lg"
            color="#2D54E8"
            type="button"
          >
            Watch again
          </Button>
        </Box>
      </FadeIn>
    </Step>
  )
}
