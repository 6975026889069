// @ts-nocheck

import React, { useMemo } from 'react'
import {
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Flex,
  Tooltip,
  Box,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton
} from '@chakra-ui/react'
import { Select, UpdateIcon } from '@blueprinthq/joy'
import { DateTime } from 'luxon'
import classes from './list.module.css'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import SendIcon from '@material-ui/icons/Send'
import CalendarToday from '@material-ui/icons/CalendarToday'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const AssessmentListComponent = ({
  assessments,
  patient,
  assigneeUser,
  onUnassignAssessment,
  onUpdateAssessment,
  dataUpdatedAt,
  handleCheckboxChange,
  checkedItems,
  isAllChecked,
  handleAllCheckboxChange,
  handleUnassignAssessment,
  handleOpenModal,
  handleSendNow,
  handleTakeNow
}: any) => {
  const activeAssessmentCount = (assessments as any).filter(
    assessment => assessment.isActive
  ).length

  const cleanedActiveAssessments = useMemo(() => {
    const filteredAssessments = [...assessments]

    const screenerIdx = filteredAssessments.findIndex(a => a.is_screener)
    if (screenerIdx > -1) {
      const [screener] = filteredAssessments.splice(screenerIdx, 1)
      filteredAssessments.unshift(screener)
    }

    return filteredAssessments.filter(assessment => assessment.isActive)
  }, [assessments, patient, activeAssessmentCount, dataUpdatedAt])

  const DateValue = ({ date, overdue, showFull }: any) => {
    if (date) {
      return (
        <Tooltip
          label={DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            color={overdue ? '#EF8552' : '#333333'}
          >
            <span style={{ fontVariant: 'none' }}>
              {DateTime.fromISO(date).toLocaleString({
                month: 'short',
                day: 'numeric'
              })}
            </span>
            {/* {overdue && <UpdateIcon ml={'2px'} fill={'#EF8552'} />} */}
          </Box>
        </Tooltip>
      )
    }

    return (
      <>
        <Box
          as="span"
          display="flex"
          alignItems="center"
          color={overdue ? '#EF8552' : '#333333'}
        >
          <Text>N/A</Text>
          {overdue && <UpdateIcon ml={'2px'} fill={'#EF8552'} />}
        </Box>
      </>
    )
  }

  return activeAssessmentCount ? (
    <>
      <Box
        w="100%"
        border="1px solid"
        borderColor="#E4E5E6"
        borderRadius="8px"
        mt={'xsmall'}
        mb={'small'}
        bg="white"
        verticalAlign={'middle'}
      >
        <Table variant="unstyled">
          <Thead>
            <Tr borderBottom={'1px solid #E4E5E6'}>
              <Th
                letterSpacing={'none'}
                className={classes.tablechange}
                pl={0}
                pt={3}
                pb={3}
                pr={0}
              >
                <Flex height="100%" alignItems="center" justifyContent="center">
                  <input
                    type="checkbox"
                    onChange={() =>
                      handleAllCheckboxChange(
                        // ensures that when the all checkbox is clicked it excludes any assessments that have cadence integration_driven
                        cleanedActiveAssessments.filter(
                          assessment =>
                            assessment.cadence_unit !== 'integration_driven'
                        ),
                        assigneeUser
                      )
                    }
                    checked={isAllChecked[assigneeUser.id] || false}
                    style={{
                      accentColor: '#2D54E8',
                      height: '18px',
                      width: '18px',
                      border: `2px solid #C9C9C9`,
                      MozAppearance: 'none',
                      WebkitAppearance: isAllChecked[assigneeUser.id]
                        ? ''
                        : 'none',
                      borderRadius: '2px'
                    }}
                  />
                </Flex>
              </Th>
              <Th
                verticalAlign="middle"
                letterSpacing={'none'}
                pl={0}
                pt={2}
                pb={3}
                pr={0}
              >
                <Flex height="100%" alignItems="center">
                  <Text
                    fontSize="12px"
                    height="16px"
                    w="90px"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    Assessment
                  </Text>
                </Flex>
              </Th>
              <Th
                verticalAlign="middle"
                letterSpacing={'none'}
                pl={0}
                pt={2}
                pb={3}
                pr={0}
              >
                <Box height="100%" display="flex" alignItems="center">
                  <Text
                    fontSize="12px"
                    height="16px"
                    w="90px"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    Frequency
                  </Text>
                </Box>
              </Th>
              <Th
                verticalAlign="middle"
                letterSpacing={'none'}
                pl={0}
                pt={2}
                pb={3}
                pr={0}
              >
                <Box height="100%" display="flex" alignItems="center">
                  <Text
                    fontSize="12px"
                    height="16px"
                    w="90px"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    Sent
                  </Text>
                </Box>
              </Th>
              <Th
                verticalAlign="middle"
                letterSpacing={'none'}
                pl={0}
                pt={2}
                pb={3}
                pr={0}
              >
                <Box height="100%" display="flex" alignItems="center">
                  <Text
                    fontSize="12px"
                    height="16px"
                    w="90px"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    Completed
                  </Text>
                </Box>
              </Th>
              <Th
                verticalAlign="middle"
                letterSpacing={'none'}
                pl={0}
                pt={2}
                pb={3}
                pr={0}
              >
                <Box height="100%" display="flex" alignItems="center">
                  <Text
                    fontSize="12px"
                    height="16px"
                    w="90px"
                    textTransform="none"
                    fontWeight="normal"
                  >
                    Next
                  </Text>
                </Box>
              </Th>
              <Th w="40px" letterSpacing={'none'} pl={0} pt={2} pb={3} pr={0}>
                <Box
                  w="40px"
                  height="100%"
                  display="flex"
                  alignItems="center"
                ></Box>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {cleanedActiveAssessments.map((data, index) => {
              const inSessionOnly = {
                key: 'in_session_only',
                name: 'In session only'
              }

              return (
                <Tr
                  _last={{ borderBottom: 'none' }}
                  borderBottom={'1px solid #E4E5E6'}
                  key={index}
                  bgColor={
                    checkedItems[data.assessment_id] &&
                    checkedItems[data.assessment_id].includes(assigneeUser)
                      ? '#2D54E80A'
                      : ''
                  }
                >
                  <Td
                    className={classes.tablechange}
                    pr={2}
                    pb={3}
                    pt={3}
                    pl={2}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {data.cadence_unit !== 'integration_driven' && (
                        <input
                          type="checkbox"
                          onChange={e => {
                            handleCheckboxChange(
                              data.assessment_id,
                              assigneeUser,
                              e.target.checked
                            )
                          }}
                          checked={
                            checkedItems[data.assessment_id] &&
                            checkedItems[data.assessment_id].includes(
                              assigneeUser
                            )
                          }
                          style={{
                            accentColor: '#2D54E8',
                            height: '18px',
                            width: '18px',
                            border: `2px solid #C9C9C9`,
                            MozAppearance: 'none',
                            WebkitAppearance:
                              checkedItems[data.assessment_id] &&
                              checkedItems[data.assessment_id].includes(
                                assigneeUser
                              )
                                ? ''
                                : 'none',
                            borderRadius: '2px'
                          }}
                        />
                      )}
                    </Box>
                  </Td>
                  <Td
                    pr={0}
                    pb={2}
                    pt={2}
                    verticalAlign="top"
                    pl={0}
                    className={classes.tablechange}
                  >
                    <Tooltip label={data.full_name}>
                      <Heading
                        color={'black'}
                        textTransform={'none'}
                        size="small"
                        m={0}
                      >
                        {data.name}
                      </Heading>
                    </Tooltip>
                  </Td>
                  <Td
                    className={classes.tablechange}
                    pt={2}
                    pb={2}
                    // pr={5}
                    pl={0}
                  >
                    {assigneeUser.id === patient.user_id &&
                    !patient.is_using_deprecated_child_flow &&
                    patient.isChildAge ? (
                      <>
                        <Tooltip
                          // maxWidth="75px"
                          placement="bottom-start"
                          label={`Since ${assigneeUser.info.firstName ||
                            'the client'} is a child and does not have an email or phone number on file, they’re limited to taking assessments in session only.`}
                          hasArrow
                          shouldWrapChildren
                        >
                          <Select
                            simple
                            isDisabled
                            options={[inSessionOnly]}
                            valueKey="key"
                            labelKey="name"
                            value={inSessionOnly}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {data.cadence_unit !== 'integration_driven' && (
                          <Text>
                            {data.cadence_value} {data.cadence_unit}
                          </Text>
                        )}
                        {data.cadence_unit === 'integration_driven' && (
                          <Heading
                            color={'black'}
                            textTransform={'none'}
                            fontWeight={'normal'}
                            size="small"
                            m={0}
                          >
                            {'Managed by Integration'}
                          </Heading>
                        )}
                      </>
                    )}
                  </Td>
                  <Td pr={0} py={2} className={classes.tablechange} pl={0}>
                    <DateValue
                      overdue={false}
                      showFull={false}
                      date={data.lastAdministrationDate}
                    />
                  </Td>
                  <Td pr={0} py={2} className={classes.tablechange} pl={0}>
                    <DateValue
                      overdue={data.isOverdue}
                      showFull={false}
                      date={data.lastCompletedDate}
                    />
                  </Td>
                  <Td pr={0} py={2} className={classes.tablechange} pl={0}>
                    <DateValue
                      overdue={false}
                      showFull={true}
                      date={data.nextAdministrationDate}
                    />
                  </Td>
                  <Td
                    // w="40px"
                    pr={3}
                    py={2}
                    className={classes.tablechange}
                    pl={0}
                  >
                    <Menu>
                      {
                        <MenuButton //Changed to cause the three dots to disappear if the cadence is integration driven
                          as={IconButton}
                          aria-label="Options"
                          icon={<MoreHorizIcon />}
                          variant="ghost"
                          height="auto"
                          _focus={{
                            outline: 'none',
                            outlineStyle: 'none'
                          }}
                        />
                      }
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            const assessmentData = {
                              [data.assessment_id]: [assigneeUser]
                            }
                            handleTakeNow(assessmentData)
                          }}
                        >
                          <Flex justifyContent="space-between" width="100%">
                            Take Now
                            <OpenInNewIcon style={{ height: 16, width: 16 }} />
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const assessmentData = {
                              [data.assessment_id]: [assigneeUser]
                            }
                            handleSendNow(assessmentData)
                          }}
                        >
                          <Flex justifyContent="space-between" width="100%">
                            Send Now
                            <SendIcon style={{ height: 16, width: 16 }} />
                          </Flex>
                        </MenuItem>
                        {data.nextAdministrationDate !== null &&
                          data.cadence_unit !== 'integration_driven' && (
                            <MenuItem
                              onClick={() => {
                                const assessmentData = {
                                  [data.assessment_id]: [assigneeUser]
                                }
                                handleOpenModal(assessmentData)
                              }}
                            >
                              <Flex justifyContent="space-between" width="100%">
                                Adjust Next Send
                                <CalendarToday
                                  style={{ height: 16, width: 16 }}
                                />
                              </Flex>
                            </MenuItem>
                          )}
                        {data.cadence_unit !== 'integration_driven' && (
                          <MenuItem
                            onClick={() => {
                              const assessmentData = {
                                [data.assessment_id]: [assigneeUser]
                              }
                              handleUnassignAssessment(assessmentData)
                            }}
                          >
                            <Flex
                              color={'#EB5164'}
                              justifyContent="space-between"
                              width="100%"
                            >
                              Unassign
                            </Flex>
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  ) : (
    <Text
      w="100%"
      p="16px"
      border={'1px solid #E4E5E6'}
      borderRadius={'8px'}
      my={'8px'}
    >
      No assessments assigned.
    </Text>
  )
}

export const AssessmentList = AssessmentListComponent
