import React, { useEffect, useState, useMemo } from 'react'
import {
  Box,
  Text,
  Flex,
  VStack,
  Button,
  Textarea,
  Input,
  RadioGroup,
  Radio,
  Checkbox
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import * as clinicianTracking from '@lib/clinician-tracking'
import { useHistory, Redirect } from 'react-router-dom'
import { useClinicControllerGetCurrentPlan } from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
import { useExperienceManager } from '@hooks'
import { shuffle } from 'lodash'

interface FormValues {
  cancellationReason: string | null
  missingFeatures: string
  otherReason: string
  selectedCompetitor: string
  cancellationFeedback: string
}

const validationSchema = Yup.object().shape({
  cancellationReason: Yup.string().required('Cancellation Reason is required'),
  missingFeatures: Yup.string(),
  otherReason: Yup.string(),
  cancellationFeedback: Yup.string()
})

const CancellationForm = ({
  onSubmit,
  isDeleteAccount = false
}: {
  onSubmit: (values: FormValues) => void
  isDeleteAccount?: boolean
}) => {
  const [isCofnirmDelete, setIsConfirmDelete] = useState(false)
  const history = useHistory()
  const { user } = useStoreState((state: any) => state.auth)
  const { isAdmin } = useExperienceManager()

  const { data: currentPlan } = useClinicControllerGetCurrentPlan(
    user.clinic.id
  )

  const planType = currentPlan?.planType

  useEffect(() => {
    clinicianTracking.trackEvent(`Viewed Cancel Subscription Page`)
  }, [])

  const version = 2

  const { data: cancellationReasons = [], isLoading }: any = useQuery(
    [endpoints.getAccountDeletionReasons.getCacheId(), version],
    () => endpoints.getAccountDeletionReasons.request({ version })
  )

  const [reasons, otherReason] = useMemo(() => {
    const reasons = shuffle(
      cancellationReasons.filter((r: any) => r.id !== 'OTHER')
    )
    const otherReason = cancellationReasons.find((r: any) => r.id === 'OTHER')
    return [reasons, otherReason]
  }, [cancellationReasons])

  const handleSubmit = async (values: any) => {
    clinicianTracking.trackEvent(
      `Cancel ${planType?.toUpperCase()} Dialog -> Clicked confirm cancel`
    )
    onSubmit(values)
  }

  if (!isAdmin) {
    return <Redirect to="/sessions" />
  }

  if (isLoading) return null

  return (
    <Formik<FormValues>
      initialValues={{
        cancellationReason: null,
        missingFeatures: '',
        otherReason: '',
        selectedCompetitor: '',
        cancellationFeedback: ''
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, setFieldValue, isValid, dirty }) => (
        <Form style={{ width: '100%' }}>
          <VStack align="left" spacing="0" gap="24px">
            <Box>
              <Text as="b">
                What’s the main reason for canceling your subscription?*
              </Text>
              <Field name="cancellationReason">
                {() => (
                  <RadioGroup
                    mt={3}
                    onChange={value => {
                      const selectedReason =
                        cancellationReasons.find((r: any) => r.id === value) ||
                        null
                      setFieldValue('cancellationReason', selectedReason.id)
                      if (selectedReason?.id !== 'MISSING_FEATURES')
                        setFieldValue('missingFeatures', '')
                      if (selectedReason?.id !== 'OTHER')
                        setFieldValue('otherReason', '')
                    }}
                    value={values.cancellationReason || ''}
                  >
                    <VStack align="left" spacing={2} ml={2}>
                      {reasons.map((reason: any) => (
                        <Box key={reason.id} w="100%">
                          <Radio value={reason.id} _focus={{ outline: 'none' }}>
                            {reason.display}
                          </Radio>
                          {values.cancellationReason === reason.id && (
                            <Box pl={6}>
                              {reason.id === 'MISSING_FEATURES' && (
                                <>
                                  <Field name="missingFeatures">
                                    {({ field }: any) => (
                                      <Input
                                        {...field}
                                        mt={2}
                                        placeholder="Which features were missing?"
                                        borderColor="light_gray"
                                      />
                                    )}
                                  </Field>
                                </>
                              )}
                            </Box>
                          )}
                        </Box>
                      ))}
                      <Box w="100%">
                        <Radio
                          value={otherReason.id}
                          _focus={{ outline: 'none' }}
                        >
                          {otherReason.display}
                        </Radio>
                        {values.cancellationReason === otherReason.id && (
                          <Box pl={6}>
                            <Field name="otherReason">
                              {({ field }: any) => (
                                <Input
                                  {...field}
                                  mt={2}
                                  placeholder="I am cancelling because..."
                                  borderColor="light_gray"
                                />
                              )}
                            </Field>
                          </Box>
                        )}
                      </Box>
                    </VStack>
                  </RadioGroup>
                )}
              </Field>
            </Box>
            <Box>
              <Text as="b">Which AI notetaker will you use now? and why?</Text>
              <Field name="selectedCompetitor">
                {({ field }: any) => (
                  <Input
                    {...field}
                    mt={2}
                    placeholder="Which one? and why?"
                    borderColor="light_gray"
                  />
                )}
              </Field>
            </Box>
            <Box>
              <Text as="b" mb={2}>
                Do you have any other feedback for our team on how we can
                improve Blueprint?
              </Text>
              <Field name="cancellationFeedback">
                {({ field }: any) => (
                  <Textarea
                    {...field}
                    mt={2}
                    placeholder="Write suggestions..."
                    _placeholder={{ opacity: 1, color: 'medium_gray' }}
                    borderColor="light_gray"
                  />
                )}
              </Field>
            </Box>
            {isDeleteAccount && (
              <Box>
                <Checkbox
                  isChecked={isCofnirmDelete}
                  onChange={() => setIsConfirmDelete(!isCofnirmDelete)}
                  isRequired
                  sx={{
                    '> span.chakra-checkbox__control': {
                      _focus: { boxShadow: 'none' }
                    }
                  }}
                >
                  I understand that deleting my account is permanent and cannot
                  be reversed.
                </Checkbox>
              </Box>
            )}
            <Flex gap="16px">
              <Button
                variant="outline"
                size="lg"
                borderColor="pale_gray"
                onClick={() => history.push('/')}
              >
                Cancel
              </Button>
              <Button
                bg="#EB5164"
                size="lg"
                isLoading={isSubmitting}
                isDisabled={
                  !isValid ||
                  !dirty ||
                  isSubmitting ||
                  (isDeleteAccount && !isCofnirmDelete)
                }
                type="submit"
              >
                {isDeleteAccount
                  ? 'Permanently delete account'
                  : 'Cancel subscription'}
              </Button>
            </Flex>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}

export default CancellationForm
