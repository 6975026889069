import React, { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  Link,
  HStack,
  Divider
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { Clipboard } from '@components/icons'
import {
  AssessmentSuggestionDTO,
  InterventionSuggestionDTO,
  WorksheetSuggestionDTO
} from '~/clinician-api/models'
import {
  getSessionControllerGetSuggestedContentQueryKey,
  useContentSuggestionControllerSubmitFeedback
} from '~/clinician-api'
import Feedback from './feedback'
import { ThumbUpIcon, ThumbDownIcon } from '@icons'

interface ContentSuggestionItemProps {
  type: 'worksheet' | 'assessment' | 'intervention'
  suggestion: (
    | AssessmentSuggestionDTO
    | WorksheetSuggestionDTO
    | InterventionSuggestionDTO
  ) & {
    name: string
  }
  sessionId: string
  onAssign: () => void
  previewUrl: string
  isDemo?: boolean
  onPreview?: () => void
  truncateRationale?: boolean
}

const MAX_LENGTH = 133

export const ContentSuggestionItem = ({
  isDemo,
  type,
  suggestion,
  sessionId,
  onAssign,
  previewUrl,
  onPreview,
  truncateRationale = false
}: ContentSuggestionItemProps) => {
  const queryClient = useQueryClient()
  const [commentsOpen, setCommentsOpen] = useState(false)
  const [rating, setRating] = useState<null | string>(
    suggestion.feedback?.rating || null
  )
  const [comments, setComments] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    setRating(suggestion.feedback?.rating || null)
  }, [suggestion.feedback?.rating])

  useEffect(() => {
    setComments('')
    setCommentsOpen(false)
  }, [suggestion.contentSuggestionId])

  const {
    mutateAsync: submitFeedback
  } = useContentSuggestionControllerSubmitFeedback({
    mutation: {
      onSuccess: () => {
        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          sessionId
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })
      }
    }
  })

  const handleFeedback = async (value: string) => {
    setRating(value)
    setComments('')
    setCommentsOpen(true)
    await submitFeedback({
      contentSuggestionId: suggestion.contentSuggestionId,
      data: { rating: value }
    })
  }

  return (
    <Box bg="white" borderRadius="8px" border="1px solid #E4E5E6">
      <Box p={4}>
        <Flex alignItems="center" justifyContent="space-between" mb={2}>
          <Flex alignItems="center">
            <Box>
              <Clipboard fill="black" />
            </Box>
            <Text ml={2} fontWeight="bold">
              {suggestion.name}
            </Text>
          </Flex>
        </Flex>
        <Box mb={4}>
          <Text color="dark_gray" fontSize="12px" lineHeight="16px">
            {truncateRationale ? (
              <>
                {isExpanded
                  ? `${suggestion.rationale} `
                  : `${suggestion.rationale?.slice(0, MAX_LENGTH)}... `}
                {suggestion.rationale.length > MAX_LENGTH && (
                  <Button
                    fontSize="12px"
                    lineHeight="16px"
                    variant="link"
                    minWidth="auto"
                    minH="auto"
                    textDecoration="none"
                    m={0}
                    _focus={{
                      outline: 'none'
                    }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? 'less' : 'more'}
                  </Button>
                )}
              </>
            ) : (
              suggestion.rationale
            )}
          </Text>
        </Box>
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <HStack
                alignItems="center"
                spacing="0"
                borderRadius="4px"
                border="1px solid"
                h="32px"
                overflow="hidden"
                borderColor="pale_gray"
              >
                <IconButton
                  aria-label="thumbs-up"
                  isRound={false}
                  variant="outline"
                  color="primary"
                  _focus={{
                    boxShadow: 'none'
                  }}
                  _hover={{
                    background: '#F5F5F7'
                  }}
                  m={0}
                  size="sm"
                  px="8px"
                  border="none"
                  borderRadius="0"
                  onClick={isDemo ? () => {} : () => handleFeedback('good')}
                  bg={rating === 'good' ? '#F5F5F7' : 'white'}
                  icon={
                    <ThumbUpIcon
                      fill={rating === 'good' ? '#2D54E8' : '#A1A1A1'}
                      size="14"
                    />
                  }
                />
                <Divider orientation="vertical" bg="pale_gray" />
                <IconButton
                  aria-label="thumbs-down"
                  isRound={false}
                  variant="outline"
                  color="primary"
                  _focus={{
                    boxShadow: 'none'
                  }}
                  _hover={{
                    background: '#F5F5F7'
                  }}
                  m={0}
                  size="sm"
                  px="8px"
                  border="none"
                  borderRadius="0"
                  onClick={isDemo ? () => {} : () => handleFeedback('bad')}
                  bg={rating === 'bad' ? '#F5F5F7' : 'white'}
                  icon={
                    <ThumbDownIcon
                      fill={rating === 'bad' ? '#2D54E8' : '#A1A1A1'}
                      size="14"
                    />
                  }
                />
              </HStack>
            </Box>
            <Flex alignItems="center" gap="16px">
              {previewUrl && (
                <Link
                  href={previewUrl}
                  target="_blank"
                  color="primary"
                  textDecoration="none"
                  _focus={{ outline: 'none' }}
                >
                  Preview
                </Link>
              )}
              {onPreview && !previewUrl && (
                <Text
                  as="span"
                  mr={4}
                  color="primary"
                  _hover={{ cursor: 'pointer' }}
                  onClick={onPreview}
                >
                  Preview
                </Text>
              )}
              <Button
                textDecoration="none"
                borderRadius="4px"
                // @ts-ignore
                disabled={suggestion.isActive}
                size="sm"
                m="0"
                _focus={{ outline: 'none' }}
                onClick={onAssign}
              >
                {/* @ts-ignore */}
                {suggestion.isActive
                  ? type === 'intervention'
                    ? 'Saved for Later'
                    : 'Assigned'
                  : type === 'intervention'
                  ? 'Save for Later'
                  : 'Assign'}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Box>
      {commentsOpen && rating && (
        <Feedback
          rating={rating}
          setCommentsOpen={setCommentsOpen}
          comments={comments}
          setComments={setComments}
          submitFeedback={submitFeedback}
          suggestionId={suggestion.contentSuggestionId}
        />
      )}
    </Box>
  )
}
