import React, { useEffect, useState } from 'react'
import {
  Box,
  VStack,
  Flex,
  Text,
  CloseButton,
  TextProps
} from '@chakra-ui/react'
import NumberOfCliniciansSurvey from '../survey-questions/number-of-clinicians-survey'
import { useStoreActions } from 'easy-peasy'

import { useOnboardingControllerSubmitSurveyResponse } from '~/clinician-api'
import { OnboardingSurveyDto } from '~/clinician-api/models'
import PersonaSurvey from '../survey-questions/persona-survey'
import { AnimatePresence, motion } from 'framer-motion'
import SurveyThankYou from '../survey-questions/survey-thankyou'
import { usePermissions, useExperienceManager } from '@hooks'
import { Button } from '@blueprinthq/joy'
import { PlayIcon } from '@components/icons'

// @ts-ignore
import Cookies from 'js-cookie'

interface WelcomeBannerProps {
  user: any
  handleClose: () => void
  setIsVisible: (isVisible: boolean) => void
  localStorageDismissedAtKey: string
  localStorageDismissedKey: string
  onOpenPreviewVideo?: () => void
}

export const WelcomeAndSurveyBanner = ({
  user,
  handleClose,
  setIsVisible,
  localStorageDismissedAtKey,
  localStorageDismissedKey,
  onOpenPreviewVideo
}: WelcomeBannerProps) => {
  const [surveyStep, setSurveyStep] = useState('')

  const { hasPermission } = usePermissions()
  const { isDocumentationAutomationEnterprise, isOnboardingV2Enabled } = useExperienceManager()
  // @ts-ignore
  const { fetchUserInfo } = useStoreActions(actions => actions.auth)

  const MotionContainer = motion(Box)

  useEffect(() => {
    if (!user) return
    if (
      !hasPermission('*:clinic:clinics:*') ||
      isDocumentationAutomationEnterprise
    ) {
      setSurveyStep('')
      return
    }

    if (user.clinic.number_of_clinicians === null) {
      setSurveyStep('step_one')
      return
    }
    if (user.clinic.organization_type === null) {
      setSurveyStep('step_two')
      return
    }
  }, [user, hasPermission, isDocumentationAutomationEnterprise])

  useEffect(() => {
    if (surveyStep !== 'step_three') return

    const timer = setTimeout(() => {
      setSurveyStep('')
    }, 3000)

    return () => clearTimeout(timer)
  }, [surveyStep])

  useEffect(() => {
    const storedDismissed = localStorage.getItem(localStorageDismissedKey)
    const storedDismissedAt = localStorage.getItem(localStorageDismissedAtKey)

    if (!storedDismissed) setIsVisible(true)

    const dismissedTimestamp = storedDismissedAt
      ? parseInt(storedDismissedAt, 10)
      : null

    if ((dismissedTimestamp === null ||
      Date.now() - dismissedTimestamp >= 24 * 60 * 60 * 1000) &&
      surveyStep !== '') setIsVisible(true)
  }, [surveyStep, setIsVisible, localStorageDismissedKey, localStorageDismissedAtKey])

  const WelcomeText = React.memo((textProps: TextProps) => (
    <Flex direction="column" align="flex-start" gap="16px">
      <Text {...textProps}>
        Blueprint is here to assist you in getting hours of{' '}
        <Text as="span" fontWeight="bold">
          time
        </Text>{' '}
        and{' '}
        <Text as="span" fontWeight="bold">
          focus
        </Text>{' '}
        back each week — so you can deliver exceptional care every session{' '}
        <Text as="span" fontStyle="italic">
          without
        </Text>{' '}
        spending nights & weekends on documentation
      </Text>
      {isOnboardingV2Enabled && (
        <Button
          ml="0px"
          w="180px"
          variant="outline"
          onClick={() => onOpenPreviewVideo && onOpenPreviewVideo()}
          leftIcon={<PlayIcon width="16px" height="16px" color="black" />}
          _hover={{
            bg: 'rgba(45, 84, 232, 0.04) ',
          }}
          _focus={{
            outline: 'none'
          }}
          fontSize="14px"
          h="32px"
        >
          Learn how it works
        </Button>
      )}
    </Flex>
  ))

  const WelcomeImage =
    'https://res.cloudinary.com/hellojoy/image/upload/v1738794046/onboarding/welcome_background.jpg'

  const {
    mutateAsync: submitSurveyResponse
  } = useOnboardingControllerSubmitSurveyResponse()

  const handleSurveyStepSubmit = (survey: OnboardingSurveyDto) => {
    submitSurveyResponse(
      {
        data: survey
      },
      {
        onSuccess: async () => {
          await fetchUserInfo()
          if (surveyStep === 'step_one') {
            setSurveyStep('step_two')
          } else if (surveyStep === 'step_two') {
            setSurveyStep('step_three')
          }
        }
      }
    )
  }

  const surveyContext = {
    hubspotutk: Cookies.get('hubspotutk'),
    pageUri: window.location.href
  }

  const welcomeContent = React.useMemo(() => (
    <AnimatePresence exitBeforeEnter>
      {surveyStep === '' && (
        <MotionContainer
          key="welcome-text"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            ease: [0.57, 0.1, 0, 1]
          }}
        >
          <WelcomeText
            sx={{
              '@media screen and (min-width: 62em)': {
                display: 'block'
              },
              '@media screen and (max-width: 62em)': {
                display: 'block'
              }
            }}
          />
        </MotionContainer>
      )}
      {surveyStep === 'step_one' && (
        <NumberOfCliniciansSurvey
          key="step_one"
          onSubmitted={(numberOfClinicians: number) => {
            handleSurveyStepSubmit({
              numberOfClinicians: numberOfClinicians,
              ...surveyContext
            })
          }}
        />
      )}
      {surveyStep === 'step_two' && (
        <PersonaSurvey
          key="step_two"
          onSubmitted={(persona: string) => {
            handleSurveyStepSubmit({
              persona: persona,
              ...surveyContext
            })
          }}
        />
      )}
      {surveyStep === 'step_three' && <SurveyThankYou key="step_three" />}
    </AnimatePresence>
  ), [surveyStep])

  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      bg="#F6F7FE"
      borderRadius="8px"
    >
      <CloseButton
        position="absolute"
        top="10px"
        right="10px"
        onClick={handleClose}
      />
      <Box flex="5" p={{ base: 6, md: 8 }}>
        <VStack spacing={4} align="stretch">
          <Text
            color="#2D54E8"
            fontSize={{ base: '16px', lg: '24px' }}
            fontWeight={{ base: 'bold', lg: 'normal' }}
          >
            Hi {user.first_name}! Welcome to Blueprint!
          </Text>
          {welcomeContent}
        </VStack>
      </Box>
      <Box
        flex="3"
        bgImage={`url(${WelcomeImage})`}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        pb={{ base: '200px', sm: '0' }}
        borderBottomRightRadius="8px"
        borderBottomLeftRadius={{ base: '8px', sm: '0' }}
        borderTopRightRadius={{ base: '0', sm: '8px' }}
      ></Box>
    </Flex>
  )
}