import React, { useEffect, useState } from 'react'
import { PanelBody, OverlayPanelHeader } from '@handlers/sessions/components'
import Panel from '../panel'
import AssessmentLibrary from '../../shared/assist/assessments/library'
import WorksheetLibrary from '../../shared/assist/worksheets/library'
import { InterventionOverview } from '../../shared/assist/interventions/overview'
import { InterventionLibrary } from '../../shared/assist/interventions/library'
import { InterventionActivity } from '../../shared/assist/interventions/activity'
import EnrollClient from '../assessments/enroll'
import AssignAssessments from '../assessments/assign'
import { AssignWorksheets } from '../worksheets/assign'
import AssessmentResults from '../assessments/results'
import { useAssistPanelContext } from '../context'
import { WorksheetResult } from '../worksheets/worksheet-result'
import { CheckInDtoV2, CheckInScoreDtoV1 } from '~/clinician-api/models'
import { AllWorksheetResults } from '../worksheets/all-worksheet-results'
import CompleteAssistSetup from '../assist-setup'
import {
  useSessionControllerPostContentSuggestions,
  getSessionControllerGetSuggestedContentQueryKey
} from '~/clinician-api'
import { useQueryClient } from 'react-query'

export enum AssistPanelOverlay {
  LIBRARY = 'LIBRARY',
  ASSESSMENT_RESULTS = 'ASSESSMENT_RESULTS',
  ALL_WORKSHEET_RESULTS = 'ALL_WORKSHEET_RESULTS',
  WORKSHEET_RESULT = 'WORKSHEET_RESULT',
  WORKSHEET_LIBRARY = 'WORKSHEET_LIBRARY',
  INTERVENTION_ACTIVITY = 'INTERVENTION_ACTIVITY',
  INTERVENTION_OVERVIEW = 'INTERVENTION_OVERVIEW',
  INTERVENTION_LIBRARY = 'INTERVENTION_LIBRARY',
  FINISH_ASSIST_SETUP = 'FINISH_ASSIST_SETUP',
  ENROLL_CLIENT = 'ENROLL_CLIENT',
  ASSIGN_ASSESSMENTS = 'ASSIGN_ASSESSMENTS',
  ASSIGN_WORKSHEETS = 'ASSIGN_WORKSHEETS'
}

interface Props {
  client: any
  data?: {
    assessmentId?: string
    assessmentScoreId?: string
    checkIn?: CheckInDtoV2
    score?: CheckInScoreDtoV1
    interventionId?: string
    interventionName?: string
    patientInterventionId?: string
    isReadOnly?: boolean
    scoresWithCheckIn?: (CheckInScoreDtoV1 & {
      checkIn?: CheckInDtoV2
    })[]
    contentSuggestionId?: string | null
    onAssign: (assessment: any) => void
  },
  handleBack: () => void
}

const panelConfig: any = {
  LIBRARY: {
    title: (props: Props) => 'Assign Assessments',
    component: (props: Props) => (
      <AssessmentLibrary
        client={props.client}
        onAssign={props.data?.onAssign!}
      />
    )
  },
  ENROLL_CLIENT: {
    title: (props: Props) => {
      return props.data?.checkIn ? 'Assign Worksheet' : 'Assign Assessment'
    },
    component: () => <EnrollClient />
  },
  ASSIGN_ASSESSMENTS: {
    title: (props: Props) => 'Assign Assessment',
    component: () => <AssignAssessments />
  },
  ASSIGN_WORKSHEETS: {
    title: (props: Props) => 'Assign Worksheet',
    component: () => <AssignWorksheets />
  },
  ASSESSMENT_RESULTS: {
    title: (props: Props) => 'Assessment Results',
    component: (props: Props) => (
      <AssessmentResults
        client={props.client}
        preselectedAssessmentId={props.data?.assessmentId}
        preselectedAssessmentScoreId={props.data?.assessmentScoreId}
      />
    )
  },
  WORKSHEET_RESULT: {
    title: (props: Props) => 'Worksheet Result',
    component: (props: Props) => (
      <WorksheetResult
        checkIn={props.data?.checkIn!}
        score={props.data?.score!}
      />
    )
  },
  ALL_WORKSHEET_RESULTS: {
    title: (props: Props) => 'All Completed Worksheets',
    component: (props: Props) => <AllWorksheetResults />
  },
  WORKSHEET_LIBRARY: {
    title: (props: Props) => 'Worksheet Library',
    component: (props: Props) => (
      <WorksheetLibrary
        client={props.client}
        onAssign={props.data?.onAssign!}
      />
    )
  },
  INTERVENTION_OVERVIEW: {
    title: (props: Props) => props.data?.interventionName,
    component: (props: Props) => (
      <InterventionOverview 
        interventionId={props.data?.interventionId || ''} 
        contentSuggestionId={props.data?.contentSuggestionId}
      />
    )
  },
  INTERVENTION_LIBRARY: {
    title: (props: Props) => 'Intervention Library',
    component: (props: Props) => <InterventionLibrary />
  },
  INTERVENTION_ACTIVITY: {
    title: (props: Props) => props.data?.interventionName,
    component: (props: Props) => (
      <InterventionActivity
        isReadOnly={props.data?.isReadOnly ?? true}
        interventionId={props.data?.interventionId || ''}
        patientInterventionId={props.data?.patientInterventionId || ''}
        contentSuggestionId={props.data?.contentSuggestionId}
      />
    )
  },
  FINISH_ASSIST_SETUP: {
    title: (props: Props) => 'Assist Setup',
    component: (props: Props) => (
      <CompleteAssistSetup handleBack={props.handleBack} />
    )
  }
}

export type PanelKey = AssistPanelOverlay

interface PanelSwitcherProps {
  client: any
}

interface PanelHistoryItem {
  panel: PanelKey
  data?: Props['data']
}

const PanelSwitcher: React.FC<PanelSwitcherProps> = ({ client }) => {
  const { panel, data, setOverlayPanel, saveInterventionLibraryData, lastSession } = useAssistPanelContext()
  const queryClient = useQueryClient()
  const [panelHistory, setPanelHistory] = useState<PanelHistoryItem[]>([])

  const {
    mutate: postContentSuggestions,
  } = useSessionControllerPostContentSuggestions({
    mutation: {
      onSuccess: () => {
        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          lastSession?.id
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })  
    }}
  })

  useEffect(() => {
    if (!panel) return;
    
    const lastHistoryItem = panelHistory[panelHistory.length - 1];
    const shouldAddToHistory = panelHistory.length === 0 || 
      !lastHistoryItem || 
      lastHistoryItem.panel !== panel;

    if (shouldAddToHistory) {
      setPanelHistory(prevHistory => [...prevHistory, { panel, data }])
    }
  }, [panel, data, panelHistory.length])

  const handleBack = () => {
    setPanelHistory(prevHistory => {
      const newHistory = [...prevHistory]
      newHistory.pop() // remove the visible panel from the history
      const previousPanelItem = newHistory[newHistory.length - 1] // get the previous panel

      if (previousPanelItem) {
        setOverlayPanel(previousPanelItem.panel, previousPanelItem.data)
      } else {
        saveInterventionLibraryData({
          scrollTop: 0,
          search: '',
          approach: {
            label: 'All treatment approaches',
            value: ''
          },
        })
        setOverlayPanel(null)
      }

      return newHistory
    })
  }

  if (!panel || !(panel in panelConfig)) return null

  const config = panelConfig[panel]

  const handleBackClick = async () => {
    if (panel === AssistPanelOverlay.FINISH_ASSIST_SETUP && client.isAssistSetupComplete) {
      await postContentSuggestions({
        id: lastSession?.id
      })
    }
    handleBack()
  }

  return (
    <Panel>
      <OverlayPanelHeader
        title={config.title({ client, data })}
        onBack={handleBackClick}
      />
      <PanelBody
        px="0"
        height={{ base: 'auto', sm: 'auto', md: 'auto' }}
        overflowY={{ base: 'auto', sm: 'auto', md: 'hidden' }}
      >
        {config.component({ client, data, handleBack })}
      </PanelBody>
    </Panel>
  )
}

export default PanelSwitcher
