import React, { useState } from 'react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@components'
import { FormikProps } from 'formik'
import { Box, HStack, Text, VStack, Flex } from '@chakra-ui/react'
import { Button, CheckIcon } from '@blueprinthq/joy'
import {
  ClinicianReminderForm,
  ClinicianReminderFields
} from './clinician-reminder-form'
import { useStoreState, useStoreActions } from 'easy-peasy'

interface ClinicianReminderModalProps {
  isOpen: boolean
  onClose: () => void
  user: any
}

const ClinicianReminderModal = ({
  isOpen,
  onClose,
  user
}: ClinicianReminderModalProps) => {
  const [showSuccess, setShowSuccess] = useState(false)

  return (
    <DialogContainer isOpen={isOpen} onClose={onClose}>
      <DialogHeader
        text={showSuccess ? 'Reminder set' : 'Send reminder'}
        onClose={onClose}
      />
      <DialogBody p={0}>
        {showSuccess ? (
          <VStack p={6} alignItems="center">
            <Flex
              w="144px"
              h="144px"
              bg="#D5DDFA"
              borderRadius="full"
              justify="center"
              align="center"
            >
              <CheckIcon height="64px" width="64px" size="lg" fill="white" />
            </Flex>
            <Box h="8px" />
            <Box>
              <Text as="span" fontWeight="bold">
                You're all set!{' '}
              </Text>
              <Text as="span">
                We can't wait to make Blueprint a part of your practice's
                continued success.
              </Text>
            </Box>
          </VStack>
        ) : (
          <ClinicianReminderForm setShowSuccess={setShowSuccess} user={user}>
            {({ isSubmitting }: FormikProps<any>) => (
              <>
                <Box p={6}>
                  <ClinicianReminderFields user={user} />
                </Box>
                <DialogFooter>
                  <HStack spacing={4} w="100%">
                    <Button
                      onClick={onClose}
                      variant="outline"
                      size="lg"
                      isFullWidth
                    >
                      I'll remember
                    </Button>
                    <Button
                      type="submit"
                      size="lg"
                      isFullWidth
                      isLoading={isSubmitting}
                    >
                      Confirm
                    </Button>
                  </HStack>
                </DialogFooter>
              </>
            )}
          </ClinicianReminderForm>
        )}
      </DialogBody>
    </DialogContainer>
  )
}

export const ClinicianReminderModalConnected = () => {
  const { isOpen } = useStoreState(
    (state: any) => state.modals.clinicianReminder
  )
  const closeModal = useStoreActions(
    (actions: any) => actions.modals.clinicianReminder.closeModal
  )
  const user = useStoreState((state: any) => state.auth.user)

  return user ? (
    <ClinicianReminderModal isOpen={isOpen} onClose={closeModal} user={user} />
  ) : null
}
