import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { DateTime } from 'luxon'
import { StoreModel } from 'src/store/types';
import { datadogLogs } from '@datadog/browser-logs'
import { store } from '../../store'

function getUserTimezone() {
  // Get the user's current DateTime
  const userDateTime = DateTime.local();

  // Get the user's timezone name
  const timezoneName = userDateTime.zoneName;

  return timezoneName;
}

function createAxiosInstance(baseURL: string) {
  const AXIOS_INSTANCE = Axios.create({ baseURL });

  AXIOS_INSTANCE.interceptors.request.use(
    config => {
      // @ts-ignore
      config.headers['X-Timezone'] = getUserTimezone()

      const {
        auth: { accessToken, isAuthenticated }
      } = store.getState()
      if (isAuthenticated) {
        // @ts-ignore
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  AXIOS_INSTANCE.interceptors.response.use(
    // res func was messing res up, data would always be undefined
    res => res,
    async err => {
      // anything above 2xx status
      const {
        // @ts-ignore
        auth: { isAuthenticated, isSSOUser, isRefreshingSSO, user }
      } = store.getState()

      const {
        auth: { refreshAccessToken, resumeSession, setIsRefreshingSSO }
      } = store.getActions() as any
      const { config, response } = err
      const contentType = response.headers?.['content-type']

      if (response.status === 401 && isAuthenticated && !isRefreshingSSO) {
        if (!config._retry) {
          config._retry = true
          try {
            if (isSSOUser) {
              setIsRefreshingSSO(true)
              await refreshAccessToken()
              setIsRefreshingSSO(false)
            } else {
              datadogLogs.logger.info('[Auth Debug - Axios Instance] Refreshing Cognito Token', { user })
              await resumeSession() // attempt to refresh cognito token
            }
            return AXIOS_INSTANCE(config) // retry the call
          } catch (_error) {
            datadogLogs.logger.error('[Auth Debug - Axios Instance] Refresh Error - Should Log Out', { user, error: _error })
            // await logout()
            console.log('should log out')
            return Promise.reject(_error)
          }
        } else {
          datadogLogs.logger.error('[Auth Debug - Axios Instance] Refresh Retry Failed - Should Log Out', { user })

          console.log('should log out')
          // await logout()
        }
      } else if (contentType && contentType.indexOf('application/json') !== -1) {
        const jsonError = err.response.data
        return Promise.reject(jsonError)
      } else {
        return Promise.reject(err.response.status)
      }
    }
  )

  return AXIOS_INSTANCE
}

const hellojoyAxiosInstance = createAxiosInstance(process.env.REACT_APP_NODE_API_ROOT_URL!)
export const hellojoyApiInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const promise = hellojoyAxiosInstance({
    ...config,
    ...options,
    headers: {
      ...options?.headers,
    }
  }).then(({ data }) => data);

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
