import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  Flex,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  FormLabel,
  CloseButton,
  Progress,
  Box,
  useToast,
  VStack
} from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@handlers/sessions/components/dialog'
import { NoteTypeDropdown } from './note-type-dropdown'
import { useStoreState } from 'easy-peasy'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useHistory } from 'react-router-dom'
import {
  useSessionControllerCreateFromUploadedAudioV2,
  useSessionControllerStartUpload,
  useSessionControllerCompleteUpload,
  getClinicControllerGetClientListQueryKey
} from '~/clinician-api'
import { DateTime } from 'luxon'
import pLimit from 'p-limit'
import { RadioButtonLoadingComplete, Group } from '@components/icons'
import {
  Select,
  Assist,
  InPersonFilled,
  Online,
  AccountIcon,
  PeopleIcon,
  TextField
} from '@blueprinthq/joy'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { datadogLogs } from '@datadog/browser-logs'
import { UploadV2, GraphicEq } from '@components/icons'
import { SearchSelect, Loading, BPDateTimePicker } from '@components'
import { IconSelect } from '.'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { debounce, uniqBy } from 'lodash'
import _ from 'lodash'
import { useExperienceManager } from '@hooks'

import * as clinicianTracking from '@lib/clinician-tracking'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'
import moment from 'moment'
import { motion, AnimatePresence } from 'framer-motion'
import { TreatmentApproachDropdown } from './treatment-approach-dropdown'

const sessionTypes = [
  {
    value: 'individual',
    title: 'Individual',
    icon: <AccountIcon />
  },
  {
    value: 'couple',
    title: 'Couple',
    icon: <PeopleIcon />
  },
  {
    value: 'group',
    title: 'Group',
    icon: <Group />
  }
]

export function useFileUpload() {
  const [countOfUploadedParts, setCountOfUploadedParts] = useState(0)
  const {
    mutateAsync: startUpload,
    data: uploadData
  } = useSessionControllerStartUpload()

  const { mutateAsync: completeUpload } = useSessionControllerCompleteUpload()
  const { mutateAsync: uploadPart } = useMutation(
    async ({ body, uploadUrl, file, partNumber }) => {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body
      })

      if (!response.ok) {
        throw new Error('Upload failed')
      }

      return {
        PartNumber: partNumber,
        ETag: response.headers.get('Etag')
      }
    },
    {
      retry: 3,
      onSuccess: data => {
        setCountOfUploadedParts(prev => prev + 1)
      },
      onError: error => {
        datadogLogs.logger.error(
          '[SESSION UPLOAD] Error uploading part:',
          {},
          error
        )
      }
    }
  )

  const uploadFileMutation = useMutation(
    async file => {
      setCountOfUploadedParts(0)

      const { uploadId, chunkSize, uploadUrls, fileId } = await startUpload({
        data: {
          contentType: file.type,
          fileSize: file.size
        }
      })

      const limit = pLimit(5)

      const uploadPromises = uploadUrls.map((uploadUrl, i) => {
        const body = file.slice(i * chunkSize, (i + 1) * chunkSize)
        return limit(() =>
          uploadPart({ body, uploadUrl, file, partNumber: i + 1 })
        )
      })

      const parts = await Promise.all(uploadPromises)
      await completeUpload({ data: { uploadId, parts, fileId } })

      return { fileId }
    },
    {
      onError: (error, variables) => {
        datadogLogs.logger.error(
          '[SESSION UPLOAD] Error uploading file:',
          {},
          error
        )
      }
    }
  )

  const percentage =
    Math.round((countOfUploadedParts / uploadData?.numberOfParts) * 100) || 0
  const fileId = uploadData?.fileId
  const uploadedCount = countOfUploadedParts
  const totalCount = uploadData?.numberOfParts

  return {
    uploadFileMutation,
    percentage,
    uploadedCount,
    totalCount,
    fileId
  }
}

const validationSchema = Yup.object({
  client: Yup.object().required('Client is required'),
  noteType: Yup.object().required('Progress note type is required'),
  sessionDate: Yup.date().required('Session date is required'),
  selectedFile: Yup.mixed().required('Audio file is required'),
  setting: Yup.string().required('Setting is required'),
  treatmentApproaches: Yup.array()
})

const AddClientDialog = ({
  onSubmit,
  setIsFormValid,
  setIsFormSubmitting,
  formRef
}) => {
  const {
    isEvidenceBasedCareEnabled,
    isDateOfBirthEnabled
  } = useExperienceManager()

  const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    pronoun: null
  }

  const pronounTypes = Object.keys(CLIENT_PRONOUNS)

  const baseSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    pronoun: Yup.string()
      .optional()
      .nullable()
  })

  const validationSchema = useMemo(() => {
    let schema = baseSchema

    if (isEvidenceBasedCareEnabled || isDateOfBirthEnabled) {
      schema = schema.shape({
        dateOfBirth: Yup.date()
          .max(moment(), 'Date of birth must be in the past')
          .required('Required')
      })
    }

    if (isDateOfBirthEnabled) {
      schema = schema.shape({
        pronoun: Yup.string().required('Required')
      })
    }

    return schema
  }, [isEvidenceBasedCareEnabled, isDateOfBirthEnabled])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {({ errors, touched, isValid, isSubmitting, dirty, handleSubmit }) => {
        setIsFormValid(!isValid || !dirty)
        setIsFormSubmitting(isSubmitting)
        return (
          <Form
            onSubmit={handleSubmit}
            style={{ width: '100%', height: '100%' }}
          >
            <VStack spacing={4} width="100%">
              <Field name="firstName">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    isInvalid={errors.firstName && touched.firstName}
                    errorText={errors.firstName}
                    isRequired
                    width="100%"
                  />
                )}
              </Field>
              <Field name="lastName">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    isInvalid={errors.lastName && touched.lastName}
                    errorText={errors.lastName}
                    isRequired
                    width="100%"
                  />
                )}
              </Field>
              {(isEvidenceBasedCareEnabled || isDateOfBirthEnabled) && (
                <Field name="dateOfBirth">
                  {({ field }) => (
                    <BPDateTimePicker
                      {...field}
                      showDate
                      isRequired
                      label="Date of Birth"
                      isInvalid={errors.dateOfBirth && touched.dateOfBirth}
                      errorText={
                        (errors.dateOfBirth &&
                          errors.dateOfBirth.includes('Invalid') &&
                          'Invalid date') ||
                        errors.dateOfBirth
                      }
                      width="100%"
                    />
                  )}
                </Field>
              )}
              <Field name="pronoun">
                {({ field, form }) => (
                  <Select
                    label="Pronouns"
                    isRequired={isDateOfBirthEnabled}
                    borderColor="lightgrey"
                    fontSize="medium"
                    value={field.value}
                    isInvalid={errors.pronoun && touched.pronoun}
                    errorText="Required"
                    onChange={value => form.setFieldValue('pronoun', value)}
                    options={pronounTypes}
                    width="100%"
                  />
                )}
              </Field>
            </VStack>
          </Form>
        )
      }}
    </Formik>
  )
}

const SessionUploadForm = ({
  onAddClientClick,
  addedClient,
  formRef,
  setIsFormValid,
  setIsFormSubmitting,
  selectedFile,
  setSelectedFile,
  selectedFileId,
  setSelectedFileId
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const [clientSearchInput, setClientSearchInput] = useState('')
  const { user } = useStoreState(state => state.auth)
  const history = useHistory()
  const {
    isPlusPlanEnabled,
    showRulaUI,
    memoryEnabled
  } = useExperienceManager()

  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const { data: settings, isLoading: isSettingsLoading } = useQuery(
    [
      endpoints.getProgressNoteSettings.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteSettings.request({
        organizationId: user?.clinic?.organization_id
      }),
    {
      enabled: !!user
    }
  )

  const { data: defaultList, isLoading: isDefaultListLoading } = useQuery(
    [endpoints.getPatientList.getCacheId()],
    () =>
      endpoints.getPatientList.request({
        page: 1,
        status: 'active',
        sortBy: 'patient_name',
        order: 'ASC',
        clinician_id: user.id,
        clinic_id: user.clinic_id
      }),
    {
      select: data => {
        return data.nodes
          .map(n => ({
            ...n,
            id: n.patient_id,
            first_name: n.first_name,
            last_name: n.last_name
          }))
          .filter(n => !n.is_demo)
      }
    }
  )

  const { refetch, data: searchResults } = useQuery(
    [
      endpoints.getPatientSearch.getCacheId(),
      'session_client_search',
      clientSearchInput
    ],
    () =>
      endpoints.getPatientSearch.request({
        search: clientSearchInput,
        include_discharged: false
      }),
    {
      enabled: false,
      initialData: [],
      select: data => {
        return data
          .map(p => ({
            ...p,
            id: p.id,
            first_name: p.first_name,
            last_name: p.last_name
          }))
          .filter(n => !n.is_demo || isInternalDemoOrg || showRulaUI)
      }
    }
  )

  const debounceClientSearch = debounce(refetch, 1000)

  useEffect(() => {
    if (!!clientSearchInput) debounceClientSearch()
  }, [clientSearchInput])

  const {
    data: { progressNoteTypes },
    isLoading: isProgressNoteTypesLoading
  } = useQuery(
    [endpoints.getProgressNoteTypes.getCacheId(), user.clinic.organization_id],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: {
        progressNoteTypes: []
      },
      enabled: !!user
    }
  )

  const {
    uploadFileMutation: {
      mutateAsync: uploadFile,
      isSuccess: isUploadSuccess,
      isLoading: isUploadingFile
    },
    fileId,
    percentage,
    uploadedCount,
    totalCount
  } = useFileUpload()

  useEffect(() => {
    if (isUploadSuccess && fileId) {
      setSelectedFileId(fileId)
    }
  }, [isUploadSuccess])

  const {
    mutateAsync: createSessionFromUploadedAudio
  } = useSessionControllerCreateFromUploadedAudioV2()

  const handleFileChange = async event => {
    const file = event.target.files[0]
    setSelectedFile(file)
    await uploadFile(file)
  }

  const handleSubmit = async values => {
    const now = DateTime.now()
    const sessionDate = DateTime.fromISO(values.sessionDate).set({
      hour: now.hour,
      minute: now.minute,
      second: now.second
    })

    if (isUploadingFile || (!selectedFileId && !fileId)) {
      toast({
        title: 'Upload incomplete',
        description: 'Please wait for the file to finish uploading',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
      return
    }

    const session = await createSessionFromUploadedAudio({
      data: {
        patientId: values.client.value,
        fileId: selectedFileId ? selectedFileId : fileId,
        noteType: values.noteType.id,
        sessionDate: sessionDate.toISO(),
        isTelehealth: values.setting === 'telehealth',
        assistEnabled: isPlusPlanEnabled,
        memoryEnabled: memoryEnabled,
        interventionSuggestionsEnabled: true,
        shouldGenerateTxPlan: true,
        treatmentApproaches: values.treatmentApproaches || []
      }
    })

    queryClient.invalidateQueries(endpoints.getPaywallValidation.getCacheId())

    history.push(
      `/patient/${session.patientId}/completed-session/${session.id}`
    )
  }

  const groupedNoteTypes = _.groupBy(progressNoteTypes, 'sessionType')

  const sessionTypeOptions = sessionTypes.filter(t =>
    Object.keys(groupedNoteTypes).includes(t.value)
  )

  const defaultNoteType = progressNoteTypes.find(
    t => t.id === user?.clinic?.organization?.default_note_type
  )

  const treatmentApproachOptions =
    settings?.preferenceOptions?.treatmentApproach?.options?.map(option => {
      return {
        id: option.value,
        display: option.label
      }
    }) || []

  useEffect(() => {
    if (addedClient) {
      const clientOption = {
        value: addedClient.patientId,
        label: `${addedClient.firstName} ${addedClient.lastName}`
      }
      formRef.current.setFieldValue('client', clientOption)
    }
  }, [addedClient, formRef])

  if (isDefaultListLoading || isProgressNoteTypesLoading) {
    return <Loading />
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          client: addedClient || null,
          sessionType: defaultNoteType.sessionType,
          noteType: defaultNoteType,
          sessionDate: DateTime.now().toFormat('yyyy-MM-dd'),
          selectedFile: selectedFile || null,
          setting: 'in-person',
          treatmentApproaches: []
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched
        }) => {
          setIsFormValid(!isValid || isUploadingFile || !values.client)
          setIsFormSubmitting(isSubmitting)
          return (
            <Form
              onSubmit={handleSubmit}
              style={{ width: '100%', height: '100%' }}
            >
              <Stack maxH="464px" spacing="small">
                <Field name="selectedFile">
                  {({ field }) => (
                    <FormControl
                      isInvalid={errors.selectedFile && touched.selectedFile}
                      onClick={() =>
                        document.getElementById('file-upload').click()
                      }
                    >
                      <Input
                        {...field}
                        type="file"
                        value=""
                        onChange={event => {
                          setFieldValue('selectedFile', event.target.files[0])
                          setSelectedFile(event.target.files[0])
                          handleFileChange(event)
                        }}
                        accept="audio/*,.wav,.aif,.aiff,.flac,.alac,.aac,.ogg,.mp3,.mp4,.m4a"
                        style={{ display: 'none' }}
                        id="file-upload"
                      />
                      <Box
                        width="100%"
                        backgroundColor="#F7F8FE"
                        p="small"
                        borderRadius="8px"
                        borderWidth="1px"
                        borderStyle="solid"
                        borderColor="pale_gray"
                        _hover={{
                          background: '#2D54E81A',
                          borderColor: '#2D54E8'
                        }}
                        cursor="pointer"
                        minHeight="105px"
                      >
                        {!field.value ? (
                          <Flex alignItems="center" flexDirection="column">
                            <Box>
                              <UploadV2 fill="black" width="45" />
                            </Box>
                            <FormLabel m="0">
                              <Text
                                color="primary"
                                _hover={{ cursor: 'pointer' }}
                              >
                                Choose Audio File
                              </Text>
                            </FormLabel>
                            <Text color="gray" fontSize="small">
                              Maximum size: 1GB
                            </Text>
                          </Flex>
                        ) : (
                          <>
                            <Flex justifyContent="space-between">
                              <Flex gap="16px">
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  width="32px"
                                >
                                  <GraphicEq />
                                </Flex>
                                <Box>
                                  <Text color="#282828">
                                    {field.value.name}
                                  </Text>
                                  <Text color="gray" fontSize="small">{`${(
                                    field.value.size / 1000000
                                  ).toFixed(2)} MB`}</Text>
                                </Box>
                              </Flex>
                              <Flex alignItems="flex-start">
                                {!isUploadingFile && (
                                  <CloseButton
                                    onClick={event => {
                                      event.stopPropagation()
                                      setFieldValue(field.name, null)
                                      setSelectedFileId(null)
                                      setSelectedFile(null)
                                    }}
                                  />
                                )}
                              </Flex>
                            </Flex>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                              gap="8px"
                            >
                              <Progress
                                size="sm"
                                color="primary"
                                borderRadius="4px"
                                value={
                                  selectedFile && !isUploadingFile
                                    ? 100
                                    : uploadedCount
                                }
                                flex="1 1 80%"
                                max={totalCount}
                              />
                              <Flex
                                flex="0 1 9%"
                                color="dark_gray"
                                justifyContent="center"
                                alignItems="center"
                                visibility={field.value ? 'visible' : 'hidden'}
                              >
                                {isUploadSuccess ||
                                (selectedFile && !isUploadingFile) ? (
                                  <RadioButtonLoadingComplete />
                                ) : (
                                  <Text color="dark_gray" fontSize="16px">
                                    {`${percentage}%`}
                                  </Text>
                                )}
                              </Flex>
                            </Flex>
                          </>
                        )}
                        {errors.selectedFile && touched.selectedFile && (
                          <Text color="red.500" fontSize="sm" mt={1}>
                            {errors.selectedFile}
                          </Text>
                        )}
                      </Box>
                    </FormControl>
                  )}
                </Field>
                <Field name="client">
                  {({ field }) => (
                    <FormControl isInvalid={errors.client && touched.client}>
                      <FormLabel fontWeight="bold">Client Name:</FormLabel>
                      <SearchSelect
                        {...field}
                        size="large"
                        maxHeight="170px"
                        onChange={value => setFieldValue('client', value)}
                        onSearch={setClientSearchInput}
                        placeholder="Select a client"
                        options={uniqBy(
                          [
                            ...searchResults.map(client => ({
                              value: client.id,
                              label: `${client.first_name} ${client.last_name}`
                            })),
                            ...defaultList.map(client => ({
                              value: client.id,
                              label: `${client.first_name} ${client.last_name}`
                            }))
                          ],
                          'value'
                        )}
                      />
                      {errors.client && touched.client && (
                        <Text color="red.500" fontSize="sm" mt={1}>
                          Client is required
                        </Text>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Button
                  color="primary"
                  onClick={onAddClientClick}
                  variant="link"
                  textDecoration="none"
                  style={{
                    justifyContent: 'flex-start'
                  }}
                  _focus={{ outline: 'none' }}
                >
                  + Add New Client
                </Button>
                <Field name="sessionType">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel fontWeight="bold">Session Type:</FormLabel>
                      <IconSelect
                        {...field}
                        onChange={value => {
                          setFieldValue(field.name, value)
                          setFieldValue('noteType', groupedNoteTypes[value][0])
                        }}
                        selectedValue={field.value}
                        options={sessionTypeOptions}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="noteType">
                  {({ field }) => (
                    <FormControl
                      isInvalid={errors.noteType && touched.noteType}
                    >
                      <FormLabel fontWeight="bold">Note Type:</FormLabel>
                      <NoteTypeDropdown
                        optionValue={field.value.id}
                        onChange={value => {
                          setFieldValue(
                            'noteType',
                            progressNoteTypes.find(note => note.id === value)
                          )
                        }}
                        progressNoteTypes={groupedNoteTypes[values.sessionType]}
                      />
                      {errors.noteType && touched.noteType && (
                        <Text color="red.500" fontSize="sm" mt={1}>
                          {errors.noteType}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </Field>

                <Field name="treatmentApproaches">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel fontWeight="bold" pointerEvents="none">
                        Treatment Approach Used:
                      </FormLabel>
                      <TreatmentApproachDropdown
                        selectedValues={field.value}
                        onChange={value => {
                          setFieldValue('treatmentApproaches', value)
                        }}
                        options={treatmentApproachOptions.filter(
                          o => o.id !== 'none'
                        )}
                      />
                    </FormControl>
                  )}
                </Field>

                <Field name="setting">
                  {({ field }) => (
                    <FormControl isInvalid={errors.setting && touched.setting}>
                      <FormLabel fontWeight="bold">Setting:</FormLabel>
                      <IconSelect
                        {...field}
                        onChange={value => setFieldValue(field.name, value)}
                        selectedValue={field.value}
                        options={[
                          {
                            value: 'telehealth',
                            title: 'Telehealth',
                            icon: <Online />
                          },
                          {
                            value: 'in-person',
                            title: 'In-Person',
                            icon: <InPersonFilled />
                          }
                        ]}
                      />
                      {errors.setting && touched.setting && (
                        <Text color="red.500" fontSize="sm" mt={1}>
                          {errors.setting}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="sessionDate" type="date">
                  {({ field }) => (
                    <FormControl
                      paddingBottom={'40px'}
                      isInvalid={errors.sessionDate && touched.sessionDate}
                    >
                      <FormLabel fontWeight="bold" htmlFor="date-input">
                        Session date:
                      </FormLabel>
                      <Input {...field} type="date" />
                      {errors.sessionDate && touched.sessionDate && (
                        <Text color="red.500" fontSize="sm" mt={1}>
                          {errors.sessionDate}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const ModalContent = ({
  isAddClientOpen,
  sessionUploadFormRef,
  addClientDialogRef,
  handleAddClientClick,
  handleEnrollClient,
  addedClient,
  addClientFormRef,
  sessionUploadActualFormRef,
  setIsFormValid,
  setIsFormSubmitting,
  selectedFile,
  setSelectedFile,
  selectedFileId,
  setSelectedFileId
}) => {
  const variants = {
    open: { opacity: 1, height: 'auto', scale: 1 },
    closed: { opacity: 0, height: '180px', scale: 1 }
  }

  return (
    <motion.div
      initial="closed"
      animate="open"
      exit="closed"
      variants={variants}
      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      {isAddClientOpen ? (
        <div ref={addClientDialogRef}>
          <AddClientDialog
            onSubmit={handleEnrollClient}
            setIsFormValid={setIsFormValid}
            setIsFormSubmitting={setIsFormSubmitting}
            formRef={addClientFormRef}
          />
        </div>
      ) : (
        <div ref={sessionUploadFormRef}>
          <SessionUploadForm
            onAddClientClick={handleAddClientClick}
            addedClient={addedClient}
            formRef={sessionUploadActualFormRef}
            setIsFormValid={setIsFormValid}
            setIsFormSubmitting={setIsFormSubmitting}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileId={selectedFileId}
            setSelectedFileId={setSelectedFileId}
          />
        </div>
      )}
    </motion.div>
  )
}

export const UploadModalV2 = ({ open, onClose }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { user } = useStoreState(state => state.auth)
  const [isAddClientOpen, setIsAddClientOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState('auto')
  const sessionUploadFormRef = useRef(null)
  const addClientDialogRef = useRef(null)
  const containerRef = useRef(null)
  const [addedClient, setAddedClient] = useState(null)
  const [isAddNewClientLoading, setIsAddNewClientLoading] = useState(false)
  const addClientFormRef = useRef(null)
  const sessionUploadActualFormRef = useRef(null)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileId, setSelectedFileId] = useState(null)

  useEffect(() => {
    if (open) {
      clinicianTracking.trackEvent('Viewed Upload Recording Modal')
    }
  }, [open])

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const newHeight = isAddClientOpen
          ? addClientDialogRef.current?.offsetHeight
          : sessionUploadFormRef.current?.offsetHeight
        setContentHeight(newHeight || 'auto')
      }
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [isAddClientOpen, open])

  const handleAddClientClick = () => setIsAddClientOpen(true)

  const { mutateAsync: enrollClient, isLoading: isEnrolling } = useMutation(
    endpoints.postSessionEnrollClient.request,
    {
      onError: () => {
        toast({
          title: 'Error creating client',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      },
      onSuccess: () => {
        const clientListQueryKey = getClinicControllerGetClientListQueryKey(
          user.clinic.id
        )
        queryClient.invalidateQueries({ queryKey: clientListQueryKey })
      }
    }
  )

  const handleEnrollClient = async ({
    firstName,
    lastName,
    dateOfBirth,
    pronoun
  }) => {
    setIsAddNewClientLoading(true)
    const newClient = await enrollClient({
      data: {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : null,
        pronoun: CLIENT_PRONOUNS[pronoun]
      }
    })
    setAddedClient({
      patientId: newClient.patientId,
      firstName: firstName,
      lastName: lastName
    })

    setIsAddClientOpen(false)
    toast({
      status: 'success',
      isClosable: true,
      duration: 3000,
      title: 'Client successfully created'
    })
    setIsAddNewClientLoading(false)
  }

  const handleFormSubmit = () => {
    if (isAddClientOpen) {
      addClientFormRef.current?.submitForm()
    } else {
      sessionUploadActualFormRef.current?.submitForm()
    }
  }

  const containerVariants = {
    open: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: 0.01
      }
    },
    closed: {
      opacity: 0.5,
      scale: 0.95,
      transition: {
        duration: 0.2,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: 0.01,
        staggerDirection: 1
      }
    }
  }

  return (
    <AnimatePresence>
      {open && (
        <DialogContainer
          isOpen={open}
          onClose={onClose}
          scrollBehavior="inside"
          as={motion.div}
          initial="closed"
          animate="open"
          exit="closed"
          variants={containerVariants}
          style={{ overflow: 'hidden' }}
          ref={containerRef}
        >
          <DialogHeader
            text={isAddClientOpen ? 'Add New Client' : 'Upload Recording'}
            onClose={isAddClientOpen ? undefined : onClose}
            onBack={
              isAddClientOpen ? () => setIsAddClientOpen(false) : undefined
            }
          />
          <DialogBody>
            <AnimatePresence exitBeforeEnter>
              <ModalContent
                key={isAddClientOpen ? 'addClient' : 'uploadForm'}
                isAddClientOpen={isAddClientOpen}
                sessionUploadFormRef={sessionUploadFormRef}
                addClientDialogRef={addClientDialogRef}
                handleAddClientClick={handleAddClientClick}
                handleEnrollClient={handleEnrollClient}
                addedClient={addedClient}
                addClientFormRef={addClientFormRef}
                sessionUploadActualFormRef={sessionUploadActualFormRef}
                setIsFormValid={setIsFormValid}
                setIsFormSubmitting={setIsFormSubmitting}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                selectedFileId={selectedFileId}
                setSelectedFileId={setSelectedFileId}
              />
            </AnimatePresence>
          </DialogBody>
          <DialogFooter>
            <Button
              size="lg"
              onClick={handleFormSubmit}
              isLoading={isFormSubmitting || isAddNewClientLoading}
              isFullWidth
              leftIcon={isAddClientOpen ? null : <Assist fill="white" />}
            >
              {isAddClientOpen ? 'Add Client' : 'Generate Note'}
            </Button>
          </DialogFooter>
        </DialogContainer>
      )}
    </AnimatePresence>
  )
}
