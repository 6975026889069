import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody, Button, Box, useMediaQuery } from '@chakra-ui/react'
import { CloseIcon } from '@blueprinthq/joy'

export const WelcomeVideoModal = ({
  handleVideoClose,
  isOpenPreviewVideo,
  onClosePreviewVideo
}: {
  handleVideoClose?: () => void
  isOpenPreviewVideo: boolean
  onClosePreviewVideo: () => void
}) => {

  const [isSmallHeight] = useMediaQuery('(max-height: 460px)')

  return (
    <Modal
      isOpen={isOpenPreviewVideo}
      onClose={onClosePreviewVideo}
      size="full"
      // isCentered
    >
      <ModalOverlay />
      <ModalContent width="100vw" minHeight="100vh" margin="0">
        <ModalBody padding="0" width="100%" height="100%" position="relative">
          <Button
            variant="ghost"
            color="primary"
            position="absolute"
            top="10px"
            right="10px"
            rightIcon={<CloseIcon fill="primary" />}
            onClick={handleVideoClose ? () => handleVideoClose() : () => onClosePreviewVideo()}
            zIndex="1"
            _focus={{
              boxShadow: 'none'
            }}
            bg={{
              base: 'transparent',
              md: 'rgba(255, 255, 255, 0.5)'
            }}
          >
            Close
          </Button>
          <Box
            width="100%"
            maxWidth="1280px"
            margin="0 auto"
            padding={{
              base: '8px',
              md: '16px'
            }}
            paddingTop={{
              base: '250px',
              sm: isSmallHeight ? '8px' : '220px',
              md: '96px'
            }}
          >
            <Box
              position="relative"
              width="100%"
              paddingTop="56.25%"
              borderRadius="6px"
              overflow="hidden"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
              >
                <video
                  preload="auto"
                  controls
                  disablePictureInPicture
                  controlsList="noremoteplayback nodownload"
                  autoPlay
                  playsInline
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    display: 'block',
                    borderRadius: '6px'
                  }}
                >
                  <source
                    src="https://res.cloudinary.com/hellojoy/video/upload/v1742925423/onboarding/Blueprint-Onboarding_with_Vivian-compile_compressed.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
