import React from 'react'
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Stack,
  HStack,
  Container,
  Flex,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useOrganizationControllerGetCustomProgressNoteTemplates } from '~/clinician-api'
import { Loading } from '@components'
import { useExperienceManager } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Check } from '@components/icons'
import { endpoints } from '@api'

const CircleNumber = ({ number }: { number: number }) => (
  <Flex
    borderRadius="16px"
    border="1px solid"
    borderColor="pale_gray"
    w="24px"
    h="24px"
    alignItems="center"
    justifyContent="center"
    bg="rgba(249, 249, 249, 1)"
  >
    <Text fontSize="12px" lineHeight="16px" color="primary" fontWeight="bold">
      {number}
    </Text>
  </Flex>
)

interface StepDescriptionProps {
  number: number
  description: string
}

const StepDescription = ({ number, description }: StepDescriptionProps) => (
  <HStack flex="1" alignItems="flex-start" gap="12px" spacing="0">
    <CircleNumber number={number} />
    <Text color="dark_gray" w="184px" fontSize="12px">
      {description}
    </Text>
  </HStack>
)

const JoinWaitList = ({ feature = 'progress_note_templates' }) => {
  const queryClient = useQueryClient()

  const { data: featureWaitlists, isLoading } = useQuery(
    endpoints.getFeatureWaitlist.getCacheId(),
    endpoints.getFeatureWaitlist.request
  )

  const { mutateAsync: joinWaitlist, isLoading: isJoining } = useMutation(
    endpoints.postJoinWaitlist.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(endpoints.getFeatureWaitlist.getCacheId())
      }
    }
  )
  const onJoinWaitlist = async () => {
    await joinWaitlist({ feature })
  }

  if (isLoading) return null

  const waitListRequested = (featureWaitlists as string[]).includes(feature)

  if (waitListRequested) {
    return (
      <Stack spacing="0px" gap="16px" alignItems="center">
        <HStack
          h="40px"
          width="226px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="24px"
          justifyContent="center"
          px="24px"
        >
          <Check fill="rgba(45, 84, 232, 1)" />
          <Text width="226px">You're on the waitlist!</Text>
        </HStack>
        <Text color="dark_gray">We'll let you know when a spot opens up.</Text>
      </Stack>
    )
  } else {
    return (
      <Button
        w="150px"
        h="40px"
        fontWeight="430"
        m={0}
        variant="outline"
        bg="primary"
        color="white"
        isLoading={isJoining}
        onClick={onJoinWaitlist}
        _focus={{
          outline: 'none'
        }}
      >
        Join Waitlist
      </Button>
    )
  }
}

const Waitlist = () => {
  return (
    <>
      <Flex
        flex="1"
        mb="medium"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Heading mb="xxsmall">Templates</Heading>
          <Text>Create custom note templates for your organization.</Text>
        </Box>
      </Flex>
      <Stack
        borderRadius="8px"
        border="1px solid"
        borderColor="pale_gray"
        bg="white"
        px="48px"
        py="16px"
        spacing="0px"
        gap="40px"
      >
        <VStack align="center" spacing="0px">
          <Image
            src="https://res.cloudinary.com/hellojoy/image/upload/v1739980834/lztmcnda5yeng7pnalmr.png"
            alt="Progress Note Templates"
          />
          <Stack spacing="0px" gap="40px" alignItems="center">
            <Text color="dark_gray">
              Easily convert a sample note into a template in seconds.
            </Text>
            <JoinWaitList />
            <Flex />
          </Stack>
        </VStack>
      </Stack>
    </>
  )
}

interface ProgressNoteTemplate {
  id: string
  name: string
  organizationId: string
  isLoading: boolean
  createdAt: string
  updatedAt: string
}

const steps = [
  'Select the sample note you wish to convert. The more thorough sample the better for our AI.',
  'Our template builder will convert your PDF into a note template in seconds.',
  'Your new note template can be modified and used immediately to generate your next note.'
]

export const ProgressNoteTemplates = () => {
  const {
    organizationId,
    customNoteTemplatesFullRelease
  } = useExperienceManager()
  const {
    data,
    isLoading
  } = useOrganizationControllerGetCustomProgressNoteTemplates(organizationId, {
    query: {
      refetchOnMount: true,
      cacheTime: 0
    }
  }) as { data: ProgressNoteTemplate[] | undefined; isLoading: boolean }

  const history = useHistory()

  if (!customNoteTemplatesFullRelease) {
    return <Waitlist />
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Flex
        flex="1"
        mb="medium"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Heading mb="xxsmall">Templates</Heading>
          <Text>Create custom note templates for your organization</Text>
        </Box>
        <Button
          m="0px"
          colorScheme="primary"
          leftIcon={<>+</>}
          width="194px"
          as={Link}
          to="/settings/templates/new"
        >
          Create Template
        </Button>
      </Flex>

      {data && data?.length > 0 ? (
        <TableContainer
          borderRadius="8px"
          border="1px solid"
          borderColor="pale_gray"
          bg="white"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Template Name</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map(template => (
                <Tr
                  key={template?.id}
                  onClick={() =>
                    history.push(`/settings/templates/${template?.id}/edit`)
                  }
                  cursor="pointer"
                  _hover={{
                    backgroundColor: 'rgb(249, 249, 249)'
                  }}
                >
                  <Td>{template?.name}</Td>
                  <Td>{template?.isLoading ? 'Processing...' : 'Ready'}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Stack
          borderRadius="8px"
          border="1px solid"
          borderColor="pale_gray"
          bg="white"
          px="48px"
          py="16px"
          spacing="0px"
          gap="40px"
        >
          <VStack align="center" spacing="0px">
            <Image
              src="https://res.cloudinary.com/hellojoy/image/upload/v1739980834/lztmcnda5yeng7pnalmr.png"
              alt="Progress Note Templates"
            />
            <Text color="dark_gray">
              Easily convert a sample note into a template in seconds.
            </Text>
            <Box h="16px" />
            <Button
              as={Link}
              to="/settings/templates/new"
              colorScheme="primary"
            >
              Choose PDF
            </Button>
          </VStack>

          <Stack gap="16px">
            <Text fontWeight="bold" textAlign="center" lineHeight="22px">
              How it works
            </Text>
            <HStack justifyContent="center" spacing="0px">
              {steps.map((description, index) => (
                <StepDescription
                  key={index}
                  number={index + 1}
                  description={description}
                />
              ))}
            </HStack>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}

export default ProgressNoteTemplates
