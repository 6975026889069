import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Box, Text, VStack, Badge } from '@chakra-ui/react'
import _ from 'lodash'

interface TranscriptPlayerProps {
  audioRef: HTMLAudioElement
  onComplete?: () => void
  transcript: DeepgramWord[]
  isFastForwarding?: boolean
}

interface WordData {
  word: string
  time: number
  endTime: number
  index: number
  isRecent?: boolean
  isCurrent?: boolean
  speaker?: number
  punctuated_word?: string
}

interface DeepgramWord {
  word: string
  start: number
  end: number
  confidence: number
  speaker: number
  speaker_confidence: number
  punctuated_word: string
}

export const DemoTranscript: React.FC<TranscriptPlayerProps> = ({
  audioRef,
  onComplete,
  transcript,
  isFastForwarding = false
}) => {
  const [words, setWords] = useState<WordData[]>([])
  const [currentTime, setCurrentTime] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const throttledSetCurrentTimeRef = useRef<any>(null)
  const fastForwardIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const lastRealTimeRef = useRef<number>(0)
  const syntheticTimeRef = useRef<number>(0)

  // Initialize with data
  useEffect(() => {
    // Map Deepgram words to our internal format
    const wordData: WordData[] = transcript.map((word, index) => ({
      word: word.word,
      time: word.start,
      endTime: word.end,
      index: index,
      speaker: word.speaker,
      punctuated_word: word.punctuated_word
    }))

    setWords(wordData)

    // Initialize the container with some padding
    if (containerRef.current) {
      containerRef.current.style.paddingTop = '16px'
      containerRef.current.scrollTop = 0
    }
  }, [])

  // Auto-highlight the current word being spoken
  const getCurrentWord = useMemo(() => {
    // Find the word that's currently being spoken (between start and end time)
    return words.find(
      word => currentTime >= word.time && currentTime <= word.endTime
    )
  }, [words, currentTime])

  // Determine which words should be visible based on current time
  const visibleWords = useMemo(() => {
    const visible = words.filter(word => word.time <= currentTime)

    // Sort by original index to maintain word order
    const sortedVisible = [...visible].sort((a, b) => a.index - b.index)

    // Mark recent and current words for styling
    return sortedVisible.map(word => ({
      ...word,
      isRecent: currentTime - word.time < 0.5, // Words appear faded for 0.5s
      isCurrent: getCurrentWord?.index === word.index // Currently spoken word
    }))
  }, [words, currentTime, getCurrentWord])

  // Handle fast forwarding with synthetic time updates
  useEffect(() => {
    // Clear any existing interval
    if (fastForwardIntervalRef.current) {
      clearInterval(fastForwardIntervalRef.current)
      fastForwardIntervalRef.current = null
    }

    if (isFastForwarding && audioRef) {
      // Store the last real time when entering fast forward mode
      lastRealTimeRef.current = audioRef.currentTime
      syntheticTimeRef.current = audioRef.currentTime

      // Update synthetic time every 250ms
      fastForwardIntervalRef.current = setInterval(() => {
        syntheticTimeRef.current += 30

        // Update the current time with our synthetic time
        setCurrentTime(syntheticTimeRef.current)

        // If we have words, check if we've reached the end of the transcript
        if (words.length > 0) {
          const lastWord = words[words.length - 1]
          if (syntheticTimeRef.current > lastWord.endTime) {
            // We've reached the end of the transcript, clear the interval
            if (fastForwardIntervalRef.current) {
              clearInterval(fastForwardIntervalRef.current)
              fastForwardIntervalRef.current = null
            }
          }
        }
      }, 250)
    }

    return () => {
      // Clean up interval on unmount or when isFastForwarding changes
      if (fastForwardIntervalRef.current) {
        clearInterval(fastForwardIntervalRef.current)
        fastForwardIntervalRef.current = null
      }
    }
  }, [isFastForwarding, audioRef, words])

  // Create throttled update function for normal playback
  useEffect(() => {
    throttledSetCurrentTimeRef.current = _.throttle((time: number) => {
      // Only update with real time if we're not fast forwarding
      if (!isFastForwarding) {
        setCurrentTime(time)
        lastRealTimeRef.current = time
        syntheticTimeRef.current = time
      }
    }, 50)

    return () => {
      if (throttledSetCurrentTimeRef.current) {
        throttledSetCurrentTimeRef.current.cancel()
      }
    }
  }, [isFastForwarding])

  // Sync with audio element during normal playback
  useEffect(() => {
    if (!audioRef) return

    // Update current time based on audio element's time
    const updateTimeFromAudio = () => {
      if (throttledSetCurrentTimeRef.current && !isFastForwarding) {
        throttledSetCurrentTimeRef.current(audioRef.currentTime)
      }
    }

    // Set up event listeners for the audio element
    audioRef.addEventListener('timeupdate', updateTimeFromAudio)

    // Handle complete
    audioRef.addEventListener('ended', () => {
      if (onComplete) onComplete()
    })

    return () => {
      // Clean up event listeners
      audioRef.removeEventListener('timeupdate', updateTimeFromAudio)
      audioRef.removeEventListener('ended', () => {
        if (onComplete) onComplete()
      })
    }
  }, [audioRef, onComplete, isFastForwarding])

  // Auto-scroll to keep the current word visible
  useEffect(() => {
    if (containerRef.current && getCurrentWord) {
      // Find the element for the current word
      const currentWordElement = containerRef.current.querySelector(
        `[data-word-index="${getCurrentWord.index}"]`
      )

      if (currentWordElement) {
        // Calculate if the element is in view
        const containerRect = containerRef.current.getBoundingClientRect()
        const elementRect = currentWordElement.getBoundingClientRect()

        // If the element is below or above the visible area, scroll to it
        if (
          elementRect.bottom > containerRect.bottom ||
          elementRect.top < containerRect.top
        ) {
          // For the initial content that doesn't fill the container, align to the top
          const isInitialContent =
            containerRef.current.scrollHeight <=
            containerRef.current.clientHeight

          currentWordElement.scrollIntoView({
            behavior: isFastForwarding ? 'auto' : 'smooth',
            block: isInitialContent ? 'start' : 'center'
          })
        }
      }
    }
  }, [getCurrentWord, isFastForwarding])

  const getSpeakerLabel = (speakerId: number | undefined | null) => {
    if (speakerId === null || speakerId === undefined) return 'Unknown'
    return speakerId === 0 ? 'Therapist' : 'Client'
  }

  // Group visible words by speaker
  const wordsByGroup = useMemo(() => {
    if (visibleWords.length === 0) return []

    const groups: { speaker: number | undefined; words: WordData[] }[] = []
    let currentGroup: {
      speaker: number | undefined
      words: WordData[]
    } | null = null

    visibleWords.forEach((word, index) => {
      if (!currentGroup) {
        currentGroup = { speaker: word.speaker, words: [word] }
      } else if (word.speaker === currentGroup.speaker) {
        currentGroup.words.push(word)
      } else {
        groups.push({ ...currentGroup })
        currentGroup = { speaker: word.speaker, words: [word] }
      }

      // Make sure to add the last group
      if (index === visibleWords.length - 1 && currentGroup) {
        groups.push({ ...currentGroup })
      }
    })

    return groups
  }, [visibleWords])

  return (
    <>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height={{
          base: '50px',
          md: '80px',
        }}
        pointerEvents="none"
        zIndex={1}
        background="linear-gradient(to bottom, 
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0.85),
            rgba(255, 255, 255, 0)
          )"
        borderTopRadius="8px"
      />
      <Box
        position="relative"
        height={{
          base: '150px',
          md: '250px',
        }}
        display="flex"
        flexDirection="column"
      >
        <Box
          ref={containerRef}
          p={'small'}
          pt="large"
          flex="1"
          overflowY="auto"
          position="relative"
          pointerEvents="none"
          sx={{
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }}
        >
          <VStack spacing={3} align="flex-start">
            {wordsByGroup.map((group, groupIndex) => (
              <Box 
                key={groupIndex} 
                width="100%" 
                mb={1} 
                pt={{
                  base: 0,
                  md: groupIndex === 0 ? '60px' : '0'
                }}
              >
                <Badge
                  backgroundColor={'transparent'}
                  textColor={group.speaker === 0 ? '#757575' : '#2D54E8'}
                  mb={0}
                >
                  {getSpeakerLabel(group.speaker)}
                </Badge>
                <Text fontSize="18px" lineHeight="1.5" my={2}>
                  {group.words.map((word, wordIndex) => (
                    <Box
                      key={`${groupIndex}-${wordIndex}`}
                      as="span"
                      display="inline-block"
                      opacity={word.isRecent ? 0.55 : 1}
                      transition="opacity 0.2s ease-in-out"
                      mx="1"
                      color={group.speaker === 0 ? '#757575' : '#2D54E8'}
                      pointerEvents="none"
                      data-word-index={word.index}
                    >
                      {word.punctuated_word || word.word}
                    </Box>
                  ))}
                </Text>
              </Box>
            ))}
          </VStack>
        </Box>
      </Box>
    </>
  )
}
