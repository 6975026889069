import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Text, Button } from '@chakra-ui/react'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'
import { FadeIn } from './animations'
import { VideoThumbnail } from './video-thumbnail'

export const VideoWalkthrough = ({
  onPlayVideo
}: {
  onPlayVideo: () => void
}) => {
  const history = useHistory()
  const videoUrl =
    'https://res.cloudinary.com/hellojoy/video/upload/v1742925423/onboarding/Blueprint-Onboarding_with_Vivian-compile_compressed.mp4'

  useEffect(() => {
    trackEvent('Onboarding Video Walkthrough Viewed', {
      version: 3
    })
  }, [])

  return (
    <Step mt={{ base: '60px', md: '24px' }} pb={4}>
      <FadeIn maxWidth="360px" margin="0 auto">
        {/* Show video thumbnail on mobile */}
        <Box display={{ base: 'block', md: 'none' }} mb={4}>
          <VideoThumbnail
            videoUrl={videoUrl}
            onPlay={onPlayVideo}
            aspectRatio="16/9"
            timestamp={12}
          />
        </Box>
        <Box textAlign="left" mb="small">
          <Text fontSize="24px" style={{ lineHeight: '44px' }}>
            {`Let's get you `}
            <Text as="span" color="primary" fontSize="24px">
              session ready!
            </Text>
          </Text>
        </Box>
        <Box flex={{ base: 1, md: 0 }} mb={{ base: '34px' }}>
          <Text fontSize="16px" fontWeight="normal">
            Take a minute to get session-ready with this quick Blueprint
            overview.
          </Text>
        </Box>
        <Box>
          <Button
            onClick={onPlayVideo}
            isFullWidth
            m={0}
            mb="small"
            type="button"
            size="lg"
          >
            Watch
          </Button>
          <Button
            onClick={() =>
              history.push('/start-session/new-client?context=onboarding')
            }
            isFullWidth
            m={0}
            variant="ghost"
            size="lg"
            color="#2D54E8"
            type="button"
          >
            Skip video
          </Button>
        </Box>
      </FadeIn>
    </Step>
  )
}
