/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type AssignedCheckInDtoDaysOfWeekItem = typeof AssignedCheckInDtoDaysOfWeekItem[keyof typeof AssignedCheckInDtoDaysOfWeekItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignedCheckInDtoDaysOfWeekItem = {
  Sun: 'Sun',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
} as const;
