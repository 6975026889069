import React from 'react'
import { Text, Button, Flex, VStack } from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'

interface DeleteMemoryConfirmationProps {
  onClose: () => void
  onDelete: () => Promise<void>
  isLoading: boolean
}

const DeleteMemoryConfirmation = ({
  onClose,
  onDelete,
  isLoading
}: DeleteMemoryConfirmationProps) => (
  <DialogContainer isOpen={true} onClose={onClose}>
    <DialogHeader 
      hideBorder
      textAlign="left"
      text="Delete memory?"
      onClose={onClose}
      pt="24px"
      pr="24px"
      pb="0px"
      pl="24px"
    />
    <DialogBody>
      <VStack spacing={6}>
        <Text>
          Are you sure you want to delete this memory? This action cannot be
          undone.
        </Text>

        <Flex justifyContent="space-between" gap={4} w="100%">
          <Button variant="outline" flex={1} onClick={onClose}>
            Cancel
          </Button>
          <Button
            bg="error"
            color="white"
            flex={1}
            onClick={onDelete}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </Flex>
      </VStack>
    </DialogBody>
  </DialogContainer>
)

export default DeleteMemoryConfirmation
