import React, { useState } from 'react'
import {
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  HStack,
  VStack,
  Divider
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { AssignedCheckInDto } from '~/clinician-api/models'

import {
  usePatientControllerGetAssignedCheckIns,
  usePatientControllerUnassignCheckIn
} from '~/clinician-api'
import { AssignWorksheetsModal } from '../../shared/assist/worksheets/assign-worksheets-modal'

type WorksheetItemProps = {
  worksheet: AssignedCheckInDto;
  onAdjustFrequency: (worksheet: AssignedCheckInDto) => void;
  onUnassign: (checkInId: string, patientId: string) => void;
  patientId: string;
}


const WorksheetItem = ({ worksheet, onAdjustFrequency, onUnassign, patientId }: WorksheetItemProps) => {
  const frequencyText = worksheet.daysOfWeek && worksheet.timeOfDay
    ? `Weekly on ${worksheet.daysOfWeek.join(', ')} at ${DateTime.fromISO(worksheet.timeOfDay).toLocaleString(DateTime.TIME_SIMPLE)}`
    : worksheet.frequencyType === 'one_time' ? 'One-time' : '--';

  return (
    <HStack
    key={worksheet.checkInId}
    p="small"
    alignItems="center"
    justify="space-between"
    w="100%"
  >
    <Box maxW="215px" w="100%">
      <Text>{worksheet.title}</Text>
    </Box>
    <HStack gap="medium">
      <Text fontSize="small" color="gray">
      {frequencyText}
      </Text>
      <Menu>
           <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<MoreHorizIcon />}
              variant="ghost"
              size="sm"
              _focus={{
                outline: 'none'
              }}
            />
            <MenuList>
              <MenuItem
                onClick={() => onAdjustFrequency(worksheet)}
              >
                Adjust Frequency
              </MenuItem>
              <MenuItem
                color="severe"
                onClick={() => onUnassign(worksheet.checkInId, patientId)}
              >
                Unassign
              </MenuItem>
            </MenuList>
          </Menu>
    </HStack>
  </HStack>
  );
};

export const AssignedWorksheets = ({ patient }: { patient: any }) => {
  const [
    selectedAssignedCheckIn,
    setSelectedAssignedCheckIn
  ] = useState<AssignedCheckInDto | null>(null)

  const { data = [], refetch } = usePatientControllerGetAssignedCheckIns(
    patient.id
  )
  const { mutate } = usePatientControllerUnassignCheckIn({
    mutation: {
      onSuccess() {
        refetch()
      }
    }
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleAdjustFrequency = (worksheet: AssignedCheckInDto) => {
    setSelectedAssignedCheckIn(worksheet);
    onOpen();
  };

  const handleUnassign = (checkInId: string, patientId: string) => {
    mutate({
      checkInId,
      patientId
    });
  };

  return (
    <>
    <Box border="1px solid" borderColor="pale_gray" rounded="md">
    {!data.length ? (
          <Text p="small">No Worksheets assigned.</Text>
        ) : (

          <VStack
          flex={1}
          alignItems="flex-start"
          divider={
            <Divider
              color="pale_gray"
              mt="0px !important"
              mb="0px !important"
            />
          }
        >
            {data.map(worksheet => (
              <WorksheetItem
                key={worksheet.checkInId}
                worksheet={worksheet}
                onAdjustFrequency={handleAdjustFrequency}
                onUnassign={handleUnassign}
                patientId={patient.id}
              />
            ))}
          </VStack>

        )}
      </Box>
      {selectedAssignedCheckIn && (
        <AssignWorksheetsModal
          client={patient}
          isOpen={isOpen}
          onClose={onClose}
          assignedCheckIn={selectedAssignedCheckIn}
        />
      )}
    </>
  )
}
