/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  ApiExceptionDto,
  AssessmentControllerV1GetAssessmentsParams,
  AssessmentDto,
  AssignedAssessmentDto,
  BirthdayVerificationStatusDto,
  CheckInDtoV2,
  CheckInScoreBodyV1,
  CheckInsControllerV1GetCheckInsParams,
  ClinicianDto,
  ConsentInfoResponse,
  CreatePartnerClinicianBody,
  ForgotPasswordConfirmBody,
  ForgotPasswordRequestCodeBody,
  KioskClinicDto,
  KioskIdentifyPatientDto,
  KioskIdentifyPatientFromAttributesBodyDto,
  KioskPatientDto,
  PatientInviteLookupBody,
  PatientInviteLookupResponseV1,
  PatientInviteSetupAccountBody,
  PatientInviteSetupAccountResponse,
  PatientInviteVerifyBody,
  PatientInviteVerifyResponse,
  SafetyNetItemDto,
  SubmitAssessmentsBodyDto,
  UnsubscribeUserResponseDto,
  UserControllerV1GetBirthdayVerificationStatusParams,
  UserDto,
  VerifyBirthdayDto,
  VerifyBirthdayResponseDto
} from './models'
import { hellojoyApiInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';



export const checkInsControllerV1GetCheckIns = (
    checkInId: string,
    params?: CheckInsControllerV1GetCheckInsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2>(
      {url: `/v1/public-api/check-ins/${checkInId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getCheckInsControllerV1GetCheckInsQueryKey = (checkInId: string,
    params?: CheckInsControllerV1GetCheckInsParams,) => {
    return [`/v1/public-api/check-ins/${checkInId}`, ...(params ? [params]: [])] as const;
    }

    
export const getCheckInsControllerV1GetCheckInsQueryOptions = <TData = Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError = ErrorType<unknown>>(checkInId: string,
    params?: CheckInsControllerV1GetCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckInsControllerV1GetCheckInsQueryKey(checkInId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>> = ({ signal }) => checkInsControllerV1GetCheckIns(checkInId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(checkInId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckInsControllerV1GetCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>>
export type CheckInsControllerV1GetCheckInsQueryError = ErrorType<unknown>

export const useCheckInsControllerV1GetCheckIns = <TData = Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError = ErrorType<unknown>>(
 checkInId: string,
    params?: CheckInsControllerV1GetCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkInsControllerV1GetCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCheckInsControllerV1GetCheckInsQueryOptions(checkInId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerV1GetUser = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<UserDto>(
      {url: `/v1/public-api/user/${userId}`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerV1GetUserQueryKey = (userId: string,) => {
    return [`/v1/public-api/user/${userId}`] as const;
    }

    
export const getUserControllerV1GetUserQueryOptions = <TData = Awaited<ReturnType<typeof userControllerV1GetUser>>, TError = ErrorType<ApiExceptionDto>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetUser>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerV1GetUserQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetUser>>> = ({ signal }) => userControllerV1GetUser(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetUser>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerV1GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetUser>>>
export type UserControllerV1GetUserQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetUser = <TData = Awaited<ReturnType<typeof userControllerV1GetUser>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerV1GetUserQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerV1GetBirthdayVerificationStatus = (
    userId: string,
    params: UserControllerV1GetBirthdayVerificationStatusParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<BirthdayVerificationStatusDto>(
      {url: `/v1/public-api/user/${userId}/birthday-verification-status`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getUserControllerV1GetBirthdayVerificationStatusQueryKey = (userId: string,
    params: UserControllerV1GetBirthdayVerificationStatusParams,) => {
    return [`/v1/public-api/user/${userId}/birthday-verification-status`, ...(params ? [params]: [])] as const;
    }

    
export const getUserControllerV1GetBirthdayVerificationStatusQueryOptions = <TData = Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError = ErrorType<ApiExceptionDto>>(userId: string,
    params: UserControllerV1GetBirthdayVerificationStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerV1GetBirthdayVerificationStatusQueryKey(userId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>> = ({ signal }) => userControllerV1GetBirthdayVerificationStatus(userId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerV1GetBirthdayVerificationStatusQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>>
export type UserControllerV1GetBirthdayVerificationStatusQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetBirthdayVerificationStatus = <TData = Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string,
    params: UserControllerV1GetBirthdayVerificationStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetBirthdayVerificationStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerV1GetBirthdayVerificationStatusQueryOptions(userId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerV1PostUnsubscribeUser = (
    userId: string,
 ) => {
      
      
      return hellojoyApiInstance<UnsubscribeUserResponseDto>(
      {url: `/v1/public-api/user/${userId}/unsubscribe`, method: 'POST'
    },
      );
    }
  


export const getUserControllerV1PostUnsubscribeUserMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>, TError,{userId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerV1PostUnsubscribeUser(userId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserControllerV1PostUnsubscribeUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>>
    
    export type UserControllerV1PostUnsubscribeUserMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostUnsubscribeUser = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>, TError,{userId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof userControllerV1PostUnsubscribeUser>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getUserControllerV1PostUnsubscribeUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerV1PostSubscribeUser = (
    userId: string,
 ) => {
      
      
      return hellojoyApiInstance<UnsubscribeUserResponseDto>(
      {url: `/v1/public-api/user/${userId}/subscribe`, method: 'POST'
    },
      );
    }
  


export const getUserControllerV1PostSubscribeUserMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>, TError,{userId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerV1PostSubscribeUser(userId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserControllerV1PostSubscribeUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>>
    
    export type UserControllerV1PostSubscribeUserMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostSubscribeUser = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>, TError,{userId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof userControllerV1PostSubscribeUser>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getUserControllerV1PostSubscribeUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerV1PostBirthdayVerification = (
    userId: string,
    verifyBirthdayDto: VerifyBirthdayDto,
 ) => {
      
      
      return hellojoyApiInstance<VerifyBirthdayResponseDto>(
      {url: `/v1/public-api/user/${userId}/birthday-verification`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: verifyBirthdayDto
    },
      );
    }
  


export const getUserControllerV1PostBirthdayVerificationMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>, TError,{userId: string;data: VerifyBirthdayDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>, TError,{userId: string;data: VerifyBirthdayDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>, {userId: string;data: VerifyBirthdayDto}> = (props) => {
          const {userId,data} = props ?? {};

          return  userControllerV1PostBirthdayVerification(userId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserControllerV1PostBirthdayVerificationMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>>
    export type UserControllerV1PostBirthdayVerificationMutationBody = VerifyBirthdayDto
    export type UserControllerV1PostBirthdayVerificationMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostBirthdayVerification = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>, TError,{userId: string;data: VerifyBirthdayDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof userControllerV1PostBirthdayVerification>>,
        TError,
        {userId: string;data: VerifyBirthdayDto},
        TContext
      > => {

      const mutationOptions = getUserControllerV1PostBirthdayVerificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerV1GetAssignedAssessments = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<AssignedAssessmentDto[]>(
      {url: `/v1/public-api/user/${userId}/assigned-assessments`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerV1GetAssignedAssessmentsQueryKey = (userId: string,) => {
    return [`/v1/public-api/user/${userId}/assigned-assessments`] as const;
    }

    
export const getUserControllerV1GetAssignedAssessmentsQueryOptions = <TData = Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError = ErrorType<unknown>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerV1GetAssignedAssessmentsQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>> = ({ signal }) => userControllerV1GetAssignedAssessments(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerV1GetAssignedAssessmentsQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>>
export type UserControllerV1GetAssignedAssessmentsQueryError = ErrorType<unknown>

export const useUserControllerV1GetAssignedAssessments = <TData = Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError = ErrorType<unknown>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedAssessments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerV1GetAssignedAssessmentsQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerV1PostSubmitAssessments = (
    userId: string,
    submitAssessmentsBodyDto: SubmitAssessmentsBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/user/${userId}/submit-assessments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: submitAssessmentsBodyDto
    },
      );
    }
  


export const getUserControllerV1PostSubmitAssessmentsMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>, TError,{userId: string;data: SubmitAssessmentsBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>, TError,{userId: string;data: SubmitAssessmentsBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>, {userId: string;data: SubmitAssessmentsBodyDto}> = (props) => {
          const {userId,data} = props ?? {};

          return  userControllerV1PostSubmitAssessments(userId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserControllerV1PostSubmitAssessmentsMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>>
    export type UserControllerV1PostSubmitAssessmentsMutationBody = SubmitAssessmentsBodyDto
    export type UserControllerV1PostSubmitAssessmentsMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostSubmitAssessments = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>, TError,{userId: string;data: SubmitAssessmentsBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof userControllerV1PostSubmitAssessments>>,
        TError,
        {userId: string;data: SubmitAssessmentsBodyDto},
        TContext
      > => {

      const mutationOptions = getUserControllerV1PostSubmitAssessmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerV1GetAssignedCheckIns = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/public-api/user/${userId}/assigned-checkins`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerV1GetAssignedCheckInsQueryKey = (userId: string,) => {
    return [`/v1/public-api/user/${userId}/assigned-checkins`] as const;
    }

    
export const getUserControllerV1GetAssignedCheckInsQueryOptions = <TData = Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError = ErrorType<unknown>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerV1GetAssignedCheckInsQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>> = ({ signal }) => userControllerV1GetAssignedCheckIns(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerV1GetAssignedCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>>
export type UserControllerV1GetAssignedCheckInsQueryError = ErrorType<unknown>

export const useUserControllerV1GetAssignedCheckIns = <TData = Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError = ErrorType<unknown>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetAssignedCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerV1GetAssignedCheckInsQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerV1PostSubmitCheckIn = (
    userId: string,
    checkInScoreBodyV1: CheckInScoreBodyV1[],
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/user/${userId}/submit-checkins`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: checkInScoreBodyV1
    },
      );
    }
  


export const getUserControllerV1PostSubmitCheckInMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>, TError,{userId: string;data: CheckInScoreBodyV1[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>, TError,{userId: string;data: CheckInScoreBodyV1[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>, {userId: string;data: CheckInScoreBodyV1[]}> = (props) => {
          const {userId,data} = props ?? {};

          return  userControllerV1PostSubmitCheckIn(userId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserControllerV1PostSubmitCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>>
    export type UserControllerV1PostSubmitCheckInMutationBody = CheckInScoreBodyV1[]
    export type UserControllerV1PostSubmitCheckInMutationError = ErrorType<ApiExceptionDto>

    export const useUserControllerV1PostSubmitCheckIn = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>, TError,{userId: string;data: CheckInScoreBodyV1[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof userControllerV1PostSubmitCheckIn>>,
        TError,
        {userId: string;data: CheckInScoreBodyV1[]},
        TContext
      > => {

      const mutationOptions = getUserControllerV1PostSubmitCheckInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerV1GetConsentInfo = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<ConsentInfoResponse>(
      {url: `/v1/public-api/user/${userId}/consent-info`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerV1GetConsentInfoQueryKey = (userId: string,) => {
    return [`/v1/public-api/user/${userId}/consent-info`] as const;
    }

    
export const getUserControllerV1GetConsentInfoQueryOptions = <TData = Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError = ErrorType<ApiExceptionDto>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerV1GetConsentInfoQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>> = ({ signal }) => userControllerV1GetConsentInfo(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerV1GetConsentInfoQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>>
export type UserControllerV1GetConsentInfoQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetConsentInfo = <TData = Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetConsentInfo>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerV1GetConsentInfoQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerV1AcceptConsent = (
    userId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/user/${userId}/accept-consent`, method: 'POST'
    },
      );
    }
  


export const getUserControllerV1AcceptConsentMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>, TError,{userId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerV1AcceptConsent(userId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserControllerV1AcceptConsentMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>>
    
    export type UserControllerV1AcceptConsentMutationError = ErrorType<unknown>

    export const useUserControllerV1AcceptConsent = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerV1AcceptConsent>>, TError,{userId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof userControllerV1AcceptConsent>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getUserControllerV1AcceptConsentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerV1GetSafetyNet = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SafetyNetItemDto[]>(
      {url: `/v1/public-api/user/${userId}/safety-net`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerV1GetSafetyNetQueryKey = (userId: string,) => {
    return [`/v1/public-api/user/${userId}/safety-net`] as const;
    }

    
export const getUserControllerV1GetSafetyNetQueryOptions = <TData = Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError = ErrorType<ApiExceptionDto>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerV1GetSafetyNetQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>> = ({ signal }) => userControllerV1GetSafetyNet(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerV1GetSafetyNetQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>>
export type UserControllerV1GetSafetyNetQueryError = ErrorType<ApiExceptionDto>

export const useUserControllerV1GetSafetyNet = <TData = Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError = ErrorType<ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerV1GetSafetyNet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerV1GetSafetyNetQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const kioskControllerV1GetClinic = (
    clinicDisplayId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<KioskClinicDto>(
      {url: `/v1/public-api/kiosk/clinic/${clinicDisplayId}`, method: 'GET', signal
    },
      );
    }
  

export const getKioskControllerV1GetClinicQueryKey = (clinicDisplayId: string,) => {
    return [`/v1/public-api/kiosk/clinic/${clinicDisplayId}`] as const;
    }

    
export const getKioskControllerV1GetClinicQueryOptions = <TData = Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError = ErrorType<ApiExceptionDto>>(clinicDisplayId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKioskControllerV1GetClinicQueryKey(clinicDisplayId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>> = ({ signal }) => kioskControllerV1GetClinic(clinicDisplayId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicDisplayId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError, TData> & { queryKey: QueryKey }
}

export type KioskControllerV1GetClinicQueryResult = NonNullable<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>>
export type KioskControllerV1GetClinicQueryError = ErrorType<ApiExceptionDto>

export const useKioskControllerV1GetClinic = <TData = Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError = ErrorType<ApiExceptionDto>>(
 clinicDisplayId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetClinic>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKioskControllerV1GetClinicQueryOptions(clinicDisplayId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const kioskControllerV1GetPatientById = (
    patientId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<KioskPatientDto>(
      {url: `/v1/public-api/kiosk/patients/${patientId}`, method: 'GET', signal
    },
      );
    }
  

export const getKioskControllerV1GetPatientByIdQueryKey = (patientId: string,) => {
    return [`/v1/public-api/kiosk/patients/${patientId}`] as const;
    }

    
export const getKioskControllerV1GetPatientByIdQueryOptions = <TData = Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError = ErrorType<ApiExceptionDto>>(patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKioskControllerV1GetPatientByIdQueryKey(patientId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>> = ({ signal }) => kioskControllerV1GetPatientById(patientId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError, TData> & { queryKey: QueryKey }
}

export type KioskControllerV1GetPatientByIdQueryResult = NonNullable<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>>
export type KioskControllerV1GetPatientByIdQueryError = ErrorType<ApiExceptionDto>

export const useKioskControllerV1GetPatientById = <TData = Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError = ErrorType<ApiExceptionDto>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof kioskControllerV1GetPatientById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKioskControllerV1GetPatientByIdQueryOptions(patientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const kioskControllerV1PostIdentifyPatientFromAttributes = (
    kioskIdentifyPatientFromAttributesBodyDto: KioskIdentifyPatientFromAttributesBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<KioskIdentifyPatientDto>(
      {url: `/v1/public-api/kiosk/identify-patient-attributes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: kioskIdentifyPatientFromAttributesBodyDto
    },
      );
    }
  


export const getKioskControllerV1PostIdentifyPatientFromAttributesMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>, TError,{data: KioskIdentifyPatientFromAttributesBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>, TError,{data: KioskIdentifyPatientFromAttributesBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>, {data: KioskIdentifyPatientFromAttributesBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  kioskControllerV1PostIdentifyPatientFromAttributes(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KioskControllerV1PostIdentifyPatientFromAttributesMutationResult = NonNullable<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>>
    export type KioskControllerV1PostIdentifyPatientFromAttributesMutationBody = KioskIdentifyPatientFromAttributesBodyDto
    export type KioskControllerV1PostIdentifyPatientFromAttributesMutationError = ErrorType<ApiExceptionDto>

    export const useKioskControllerV1PostIdentifyPatientFromAttributes = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>, TError,{data: KioskIdentifyPatientFromAttributesBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof kioskControllerV1PostIdentifyPatientFromAttributes>>,
        TError,
        {data: KioskIdentifyPatientFromAttributesBodyDto},
        TContext
      > => {

      const mutationOptions = getKioskControllerV1PostIdentifyPatientFromAttributesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const publicOnboardingControllerPostLookup = (
    patientInviteLookupBody: PatientInviteLookupBody,
 ) => {
      
      
      return hellojoyApiInstance<PatientInviteLookupResponseV1>(
      {url: `/v1/public-api/onboarding/lookup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: patientInviteLookupBody
    },
      );
    }
  


export const getPublicOnboardingControllerPostLookupMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>, TError,{data: PatientInviteLookupBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>, TError,{data: PatientInviteLookupBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>, {data: PatientInviteLookupBody}> = (props) => {
          const {data} = props ?? {};

          return  publicOnboardingControllerPostLookup(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PublicOnboardingControllerPostLookupMutationResult = NonNullable<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>>
    export type PublicOnboardingControllerPostLookupMutationBody = PatientInviteLookupBody
    export type PublicOnboardingControllerPostLookupMutationError = ErrorType<ApiExceptionDto>

    export const usePublicOnboardingControllerPostLookup = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>, TError,{data: PatientInviteLookupBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof publicOnboardingControllerPostLookup>>,
        TError,
        {data: PatientInviteLookupBody},
        TContext
      > => {

      const mutationOptions = getPublicOnboardingControllerPostLookupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const publicOnboardingControllerPostVerify = (
    patientInviteVerifyBody: PatientInviteVerifyBody,
 ) => {
      
      
      return hellojoyApiInstance<PatientInviteVerifyResponse>(
      {url: `/v1/public-api/onboarding/verification-code`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: patientInviteVerifyBody
    },
      );
    }
  


export const getPublicOnboardingControllerPostVerifyMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>, TError,{data: PatientInviteVerifyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>, TError,{data: PatientInviteVerifyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>, {data: PatientInviteVerifyBody}> = (props) => {
          const {data} = props ?? {};

          return  publicOnboardingControllerPostVerify(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PublicOnboardingControllerPostVerifyMutationResult = NonNullable<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>>
    export type PublicOnboardingControllerPostVerifyMutationBody = PatientInviteVerifyBody
    export type PublicOnboardingControllerPostVerifyMutationError = ErrorType<ApiExceptionDto>

    export const usePublicOnboardingControllerPostVerify = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>, TError,{data: PatientInviteVerifyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof publicOnboardingControllerPostVerify>>,
        TError,
        {data: PatientInviteVerifyBody},
        TContext
      > => {

      const mutationOptions = getPublicOnboardingControllerPostVerifyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const publicOnboardingControllerPostSetupAccount = (
    patientInviteSetupAccountBody: PatientInviteSetupAccountBody,
 ) => {
      
      
      return hellojoyApiInstance<PatientInviteSetupAccountResponse>(
      {url: `/v1/public-api/onboarding/account`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: patientInviteSetupAccountBody
    },
      );
    }
  


export const getPublicOnboardingControllerPostSetupAccountMutationOptions = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>, TError,{data: PatientInviteSetupAccountBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>, TError,{data: PatientInviteSetupAccountBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>, {data: PatientInviteSetupAccountBody}> = (props) => {
          const {data} = props ?? {};

          return  publicOnboardingControllerPostSetupAccount(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PublicOnboardingControllerPostSetupAccountMutationResult = NonNullable<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>>
    export type PublicOnboardingControllerPostSetupAccountMutationBody = PatientInviteSetupAccountBody
    export type PublicOnboardingControllerPostSetupAccountMutationError = ErrorType<ApiExceptionDto>

    export const usePublicOnboardingControllerPostSetupAccount = <TError = ErrorType<ApiExceptionDto>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>, TError,{data: PatientInviteSetupAccountBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof publicOnboardingControllerPostSetupAccount>>,
        TError,
        {data: PatientInviteSetupAccountBody},
        TContext
      > => {

      const mutationOptions = getPublicOnboardingControllerPostSetupAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const authenticationControllerV1PostForgotPasswordRequestCode = (
    forgotPasswordRequestCodeBody: ForgotPasswordRequestCodeBody,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/authentication/forgot-password/request-code`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: forgotPasswordRequestCodeBody
    },
      );
    }
  


export const getAuthenticationControllerV1PostForgotPasswordRequestCodeMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>, TError,{data: ForgotPasswordRequestCodeBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>, TError,{data: ForgotPasswordRequestCodeBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>, {data: ForgotPasswordRequestCodeBody}> = (props) => {
          const {data} = props ?? {};

          return  authenticationControllerV1PostForgotPasswordRequestCode(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthenticationControllerV1PostForgotPasswordRequestCodeMutationResult = NonNullable<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>>
    export type AuthenticationControllerV1PostForgotPasswordRequestCodeMutationBody = ForgotPasswordRequestCodeBody
    export type AuthenticationControllerV1PostForgotPasswordRequestCodeMutationError = ErrorType<unknown>

    export const useAuthenticationControllerV1PostForgotPasswordRequestCode = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>, TError,{data: ForgotPasswordRequestCodeBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordRequestCode>>,
        TError,
        {data: ForgotPasswordRequestCodeBody},
        TContext
      > => {

      const mutationOptions = getAuthenticationControllerV1PostForgotPasswordRequestCodeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const authenticationControllerV1PostForgotPasswordConfirm = (
    forgotPasswordConfirmBody: ForgotPasswordConfirmBody,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/public-api/authentication/forgot-password/confirm`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: forgotPasswordConfirmBody
    },
      );
    }
  


export const getAuthenticationControllerV1PostForgotPasswordConfirmMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>, TError,{data: ForgotPasswordConfirmBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>, TError,{data: ForgotPasswordConfirmBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>, {data: ForgotPasswordConfirmBody}> = (props) => {
          const {data} = props ?? {};

          return  authenticationControllerV1PostForgotPasswordConfirm(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthenticationControllerV1PostForgotPasswordConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>>
    export type AuthenticationControllerV1PostForgotPasswordConfirmMutationBody = ForgotPasswordConfirmBody
    export type AuthenticationControllerV1PostForgotPasswordConfirmMutationError = ErrorType<unknown>

    export const useAuthenticationControllerV1PostForgotPasswordConfirm = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>, TError,{data: ForgotPasswordConfirmBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof authenticationControllerV1PostForgotPasswordConfirm>>,
        TError,
        {data: ForgotPasswordConfirmBody},
        TContext
      > => {

      const mutationOptions = getAuthenticationControllerV1PostForgotPasswordConfirmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const assessmentControllerV1GetAssessments = (
    params: AssessmentControllerV1GetAssessmentsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<AssessmentDto[]>(
      {url: `/v1/public-api/assessments`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getAssessmentControllerV1GetAssessmentsQueryKey = (params: AssessmentControllerV1GetAssessmentsParams,) => {
    return [`/v1/public-api/assessments`, ...(params ? [params]: [])] as const;
    }

    
export const getAssessmentControllerV1GetAssessmentsQueryOptions = <TData = Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError = ErrorType<unknown>>(params: AssessmentControllerV1GetAssessmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssessmentControllerV1GetAssessmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>> = ({ signal }) => assessmentControllerV1GetAssessments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError, TData> & { queryKey: QueryKey }
}

export type AssessmentControllerV1GetAssessmentsQueryResult = NonNullable<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>>
export type AssessmentControllerV1GetAssessmentsQueryError = ErrorType<unknown>

export const useAssessmentControllerV1GetAssessments = <TData = Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError = ErrorType<unknown>>(
 params: AssessmentControllerV1GetAssessmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof assessmentControllerV1GetAssessments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssessmentControllerV1GetAssessmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const partnerOnboardingControllerCreatePartnerClinician = (
    organizationId: string,
    createPartnerClinicianBody: CreatePartnerClinicianBody,
 ) => {
      
      
      return hellojoyApiInstance<ClinicianDto>(
      {url: `/v1/public-api/partner/${organizationId}/clinician`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPartnerClinicianBody
    },
      );
    }
  


export const getPartnerOnboardingControllerCreatePartnerClinicianMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>, TError,{organizationId: string;data: CreatePartnerClinicianBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>, TError,{organizationId: string;data: CreatePartnerClinicianBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>, {organizationId: string;data: CreatePartnerClinicianBody}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  partnerOnboardingControllerCreatePartnerClinician(organizationId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PartnerOnboardingControllerCreatePartnerClinicianMutationResult = NonNullable<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>>
    export type PartnerOnboardingControllerCreatePartnerClinicianMutationBody = CreatePartnerClinicianBody
    export type PartnerOnboardingControllerCreatePartnerClinicianMutationError = ErrorType<unknown>

    export const usePartnerOnboardingControllerCreatePartnerClinician = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>, TError,{organizationId: string;data: CreatePartnerClinicianBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof partnerOnboardingControllerCreatePartnerClinician>>,
        TError,
        {organizationId: string;data: CreatePartnerClinicianBody},
        TContext
      > => {

      const mutationOptions = getPartnerOnboardingControllerCreatePartnerClinicianMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
