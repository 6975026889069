import React from 'react'
import { useQuery } from 'react-query'
import { Box, Flex, VStack, Text, Select, Link } from '@chakra-ui/react'
import { Formik, Form, Field, FieldProps, FormikProps } from 'formik'
import { NewTabIcon } from '@blueprinthq/joy'
import { useCadenceOptions } from '../use-cadence-options'
import { Clipboard } from '@components/icons'
import { endpoints } from '@api'
import * as Yup from 'yup'
import moment from 'moment'
import { formatCadence, buildAssessmentPreviewUrl } from '@utilities'
import { AssignableAssessment, AssignAssessmentsFormValues, Patient } from '@handlers/sessions/types'

interface AssignAssessmentsFormProps {
  assessment: AssignableAssessment
  client: { id: string, user_id: string }
  onSubmit: (values: any) => void
  children: (props: any) => React.ReactNode
}

interface AssignAssessmentsFormFieldsProps {
  client: Patient
  formikProps: any
  assessments: any[]
}

const validationSchema = Yup.object().shape({
  clinicAssessmentId: Yup.string().required(),
  cadence: Yup.string().required(),
  suggestionId: Yup.string(),
  nextAdministrationDate: Yup.string()
})

export const AssignAssessmentsForm = ({
  assessment,
  client,
  onSubmit,
  children
}: AssignAssessmentsFormProps) => {
  const {
    isLoading: areAssessmentsLoading,
    data: clientAssessmentsGroupedByAssignee
  } = useQuery(
    [endpoints.getAllAssessmentsForClientByAssignee.getCacheId(), client.id],
    () =>
      endpoints.getAllAssessmentsForClientByAssignee.request({ id: client.id }),
    {
      placeholderData: [{ assessments: [] }],
      enabled: !!client.id
    }
  )

  const {
    isLoading: isNextDeliveryDateLoading,
    data: nextDeliveryDateData
  } = useQuery(
    [endpoints.getClinicianUserNextDeliveryDate.getCacheId(), client.id],
    () => endpoints.getClinicianUserNextDeliveryDate.request({ id: client.id }),
    {
      placeholderData: [],
      enabled: !!client.id
    }
  )

  const handleSubmit = (values: AssignAssessmentsFormValues) => {
    onSubmit(values)
  }

  // @ts-ignore
  const allAssessments = clientAssessmentsGroupedByAssignee[0].assessments

  if (areAssessmentsLoading || isNextDeliveryDateLoading) return null

  const initialValues = {
    clinicAssessmentId: assessment.clinicAssessmentId,
    cadence: formatCadence(assessment.cadenceValue, assessment.cadenceUnit),
    contentSuggestionId: assessment.contentSuggestionId,
    // @ts-ignore
    nextAdministrationDate: nextDeliveryDateData[0]?.nextAdministrationDate
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps: FormikProps<AssignAssessmentsFormValues>) => (
        <Form>{children({ formikProps, allAssessments })}</Form>
      )}
    </Formik>
  )
}

export const AssignAssessmentsFormFields = ({
  client,
  formikProps,
  assessments = []
}: AssignAssessmentsFormFieldsProps) => {
  const { cadenceOptions } = useCadenceOptions()
  const { values } = formikProps
  const { nextAdministrationDate } = values

  const assessment = assessments.find(
    (a: any) => values.clinicAssessmentId === a.id
  )

  if (!assessment) return null

  return (
    <Box>
      <VStack align="stretch" spacing="32px">
        <VStack align="stretch" spacing="13px">
          <>
            <Flex
              flexDir="row"
              justifyContent="left"
              gap="small"
              border="1px solid"
              borderColor="pale_gray"
              borderRadius="4px"
              p="small"
            >
              <Flex flexDir="column" justifyContent="center">
                <Clipboard />
              </Flex>
              <Flex flex="1" flexDir="column" gap="4px" justifyContent="center">
                <Text fontWeight="bold" fontSize="16px">{assessment.name}</Text>
                <Text fontSize="14px">{assessment.full_name}</Text>
                <Text fontSize="12px" textColor="gray">
                  {assessment.disorder} |{' '}
                  {assessment.content?.sections[0]?.questions?.length} questions
                </Text>
              </Flex>
              <Flex alignItems="center" color="primary">
                <Link
                  mr={2}
                  href={buildAssessmentPreviewUrl(assessment.assessment_id)}
                  target="_blank"
                  _hover={{ textDecoration: 'none' }}
                  _focus={{ outline: 'none' }}
                  fontSize="16px"
                >
                  Preview
                </Link>
                <NewTabIcon h="16px" w="16px" fill="#2d54e8" />
              </Flex>
            </Flex>
            <Box>
              <Text fontWeight="bold" mb={"8px"}>Frequency:</Text>
              <Field name="cadence">
                {({ field }: FieldProps) => (
                  <Select
                    border="1px solid"
                    borderColor="pale_gray"
                    borderRadius="4px"
                    h="48px"
                    _focus={{
                      outline: 'none'
                    }}
                    {...field}
                  >
                    {cadenceOptions.map(cadence => {
                      return (
                        <option key={cadence} value={cadence}>
                          {cadence}
                        </option>
                      )
                    })}
                  </Select>
                )}
              </Field>
            </Box>
          </>
          <Box
            px="22px"
            py={4}
            mt="16px !important"
            bg="rgba(45, 84, 232, 0.04)"
            borderRadius="8px"
            border="1px solid #E4E5E6"
          >
            {nextAdministrationDate ? (
              <Text as="span">
                <Text as="span" fontWeight="bold">
                  {client.first_name} {client.last_name}
                </Text>{' '}
                will start receiving delivery of the
                <Text fontWeight="bold" as="span">
                  {' '}
                  {assessment.name}
                </Text>{' '}
                assessment around
                <Text as="span" fontWeight="bold">
                  {' '}
                  {moment(nextAdministrationDate).format('h:mm A')}
                </Text>{' '}
                on
                <Text fontWeight="bold" as="span">
                  {' '}
                  {moment(nextAdministrationDate).format('MMMM D, YYYY')}.
                </Text>
              </Text>
            ) : (
              <Text as="span">
                <Text as="span" fontWeight="bold">
                  {client.first_name} {client.last_name}
                </Text>{' '}
                will receive a delivery of the
                <Text fontWeight="bold" as="span">
                  {' '}
                  {assessment?.name}
                </Text>{' '}
                assessment
                <Text as="span" fontWeight="bold">
                  {' '}
                  {values.cadence?.toLocaleLowerCase()}
                </Text>{' '}
                starting
                <Text fontWeight="bold" as="span">
                  {' '}
                  today.
                </Text>
              </Text>
            )}
          </Box>
        </VStack>
      </VStack>
    </Box>
  )
}
