import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { PlayArrow } from '@material-ui/icons'
import { getCloudinaryVideoPoster } from '../utils/video-utils'

interface VideoThumbnailProps {
  videoUrl: string
  onPlay: () => void
  width?: string | number
  height?: string | number
  aspectRatio?: string
  timestamp?: number
}

export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  videoUrl,
  onPlay,
  width = '100%',
  height = 'auto',
  aspectRatio,
  timestamp = 0,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const posterUrl = getCloudinaryVideoPoster(videoUrl, timestamp)
  
  // Load the poster image
  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setIsLoading(false)
    }
    img.onerror = () => {
      setIsLoading(false)
      setHasError(true)
    }
    img.src = posterUrl
    
    return () => {
      img.onload = null
      img.onerror = null
    }
  }, [posterUrl])
  
  // Handle click on the container
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onPlay()
    return false
  }
  
  return (
    <Box 
      position="relative" 
      width={width}
      height={height}
      style={{ aspectRatio: aspectRatio }}
      borderRadius="6px" 
      overflow="hidden" 
      cursor="pointer"
      onClick={handleClick}
    >
      {/* Loading indicator */}
      {isLoading && (
        <Box 
          position="absolute" 
          top="0" 
          left="0" 
          right="0" 
          bottom="0" 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
          zIndex="2"
          bg="rgba(0,0,0,0.1)"
          borderRadius="6px"
        >
          <Spinner size="xl" color="primary" />
        </Box>
      )}
      
      {/* Poster image */}
      <Box
        as="img"
        src={posterUrl}
        alt="Video thumbnail"
        width="100%"
        height="100%"
        objectFit="cover"
        display={isLoading ? 'none' : 'block'}
        borderRadius="6px"
      />
      
      {/* Play button overlay */}
      {!isLoading && !hasError && (
        <Box 
          position="absolute" 
          top="0" 
          left="0" 
          right="0" 
          bottom="0" 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
          zIndex="2"
        >
          <Flex
            width="68px"
            height="68px"
            backgroundColor="rgba(255, 255, 255, 0.6)"
            borderRadius="50%"
            justifyContent="center"
            alignItems="center"
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
            transition="transform 0.2s"
            _hover={{
              transform: "scale(1.1)",
              backgroundColor: "rgba(255, 255, 255, 0.8)"
            }}
          >
            <PlayArrow
              htmlColor="#2D54E8"
              style={{ height: '40px', width: '40px' }}
            />
          </Flex>
        </Box>
      )}
      
      {/* Error message */}
      {hasError && (
        <Box 
          position="absolute" 
          top="0" 
          left="0" 
          right="0" 
          bottom="0" 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
          zIndex="2"
          bg="rgba(0,0,0,0.7)"
          borderRadius="6px"
          p={4}
          textAlign="center"
        >
          <Text color="white" fontWeight="bold">
            Unable to load video. Click to try again.
          </Text>
        </Box>
      )}
    </Box>
  )
} 