import React, { useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  type ExpandedIndex,
  IconButton,
  HStack,
  Text,
  Stack,
  Flex,
  Divider,
  useBreakpointValue
} from '@chakra-ui/react'
import { endpoints } from '@api'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { DashedCircle } from '@components/icons'
import { AGE_RANGES } from '@constants/ageRanges'
import TREATMENT_SETTINGS from '@constants/treatmentSettings'
import { useStoreState } from 'easy-peasy'
import { useTreatmentPlan } from '@hooks'
import { RadioButtonLoadingComplete } from '@components/icons'
import { RiskLevelPanel, OptionPanel, FocusOfTreatmentPanel, TreatmentApproachPanel } from './components'
import { 
  useSessionControllerPatchSessionV2,
  getSessionControllerGetCompletedSessionDetailsQueryKey,
  getSessionControllerGetCompletedSessionDetailsV2QueryKey,
  getSessionControllerGetContentExclusionReasonsQueryKey 
} from '~/clinician-api'
import { StoreModel } from 'src/store/types'

type AgeRangeKey = keyof typeof AGE_RANGES

type SuggestionOption = {
  name: string
  selectedSubtext: () => JSX.Element
  render: () => JSX.Element
  renderIcon?: () => JSX.Element
}

const SuggestionStrength = ({ account, session }: any) => {
  const queryClient = useQueryClient()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const { treatmentPlan,focusOptions, client, initialSelectedFocuses, updateMutation: patchTxPlan, generateMutation: generateTxPlan  } = useTreatmentPlan()
  
  const initialFocusOptions = initialSelectedFocuses
    ?.map(focus => focus?.value)
    .filter((value): value is string => value !== undefined) || []

  const [expandedIndex, setExpandedIndex] = useState<ExpandedIndex | undefined>(undefined)
  const [isHighRisk, setIsHighRisk] = useState<boolean | undefined>(client?.high_risk_timestamp ? client?.is_high_risk : undefined)
  const [focusOfTreatmentSearch, setFocusOfTreatmentSearch] = useState<string>('')
  const [selectedAgeRange, setSelectedAgeRange] = useState<AgeRangeKey | undefined>(client?.age_range as AgeRangeKey)
  const [selectedTreatmentApproaches, setSelectedTreatmentApproaches] = useState<string[]>(session?.treatmentApproaches)
  const [selectedFocusOptions, setSelectedFocusOptions] = useState<(string)[]>(initialFocusOptions)
  const [selectedTreatmentSetting, setSelectedTreatmentSetting] = useState<string | undefined>(account?.treatment_setting)
  const [selectedProviderType, setSelectedProviderType] = useState<string | undefined>(account?.license_level)

  const accordianButtonHoverBg = useBreakpointValue({
    base: 'transparent',
    sm: 'transparent',
    md: 'pale_gray'
  })

  const { data: settings } = useQuery(
    [endpoints.getProgressNoteSettings.getCacheId(), user?.clinic?.organization_id],
    () => endpoints.getProgressNoteSettings.request({ organizationId: user?.clinic?.organization_id }),
    { enabled: !!user }
  )

  const { data: licenseLevels } = useQuery(
    [endpoints.getLicenseLevels.getCacheId()],
    endpoints.getLicenseLevels.request,
    { initialData: [] }
  )

  let treatmentApproachOptions: { value: string, label: string }[] = (settings as any)?.preferenceOptions?.treatmentApproach?.options?.map(
    (option: any) => ({
      value: option.value,
      label: option.label
    })
  ) || []

  treatmentApproachOptions = treatmentApproachOptions
    .filter(option => option.value !== 'none')
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter(option => option.value !== 'other')
    .concat(treatmentApproachOptions.find(option => option.value === 'other') || [])

  const focusOfTreatmentOptions = focusOptions
    .filter(option => option.label.toLowerCase().includes(focusOfTreatmentSearch.toLowerCase()))
    .sort((a, b) => a.label.localeCompare(b.label))

  const ageRangeOptions = Object.entries(AGE_RANGES).map(([key, value]) => ({
    value: key,
    label: value.display
  }))

  const providerTypeOptions: [{ label: string, value: string }]  = (licenseLevels as any).map((licenseLevel: any) => ({
    label: licenseLevel.display,
    value: licenseLevel.id
  })).sort((a: any, b: any) => {
    const order = ['masters', 'psychologist', 'bachelor', 'psychiatrist', 'technician', 'nurse_practicioner', 'registered_nurse', 'other']
    return order.indexOf(a.value) - order.indexOf(b.value)
  })

  const { mutate: patchSession } = useSessionControllerPatchSessionV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getSessionControllerGetCompletedSessionDetailsQueryKey(session.id)
        )
        queryClient.invalidateQueries(
          getSessionControllerGetCompletedSessionDetailsV2QueryKey(session.id)
        )
        queryClient.invalidateQueries(
          getSessionControllerGetContentExclusionReasonsQueryKey(session.id)
        )
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          client?.id
        ])
      }
    }
  })

  const {
    mutateAsync: updateProvider,
  } = useMutation(
    async (data: object) => {
      await endpoints.patchOrganizationProviders.request({
        organizationId: account.clinic.organization_id,
        providerId: account.id,
        data: {
          email: account.email,
          first_name: account.first_name,
          last_name: account.last_name,
          ...data
        }
      })
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([endpoints.getClinicianUserAccount.getCacheId(), client?.id])
        queryClient.invalidateQueries(getSessionControllerGetContentExclusionReasonsQueryKey(session.id))
      }
    }
  )

  const { mutate: updateClient } = useMutation(
    endpoints.patchClinicianUserInfo.request,
    {
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          client?.id
        ])
        queryClient.invalidateQueries(getSessionControllerGetContentExclusionReasonsQueryKey(session.id))
      }
    }
  )

  const { mutate: toggleIsHighRisk, isLoading } = useMutation(
    endpoints.patchClientHighRisk.request,
    {
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          client?.id
        ])
        queryClient.invalidateQueries(getSessionControllerGetContentExclusionReasonsQueryKey(session.id))
      }
    }
  )

  const suggestionOptions: SuggestionOption[] = [
    {
      name: 'Client risk level',
      selectedSubtext: () => (
        <Text color={isHighRisk !== undefined ? 'primary' : 'medium_gray'} fontSize="12px" lineHeight="16px">
          {isHighRisk ? 'Is high risk' : 'Is not high risk'}
        </Text>
      ),
      render: () => (
        <RiskLevelPanel
          value={isHighRisk}
          onClick={(value: boolean) => {
            setIsHighRisk(value)
            setExpandedIndex([])
            toggleIsHighRisk({
              clientId: client?.id,
              data: {
                isHighRisk: value
              }
            })
          }}
        />
      ),
      renderIcon: () => (
        isHighRisk !== undefined ? <RadioButtonLoadingComplete /> : <DashedCircle />
      )
    },
    {
      name: 'Client age range',
      selectedSubtext: () => (
        <Text color={selectedAgeRange ? 'primary' : 'medium_gray'} fontSize="12px" lineHeight="16px">
          {selectedAgeRange
            ? ageRangeOptions.find(option => option.value === selectedAgeRange)?.label
            : 'Select one'}
        </Text>
      ),
      render: () => (
        <OptionPanel
          options={ageRangeOptions}
          value={selectedAgeRange}
          onClick={(value: string) => {
            setSelectedAgeRange(value as AgeRangeKey)
            setExpandedIndex([])
            updateClient({
              patientId: client?.id,
              data: { 
                age_range: value
              }
            })
          }}
        />
      ),
      renderIcon: () => (
        selectedAgeRange ? <RadioButtonLoadingComplete /> : <DashedCircle />
      )
    },
    {
      name: 'Treatment approach',
      selectedSubtext: () => (
        <Text color={selectedTreatmentApproaches?.length ? 'primary' : 'medium_gray'} fontSize="12px" lineHeight="16px">
          {selectedTreatmentApproaches?.length
            ? (selectedTreatmentApproaches || [])
                .map(option => treatmentApproachOptions.find(item => item.value === option)?.label)
                .join(', ')
            : 'Select all that apply'}
        </Text>
      ),
      render: () => (
        <TreatmentApproachPanel
          options={treatmentApproachOptions}
          selectedValues={selectedTreatmentApproaches}
          onChange={(value) => {
            setSelectedTreatmentApproaches(value)
            patchSession({ id: session?.id, data: { treatmentApproaches: value }})
          }}
        />
      ),
      renderIcon: () => (
        selectedTreatmentApproaches?.length ? <RadioButtonLoadingComplete /> : <DashedCircle />
      )
    },
    {
      name: 'Focus of treatment',
      selectedSubtext: () => (
        <Text color={selectedFocusOptions.length ? 'primary' : 'medium_gray'} fontSize="12px" lineHeight="16px">
          {selectedFocusOptions.length
            ? selectedFocusOptions
                .map(option => focusOptions.find(item => item.value === option)?.label)
                .join(', ')
            : 'Select all that apply'}
        </Text>
      ),
      render: () => (
        <FocusOfTreatmentPanel
          options={focusOfTreatmentOptions}
          searchValue={focusOfTreatmentSearch}
          selectedValues={selectedFocusOptions}
          onSearchChange={setFocusOfTreatmentSearch}
          onOptionToggle={(value) => {
            const updatedValues = selectedFocusOptions.includes(value)
              ? selectedFocusOptions.filter(item => item !== value)
              : [...selectedFocusOptions, value]

            setSelectedFocusOptions(updatedValues)
            if (treatmentPlan) {
              patchTxPlan.mutate({ focusOfTreatmentIds: updatedValues })
            } else {
              generateTxPlan.mutate({ 
                focusOfTreatmentIds: updatedValues,
                generatedForSessionId: session?.id
              })
            }
          }}
        />
      ),
      renderIcon: () => (
        selectedFocusOptions.length > 0 ? <RadioButtonLoadingComplete /> : <DashedCircle />
      )
    },
    {
      name: 'Treatment setting',
      selectedSubtext: () => (
        <Text color={selectedTreatmentSetting ? 'primary' : 'medium_gray'} fontSize="12px" lineHeight="16px">
          {selectedTreatmentSetting
            ? TREATMENT_SETTINGS.find(option => option.value === selectedTreatmentSetting)?.label
            : 'Select one'}
        </Text>
      ),
      render: () => (
        <OptionPanel
          options={TREATMENT_SETTINGS}
          value={selectedTreatmentSetting}
          onClick={(value) => {
            setSelectedTreatmentSetting(value)
            setExpandedIndex([])
            updateProvider({ treatment_setting: value })
          }}
        />
      ),
      renderIcon: () => (
        selectedTreatmentSetting ? <RadioButtonLoadingComplete /> : <DashedCircle />
      )
    },
    {
      name: 'Provider type',
      selectedSubtext: () => (
        <Text color={selectedProviderType ? 'primary' : 'medium_gray'} fontSize="12px" lineHeight="16px">
          {selectedProviderType
            ? providerTypeOptions.find(option => option.value === selectedProviderType)?.label
            : 'Select one'}
        </Text>
      ),
      render: () => (
        <OptionPanel
          options={providerTypeOptions}
          value={selectedProviderType}
          onClick={(value) => {
            setSelectedProviderType(value)
            setExpandedIndex([])
            updateProvider({ license_level: value })
          }}
        />
      ),
      renderIcon: () => (
        selectedProviderType ? <RadioButtonLoadingComplete /> : <DashedCircle />
      )
    }
  ]

  return (
    <Accordion allowToggle index={expandedIndex} onChange={index => setExpandedIndex(index)}>
      {suggestionOptions.map((option, index) => (
        <AccordionItem key={index}>
          <AccordionButton
            _hover={{ bg: 'none' }}
            _focus={{ outline: 'none' }}
            p="0"
            w="100%"
            h="auto"
            borderRadius="full"
            display="flex"
            alignItems="center"
            role="group"
          >
            <HStack spacing="0" gap="12px" flex="1" py="16px" px="24px">
              <Flex>
                {option.renderIcon ? option.renderIcon() : <DashedCircle />}
              </Flex>
              <Stack spacing="0" flex="1" textAlign="left">
                <Text>{option.name}</Text>
                {option.selectedSubtext()}
              </Stack>
              <Flex justifyContent="center" alignItems="center" w="40px" h="40px" >
                <IconButton
                  m="0"
                  variant="ghost"
                  _hover={{ bg: accordianButtonHoverBg }}
                  _groupHover={{ background: accordianButtonHoverBg }}
                  _focus={{ outline: 'none' }}
                  aria-label='accordion-arrow-down'
                  icon={<AccordionIcon />}
                />
              </Flex>
            </HStack>
          </AccordionButton>
          <AccordionPanel px="24px" w="100%" pt="0">
            {option.render()}
          </AccordionPanel>
          <Divider />
        </AccordionItem>
      ))}
    </Accordion>
  )    
}

export default SuggestionStrength