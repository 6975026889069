import React from 'react'
import { Flex, Box, Image, Button } from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon, LogoutIcon } from '@blueprinthq/joy'
import BlueprintLogo from '../../../images/brand/blueprint_b_logo.svg'
import BlueprintLogoFull from '../../../images/brand/blue_logo.svg'

const MobileNav = ({
  onBack,
  onSkip,
  isInteractiveDemo
}: {
  onBack: (() => void) | null
  onSkip: (() => void) | null
  isInteractiveDemo: boolean
}) => (
  <Flex
    display={{ base: 'flex', md: 'none' }}
    justify={{ base: 'center', md: 'flex-start' }}
    align="center"
    pt={3}
    pb={2}
    px={6}
    position="relative"
    h="52px"
  >
    {onBack && (
      <Button
        variant="ghost"
        color="primary"
        position="absolute"
        top="5px"
        left="5px"
        display={{ base: 'flex', md: 'none' }}
        leftIcon={<ArrowLeftIcon fill="primary" />}
        onClick={onBack}
        _focus={{
          boxShadow: 'none'
        }}
      >
        Back
      </Button>
    )}
    <Image
      loading="eager"
      src={BlueprintLogo}
      cursor="pointer"
      display={{ base: 'block', md: 'none' }}
    />
    {onSkip && (
      <Button
        variant="ghost"
        color="primary"
        position="absolute"
        top="5px"
        right="5px"
        display={{ base: 'flex', md: 'none' }}
        rightIcon={isInteractiveDemo ? <LogoutIcon fill="primary" /> : <ArrowRightIcon fill="primary" />}
        onClick={onSkip}
        _focus={{
          boxShadow: 'none'
        }}
      >
        {isInteractiveDemo ? 'End demo' : 'Skip'}
      </Button>
    )}
  </Flex>
)

const DesktopNav = ({
  onBack,
  onSkip,
  isInteractiveDemo
}: {
  onBack: (() => void) | null
  onSkip: (() => void) | null
  isInteractiveDemo: boolean
}) => (
  <Box display={{ base: 'none', md: 'block' }} h="47px">
    <Box pl={1} py={2} pr={4}>
      <Image
        loading="eager"
        src={BlueprintLogoFull}
        cursor="pointer"
        display={{ base: 'none', md: 'block' }}
      />
    </Box>
    <Flex width="100%" justifyContent="space-between">
      {onBack && (
        <Box pt={3} pb={2} px={0}>
          <Button
            pl={0}
            m={0}
            variant="ghost"
            color="primary"
            leftIcon={<ArrowLeftIcon fill="primary" mt="2px" />}
            onClick={onBack}
            _focus={{
              boxShadow: 'none'
            }}
          >
            Back
          </Button>
        </Box>
      )}
      {onSkip && (
        <Box pt={3} pb={2} px={0} display="flex" justifyContent="flex-end" flex={!onBack ? '1' : '0'}>
          <Button
            pl={0}
            m={0}
            variant="ghost"
            color="primary"
            rightIcon={isInteractiveDemo ? <LogoutIcon fill="primary" mt="2px" /> : <ArrowRightIcon fill="primary" mt="2px" />}
            onClick={onSkip}
            _focus={{
              boxShadow: 'none'
            }}
          >
            {isInteractiveDemo ? 'End demo' : 'Skip'}
          </Button>
        </Box>
      )}
    </Flex>
  </Box>
)
export const Navbar = ({
  isInteractiveDemo,
  onBack,
  onSkip
}: {
  isInteractiveDemo: boolean
  onBack: (() => void) | null
  onSkip: (() => void) | null
}) => {
  return (
    <>
      <MobileNav onBack={onBack} onSkip={onSkip} isInteractiveDemo={isInteractiveDemo} />
      <DesktopNav onBack={onBack} onSkip={onSkip} isInteractiveDemo={isInteractiveDemo} />
    </>
  )
}
