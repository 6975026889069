import React from 'react'
import { Text, Box, Button, Flex, VStack } from '@chakra-ui/react'
import WarningIcon from '@material-ui/icons/Warning'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'

interface ClearMemoryConfirmationProps {
  onClose: () => void
  onClear: () => Promise<void>
  isLoading: boolean
}

const ClearMemoryConfirmation = ({
  onClose,
  onClear,
  isLoading
}: ClearMemoryConfirmationProps) => (
  <DialogContainer isOpen={true} onClose={onClose}>
    <DialogHeader
      text="Clear memory?"
      onClose={onClose}
      hideBorder
      textAlign="left"
      pt="24px"
      pr="24px"
      pb="0px"
      pl="24px"
    />
    <DialogBody>
      <VStack spacing={2}>
        <Text>
          Are you sure you want to clear all saved instructions from Blueprint's
          memory? This will remove all customization rules that have been
          applied to your notes.
        </Text>

        <Box bg="yellow.50" p={4} borderRadius="md" w="100%">
          <Flex>
            <Box mr={2} color="yellow.400">
              <WarningIcon />
            </Box>
            <Text fontWeight="bold">
              Warning: This action cannot be undone.
            </Text>
          </Flex>
        </Box>

        <Flex justifyContent="space-between" gap={4} w="100%">
          <Button variant="outline" flex={1} onClick={onClose}>
            Cancel
          </Button>
          <Button
            bg="error"
            color="white"
            flex={1}
            onClick={onClear}
            isLoading={isLoading}
          >
            Clear memory
          </Button>
        </Flex>
      </VStack>
    </DialogBody>
  </DialogContainer>
)

export default ClearMemoryConfirmation
