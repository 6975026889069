import React, { useState, useEffect } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import {
  useOnboardingControllerValidateEmail,
  useOnboardingControllerExperiment
} from '~/clinician-api'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { AnimatePresence, motion } from 'framer-motion'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Box, Flex, Button } from '@chakra-ui/react'
import { Navbar } from './components/navbar'
import { ProgressBar } from './components/progress-bar'
import { Loading } from '@components'
import { GetStarted } from './components/get-started'
import { RecordingReadiness } from './components/recording-readiness'
import { VideoWalkthrough } from './components/video-walkthrough'
import { InteractiveDemo } from './components/interactive-demo'
import { Email } from './components/email'
import { Name } from './components/name'
import { Password } from './components/password'
import { Practice } from './components/practice'
import { DEFAULT_PASSWORD_SCHEMA } from '../../components/passwordrules'
import { useMutation } from 'react-query'
import { endpoints } from '@api'
import { setUserProperties, trackEvent } from '@lib/clinician-tracking'
// @ts-ignore
import Cookies from 'js-cookie'
import { TestimonialCarousel } from './components/testimonial-carousel'
import { VideoOfframp } from './components/video-offramp'
import { PlayArrow } from '@material-ui/icons'
import { useDisclosure } from '@chakra-ui/react'
import { FadeIn } from './components/animations'
import { WelcomeVideoModal } from './components/welcome-video-modal'
import { AffiliateProgram } from './components/affiliate-program'
import { NotReadyToRecord } from './components/not-ready-to-record'
import { buildUrl } from './utils/build-url'
import { SelectNote } from './components/select-note'
import { useQueryParams } from '@hooks'
const MotionContainer = motion(Box)

const Image1 =
  'https://res.cloudinary.com/hellojoy/image/upload/v1737995311/onboarding/onboarding2.jpg'
const Image2 =
  'https://res.cloudinary.com/hellojoy/image/upload/v1737995189/onboarding/onboarding_ovhmel.jpg'
const Image3 =
  'https://res.cloudinary.com/hellojoy/image/upload/v1740003551/onboarding/entering-therapy.webp'
const images = [Image1, Image2, Image3]

const validationSchemas = {
  email: Yup.object({
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required')
  }),
  name: Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required')
  }),
  password: Yup.object({
    password: DEFAULT_PASSWORD_SCHEMA
  }),
  organizationName: Yup.object({
    organizationName: Yup.string().required('Practice name is required')
  })
}

export interface FormValues {
  email: string
  firstName: string
  lastName: string
  password: string
  organizationName: string
}

export const baseUrl = '/onboarding'
const LOCAL_STORAGE_HUBSPOT_BRIDGE_ID = 'hubspotBridgeId'
const LOCAL_STORAGE_ONBOARDING_VERSION = 'onboardingVersion'

enum OnboardingVersion {
  CURRENT = 2,
  EXPERIMENT = 3
}

function useRewardfulReferral() {
  const [referralId, setReferralId] = useState<string | null>(null)

  useEffect(() => {
    // @ts-ignore
    if (window && window.rewardful) {
      // @ts-ignore
      window.rewardful('ready', () => {
        // @ts-ignore
        const properties = window.Rewardful
        if (properties && properties.referral) {
          setReferralId(properties.referral)
        }
      })
    }
  }, [])

  return referralId
}

export const SignupV2 = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [isSignupError, setIsSignupError] = useState(false)
  const [hubspotBridgeId, setHubspotBridgeId] = useState(() => {
    return localStorage.getItem(LOCAL_STORAGE_HUBSPOT_BRIDGE_ID) || null
  })
  const [onboardingVersion, setOnboardingVersion] = useState(() => {
    const storedVersion = localStorage.getItem(LOCAL_STORAGE_ONBOARDING_VERSION)
    const version = storedVersion ? Number(storedVersion) : null
    return Number.isNaN(version) ? null : version
  })
  const {
    isOpen: isOpenPreviewVideo,
    onOpen: onOpenPreviewVideo,
    onClose: onClosePreviewVideo
  } = useDisclosure()
  const [previousPaths, setPreviousPaths] = useState<string[]>([])
  const [soloReferralId, setSoloReferralId] = useState<string | null>(null)
  const [groupReferralId, setGroupReferralId] = useState<string | null>(null)
  const [affiliateSource, setAffiliateSource] = useState<string | null>(null)
  const [rewardfulReferrerTag, setRewardfulReferrerTag] = useState<
    string | null
  >(null)
  const queryParams = useQueryParams()
  const forceVersion = queryParams.get('forceVersion')

  const {
    data: onboardingControllerExperiment
  } = useOnboardingControllerExperiment(
    {
      forceVersion: forceVersion === '3' ? '3' : forceVersion === '2' ? '2' : ''
    },
    { query: { enabled: onboardingVersion === null } }
  )

  const location = useLocation()
  const history = useHistory()
  const authLogin = useStoreActions((actions: any) => actions.auth.login)
  const { isAuthenticated } = useStoreState((state: any) => state.auth)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const soloParam = searchParams.get('solo')
    const groupParam = searchParams.get('group')
    const affiliateParam = searchParams.get('affiliate')
    const rewardfulReferrerTag = searchParams.get('via')

    if (soloParam) setSoloReferralId(soloParam)
    if (groupParam) setGroupReferralId(groupParam)
    if (affiliateParam) setAffiliateSource(affiliateParam)
    if (rewardfulReferrerTag) setRewardfulReferrerTag(rewardfulReferrerTag)
  }, [location.search])

  const {
    mutate: validateEmail,
    isLoading: isValidatingEmail
  } = useOnboardingControllerValidateEmail()

  const rewardfulReferralId = useRewardfulReferral()

  useEffect(() => {
    if (onboardingControllerExperiment?.version && !onboardingVersion) {
      localStorage.setItem(
        LOCAL_STORAGE_ONBOARDING_VERSION,
        String(onboardingControllerExperiment.version)
      )
      setOnboardingVersion(onboardingControllerExperiment.version)
    }
  }, [onboardingControllerExperiment?.version])

  useEffect(() => {
    if (onboardingVersion) {
      setUserProperties({
        onboardingVersion: onboardingVersion
      })
    }
  }, [onboardingVersion])

  useEffect(() => {
    // preload the images before rendering to prevent flashing
    let loadedCount = 0
    images.forEach(src => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        loadedCount++
        if (loadedCount === images.length) {
          setImagesLoaded(true)
        }
      }
      img.onerror = () => {
        loadedCount++
        if (loadedCount === images.length) {
          setImagesLoaded(true)
        }
      }
    })
  }, [])

  useEffect(() => {
    setPreviousPaths(prev => [...prev, location.pathname])
  }, [location.pathname])

  const { mutateAsync: createAccount, isLoading: isSigningUp } = useMutation(
    endpoints.postSelfServeSignup.request,
    {
      onSuccess: async (_, variables) => {
        setIsSignupError(false)
        const credentials = {
          email: variables.data.email,
          password: variables.data.password
        }
        await authLogin(credentials)
        localStorage.removeItem(LOCAL_STORAGE_HUBSPOT_BRIDGE_ID)
        if (onboardingVersion === OnboardingVersion.EXPERIMENT) {
          history.replace(`${baseUrl}/recording-readiness`)
        } else {
          history.replace(`${baseUrl}/get-started`)
        }
      },
      onError(error) {
        console.log('v2_onboarding_signup_error', error)
        setIsSignupError(true)
      }
    }
  )

  const { mutateAsync: submitHubspotTrialLead } = useMutation(
    endpoints.postHubspotTrialLead.request,
    {
      onSuccess(data: any) {
        localStorage.setItem(
          LOCAL_STORAGE_HUBSPOT_BRIDGE_ID,
          data.hubspotBridgeId
        )
        setHubspotBridgeId(data.hubspotBridgeId)
      },
      onError(error) {
        console.log('error', error)
      }
    }
  )

  const postSignupURLs = [
    `${baseUrl}/recording-readiness`,
    `${baseUrl}/video-walkthrough`,
    `${baseUrl}/video-offramp`,
    `${baseUrl}/interactive-demo`,
    `${baseUrl}/get-started`,
    `${baseUrl}/sample-notes`,
    `${baseUrl}/select-note`
  ]

  useEffect(() => {
    if (
      isAuthenticated &&
      !postSignupURLs.includes(location.pathname) &&
      !isSigningUp
    ) {
      history.push('/')
    }
  }, [isAuthenticated, location.pathname, isSigningUp, history])

  const didStartFromAffiliate = () => {
    return (
      previousPaths.length > 0 && previousPaths[0] === `${baseUrl}/affiliate`
    )
  }

  if (!imagesLoaded) {
    return (
      <Flex h="100vh" align="center" justify="center">
        <Loading />
      </Flex>
    )
  }

  const getHeroImageUrl = () => {
    switch (location.pathname) {
      case `${baseUrl}/affiliate`:
        return Image2
      case baseUrl:
        return Image2
      case `${baseUrl}/name`:
        return Image2
      case `${baseUrl}/password`:
        return Image2
      case `${baseUrl}/practice`:
        return Image1
      case `${baseUrl}/recording-readiness`:
        return Image3
      case `${baseUrl}/video-walkthrough`:
        return Image3
      default:
        return null
    }
  }

  const heroImageUrl = getHeroImageUrl()

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    organizationName: ''
  }

  const getCurrentStepValidation = (pathname: string) => {
    switch (pathname) {
      case baseUrl:
        return validationSchemas.email
      case `${baseUrl}/name`:
        return validationSchemas.name
      case `${baseUrl}/password`:
        return validationSchemas.password
      case `${baseUrl}/practice`:
        return validationSchemas.organizationName
      default:
        return null
    }
  }

  const getBackLink = () => {
    switch (location.pathname) {
      case baseUrl:
        return null
      case `${baseUrl}/name`:
        return () => history.push(buildUrl({ baseUrl, newPath: '', location }))
      case `${baseUrl}/password`:
        return () =>
          history.push(buildUrl({ baseUrl, newPath: '/name', location }))
      case `${baseUrl}/practice`:
        return () =>
          history.push(buildUrl({ baseUrl, newPath: '/password', location }))
      case `${baseUrl}/video-walkthrough`:
        return () =>
          history.push(
            buildUrl({ baseUrl, newPath: '/recording-readiness', location })
          )
      case `${baseUrl}/video-offramp`:
        return () =>
          history.push(
            buildUrl({ baseUrl, newPath: '/video-walkthrough', location })
          )
      case `${baseUrl}/interactive-demo`:
        return () =>
          history.push(
            buildUrl({ baseUrl, newPath: '/recording-readiness', location })
          )
      case `${baseUrl}/select-note`:
        return () =>
          history.push(
            buildUrl({ baseUrl, newPath: '/interactive-demo', location })
          )
      default:
        return null
    }
  }

  const getSkipLink = () => {
    switch (location.pathname) {
      case `${baseUrl}/get-started`:
        return () =>
          history.push(
            buildUrl({ baseUrl, newPath: '/sample-notes', location })
          )
      case `${baseUrl}/sample-notes`:
        return () => history.push(buildUrl({ baseUrl, newPath: '', location }))
      case `${baseUrl}/interactive-demo`:
        return () =>
          history.push(buildUrl({ baseUrl, newPath: '/select-note', location }))
      default:
        return null
    }
  }

  const getProgress = () => {
    switch (location.pathname) {
      case baseUrl:
        return 0
      case `${baseUrl}/name`:
        return 20
      case `${baseUrl}/password`:
        return 40
      case `${baseUrl}/recording-readiness`:
        return 70
      case `${baseUrl}/practice`:
        return 60
      case `${baseUrl}/get-started`:
      case `${baseUrl}/video-walkthrough`:
        return 80
      case `${baseUrl}/video-offramp`:
      case `${baseUrl}/sample-notes`:
        return 90
      case `${baseUrl}/interactive-demo`:
        return null
      case `${baseUrl}/select-note`:
        return 95
      default:
        return 0
    }
  }

  const getTestimonialStep = () => {
    if (didStartFromAffiliate()) {
      switch (location.pathname) {
        case baseUrl:
          return 2
        case `${baseUrl}/affiliate`:
          return 1
        case `${baseUrl}/name`:
          return 3
        case `${baseUrl}/password`:
          return 4
        case `${baseUrl}/practice`:
          return 5
        default:
          return 1
      }
    } else {
      switch (location.pathname) {
        case baseUrl:
          return 1
        case `${baseUrl}/affiliate`:
          return 5
        case `${baseUrl}/name`:
          return 2
        case `${baseUrl}/password`:
          return 3
        case `${baseUrl}/practice`:
          return 4
        default:
          return 1
      }
    }
  }

  const isInteractiveDemo = location.pathname === `${baseUrl}/interactive-demo`

  const handleSubmit = async (values: FormValues) => {
    await createAccount({
      data: {
        ...values,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hubspotutk: Cookies.get('hubspotutk'),
        hubspotBridgeId,
        referralId: Cookies.get('bp_referral') ?? '',
        rewardfulReferralId: rewardfulReferralId,
        facebookClickId: Cookies.get('_fbc'),
        facebookPixelId: Cookies.get('_fbp'),
        pageUri: window.location.href,
        trialVariant:
          onboardingVersion === OnboardingVersion.EXPERIMENT
            ? 'feb_2025_with_session_demo'
            : 'feb_2025',
        initialProduct: 'documentation_automation',
        trialType: 'freemium',
        affiliateSource: rewardfulReferrerTag
      }
    })
  }

  const handleNextStepValidation = async ({
    validateForm,
    setTouched,
    onSuccess
  }: {
    validateForm: () => Promise<Partial<typeof initialValues>>
    values: typeof initialValues
    setTouched: (touched: Record<string, boolean>) => void
    onSuccess: () => void
  }) => {
    const errors = await validateForm()
    if (Object.keys(errors).length === 0) {
      onSuccess()
    } else {
      setTouched(
        Object.keys(errors).reduce<Record<string, boolean>>((acc, field) => {
          acc[field] = true
          return acc
        }, {})
      )
    }
  }

  const handleUpdateHubspotLead = async (values: FormValues) => {
    submitHubspotTrialLead(
      {
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          hubspotBridgeId,
          hubspotutk: Cookies.get('hubspotutk'),
          referralId: Cookies.get('bp_referral') ?? '',
          facebookClickId: Cookies.get('_fbc'),
          facebookPixelId: Cookies.get('_fbp'),
          pageUri: window.location.href,
          trialVariant:
            onboardingVersion === OnboardingVersion.EXPERIMENT
              ? 'feb_2025_with_session_demo'
              : 'feb_2025',
          affiliateReferrerId: rewardfulReferrerTag
        }
      },
      {
        onSuccess: data => {
          setHubspotBridgeId(data.hubspotBridgeId)
        }
      }
    )
  }

  const isVideoWalkthrough =
    location.pathname === `${baseUrl}/video-walkthrough`

  const handleVideoClose = () => {
    onClosePreviewVideo()
    history.push('/onboarding/video-offramp')
  }

  const handlePlayVideo = () => {
    onOpenPreviewVideo()
  }

  const handleSoloClick = () => {
    trackEvent('Affiliate Program Solo Selected', {
      referralId: soloReferralId,
      onboardingVersion: onboardingVersion,
      affiliate: affiliateSource
    })

    window.location.href = buildUrl({
      baseUrl,
      newPath: '',
      location,
      params: { via: soloReferralId }
    })
  }

  const handleGroupClick = () => {
    trackEvent('Affiliate Program Group Selected', {
      referralId: groupReferralId,
      onboardingVersion: onboardingVersion,
      affiliate: affiliateSource
    })

    window.location.href = buildUrl({
      baseUrl,
      newPath: '',
      location,
      params: { via: groupReferralId }
    })
  }

  return (
    <Flex
      w="100%"
      h="100%"
      p={{ base: 0, md: 4 }}
      gap={{
        base: 0,
        md: location.pathname === `${baseUrl}/get-started` ? 0 : 4
      }}
      bgColor="#F6F7FE"
      _before={{
        content: '""',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage:
          location.pathname === '/onboarding/interactive-demo'
            ? {
                base:
                  'url(https://res.cloudinary.com/hellojoy/image/upload/v1742580151/onboarding/faux_session-bg-mobile-compress-next.webp)',
                sm:
                  'url(https://res.cloudinary.com/hellojoy/image/upload/v1742580129/onboarding/faux_session-bg-tablet-compress.webp)',
                md:
                  'url(https://res.cloudinary.com/hellojoy/image/upload/v1742842078/onboarding/demo-bg-crop-desktop-compress-next.webp)'
              }
            : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0,
        animation:
          location.pathname === '/onboarding/interactive-demo'
            ? 'fadeIn 1s ease-in forwards 1s'
            : 'none'
      }}
      sx={{
        '@keyframes fadeIn': {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 }
        }
      }}
    >
      <Flex
        h="100%"
        flexDirection="column"
        width={{ base: '100%', md: heroImageUrl ? '50%' : '100%' }}
        transition="all 500ms cubic-bezier(0.920, 0.005, 0.145, 1.000)"
        position="relative"
        data-testid="onboarding-container"
      >
        <Navbar
          onBack={getBackLink()}
          onSkip={getSkipLink()}
          isInteractiveDemo={isInteractiveDemo}
        />
        <Flex flex="1" justifyContent={{ base: 'flex-start', md: 'center' }}>
          <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={getCurrentStepValidation(location.pathname)}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              validateForm,
              values,
              setTouched,
              setFieldTouched,
              handleSubmit,
              setFieldError
            }) => (
              <Form
                noValidate
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <AnimatePresence exitBeforeEnter>
                  <Switch location={location} key={location.pathname}>
                    <Route
                      exact
                      path={baseUrl}
                      render={() => (
                        <Email
                          isLoading={isValidatingEmail}
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                validateEmail(
                                  {
                                    data: { email: values.email }
                                  },
                                  {
                                    onSuccess: () => {
                                      handleUpdateHubspotLead(values)
                                      history.push(
                                        buildUrl({
                                          baseUrl,
                                          newPath: '/name',
                                          location
                                        })
                                      )
                                    },
                                    onError: (error: any) => {
                                      setFieldError(
                                        'email',
                                        'An account with this email already exists, try logging in instead'
                                      )
                                      setFieldTouched('email', true, false)
                                    }
                                  }
                                )
                              }
                            })
                          }
                          onboardingVersion={onboardingVersion}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/affiliate`}
                      render={() => (
                        <AffiliateProgram
                          onSoloClick={handleSoloClick}
                          onGroupClick={handleGroupClick}
                          onboardingVersion={onboardingVersion}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/name`}
                      render={() => (
                        <Name
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                handleUpdateHubspotLead(values)
                                history.push(
                                  buildUrl({
                                    baseUrl,
                                    newPath: '/password',
                                    location
                                  })
                                )
                              }
                            })
                          }
                          onboardingVersion={onboardingVersion}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/password`}
                      render={() => (
                        <Password
                          firstName={values.firstName}
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                history.push(
                                  buildUrl({
                                    baseUrl,
                                    newPath: '/practice',
                                    location
                                  })
                                )
                              }
                            })
                          }
                          onboardingVersion={onboardingVersion}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/practice`}
                      render={() => (
                        <Practice
                          isLoading={isSigningUp}
                          isError={isSignupError}
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                handleSubmit()
                              }
                            })
                          }
                          onboardingVersion={onboardingVersion}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/get-started`}
                      render={() => <GetStarted />}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/recording-readiness`}
                      render={() => <RecordingReadiness />}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/video-walkthrough`}
                      render={() => (
                        <VideoWalkthrough onPlayVideo={handlePlayVideo} />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/video-offramp`}
                      render={() => (
                        <VideoOfframp onPlayVideo={handlePlayVideo} />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/interactive-demo`}
                      render={() => <InteractiveDemo />}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/select-note`}
                      render={() => <SelectNote />}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/sample-notes`}
                      render={() => <NotReadyToRecord />}
                    />
                  </Switch>
                </AnimatePresence>
              </Form>
            )}
          </Formik>
        </Flex>
        <ProgressBar progress={getProgress()} />
      </Flex>
      <Box
        width={{ base: '0%', md: heroImageUrl ? '50%' : '0%' }}
        bg={!isVideoWalkthrough ? 'gray.100' : '#EAEEFD'}
        display="flex"
        borderRadius="8px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="relative"
        data-testid="hero-image-container"
      >
        {isVideoWalkthrough ? (
          <FadeIn
            duration={0.5}
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Button
              variant="unstyled"
              aria-label="Play video"
              w="80%"
              position="relative"
              overflow="hidden"
              maxHeight={{
                base: '300px',
                sm: '420px',
                md: '600px'
              }}
              style={{
                borderRadius: '0px',
                height: 'auto',
                aspectRatio: '9/16'
              }}
              cursor="pointer"
              onClick={handlePlayVideo}
              transition="all 0.2s ease-in-out"
              _hover={{
                transform: 'translateY(-10px)',
                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.15)'
              }}
            >
              <video
                preload="auto"
                autoPlay
                muted
                playsInline
                loop
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  display: 'block'
                }}
              >
                <source
                  src="https://res.cloudinary.com/hellojoy/video/upload/v1742925409/onboarding/onboarding_preview_compressed.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <Box position="absolute" bottom="18px" left="18px">
                <Flex
                  width="98px"
                  height="98px"
                  backgroundColor="rgba(255, 255, 255, 0.6)"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <PlayArrow
                    htmlColor="#ffffff"
                    style={{ height: '68px', width: '68px' }}
                  />
                </Flex>
              </Box>
            </Button>
          </FadeIn>
        ) : heroImageUrl ? (
          <AnimatePresence>
            <MotionContainer
              key={heroImageUrl}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                opacity: { duration: 0.5, ease: 'easeInOut' },
                exit: { duration: 0.1, ease: 'easeInOut' }
              }}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: `url(${heroImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            ></MotionContainer>
          </AnimatePresence>
        ) : null}

        {!isVideoWalkthrough && (
          <Box
            w="100%"
            position="absolute"
            top="50%;"
            transform="translateY(-50%)"
          >
            <TestimonialCarousel progress={getTestimonialStep()} />
          </Box>
        )}
      </Box>
      <WelcomeVideoModal
        handleVideoClose={handleVideoClose}
        isOpenPreviewVideo={isOpenPreviewVideo}
        onClosePreviewVideo={onClosePreviewVideo}
      />
    </Flex>
  )
}
