import React, { useEffect } from 'react'
import {
  Flex,
  Container,
  Heading,
  Text,
  ListItem,
  UnorderedList,
  useToast
} from '@chakra-ui/react'
import { useQuery, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { endpoints } from '@api'
import { CancellationForm } from '@components'

export const DeleteV3 = () => {
  const { user } = useStoreState((state: any) => state.auth)
  const history = useHistory()
  const toast = useToast()

  const { data: billing, isFetching: isBillingLoading } = useQuery<any>(
    [endpoints.getClinicBilling.getCacheId(), user.clinic && user.clinic.id],
    () =>
      endpoints.getClinicBilling.request({
        clinicId: user.clinic.id
      }),
    {
      initialData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        // default to the currently selected clinic, otherwise use the user's clinic
        return data
      }
    }
  )

  const logout = useStoreActions((actions: any) => actions.auth.logout)

  const { mutateAsync: deleteAccount } = useMutation(
    endpoints.postDeleteAccount.request,
    {
      onSuccess() {
        logout()
      },
      onError(err) {
        toast({
          title: 'We ran into an issue deleting your account.',
          description: `Please try again or contact support at ${process.env.REACT_APP_HELP_EMAIL}.`,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  useEffect(() => {
    if (!isBillingLoading && billing) {
      if (!billing?.canCancel) {
        history.push('/dashboard')
      }
    }
  }, [billing])

  const handleSubmit = async (values: any) => {
    await deleteAccount({
      cancellation_reason: { id: values.cancellationReason },
      cancellation_feedback: values.cancellationFeedback || null,
      missing_features: values.missingFeatures || null,
      other_reason: values.otherReason || null,
      selected_competitor: values.selectedCompetitor || null
    })
  }

  return (
    <Container maxWidth="672px" centerContent py="40px">
      <Flex flexDirection="column" w="100%" gap="24px" px="small">
        <Flex flexDirection="column" gap="32px">
          <Heading fontSize="24px">Delete your Blueprint account</Heading>
          <Flex flexDirection="column" gap="8px">
            <Text as="b">When you delete your Blueprint account...</Text>
            <UnorderedList>
              <ListItem>
                All clinician accounts will be automatically deleted.
              </ListItem>
              <ListItem>
                All enrolled clients will be automatically deleted.
              </ListItem>
              <ListItem>
                You will no longer be able to log in to the Blueprint portal.
              </ListItem>
              <ListItem>
                You will no longer be billed (unless you have
                outstanding/overdue invoices).
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
        <CancellationForm onSubmit={handleSubmit} isDeleteAccount />
      </Flex>
    </Container>
  )
}
