/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type PatchConsentSettingDTOType = typeof PatchConsentSettingDTOType[keyof typeof PatchConsentSettingDTOType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchConsentSettingDTOType = {
  none: 'none',
  default: 'default',
  custom: 'custom',
} as const;
