import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Text,
  Button,
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'

export const RecordingReadiness = () => {
  // TODO: uncomment this once ready for production
  // Not redirecting here makes testing easier
  const history = useHistory() 
  // const { isAuthenticated } = useStoreState((state: any) => state.auth)

  // if (!isAuthenticated) {
  //   history.push('/')
  // }

  useEffect(() => {
    trackEvent('Onboarding Recording Readiness Viewed', {
      version: 3
    })
  }, [])

  return (
    <Step mt={{ base: '60px', md: 0 }} pb={4}>
      <Box textAlign="left" mb="34px">
        <Text fontSize="32px" fontWeight="bold" style={{ lineHeight: '44px' }}>
          Are you ready to<br />
          record a real session<br />
          <Text as="span" color="primary" fontSize="32px" fontWeight="bold">
            in the next few<br />
            minutes?
          </Text>
        </Text>
      </Box>
      <Box flex={{ base: 1, md: 0 }} mb={{ base: 0, md: '65px' }}>
        <Text fontSize="16px" fontWeight="normal">
          If <b>yes</b>, we can get you 'record ready' in 2 minutes.<br />
          If <b>no</b>, let's explore Blueprint with a test session!
        </Text>
      </Box>
      <Box pt={4} pb={4}>
        <Button 
          isFullWidth 
          m={0}
          mb={4}
          size="lg"
          type="button"
          onClick={() => history.push('/onboarding/video-walkthrough')}
        >
          Yes, I have a client soon
        </Button>
        <Button
          isFullWidth
          m={0}
          variant="outline"
          size="lg"
          type="button"
          onClick={() => history.push('/onboarding/interactive-demo')}
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(45, 84, 232, 0.04) !important'
            }
          }}
        >
          No, show me a demo
        </Button>
      </Box>
    </Step>
  )
}