import React from 'react'
import {
  Box,
  Flex,
  Input,
  Text,
  HStack,
  Link,
  Stack,
  Divider,
  Button,
  FlexProps
} from '@chakra-ui/react'
import { useCheckInControllerGetCheckInsForPlusCustomer, usePatientControllerGetAssignedCheckIns, useSessionControllerGetDemoSuggestedContent, useSessionControllerGetSuggestedContent } from '~/clinician-api'
import { buildCheckInPreviewUrl } from '@utilities'
import { useExperienceManager } from '@hooks'
import { endpoints } from '@api'
import { isMobile } from 'react-device-detect'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'


const WorksheetRow = ({
  title,
  subtitle,
  previewUrl,
  onAssign,
  isAssigned,
  isLast
}: any) => {
  return (
    <Stack spacing="0">
      <HStack py="12px" justifyContent="space-between" gap="24px">
        <Stack spacing="4px">
          <Text fontSize={isMobile ? "14px" : "16px"}>{title}</Text>
          <Text fontSize={isMobile ? "12px" : "14px"} color="dark_gray">{subtitle}</Text>
        </Stack>
        <Stack
          gap={{ base: "12px", sm: "12px" }}
          spacing={{ base: "0", sm: "0" }}
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'center' }}
        >
          <Button
            isDisabled={isAssigned}
            textDecoration="none"
            borderRadius="8px"
            size={"sm"}
            fontSize={isMobile ? "14px" : "16px"}
            _focus={{ outline: 'none' }}
            onClick={onAssign}
            w="110px"
            order={{ base: 1, sm: 2 }}
          >
            {isAssigned ? 'Assigned' : 'Assign'}
          </Button>
          <Link
            href={previewUrl}
            target="_blank"
            color="primary"
            textDecoration="none"
            fontSize={isMobile ? "14px" : "16px"}
            _focus={{ outline: 'none' }}
            mr={{ base: 0, sm: 4 }}
            order={{ base: 2, sm: 1 }}
          >
            Preview
          </Link>
        </Stack>
      </HStack>
      {!isLast && <Divider m="0" />}
    </Stack>
  )
}

interface LibraryProps {
  client: { id: string, is_demo: boolean }
  onAssign: (assessment: any) => void
  searchContainerProps?: FlexProps
}

const Library = ({ client, onAssign, searchContainerProps = {} }: LibraryProps) => {
  const [searchText, setSearchText] = React.useState('')

  const { data: checkIns = [] } = useCheckInControllerGetCheckInsForPlusCustomer()

  const { data: assignedCheckIns = [] } = usePatientControllerGetAssignedCheckIns(
    client.id
  )

  let match: any = useRouteMatch('/patient/:patientId/session/:sessionId')

  const { isDemoClinician } = useExperienceManager()


  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId(), client?.id],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client?.id
      })
    },
    {
      enabled: !!client?.id
    }
  )

  const sessionId = lastSessionQuery?.data?.id
  const showDemoExperience = client?.is_demo && isDemoClinician

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId && !showDemoExperience
    }
  })

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId || match?.params?.sessionId,
    { demoContext: 'in-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  const suggestedWorksheets = showDemoExperience
  ? demoData?.suggestedWorksheets?.filter(worksheet => !worksheet.isActive)
    : suggestionsQuery?.data?.suggestedWorksheets?.filter(worksheet => !worksheet.isActive)

  const handleAssign = async (worksheet: any) => {
    onAssign(worksheet)
  }

  // @ts-ignore
  const filteredWorksheets: any[] = checkIns?.filter(w => w.type === 'treatment_activity' && w.title.toLowerCase().includes(searchText.toLowerCase())) || []

  return (
    <Box flex="1" overflowY="hidden">
      <Flex
        h="100px"
        align="center"
        flex="1"
        borderBottom="1px solid"
        borderColor="pale_gray"
        p="24px"
        {...searchContainerProps}
      >
        <Input
          placeholder="Search worksheets"
          onChange={e => setSearchText(e.currentTarget.value)}
          value={searchText}
          height="58px"
        />
      </Flex>
      <Stack pb={4} h={isMobile ? "calc(100vh - 250px)" : suggestedWorksheets && suggestedWorksheets?.length > 0 ? "calc(100vh - 210px)" : "calc(100vh - 190px)"} overflowY="scroll" spacing="0">

      {suggestedWorksheets && suggestedWorksheets?.length > 0 && (
        <>
          <Box p="4px 24px" bg="#F4F4F4">
            <Text fontWeight="bold" color="#757575" fontSize="12px" textTransform="uppercase">
              Suggestions
            </Text>
      </Box>
      <Stack px="24px" pt={'4px'} pb={'32px'} h={'100%'} spacing="0">
        {suggestedWorksheets?.map((w, index) => (
          <WorksheetRow
            key={w.checkInId}
            title={w.worksheetName}
            subtitle={w.rationale}
            previewUrl={buildCheckInPreviewUrl(w.checkInId)}
            isAssigned={assignedCheckIns.some(c => c.checkInId === w.checkInId )}
            onAssign={() => handleAssign(w)}
            isLast={index === suggestedWorksheets.length - 1}
          />
            ))}
          </Stack>
        </>
      )}

      <Box p="4px 24px" bg="#F4F4F4">
        <Text fontWeight="bold" color="#757575" fontSize="12px" textTransform="uppercase">
          A to Z
        </Text>
      </Box>
      <Stack px="24px" pt={'4px'} pb={'32px'} h={"100%"} spacing="0">
        {filteredWorksheets.map((w, index) => (
          <WorksheetRow
            key={w.id}
            title={w.title}
            subtitle={w.subtitle}
            previewUrl={buildCheckInPreviewUrl(w.id)}
            isAssigned={assignedCheckIns.some(c => c.checkInId === w.id )}
            onAssign={() => handleAssign(w)}
            isLast={index === filteredWorksheets.length - 1}
          />
        ))}
        </Stack>
      </Stack>
    </Box>
  )
}

export default Library
