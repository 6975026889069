import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { StoreProvider, useStoreState, useStoreActions } from 'easy-peasy'
import { ChakraProvider } from '@chakra-ui/react'
import { theme as defaultJoyTheme } from '@blueprinthq/joy'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MuiThemeProvider } from '@material-ui/core/styles'
import FontFaceObserver from 'fontfaceobserver'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { datadogLogs } from '@datadog/browser-logs'
import * as clinicianTracking from './lib/clinician-tracking'
import { datadogRum } from '@datadog/browser-rum'
import { Router } from 'react-router-dom'
import history from './history'

import './lib/yup-phone'

import './css/base.css'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import theme from './theme'
import { ClinicSelectProvider } from './containers/clinic-select'
import { AssignCheckInsModalProvider } from './containers/assign-check-ins-modal'

// Rewardful initialization - using the exact format from the documentation
// This must be placed before the script tag that loads Rewardful
if (typeof window !== 'undefined') {
  ;(function(w, r) {
    w._rwq = r
    w[r] =
      w[r] ||
      function() {
        ;(w[r].q = w[r].q || []).push(arguments)
      }
  })(window, 'rewardful')
}

let config
import('./config').then(c => {
  config = c.default
  if (config.globalCSSPath) {
    return import(`${config.globalCSSPath}`)
  }
})

// Load the Rewardful script
if (typeof document !== 'undefined' && process.env.REACT_APP_REWARDFUL_ID) {
  const script = document.createElement('script')
  script.async = true
  script.src =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://affiliate-collector.blueprint.ai/rw.js'
      : 'https://affiliate-collector.staging.blueprint.ai/rw.js'
  script.setAttribute('data-rewardful', process.env.REACT_APP_REWARDFUL_ID)
  document.head.appendChild(script)
}

if (
  window.Intercom &&
  process.env.REACT_APP_HIDE_INTERCOM !== 'true' &&
  process.env.REACT_APP_ENV !== 'development' &&
  !window.location.pathname.includes('/extension') &&
  !window.location.pathname.includes('/widget')
) {
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_KEY
  })
}
if (process.env.REACT_APP_ENV !== 'development') {
  if (process.env.REACT_APP_DATADOG_RUM_ENABLED === 'true') {
    datadogRum.init({
      site: 'datadoghq.com',
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      env: process.env.REACT_APP_ENV,
      service: process.env.REACT_APP_DATADOG_SERVICE,
      version: process.env.REACT_APP_DD_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      proxy: options =>
        `https://data-collector.blueprint-health.com${options.path}?${options.parameters}`
    })
  }
  datadogLogs.init({
    env: process.env.REACT_APP_ENV,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    version: process.env.REACT_APP_DD_VERSION,
    forwardErrorsToLogs: true,
    proxy: options =>
      `https://data-collector.blueprint-health.com${options.path}?${options.parameters}`
  })
}

// Mixpanel Setup
clinicianTracking.initTracking()

const rootEl = document.getElementById('root')
rootEl.addEventListener('click', event => {
  const { target } = event
  let el = target

  // Handles click events of elements inside of a button or link
  if (target.nodeName !== 'BUTTON' && target.nodeName !== 'A') {
    const parentButton = target.closest('button')
    const parentA = target.closest('a')

    if (parentButton) {
      el = parentButton
    } else if (parentA) {
      el = parentA
    } else {
      // Do not log any events if there is no parent button or link
      return
    }
  }

  const eventName = el.dataset.clickEventName

  if (
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'staging'
  ) {
    console.log('Tracking click event', { eventName, nodeType: el.nodeName })
  }

  if (eventName) {
    clinicianTracking.trackEvent(eventName)
  }

  if (el.nodeName === 'BUTTON') {
    clinicianTracking.trackEvent('Clicked Button', {
      buttonText: el.getAttribute('aria-label') || el.textContent
    })
  } else if (el.nodeName === 'A') {
    clinicianTracking.trackEvent('Clicked Link', {
      linkText: el.getAttribute('aria-label') || el.textContent
    })
  }
})

export const Context = React.createContext()
class AppProvider extends React.Component {
  state = {
    active_assessments: [],
    patient_info: {},
    patient_list: [],
    clinician_list: [],
    updateAssessments: new_active_assessments =>
      this.setState({ active_assessments: new_active_assessments }),
    updatePatientInfo: patient_info => this.setState({ patient_info }),
    clearPatientList: () => this.setState({ patient_list: [] })
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

const SessionWrap = ({ children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const { isAuthenticated } = useStoreState(state => state.auth)
  const resumeSession = useStoreActions(actions => actions.auth.resumeSession)

  useEffect(() => {
    async function run() {
      if (!isAuthenticated) {
        setIsAuthenticating(true)
        try {
          datadogLogs.logger.info(
            '[Auth Debug - SessionWrap] Attempting to resume session'
          )
          await resumeSession()
        } catch (error) {
          console.log(error)
        }
        setIsAuthenticating(false)
      }
    }
    run()
  }, [isAuthenticated])

  return isAuthenticating ? null : children
}

serviceWorker.unregister()

export const queryClient = new QueryClient()

const App = () => (
  <ChakraProvider theme={defaultJoyTheme} portalZIndex={9999999999}>
    <QueryClientProvider client={queryClient}>
      <StoreProvider store={store}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppProvider>
              <SessionWrap>
                <ClinicSelectProvider>
                  <AssignCheckInsModalProvider>
                    <Router history={history}>
                      <Routes />
                    </Router>
                  </AssignCheckInsModalProvider>
                </ClinicSelectProvider>
              </SessionWrap>
            </AppProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </StoreProvider>
    </QueryClientProvider>
  </ChakraProvider>
)

async function renderApp() {
  try {
    const fonts = [
      new FontFaceObserver('TofinoPersonal'),
      new FontFaceObserver('TofinoPersonal', { weight: 'bold' })
    ]
    await Promise.all(fonts.map(font => font.load(null, 5000)))
  } catch (err) {
    console.error('Error loading fonts', err)
  } finally {
    ReactDOM.render(<App />, document.getElementById('root'))
  }
}

renderApp()

export default Context
