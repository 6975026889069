import React from 'react'
import { cloudinaryAssetToUrl } from '../../../utilities'
import { Link } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { Button, NewTabIcon } from '@blueprinthq/joy'
import { Gift } from '../../../components/icons'
import {
  Box,
  HStack,
  Avatar,
  Heading,
  Text,
  MenuItem,
  Divider,
  Flex,
  Spacer
} from '@chakra-ui/react'
import BusinessIcon from '@material-ui/icons/Business'
import { useNavLinks, useExperienceManager } from '@hooks'
import { REFERRAL_AMOUNT } from '../../../constants/referral'
import { trackEvent } from '@lib/clinician-tracking'

export const UserSettingsDropdown = ({ user, isLegacyPlan }) => {
  const { settingsLinkGroups } = useNavLinks(isLegacyPlan)
  const {
    isEvidenceBasedCareEnabled,
    showReferralV2Experience
  } = useExperienceManager()
  const linkGroups = settingsLinkGroups.filter(
    grp => grp.group === 'Clinic' || grp.group === 'Organization'
  )
  const openPlansModal = useStoreActions(
    actions => actions.modals.choosePlan.openModal
  )
  return (
    <Box pb="0">
      <HStack align="start" padding="small">
        <Avatar
          width="44px"
          height="44px"
          src={cloudinaryAssetToUrl(user.avatar, {
            transformations: {
              resize: {
                height: 128,
                width: 128,
                type: 'scale'
              }
            }
          })}
          name={`${user.first_name} ${user.last_name}`}
          bg="pale_gray"
          size="sm"
          mr="xsmall"
          fontWeight="bold"
        />
        <Box>
          <Heading size="md">
            {user.first_name} {user.last_name}
          </Heading>
          <Text mt="2px">{user.email}</Text>
          <Button
            mt="12px"
            variant="outline"
            size="sm"
            pl="0"
            pr="0"
            overflow="clip"
          >
            <MenuItem
              pt="0"
              pb="0"
              pr="12px"
              pl="12px"
              minHeight="100%"
              as={Link}
              to="/settings/account"
            >
              Edit profile
            </MenuItem>
          </Button>
        </Box>
      </HStack>
      <HStack
        bg="#E4E5E6"
        pt="xsmall"
        pb="xsmall"
        pl="small"
        pr="small"
        spacing="xsmall"
      >
        <BusinessIcon />
        <Text size="12px" as="b">
          {user.clinic.name}
        </Text>
      </HStack>
      {linkGroups.map((linkGroup, i) => (
        <Box key={i}>
          {linkGroup.links.map((link, idx) => (
            <Box
              key={idx}
              data-intercom-target={`user-settings-dropdown-${link.display}`}
            >
              <MenuItem
                as={Link}
                to={link.path}
                onClick={() => {
                  link.key === 'plans' && openPlansModal()
                }}
              >
                {link.display}
              </MenuItem>
            </Box>
          ))}
        </Box>
      ))}
      {showReferralV2Experience ? (
        <MenuItem
          as={Link}
          onClick={() =>
            trackEvent('Referral V2 -> Clicked Refer A Friend', {
              source: 'User Settings Dropdown'
            })
          }
          to="/refer"
        >
          <Flex w="100%" align="center" justify="space-between">
            <Text color="primary">
              Refer a friend, get {REFERRAL_AMOUNT} in credits
            </Text>
            <Spacer />
            <Gift fill="#2D54E8" />
          </Flex>
        </MenuItem>
      ) : (
        <MenuItem as={Link} to="/refer">
          Refer (Get $100 off your bill)
        </MenuItem>
      )}
      {isEvidenceBasedCareEnabled && (
        <MenuItem
          as={Link}
          to="#"
          className="bot_benefit_check"
          rel="noopener noreferrer"
        >
          <Flex direction="row" width="100%" align="center">
            <Text>Benefit Check</Text>
            <Spacer />
            <NewTabIcon size="sm" />
          </Flex>
        </MenuItem>
      )}
      <MenuItem
        as={Link}
        to={{ pathname: process.env.REACT_APP_HELP_URL }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Flex direction="row" width="100%" align="center">
          <Text>Help Center</Text>
          <Spacer />
          <NewTabIcon size="sm" />
        </Flex>
      </MenuItem>
      <Divider />
      <MenuItem as={Link} to="/logout">
        Log out
      </MenuItem>
    </Box>
  )
}
