import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import {
  Box,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Text,
  Divider,
  Checkbox,
  Tooltip
} from '@chakra-ui/react'
import { Button, Checkbox as BPCheckbox } from '@blueprinthq/joy'
import { isMobile } from 'react-device-detect'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { Loading, AlertDialog } from '@components'
import { Warning } from '@components/icons'
import { endpoints } from '@api'
import { useLocation, useHistory } from 'react-router-dom'
import { useStyles } from '../styles'
import { useExperienceManager } from '@hooks'
import {
  getPatientControllerGetAssignedCheckInsQueryKey,
  useDischargeSummariesControllerGenerateDischargeSummary,
  useDischargeSummariesControllerGetDischargeSummaries
} from '~/clinician-api'
import { trackEvent } from '@lib/clinician-tracking'

const ArchivePatientModal = ({
  patient,
  reason,
  sendEOCAssessments,
  isOpen,
  handleCloseModal
}) => {
  const queryClient = useQueryClient()
  const [selectedReason, setSelectedReason] = useState(reason || '')
  const [sendEndOfCareAssessments, setSendEndOfCareAssessments] = useState(
    sendEOCAssessments ?? true
  )
  const [errorMsg, setErrorMsg] = useState(null)
  const [
    shouldGenerateDischargeSummary,
    setShouldGenerateDischargeSummary
  ] = useState(true)
  const [showSuccessContent, setShowSuccessContent] = useState(false)
  const classes = useStyles()
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  const location = useLocation()
  const history = useHistory()

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const {
    data: dischargeSummaries
  } = useDischargeSummariesControllerGetDischargeSummaries(patient.id, {
    query: {
      enabled: !isEvidenceBasedCareEnabled,
      initialData: []
    }
  })

  const hasDischargeSummary = useMemo(
    () => Boolean(dischargeSummaries.length),
    [dischargeSummaries]
  )

  useEffect(() => {
    if (hasDischargeSummary || isEvidenceBasedCareEnabled) {
      setShouldGenerateDischargeSummary(false)
    }
  }, [hasDischargeSummary, isEvidenceBasedCareEnabled])

  const { isLoading: isArchiveReasonsLoading, data } = useQuery(
    endpoints.getClinicianArchiveReasons.getCacheId(),
    endpoints.getClinicianArchiveReasons.request,
    {
      enabled: !!patient,
      placeholderData: []
    }
  )

  const resetAndCloseModal = useCallback(() => {
    handleCloseModal()
    // Avoid user from seeing value being changed well closing modal
    setTimeout(() => {
      setSelectedReason('')
      setErrorMsg('')
    }, 150)
  })

  const {
    mutateAsync: generateDischargeSummary
  } = useDischargeSummariesControllerGenerateDischargeSummary({
    mutation: {
      enabled: shouldGenerateDischargeSummary
    }
  })

  const {
    mutate: executeArchivePatient,
    isLoading: isArchiveLoading
  } = useMutation(endpoints.postCliniciansUsersArchive.request, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(endpoints.getPatientList.getCacheId()),
        queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          patient.id
        ]),
        queryClient.invalidateQueries(
          endpoints.getClinicianUserActiveAssessments.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getCheckInModulesForUser.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getUnreadNotificationCount.getCacheId()
        ),
        queryClient.invalidateQueries(
          getPatientControllerGetAssignedCheckInsQueryKey(patient.id)
        )
      ])
      queryClient.invalidateQueries([
        endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
        patient.id
      ])

      if (shouldGenerateDischargeSummary || hasDischargeSummary) {
        setShowSuccessContent(true)
      } else {
        resetAndCloseModal()
        await queryClient.invalidateQueries(
          endpoints.getClinicianUserAccount.getCacheId()
        )
      }

      const message = isEvidenceBasedCareEnabled
        ? `${patient.first_name} ${patient.last_name} was successfully discharged!`
        : `${patient.first_name} ${patient.last_name} was successfully archived!`
      setSnackbarMessage({
        variant: 'success',
        message
      })

      if (location.pathname.includes('pre-enroll-patient')) {
        history.replace(
          location.pathname.replace('pre-enroll-patient', 'patient')
        )
      }
    },
    onError: () =>
      setErrorMsg(
        'Oops, something went wrong. Please refresh the page and try again.'
      )
  })

  const handleSelectReason = useCallback(reason => setSelectedReason(reason), [
    setSelectedReason
  ])

  const submit = useCallback(() => {
    executeArchivePatient({
      clientId: patient.id,
      data: {
        archive_reason: selectedReason,
        send_end_of_care_assessments:
          isEvidenceBasedCareEnabled && sendEndOfCareAssessments
      }
    })

    if (shouldGenerateDischargeSummary) {
      trackEvent(
        'Discharge Summary -> Archived Client with Generate Summary Selected',
        {
          isEvidenceBasedCareEnabled,
          hasDischargeSummary,
          clientId: patient.id
        }
      )
      generateDischargeSummary({ patientId: patient.id })
    }
  }, [
    patient,
    selectedReason,
    executeArchivePatient,
    sendEndOfCareAssessments,
    isEvidenceBasedCareEnabled,
    shouldGenerateDischargeSummary,
    hasDischargeSummary,
    generateDischargeSummary
  ])

  return isEvidenceBasedCareEnabled ? (
    <Dialog
      open={isOpen}
      onClose={resetAndCloseModal}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={isMobile}
    >
      {isArchiveReasonsLoading ? (
        <div style={{ height: '256px' }}>
          <Loading />
        </div>
      ) : (
        <div className={classes.dialog}>
          <DialogTitle>
            <Heading className={classes.centerText}>Discharge Client?</Heading>
          </DialogTitle>
          <DialogContent className="content">
            <Text align="center">
              Are you sure you want to discharge this client?
            </Text>
            {errorMsg && <p className="errorMessage">{errorMsg}</p>}
            {!reason && !sendEOCAssessments && (
              <>
                <Heading size="sm" mt="5" mb="3">
                  Select a reason:
                </Heading>
                <RadioGroup
                  value={selectedReason}
                  onChange={value => handleSelectReason(value)}
                >
                  <Stack>
                    {data.map(reason => (
                      <Radio key={reason} value={reason}>
                        {reason}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                {isEvidenceBasedCareEnabled && (
                  <>
                    <Divider my="3" />
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        isChecked={sendEndOfCareAssessments}
                        onChange={e =>
                          setSendEndOfCareAssessments(e.target.checked)
                        }
                      >
                        Send end of care assessments to client
                      </Checkbox>
                      <Tooltip
                        label="When a client is discharged, they will be asked to complete their assigned assessments one last time along with a 1-question satisfaction with care survey."
                        maxWidth="300px"
                        placement="bottom-start"
                        hasArrow
                      >
                        <Box ml="2">
                          <Warning width="20px" height="20px" fill="black" />
                        </Box>
                      </Tooltip>
                    </Box>
                  </>
                )}
              </>
            )}
            <div className={classes.buttonContainer}>
              <Button
                onClick={resetAndCloseModal}
                size="lg"
                variant="outline"
                width="100%"
              >
                Cancel
              </Button>
              <Button
                isLoading={isArchiveLoading}
                size="lg"
                onClick={submit}
                disabled={!selectedReason}
                bg="severe"
                width="100%"
              >
                Discharge
              </Button>
            </div>
          </DialogContent>
        </div>
      )}
    </Dialog>
  ) : (
    <>
      <AlertDialog
        header={'Archive this client?'}
        isOpen={isOpen && !showSuccessContent}
        onClose={handleCloseModal}
        onConfirm={submit}
        text={
          <>
            Archiving a client removes them from the list of active clients, but
            keeps their data intact and allows you to restore them at any time.
            <br />
            <br />
            Archiving a client can be useful when you no longer see a client but
            want to keep their files and information
            {!hasDischargeSummary && (
              <Box
                p="16px"
                mt="medium"
                mb="small"
                borderRadius="8px"
                border={
                  shouldGenerateDischargeSummary ? '1px solid #C0CCF8' : ''
                }
                bg="gray.100"
              >
                <BPCheckbox
                  size="lg"
                  isChecked={shouldGenerateDischargeSummary}
                  onChange={e =>
                    setShouldGenerateDischargeSummary(e.target.checked)
                  }
                >
                  <Text
                    ml="4px"
                    color={shouldGenerateDischargeSummary ? 'primary' : ''}
                  >
                    Generate a Discharge Summary
                  </Text>
                </BPCheckbox>
              </Box>
            )}
          </>
        }
        isConfirming={isArchiveLoading}
        confirmText={'Archive client'}
      />
      <AlertDialog
        header={'Archive successful'}
        isOpen={isOpen && showSuccessContent}
        onClose={handleCloseModal}
        onConfirm={async () => {
          resetAndCloseModal()
          await queryClient.invalidateQueries(
            endpoints.getClinicianUserAccount.getCacheId()
          )
        }}
        text={
          hasDischargeSummary ? (
            <>
              This client is now archived and you can view their Discharge
              Summary at any time in the 'Tx Plan & Discharge' tab
            </>
          ) : (
            <>
              This client is now archived and their Discharge Summary is
              generating in the 'Tx Plan & Discharge' tab
            </>
          )
        }
        isConfirming={isArchiveLoading}
        confirmText={'Done'}
        hideCancel
      />
    </>
  )
}

export default React.memo(ArchivePatientModal)
