import React, { ReactNode, forwardRef } from 'react'
import { useExperienceManager } from '@hooks'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Divider,
  AlertDialogFooter,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ArrowLeftIcon } from '@blueprinthq/joy'

type ScrollBehavior = 'inside' | 'outside'

export const DialogContainer = forwardRef<HTMLDivElement, {
  children: ReactNode
  onClose: () => void
  isOpen: boolean
  closeOnOverlayClick?: boolean
  size?: string
  scrollBehavior?: ScrollBehavior
}>(({
  children,
  onClose,
  isOpen,
  size,
  scrollBehavior
}, ref) => {
  const { isExtension, isWidget } = useExperienceManager()
  const modalSize = useBreakpointValue({
    base: 'full',
    sm: 'md'
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isExtension || isWidget ? 'full' : size || modalSize}
      returnFocusOnClose={false}
      isCentered
      scrollBehavior={
        scrollBehavior
          ? scrollBehavior
          : size || modalSize === 'full'
            ? 'inside'
            : 'outside'
      }
    >
      <ModalOverlay />
      <ModalContent ref={ref} borderRadius={modalSize === 'full' ? '0px' : '4px'}>
        {children}
      </ModalContent>
      </Modal>
    )
  }
)

DialogContainer.displayName = 'DialogContainer'

export const DialogBody = ({
  children,
  p = 6
}: {
  children: ReactNode
  p?: number | string
}) => {
  return <ModalBody p={p}>{children}</ModalBody>
}

export const DialogHeader = ({
  text,
  onClose,
  onBack,
  hideBorder = false,
  textAlign = 'center',
  pt = 5,
  pr = 5,
  pb = 5,
  pl = 5
}: {
  text: string
  onClose?: () => void
  onBack?: () => void,
  hideBorder?: boolean,
  textAlign?: 'left' | 'center' | 'right',
  pt?: number | string,
  pr?: number | string,
  pb?: number | string,
  pl?: number | string
}) => {

  const handleBack = (e: any) => {
    e.preventDefault()
    if (onBack) {
      onBack()
    }
  }

  return (
    <>
      <ModalHeader 
        fontSize="20px" 
        textAlign={textAlign} 
        pt={pt}
        pr={pr}
        pb={pb}
        pl={pl}
      >
        {text}
      </ModalHeader>
      <Box>
        {onBack && (
          <ModalCloseButton _focus={{ outline: 'none' }} top={5} left={2} onClick={handleBack}>
            <ArrowLeftIcon fill="inherit" />
          </ModalCloseButton>
        )}
        {onClose && <ModalCloseButton _focus={{ outline: 'none' }} top={5} />}
      </Box>
      {!hideBorder && <Divider />}
    </>
  )
}

export const DialogFooter = ({
  children,
  p = 4,
  bg = 'white',
  borderTop = '1px solid #E4E5E6'
}: {
  children: ReactNode
  p?: number | string
  bg?: string
  borderTop?: string
}) => {
  return (
    <AlertDialogFooter
      height={'80px'}
      borderTop={borderTop}
      p={p}
      bg={bg}
      borderBottomRadius="4px"
    >
      {children}
    </AlertDialogFooter>
  )
}
