const REFERRAL_AMOUNT = '$100'

const SOCIAL_LINKS_COPY = `I love Blueprint's AI Assistant for automating progress notes! It also shares client-focused insights along the way 😀 Therapist friends: Use my referral code to get 5 free notes and ${REFERRAL_AMOUNT} off any paid plan if you like it!`

const OG_TAG_TITLE = `Join Blueprint - Get ${REFERRAL_AMOUNT} Credit!`

const REFERRAL_IMAGE_URL =
  'https://res.cloudinary.com/hellojoy/image/upload/v1740008080/referral_image_2_v7t9nb.jpg'

const REFERRAL_IMAGE_BLURRED_EDGES_URL =
  'https://res.cloudinary.com/hellojoy/image/upload/v1740157846/referral_image_blurred_edges_az5eqq.jpg'

const FB_APP_ID = '1682632589272688'

module.exports = {
  REFERRAL_AMOUNT,
  SOCIAL_LINKS_COPY,
  OG_TAG_TITLE,
  REFERRAL_IMAGE_URL,
  FB_APP_ID,
  REFERRAL_IMAGE_BLURRED_EDGES_URL
}
