import React from 'react'
import { Box, Flex, Image as ChakraImage, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionFlex = motion(Flex)

type TransitionProps = {
  duration: number
  delay: number
}

export const TestimonialCard = ({
  testimonial,
  attribution,
  attributionClinic,
  image,
  imageAlt,
  inFocus,
  childProps,
  transitionProps
}: {
  testimonial: string
  attribution: string
  attributionClinic?: string
  image: string
  imageAlt: string
  inFocus: boolean
  childProps?: Record<string, any>
  transitionProps: TransitionProps
}) => {
  return (
    <Flex justify="center" align="center" flexShrink={0} {...childProps}>
      <MotionFlex
        direction="column"
        bg="rgba(0, 0, 0, 0.1)" // Transparent black background
        backdropFilter="blur(20px)" // Blurs background
        borderRadius="8px"
        initial={{ opacity: 0 }}
        animate={{ opacity: inFocus ? 1 : 0.4 }}
        transition={{
          duration: transitionProps.duration,
          delay: transitionProps.delay
        }}
      >
        <Box h="12px" my="15px" px="15px">
          <ChakraImage
            src="https://res.cloudinary.com/hellojoy/image/upload/v1740617576/onboarding/testimonial-begin-quote.svg"
            alt="Begin quote"
            width="16px"
            height="12px"
          />
        </Box>
        <Box h="100%">
          <Box w="70%" m="auto" textAlign="center">
            <Text color={'white'} fontSize="16px">
              {testimonial}
            </Text>
            <Text color={'white'} fontSize="12px" mt="10px">
              {attribution}
            </Text>
            <Text color="#ffffff80" fontSize="12px">
              {attributionClinic}
            </Text>
            <ChakraImage
              src={image}
              alt={imageAlt}
              w="33px"
              h="33px"
              mx="auto"
              mt="15px"
            />
          </Box>
        </Box>
        <Box h="12px" my="15px" px="15px">
          <ChakraImage
            src="https://res.cloudinary.com/hellojoy/image/upload/v1740617576/onboarding/testimonial-end-quote.svg"
            alt="End quote"
            style={{ marginLeft: 'auto' }}
            width="16px"
            height="12px"
          />
        </Box>
      </MotionFlex>
    </Flex>
  )
}
