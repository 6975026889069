import React, { useMemo } from 'react'
import { AddCircleIcon, CheckCircle } from '@blueprinthq/joy'
import {
  Box,
  Flex,
  Input,
  Text,
  HStack,
  Link,
  Stack,
  Divider,
  Button,
  FlexProps
} from '@chakra-ui/react'
import { AssessmentProvider, useAssessmentLibraryContext } from './context'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import { useRouteMatch } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
const AssessmentRow = ({
  assessmentId,
  clinicAssessmentId,
  assessmentName,
  rationale,
  title,
  subtitle,
  previewUrl,
  isSelected,
  onSelect,
  onAssign,
  isAssigned,
  isLast
}: any) => {
  const icon = useMemo(() => {
    let i = (
      <AddCircleIcon
        onClick={() => onSelect(clinicAssessmentId)}
        role="button"
        aria-label="Select Assessment"
        tabIndex={0}
        cursor="pointer"
        fill="primary"
        sx={{ marginLeft: '0' }}
      />
    )

    if (isAssigned) {
      i = <CheckCircle w="24px" h="24px" fill="#E4E5E6" />
    } else if (isSelected) {
      i = (
        <CheckCircle
          onClick={() => onSelect(clinicAssessmentId)}
          w="24px"
          h="24px"
          role="button"
          aria-label="Deselect Assessment"
          tabIndex={0}
          cursor="pointer"
          fill="primary"
          sx={{ marginLeft: '0' }}
        />
      )
    }

    return i
  }, [assessmentId, isAssigned, isSelected, onSelect])

  return (
    <Stack spacing="0">
      <HStack py="12px" justifyContent="space-between" gap="24px">
        <Stack spacing="4px">
          <Text fontSize={isMobile ? '14px' : '16px'}>
            {title ? title : assessmentName}
          </Text>
          <Text fontSize={isMobile ? '12px' : '14px'} color="dark_gray">
            {subtitle ? subtitle : rationale}
          </Text>
        </Stack>
        <Stack
          gap="12px"
          spacing="0"
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'center' }}
        >
          <Button
            isDisabled={isAssigned}
            textDecoration="none"
            borderRadius="8px"
            size="sm"
            _focus={{ outline: 'none' }}
            onClick={onAssign}
            w="110px"
            fontSize={isMobile ? '14px' : '16px'}
            order={{ base: 1, sm: 2 }}
          >
            {isAssigned ? 'Assigned' : 'Assign'}
          </Button>
          <Link
            href={previewUrl}
            target="_blank"
            color="primary"
            textDecoration="none"
            fontSize={isMobile ? '14px' : '16px'}
            _focus={{ outline: 'none' }}
            mr={{ base: 0, sm: 4 }}
            order={{ base: 2, sm: 1 }}
          >
            Preview
          </Link>
        </Stack>
      </HStack>
      {!isLast && <Divider m="0" />}
    </Stack>
  )
}

interface LibraryProps {
  onAssign: (assessment: any) => void
  searchContainerProps?: FlexProps
}

const Library = ({
  onAssign,
  searchContainerProps = {},
  client
}: LibraryProps & { client: any }) => {
  const {
    filteredAssessments,
    setSearchText,
    searchText
  } = useAssessmentLibraryContext()
  let match: any = useRouteMatch('/patient/:patientId/session/:sessionId')

  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId(), client?.id],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client?.id
      })
    },
    {
      enabled: !!client?.id
    }
  )

  const sessionId = lastSessionQuery?.data?.id

  const { isDemoClinician } = useExperienceManager()
  const showDemoExperience = client?.is_demo && isDemoClinician

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: (data: any) => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId && !showDemoExperience
    }
  })

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId || match?.params?.sessionId,
    { demoContext: 'in-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  const suggestedAssessments = showDemoExperience
    ? demoData?.suggestedAssessments?.filter(
        (suggestion: any) => !suggestion.isActive
      )
    : suggestionsQuery?.data?.suggestedAssessments?.filter(
        (suggestion: any) => !suggestion.isActive
      )

  const handleAssign = async (assessment: any) => {
    onAssign(assessment)
  }

  return (
    <Box flex="1" overflowY="hidden">
      <Flex
        h="100px"
        align="center"
        flex="1"
        borderBottom="1px solid"
        borderColor="pale_gray"
        p="24px"
        {...searchContainerProps}
      >
        <Input
          placeholder="Search assessments"
          onChange={e => setSearchText(e.currentTarget.value)}
          value={searchText}
          style={{
            height: '58px'
          }}
        />
      </Flex>

      <Stack spacing={0} h="calc(100vh - 190px)" overflowY="scroll">
        {/* Suggestions Section */}
        {suggestedAssessments?.length > 0 && (
          <>
            <Box p="4px 24px" w="100%" bg="#F4F4F4">
              <Text
                fontWeight="bold"
                fontSize="12px"
                color="#757575"
                textTransform="uppercase"
              >
                Suggestions
              </Text>
            </Box>
            <Stack px="24px" pt={'4px'} pb={'32px'} spacing="0">
              {suggestedAssessments?.map((a: any, index: any) => (
                <AssessmentRow
                  key={a.assessmentId}
                  {...a}
                  onAssign={() => handleAssign(a)}
                  isLast={index === suggestedAssessments.length - 1}
                />
              ))}
            </Stack>
          </>
        )}

        {/* A-Z Section */}
        <Box p="4px 24px" w="100%" bg="#F4F4F4">
          <Text
            fontWeight="bold"
            fontSize="12px"
            color="#757575"
            textTransform="uppercase"
          >
            A to Z
          </Text>
        </Box>
        <Stack px="24px" pt={'4px'} pb={'32px'} spacing="0">
          {filteredAssessments.map((a, index) => (
            <AssessmentRow
              key={a.id}
              {...a}
              onAssign={() => handleAssign(a)}
              isLast={index === filteredAssessments.length - 1}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  )
}

const WrappedLibrary = ({
  client,
  onAssign,
  searchContainerProps
}: LibraryProps & { client: any }) => {
  return (
    <AssessmentProvider client={client}>
      <Library
        onAssign={onAssign}
        client={client}
        searchContainerProps={searchContainerProps}
      />
    </AssessmentProvider>
  )
}

export default WrappedLibrary
