/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type ClinicControllerGetAssignedCheckInsType = typeof ClinicControllerGetAssignedCheckInsType[keyof typeof ClinicControllerGetAssignedCheckInsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClinicControllerGetAssignedCheckInsType = {
  treatment_activity: 'treatment_activity',
  symptom: 'symptom',
} as const;
