import React, { useEffect } from 'react'
import { Box, Flex, Text, Button, Link } from '@chakra-ui/react'
import { Field, FieldProps, useFormikContext } from 'formik'
import { TextField } from '@blueprinthq/joy'
import { FadeIn } from './animations'
import { Step } from './step'
import { AlertBox } from '@components'
import { RedirectToBeginning } from './redirect-to-beginning'
import { trackEvent } from '@lib/clinician-tracking'

interface PracticeProps {
  onNext: () => void
  isError?: boolean
  isLoading: boolean
  onboardingVersion: number | null
}

export const Practice: React.FC<PracticeProps> = ({
  onNext,
  isError = false,
  isLoading,
  onboardingVersion
}) => {
  useEffect(() => {
    if (onboardingVersion) {
      trackEvent('Onboarding Practice Viewed', {
        version: onboardingVersion
      })
    }
  }, [onboardingVersion])

  const { setFieldTouched, setFieldError } = useFormikContext()

  useEffect(() => {
    setFieldTouched('organizationName', false)
    setFieldError('organizationName', '')
  }, [setFieldTouched, setFieldError])

  return (
    <Step mt={{ base: '65px', md: 0 }}>
      <RedirectToBeginning />
      <FadeIn>
        <Flex
          flexDirection="column"
          justifyContent={{ base: 'space-between', md: 'center' }}
          h="100%"
        >
          <Box textAlign="left" mb="34px">
            <Text fontSize="24px">
              What's the name of your
              <br /> practice?
            </Text>
          </Box>
          <Box flex={{ base: 1, md: 0 }} mb={{ base: 0, md: '65px' }}>
            <Field name="organizationName">
              {({ field, meta }: FieldProps) => {
                return (
                  <Box mb={4}>
                    <TextField
                      {...field}
                      onChange={e => {
                        field.onChange(e)
                        setFieldError('organizationName', '')
                      }}
                      w="100%"
                      isRequired
                      label="Practice Name"
                      isInvalid={!!meta.error && !!meta.touched}
                      errorText={meta.error}
                      required
                      bg="white"
                      activeLabelStyle={{
                        bgGradient: 'linear(to-t, white, #F6F7FE)'
                      }}
                      autoFocus
                    />
                  </Box>
                )
              }}
            </Field>
          </Box>
        </Flex>
      </FadeIn>
      <Box pt={4} pb={8}>
        {isError && (
          <AlertBox
            mb={4}
            role="alert"
            sx={{
              a: {
                color: 'primary'
              }
            }}
          >
            <Text mb="4px">
              Something went wrong, please try again — if the problem persists
              please reach out to{' '}
              <Link href="mailto:help@blueprint.ai">help@blueprint.ai</Link>
            </Text>
          </AlertBox>
        )}
        <Button
          isFullWidth
          onClick={onNext}
          m={0}
          isLoading={isLoading}
          type="submit"
          size="lg"
        >
          Continue
        </Button>
      </Box>
    </Step>
  )
}
