/**
 * Utility functions for handling video playback across different devices and browsers
 */

/**
 * Detects if the current browser is running on a mobile device
 * @returns true if on a mobile device, false otherwise
 */
export const isMobileDevice = (): boolean => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

/**
 * Determines if the browser can play a specific video type
 * @param mimeType MIME type to check
 * @returns true if the browser can play the format, false otherwise
 */
export const canPlayVideoType = (mimeType: string): boolean => {
  const video = document.createElement('video');
  return !!video.canPlayType(mimeType);
};

/**
 * Gets the appropriate MIME type for the video source based on browser support
 * @param videoPath Path to the video file
 * @returns An object with source url and corresponding mime type
 */
export const getVideoSource = (videoPath: string): { src: string; type: string } => {
  // Original .mov file
  const originalSource = {
    src: videoPath,
    type: 'video/quicktime'
  };
  
  // Alternative formats
  const mp4Source = {
    src: videoPath.replace('.mov', '.mp4'),
    type: 'video/mp4'
  };
  
  const webmSource = {
    src: videoPath.replace('.mov', '.webm'),
    type: 'video/webm'
  };
  
  // Check browser support for each format
  if (canPlayVideoType('video/mp4')) {
    return mp4Source;
  } else if (canPlayVideoType('video/webm')) {
    return webmSource;
  }
  
  // Default to original source
  return originalSource;
};

/**
 * Creates a poster image URL from a Cloudinary video URL
 * Uses Cloudinary's transformation capabilities to extract a frame
 * @param videoUrl The Cloudinary video URL
 * @param timestamp Optional timestamp in seconds to extract the frame from (default: 0)
 * @returns URL for a poster image for the video
 */
export const getCloudinaryVideoPoster = (videoUrl: string, timestamp: number = 0): string => {
  if (!videoUrl.includes('cloudinary.com')) {
    return videoUrl; // Not a Cloudinary URL, return as is
  }
  
  // Parse Cloudinary URL components
  const urlParts = videoUrl.split('/');
  const uploadIndex = urlParts.findIndex(part => part === 'upload');
  
  if (uploadIndex === -1) {
    return videoUrl; // Not a standard Cloudinary URL
  }
  
  // Insert transformation: convert to image, use the specified frame, optimize quality
  // so_X.XX parameter sets the second offset for the frame extraction
  urlParts.splice(uploadIndex + 1, 0, `w_1200,h_675,q_auto:good,f_auto,c_fill/so_${timestamp.toFixed(2)}`);
  
  // Change video extension to jpg
  const fileNameIndex = urlParts.length - 1;
  const fileName = urlParts[fileNameIndex];
  urlParts[fileNameIndex] = fileName.replace(/\.(mov|mp4|webm)$/, '.jpg');
  
  return urlParts.join('/');
};

/**
 * Adds loading and error handling to a video element
 * @param videoRef React ref to the video element
 * @param onLoad Callback when video is loaded
 * @param onError Callback when video fails to load
 * @returns Cleanup function to remove event listeners
 */
export const setupVideoLoadHandlers = (
  videoRef: React.RefObject<HTMLVideoElement>,
  onLoad: () => void,
  onError: () => void
): (() => void) => {
  if (!videoRef.current) return () => {};

  const handleCanPlay = () => {
    onLoad();
  };

  const handleError = () => {
    onError();
    console.error('Video failed to load');
  };

  videoRef.current.addEventListener('canplay', handleCanPlay);
  videoRef.current.addEventListener('error', handleError);

  return () => {
    if (!videoRef.current) return;
    videoRef.current.removeEventListener('canplay', handleCanPlay);
    videoRef.current.removeEventListener('error', handleError);
  };
}; 