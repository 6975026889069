/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type AssignCheckInDtoFrequencyType = typeof AssignCheckInDtoFrequencyType[keyof typeof AssignCheckInDtoFrequencyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignCheckInDtoFrequencyType = {
  weekly: 'weekly',
  one_time: 'one_time',
} as const;
