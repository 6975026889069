import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import {
  Box,
  Menu,
  Button,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItem,
  MenuItemOption,
  Checkbox,
  Flex,
  HStack
} from '@chakra-ui/react'
import { Chip } from '../../reports/components/chip'
import { ChevronDown } from '@components/icons'

type Props = {
  selectedValues: string[] | null
  onChange: (values: string[] | null) => void
  options: Array<{
    id: string
    display: string
  }>
}

const selectNoneOptionValue = 'none'
const selectNoneOptionLabel = 'Not specified'
const SINGLE_SELECT_IDS = ['other', 'medication_management']

export const TreatmentApproachDropdown = ({
  selectedValues,
  onChange,
  options
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)

  useEffect(() => {
    const updateWidth = () => {
      if (buttonRef.current) {
        setMenuWidth(buttonRef.current.offsetWidth)
      }
    }
    updateWidth()

    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  const multiSelectOptions = useMemo(() => {
    return options.filter(
      opt =>
        opt.id !== selectNoneOptionValue &&
        !SINGLE_SELECT_IDS.includes(opt.id)
    )
  }, [options])

  const singleSelectOptions = useMemo(() => {
    return options.filter(opt =>
      SINGLE_SELECT_IDS.includes(opt.id)
    )
  }, [options])

  let chips
  if (!selectedValues?.length) {
    chips = <Chip label={selectNoneOptionLabel} fontSize="14px" />
  } else {
    const selectedOptions = options?.filter(opt =>
      selectedValues.includes(opt.id)
    )
    chips = selectedOptions?.map(opt => (
      <Chip
        key={`approach-chip-${opt.id}`}
        label={opt.display}
        onClose={() => onClearChip(opt.id)}
        fontSize="14px"
      />
    ))
  }

  const onClearChip = (optionId: string) => {
    if (selectedValues?.length === 1) {
      // If removing the last option, set to null
      onChange(null)
    } else {
      onChange(selectedValues?.filter(id => id !== optionId) ?? null)
    }
  }

  const handleChange = (optionId: string) => {
    if (optionId === 'none') {
      return onChange(null)
    }
    // Check if this is a single select option
    if (singleSelectOptions.some(opt => opt.id === optionId)) {
      // For single select options, either select just this option or remove it
      if (selectedValues?.includes(optionId)) {
        // Deselect the option
        const newValues = selectedValues.filter(id => id !== optionId)
        return onChange(newValues.length === 0 ? null : newValues)
      }
      // Select only this option, clearing all other selections
      return onChange([optionId])
    }

    // Handle multi-select options
    if (selectedValues?.includes(optionId)) {
      // Deselect the option
      const newValues = selectedValues.filter(id => id !== optionId)
      return onChange(newValues.length === 0 ? null : newValues)
    }
    // Add option to selected, but only if we haven't reached the limit of 3
    const currentMultiSelectCount =
      selectedValues?.filter(
        id => !singleSelectOptions.some(opt => opt.id === id)
      ).length ?? 0

    if (currentMultiSelectCount >= 3) {
      return
    }

    const newValues = [...(selectedValues || []), optionId]
    return onChange(
      newValues.filter(id => !singleSelectOptions.some(opt => opt.id === id))
    )
  }

  return (
    <Menu closeOnSelect={false}>
      <CustomMenuButton 
        ref={buttonRef} 
        chips={chips} 
        onChange={onChange}
        selectedValues={selectedValues}
      />
      <MenuList
        width={`${menuWidth}px`}
        sx={{ maxHeight: '240px', overflowY: 'auto', pt: '0px' }}
        zIndex={1000}
      >
        <MenuOptionGroup
          type="checkbox"
          title="SELECT UP TO 3"
          backgroundColor="#F4F4F4"
          sx={{
            margin: '0px',
            paddingLeft: '16px',
            py: 'xxsmall',
            color: 'dark_gray',
            fontSize: 'xsm'
          }}
        >
          {multiSelectOptions.map(opt => {
            const currentMultiSelectCount =
              selectedValues?.filter(
                id => !singleSelectOptions.some(sOpt => sOpt.id === id)
              ).length ?? 0
            const isDisabled =
              currentMultiSelectCount >= 3 && !selectedValues?.includes(opt.id)

            return (
              <MenuItem key={opt.id} isDisabled={isDisabled} onClick={() => handleChange(opt.id)}>
                <Checkbox
                  w="100%"
                  value={opt.id}
                  isChecked={Boolean(selectedValues?.includes(opt.id))}
                  isDisabled={isDisabled}
                  pointerEvents="none"
                >
                  {opt.display}
                </Checkbox>
              </MenuItem>
            )
          })}
        </MenuOptionGroup>
        <MenuOptionGroup
          type="radio"
          title="SINGLE SELECTION"
          backgroundColor="#F4F4F4"
          sx={{
            margin: '0px',
            paddingLeft: '16px',
            py: 'xxsmall',
            color: 'dark_gray',
            fontSize: 'xsm'
          }}
        >
          {singleSelectOptions.map(opt => (
            <MenuItem key={opt.id} onClick={() => handleChange(opt.id)}>
              <Checkbox
                w="100%"
                value={opt.id}
                isChecked={Boolean(selectedValues?.includes(opt.id))}
                pointerEvents="none"
              >
                {opt.display}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuOptionGroup>
        <MenuOptionGroup
          type="checkbox"
          title="DEFAULT"
          backgroundColor="#F4F4F4"
          sx={{
            margin: '0px',
            paddingLeft: '16px',
            py: 'xxsmall',
            color: 'dark_gray',
            fontSize: 'xsm'
          }}
        >
          <MenuItem key={'none'} onClick={() => handleChange('none')}>
            <Checkbox
              w="100%"
              value="none"
              isChecked={!selectedValues?.length}
              pointerEvents="none"
            >
              Not specified
            </Checkbox>
          </MenuItem>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

const CustomMenuButton = React.forwardRef(
  ({ chips, sx = {}, onChange, selectedValues, ...rest }: any, ref) => {
    const inner = (
      <Flex alignItems="center" width="100%" justifyContent="space-between">
        <Flex alignItems="center" minWidth="0" flex="1">
          <Box flex="1" minWidth="0">
            <HStack
              p="1"
              spacing="xsmall"
              width="100%"
              overflow="hidden"
              css={{
                maskImage:
                  'linear-gradient(to right, black 90%, transparent 100%)'
              }}
            >
              {chips}
              {selectedValues?.length ? (
                <Box pl="xsmall">
                  <Button
                    variant="ghost"
                    size="sm"
                    color="primary"
                    fontSize="14px"
                    height="auto"
                    padding="0"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(null);
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              ) : null}
            </HStack>
          </Box>
        </Flex>
        <Box flexShrink={0} ml="xsmall">
          <ChevronDown color="black" fill="black" />
        </Box>
      </Flex>
    )

    return (
      <MenuButton
        ref={ref}
        m="0"
        as={Button}
        background="white"
        borderRadius="6px"
        w="100%"
        border="1px solid rgb(228, 229, 230)"
        borderColor="#C9C9C9"
        height="48px"
        paddingInlineStart={4}
        color="black"
        sx={{
          span: {
            pointerEvents: 'all'
          },
          ...sx
        }}
        {...rest}
      >
        {inner}
      </MenuButton>
    )
  }
)
