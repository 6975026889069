import React from 'react'
import {
  VStack,
  Box,
  Text,
  Divider,
  Button,
  Heading,
  Flex
} from '@chakra-ui/react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useExperienceManager } from '@hooks'
import {
  usePatientControllerGetSessionList,
  useSessionControllerPostSession
} from '~/clinician-api'
import { SessionListItem } from '@components'
import moment from 'moment'

export function SessionList(props: { patient: { is_archived: boolean } }) {
  const match: any = useRouteMatch('/patient/:clientId/settings/sessions')
  const { patient } = props
  const {
    data: sessionList,
    isLoading: isSessionListLoading,
    refetch: refetchSessionList
  } = usePatientControllerGetSessionList(match.params.clientId, {
    query: {
      initialData: [],
      refetchInterval: (data: any) => {
        if (data?.some((s: any) => s.isLoading)) {
          return 5000
        }

        return false
      }
    }
  })

  const history = useHistory()
  const { isExtension, isPlusPlanEnabled } = useExperienceManager()

  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()

  const onStartSessionClick = async () => {
    const sessionDraft = await createSessionDraft({
      data: {
        patientId: match.params.clientId,
        isChime: false,
        isExtension,
        extensionInitiatedFrom: isExtension ? document.referrer : null,
        resumeInProgress: true,
        isDictation: false,
        isAssistEnabled: isPlusPlanEnabled
      }
    })
    history.push(`/patient/${match.params.clientId}/session/${sessionDraft.id}`)
  }

  return (
    <Box w="100%" id="pendo-session-list-container-tag">
      <Box mb="small">
        <Heading>Sessions</Heading>
      </Box>
      <Box
        border="solid 1px"
        borderColor="pale_gray"
        w="100%"
        borderRadius="8px"
        overflow="hidden"
      >
        {!isSessionListLoading && (sessionList?.length === 0 || !sessionList) ? (
          <Flex align="center" justify="center" height="250px">
            <Flex align="center" direction="column">
              <Text fontWeight="bold" mt="xsmall" mb="xsmall">
                No sessions yet
              </Text>
              {!patient.is_archived && (
                <>
                  <Text
                    align="center"
                    maxWidth="350px"
                    whiteSpace="break-spaces"
                  >
                    Ready to run a great therapy session and save time on
                    writing progress notes?
                  </Text>
                  <Button
                    mt="medium"
                    id="pendo-start-session-tag"
                    paddingLeft="37.5px"
                    paddingRight="37.5px"
                    _focus={{
                      boxShadow: 'none'
                    }}
                    onClick={onStartSessionClick}
                  >
                    New Session
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        ) : (
          <VStack w="100%" divider={<Divider />} spacing={0}>
            {(sessionList || []).map((session: any) => {
              return (
                <SessionListItem
                  key={`session-list-${session.id}`}
                  sessionId={session.id}
                  patientId={session.patientId}
                  header={DateTime.fromISO(session.date).toFormat('LLL dd')}
                  startedAt={moment(session.startedAt)
                    .local()
                    .format()}
                  completedAt={moment(session.completedAt)
                    .local()
                    .format()}
                  isUpload={session.isUpload}
                  clinicianName={session.clinicianFullName}
                  inProgress={session.isRecordingInProgress}
                  isDictation={session.isDictation}
                  onDelete={() => refetchSessionList()}
                  isLoading={session.isLoading}
                />
              )
            })}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
