import React, { useEffect } from 'react'
import {
  Text,
  Flex,
  UnorderedList,
  ListItem,
  Container,
  useToast,
  Heading
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import * as clinicianTracking from '@lib/clinician-tracking'
import { standardErrorDescription } from '@constants/Copy'
import { CancellationForm } from '@components'
import { useHistory, Redirect } from 'react-router-dom'
import { useClinicControllerGetCurrentPlan } from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
import { useExperienceManager } from '@hooks'
import moment from 'moment'

const CancelSubscriptionV2 = () => {
  const history = useHistory()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { user } = useStoreState((state: any) => state.auth)
  const { isAdmin } = useExperienceManager()

  const { data: currentPlan } = useClinicControllerGetCurrentPlan(
    user.clinic.id
  )

  const planType = currentPlan?.planType
  const clinicId = user.clinic_id
  const subscriptionId = currentPlan?.subscriptionId
  const planName = currentPlan?.planName

  useEffect(() => {
    clinicianTracking.trackEvent(`Viewed Cancel Subscription Page`)
  }, [])

  const { mutateAsync: cancelPlan } = useMutation(
    endpoints.patchIndividualBillingPlan.request,
    {
      onSuccess() {
        history.replace('/')
        toast({
          title: `Your subscription has been canceled`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
      },
      onError(err: any) {
        toast({
          title: `We ran into an issue canceling your subscription`,
          description: standardErrorDescription(),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const handleSubmit = async (values: any) => {
    clinicianTracking.trackEvent(
      `Cancel ${planType?.toUpperCase()} Dialog -> Clicked confirm cancel`
    )
    await cancelPlan({
      data: {
        action: 'cancel',
        clinicId,
        subscriptionId,
        planType,
        planName,
        cancellationReason: { id: values.cancellationReason },
        cancellationFeedback: values.cancellationFeedback || null,
        missingFeatures: values.missingFeatures || null,
        otherReason: values.otherReason || null,
        selectedCompetitor: values.selectedCompetitor || null
      }
    })
  }

  if (!isAdmin) {
    return <Redirect to="/sessions" />
  }

  return (
    <Container maxWidth="672px" centerContent py="40px">
      <Flex flexDirection="column" flex="1" gap="24px" px="small">
        <Flex flexDirection="column" gap="32px">
          <Heading fontSize="24px">Cancel your Blueprint subscription</Heading>
          <Flex flexDirection="column" gap="8px">
            <Text as="b">
              Here's what will happen when your subscription ends on{' '}
              {moment(currentPlan?.billingEnd).format('MMM D, YYYY')}:
            </Text>
            <UnorderedList>
              <ListItem>
                All activities assigned to clients will be unassigned.
              </ListItem>
              <ListItem>
                You will no longer be billed (unless you have
                outstanding/overdue invoices).
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
        <CancellationForm onSubmit={handleSubmit} />
      </Flex>
    </Container>
  )
}

export default CancelSubscriptionV2
