import React from 'react'
import { Box, Flex, Text, Button } from '@chakra-ui/react'
import { useProgressNoteTemplateMemoryControllerFindByNoteType } from '~/clinician-api'

interface ReadOnlyMemoriesProps {
  noteType: string
  onManageClick: () => void
}

const ReadOnlyMemories: React.FC<ReadOnlyMemoriesProps> = ({
  noteType,
  onManageClick
}) => {
  const {
    data: memories = [],
    isLoading
  } = useProgressNoteTemplateMemoryControllerFindByNoteType({
    noteType
  })

  return (
    <Box
      width="100%"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      bg="rgba(249, 249, 249, 1)"
      p={4}
    >
      <Flex justify="space-between" align="center" mb={2}>
        <Text fontWeight="bold">Memory</Text>
        <Button
          variant="link"
          color="blue.500"
          textDecoration="none"
          _focus={{ outline: 'none' }}
          onClick={onManageClick}
        >
          Manage
        </Button>
      </Flex>

      {isLoading ? (
        <Text color="gray.500" fontStyle="italic" textAlign="center">
          Loading memories...
        </Text>
      ) : memories && memories.length > 0 ? (
        memories.map(memory => (
          <Text color="gray.500" key={memory.id}>
            • {memory.content}
          </Text>
        ))
      ) : (
        <Text color="gray.500" fontStyle="italic" textAlign="center">
          Nothing saved to memory yet
        </Text>
      )}
    </Box>
  )
}

export default ReadOnlyMemories
