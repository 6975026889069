import React, { Component, ErrorInfo, ReactNode } from 'react'
import { datadogRum } from '@datadog/browser-rum'

interface Props {
  children: ReactNode
}

interface State {
  error: Error | null
  errorInfo: ErrorInfo | null
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const renderingError = new Error(error.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = errorInfo.componentStack
    renderingError.cause = error

    datadogRum.addError(renderingError)

    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render(): ReactNode {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2
            style={{
              maxWidth: '375px',
              margin: '100px auto',
              color: '#B5342A'
            }}
          >
            This page was unable to load. Please refresh your browser page to
            try again.
            <br />
            If the page still doesn&apos;t load, send us an email{' '}
            {process.env.REACT_APP_HELP_EMAIL} -- we&apos;re here to help!
          </h2>
        </div>
      )
    }

    return this.props.children
  }
}
