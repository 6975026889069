import React from 'react'
import { Box, Button, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'

interface BeforeYouBeginItemProps {
  textColor: string | undefined
  subtextColor: string | undefined
  backgroundColor: string
  icon: JSX.Element
  buttonText: string
  text: string
  subtext: string
  handleClick: () => void
}

const BeforeYouBeginItem: React.FC<BeforeYouBeginItemProps> = ({
  backgroundColor,
  textColor,
  subtextColor,
  icon,
  buttonText,
  text,
  subtext,
  handleClick
}) => {



  return (
    <>
     <VStack
        p="16px"
        borderRadius="8px"
        bg={backgroundColor}
        spacing="8px"
        align="flex-start"
        width="100%"
        display={{ base: 'flex', md: 'none' }}
      >
        <Box>{icon}</Box>
        <Stack spacing="2px" width="100%">
          <Text fontWeight="medium" fontSize="16px" color={textColor}>
            {text}
          </Text>
          <Text fontSize="12px" color={subtextColor}>
            {subtext}
          </Text>
        </Stack>
        <Button
          variant="link"
          onClick={handleClick}
          textDecoration="none"
          color={textColor}
          _focus={{ outline: 'none' }}
          p="0"
          flexShrink={0}
          minW="fit-content"
          height="auto"
          fontWeight="medium"
          fontSize="16px"
        >
          {buttonText}
        </Button>
      </VStack>
    <HStack
      justifyContent="space-between"
      py="12px"
      px="16px"
      borderRadius="8px"
      bg={backgroundColor}
      spacing="12px"
      width="100%"
      display={{ base: 'none', md: 'flex' }}
    >
      <HStack gap="8px" flex="1" minWidth="0">
        <Box flexShrink={0}>{icon}</Box>
        <Stack spacing="0" flex="1" minWidth="0">
          <Text fontWeight="medium" color={textColor} noOfLines={2}>
            {text}
          </Text>
          <Text fontSize="12px" color={subtextColor} noOfLines={2}>
            {subtext}
          </Text>
        </Stack>
      </HStack>
      <Button
        variant="link"
        onClick={handleClick}
        textDecoration="none"
        color={textColor}
        _focus={{ outline: 'none' }}
        p="0"
        flexShrink={0}
        minW="fit-content"
        height="auto"
        fontWeight="medium"
        fontSize="16px"
      >
        {buttonText}
      </Button>
    </HStack>
    </>
  )
}

export default BeforeYouBeginItem
