import { useMemo } from 'react'
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import {
  getSessionControllerGetSessionsListQueryKey,
  useSessionControllerCreateSampleSession
} from '~/clinician-api'
import { useQueryClient } from 'react-query'
import { useExperienceManager } from '@hooks'
import { datadogLogs } from '@datadog/browser-logs'

enum SampleNoteID {
  INTAKE = 'intake',
  CBT = 'cbt',
  DBT = 'dbt',
  EMDR = 'emdr',
  MED_MANAGEMENT = 'med_management'
}

interface SampleNote {
  label: string
  identifier: SampleNoteID
}

const SAMPLE_NOTES: SampleNote[] = [
  {
    label: 'Intake',
    identifier: SampleNoteID.INTAKE
  },
  {
    label: 'CBT',
    identifier: SampleNoteID.CBT
  },
  {
    label: 'DBT',
    identifier: SampleNoteID.DBT
  },
  {
    label: 'EMDR',
    identifier: SampleNoteID.EMDR
  }
]

export function useSampleSessions() {
  const history = useHistory()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { showRulaUI } = useExperienceManager()

  const {
    mutateAsync: executeCreateSampleSession,
    isLoading
  } = useSessionControllerCreateSampleSession()

  const createSampleSession = async (sampleType: string, noteType?: string) => {
    try {
      const response = await executeCreateSampleSession({
        data: { sampleType, noteType }
      })

      queryClient.invalidateQueries(
        getSessionControllerGetSessionsListQueryKey()
      )

      history.push(
        `/patient/${response.patientId}/completed-session/${response.sessionId}?isDemo=true${noteType ? '&context=welcome-demo' : ''}`
      )
    } catch (err: any) {
      datadogLogs.logger.error('failed to create sample session', {}, err)
      toast({
        title: 'Error',
        description: 'There was an error creating the sample progress note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  const allNotes = useMemo(() => {
    return showRulaUI
      ? [
          { label: 'Med Management', identifier: SampleNoteID.MED_MANAGEMENT },
          ...SAMPLE_NOTES,
        ]
      : SAMPLE_NOTES
  }, [showRulaUI])

  return {
    sampleNotes: allNotes,
    createSampleSession,
    isLoading
  }
}
