import React, { useCallback, useEffect } from 'react'
import {
  Route,
  useHistory,
  useParams,
  useRouteMatch,
  useLocation
} from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Flex, Box, Text, Button } from '@chakra-ui/react'
import { endpoints } from '../../../api'
import styles from './patient-profile.module.css'
import { PatientProfileHeader } from './components/patient-header/patient-header'
import Summary from './components/summary'
import { ProfileSettingsNav } from '../components'
import { Loading } from '../../../components'
import {
  Account,
  AssessmentSelector,
  AssistAssessments,
  CheckinModuleSelector,
  InterventionSelector,
  AssistInterventions,
  TreatmentPlan,
  TxPlanAndDischarge,
  Consent,
  AssistWorksheets
} from '../../../components'
import { Programs } from '@components/patientsettings/programs'
import { ClientStatusBuckets } from '../../../constants/clientStatusBuckets'
import { SessionList } from '@handlers/sessions'
import { useExperienceManager } from '@hooks'
import { FlagsmithFeatures } from '@constants/flagsmith'
import flagsmith from 'flagsmith'
import queryString from 'query-string'
import {
  usePatientControllerGetCheckInScores,
  usePatientControllerGetAssignedCheckIns,
  usePatientInterventionsControllerGetAllPatientInterventions
} from '~/clinician-api'

import '../../../css/rechart.css'
import '../../../css/typography.css'

export const PatientProfile = props => {
  const { id: patientId } = useParams()
  const { user } = useStoreState(state => state.auth)
  const history = useHistory()
  const { search } = useLocation()
  const query = queryString.parse(search)

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )
  const activeTab = useStoreState(state => state.patientProfile.activeTab)
  const [showNotClient, setShowNotClient] = React.useState(false)

  const showConsentTab = flagsmith.hasFeature(FlagsmithFeatures.CLIENT_CONSENT)
  const checkinsEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.SYMPTOM_TRACKER_AND_WORKSHEETS_ENABLED
  )

  const { isLoading: isPatientLoading, data: patient } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), patientId],
    () => endpoints.getClinicianUserAccount.request({ id: patientId }),
    {
      onError: error => {
        if (error === 403) {
          setShowNotClient(true)
        }
        console.error(error.message) //TODO
      }
    }
  )
  const match = useRouteMatch('/patient/:id')

  const {
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnabled,
    isPlusPlanEnabled
  } = useExperienceManager()

  useEffect(() => {
    if (!isEvidenceBasedCareEnabled && match?.isExact) {
      if (query.target === 'assessments') {
        history.replace(`/patient/${patientId}/settings/outcome_measures`)
      } else {
        history.replace(`/patient/${patientId}/settings/sessions`)
      }
    }
  }, [isEvidenceBasedCareEnabled, match, patientId, history, query.target])

  useEffect(() => {
    if (patient && patient.status === ClientStatusBuckets.AWAITING_INVITE) {
      history.replace(`/pre-enroll-patient/${patient.id}`)
    }
    if (patient && patient.status === ClientStatusBuckets.PENDING) {
      history.replace(`/pending-client/${patient.id}`)
    }
  }, [patient, history])

  const {
    isLoading: isDeliveryDateLoading,
    data: deliveryDateData
  } = useQuery(
    [endpoints.getClinicianUserNextDeliveryDate.getCacheId(), patientId],
    () => endpoints.getClinicianUserNextDeliveryDate.request({ id: patientId })
  )

  const {
    isLoading: isActiveAssessmentsLoading,
    data: activeAssessments
  } = useQuery(
    [endpoints.getClinicianUserActiveAssessments.getCacheId(), patientId],
    () => endpoints.getClinicianUserActiveAssessments.request({ id: patientId })
  )

  const {
    isLoading: isAssessmentScoresLoading,
    data: assessmentScores
  } = useQuery(
    [endpoints.getPatientAssessmentScores.getCacheId(), patientId],
    () =>
      endpoints.getPatientAssessmentScores.request({
        patientId,
        includeFreeTextQuestions: true
      })
  )

  const {
    data: assignedCheckIns = []
  } = usePatientControllerGetAssignedCheckIns(patientId)

  const { data: checkInScores } = usePatientControllerGetCheckInScores(
    patientId,
    {
      query: {
        initialData: {
          scores: [],
          versions: []
        }
      }
    }
  )

  const {
    data: interventions = [],
    isLoading: isInterventionsLoading
  } = usePatientInterventionsControllerGetAllPatientInterventions(patientId)

  const { mutateAsync: executeReinvite } = useMutation(
    endpoints.postReinviteToDownload.request,
    {
      onSuccess: () =>
        setSnackbarMessage({
          variant: 'success',
          message: 'Invitation was sent!'
        }),
      onError: () =>
        setSnackbarMessage({
          variant: 'error',
          message: 'Invitation was not sent.'
        })
    }
  )

  const sendPatientDownloadInvite = useCallback(async () => {
    if (patient) {
      await executeReinvite({ userId: patient.id })
    }
  }, [patient, executeReinvite])

  if (
    isPatientLoading ||
    isDeliveryDateLoading ||
    isActiveAssessmentsLoading ||
    isAssessmentScoresLoading ||
    isInterventionsLoading
  ) {
    return <Loading />
  }

  if (showNotClient) {
    return (
      <>
        <Flex
          width="100%"
          height="100vh"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box textAlign="center" mb="4">
            <Text fontSize="xl" fontWeight="bold">
              This client is not available to you.
            </Text>
          </Box>
          <Button
            colorScheme="blue"
            onClick={() => {
              history.push('/patients/active')
            }}
          >
            Back to my clients
          </Button>
        </Flex>
      </>
    )
  }

  const clientHasAssessmentData =
    assessmentScores?.length > 0 || activeAssessments?.length > 0
  const clientHasWorksheetData =
    checkInScores?.length > 0 || assignedCheckIns?.length > 0
  const clientHasInterventionData = interventions?.length > 0

  const TabElements = [
    {
      key: 'summary',
      display: 'Summary',
      path: '',
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+$|\/.*patient\/\w+-\w+-\w+-\w+-\w+\/$/g,
      isDisabled: !isEvidenceBasedCareEnabled,
      isHidden: !isEvidenceBasedCareEnabled
    },
    {
      key: 'account',
      display: isEvidenceBasedCareEnabled ? 'Client & Participants' : 'Client',
      path: '/settings/account',
      RouteComponent: Account,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/account/g
    },
    {
      key: 'sessions',
      display: 'Sessions',
      path: '/settings/sessions',
      RouteComponent: SessionList,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/sessions/g
    },
    {
      key: 'txPlanAndDischarge',
      display: 'Tx Plan & Discharge',
      path: '/settings/tx-plan-discharge',
      RouteComponent: TxPlanAndDischarge,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/tx-plan-discharge/g,
      isHidden: !isDocumentationAutomationEnabled
    },
    {
      key: 'assessments',
      display: 'Assessments',
      path: '/settings/outcome_measures',
      RouteComponent: isEvidenceBasedCareEnabled
        ? AssessmentSelector
        : AssistAssessments,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/outcome_measures/g,
      isDisabled: false,
      isHidden: isEvidenceBasedCareEnabled
        ? false
        : isPlusPlanEnabled
        ? false
        : clientHasAssessmentData
        ? false
        : true
    },
    {
      key: 'interventions',
      display: <>Interventions</>,
      path: '/settings/interventions',
      //EBC should see old intervention componenet, Plus should see assist interventions component
      RouteComponent: isPlusPlanEnabled
        ? AssistInterventions
        : InterventionSelector,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/interventions/g,
      isDisabled: false,
      isHidden: isEvidenceBasedCareEnabled
        ? false
        : isPlusPlanEnabled
        ? false
        : clientHasInterventionData
        ? false
        : true
    },
    {
      key: 'symptomTrackers',
      display: 'Symptom Trackers',
      path: '/settings/symptom_tracking',
      RouteComponent: CheckinModuleSelector,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/symptom_tracking/g,
      isDisabled: !isEvidenceBasedCareEnabled,
      isHidden: !isEvidenceBasedCareEnabled || checkinsEnabled === false
    },
    {
      key: 'worksheets',
      display: 'Worksheets',
      path: '/settings/worksheets',
      RouteComponent: isEvidenceBasedCareEnabled
        ? CheckinModuleSelector
        : AssistWorksheets,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/worksheets/g,
      isDisabled: false,
      isHidden: isEvidenceBasedCareEnabled
        ? false
        : isPlusPlanEnabled
        ? false
        : clientHasWorksheetData
        ? false
        : true
    },
    {
      key: 'programs',
      display: 'Programs',
      path: '/settings/programs',
      RouteComponent: Programs,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/programs/g,
      isDisabled: !isEvidenceBasedCareEnabled,
      isHidden: !isEvidenceBasedCareEnabled
    },
    {
      key: 'consent',
      display: 'Consent',
      path: '/settings/consent',
      RouteComponent: Consent,
      matcher: /\/.*patient\/\w+-\w+-\w+-\w+-\w+\/settings\/consent/g,
      isHidden: !showConsentTab
    }
  ]

  const isSuperAdmin = user?.roles.some(role => role.name === 'superadmin')

  const tabs =
    !patient ||
    (!isSuperAdmin && patient.is_archived && isEvidenceBasedCareEnabled)
      ? []
      : TabElements.filter(t => !t.isHidden)

  if (!patient) {
    return <Loading />
  }

  return (
    <div className={styles.whiteBack}>
      <PatientProfileHeader
        patientId={patient.id}
        tabs={tabs}
        isEvidenceBasedCareEnabled={isEvidenceBasedCareEnabled}
      />
      <div className={styles.content}>
        <Route
          path="/patient/:id/settings"
          render={() => (
            <ProfileSettingsNav
              user_id={patient.id}
              patient={{ ...patient, next_delivery_date: deliveryDateData }}
              context={props.context}
              tabs={tabs}
            />
          )}
        />
        <Route
          exact
          path="/patient/:id"
          render={() =>
            patient &&
            (!patient.is_archived || isSuperAdmin) && (
              <>
                <Summary
                  user_role={props.user_role}
                  api={props.api}
                  auth={props.auth}
                  user_id={patient.id}
                  patient={{
                    ...patient,
                    next_delivery_date: deliveryDateData,
                    hasApp:
                      patient.completed_onboarding ||
                      !!patient.platform ||
                      patient.medical_record === 'demo'
                  }}
                  location={props.location}
                  sendInvite={sendPatientDownloadInvite}
                  activeTab={activeTab}
                  activeAssessments={activeAssessments}
                  assessmentScores={assessmentScores}
                />
              </>
            )
          }
        />
      </div>
    </div>
  )
}
