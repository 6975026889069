import { Location } from 'history'

/**
 * Builds a URL with query parameters, ensuring the Rewardful 'via' parameter appears first
 * for proper cookie generation and tracking.
 *
 * @param baseUrl - The base URL path without query parameters
 * @param newPath - The path segment to append to the base URL
 * @param location - The current location object from React Router
 * @param params - Additional query parameters to include
 * @returns The constructed URL with properly ordered query parameters
 */
export const buildUrl = ({
  baseUrl,
  newPath,
  location,
  params = {}
}: {
  baseUrl: string
  newPath: string
  location: Location
  params?: Record<string, string | null>
}): string => {
  const currentParams = new URLSearchParams(location.search)
  const paramsToCarryOver = new URLSearchParams()

  let rewardfulParam = params.via || currentParams.get('via')

  // Handle all other parameters except via
  currentParams.forEach((value, param) => {
    if (param !== 'solo' && param !== 'group' && param !== 'via') {
      paramsToCarryOver.set(param, value)
    }
  })

  Object.entries(params).forEach(([key, value]) => {
    if (value && key !== 'via') {
      paramsToCarryOver.set(key, value)
    }
  })

  const otherParams = paramsToCarryOver.toString()
  let queryString = ''

  // The Rewardful via param MUST come first for attribution to work
  if (rewardfulParam) {
    queryString = `via=${rewardfulParam}${otherParams ? `&${otherParams}` : ''}`
  } else {
    queryString = otherParams
  }

  return `${baseUrl}${newPath}${queryString ? `?${queryString}` : ''}`
}
