import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  IconButton,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react'
import _ from 'lodash' // Add lodash import for throttling
import { Step } from './step'
import { AudioWaveform } from './audio-waveform'
import { DemoTranscript } from './demo-transcript'
import { AnimatedTextExplainer } from './animated-text-explainer'
import { trackEvent } from '@lib/clinician-tracking'
import { FastForwardIcon } from '@icons'
import { UnmuteIcon } from '@icons'
import { keyframes } from '@emotion/react'

export enum DemoStep {
  WELCOME = 'welcome',
  TRANSCRIPT_PLAYING = 'transcript_playing',
  TRANSCRIPT_FAST_FORWARDED = 'transcript_fast_forwarded',
  SESSION_COMPLETE = 'session_complete'
}

const DEVELOPMENT_MODE = process.env.REACT_APP_ENV === 'development'

const initialFadeInDuration = DEVELOPMENT_MODE ? '0s' : '1s'
const fadeInDelay = DEVELOPMENT_MODE ? '0s' : '2s'

export const InteractiveDemo = () => {
  const history = useHistory()
  // const { isAuthenticated } = useStoreState((state: any) => state.auth)

  // if (!isAuthenticated) {
  //   history.push('/')
  // }

  const [currentStep, setCurrentStep] = useState(DemoStep.WELCOME)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const [isMuted, setIsMuted] = useState(false)
  const [audioProgress, setAudioProgress] = useState(0)
  const [isTranscriptCollapsing, setIsTranscriptCollapsing] = useState(false)
  const [currentTimeDisplay, setCurrentTimeDisplay] = useState('0:00')
  const [isActuallyFastForwarding, setIsActuallyFastForwarding] = useState(
    false
  )
  const [animatedProgress, setAnimatedProgress] = useState(0)

  const [visibleTextBox1, setVisibleTextBox1] = useState(false)
  const [visibleTextBox2, setVisibleTextBox2] = useState(false)
  const [visibleTextBox3, setVisibleTextBox3] = useState(false)
  const [transcript, setTranscript] = useState([])
  const [transcriptAlreadyCollapsed, setTranscriptAlreadyCollapsed] = useState(
    false
  )
  const collapseTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)
  const fastForwardTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const animationFrameIdRef = useRef<number | null>(null)
  const [controlsFadingOut, setControlsFadingOut] = useState(false)

  useEffect(() => {
    async function fetchTranscript() {
      const response = await fetch(
        'https://res.cloudinary.com/hellojoy/raw/upload/v1742577914/onboarding/demo-transcript.json'
      )
      const transcript = await response.json()
      setTranscript(transcript)
    }

    fetchTranscript()
  }, [])

  useEffect(() => {
    let timer1: NodeJS.Timeout, timer2: NodeJS.Timeout, timer3: NodeJS.Timeout

    if (
      currentStep === DemoStep.TRANSCRIPT_PLAYING ||
      currentStep === DemoStep.TRANSCRIPT_FAST_FORWARDED
    ) {
      timer1 = setTimeout(() => setVisibleTextBox1(true), 3000)
      timer2 = setTimeout(() => setVisibleTextBox2(true), 9000)
      timer3 = setTimeout(() => setVisibleTextBox3(true), 15000)
    } else {
      // Reset visibility when step changes
      setVisibleTextBox1(false)
      setVisibleTextBox2(false)
      setVisibleTextBox3(false)
    }

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearTimeout(timer3)
    }
  }, [currentStep])

  useEffect(() => {
    if (!audioRef.current) return

    const audioElement = audioRef.current

    // Use a properly throttled update function to prevent too many state updates
    const updateProgress = _.throttle(() => {
      if (!audioElement.duration) return

      setAudioProgress(audioElement.currentTime / audioElement.duration)

      const minutes = Math.floor(audioElement.currentTime / 60)
      const seconds = Math.floor(audioElement.currentTime % 60)
      setCurrentTimeDisplay(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`)
    }, 250)

    audioElement.addEventListener('timeupdate', updateProgress)
    // Load method helps ensure progressive loading starts
    audioElement.load()

    return () => {
      audioElement.removeEventListener('timeupdate', updateProgress)
      updateProgress.cancel() // Cancel any pending throttled executions
    }
  }, [audioRef])

  const handleStartDemo = () => {
    setCurrentStep(DemoStep.TRANSCRIPT_PLAYING)

    if (!audioRef.current) return

    // Ensure audio is loaded before playing
    if (audioRef.current.readyState < 2) {
      audioRef.current.load()
      const readyHandler = () => {
        startPlayback()
        if (!audioRef.current) return
        audioRef.current.removeEventListener('canplay', readyHandler)
      }

      audioRef.current.addEventListener('canplay', readyHandler)
    } else {
      startPlayback()
    }
    
    // Add scroll to bottom for mobile using window
    setTimeout(() => {
      if (window.innerWidth < 768) { // Mobile breakpoint
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
      }
    }, 500)
  }

  const startPlayback = () => {
    if (!audioRef.current) return
    audioRef.current.volume = isMuted ? 0 : 1

    setTimeout(() => {
      try {
        if (!audioRef.current) return
        const playPromise = audioRef.current.play()
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            if (error.name === 'NotAllowedError') {
              setIsMuted(true)
              if (!audioRef.current) return
              audioRef.current.volume = 0
            }
          })
        }
      } catch (e) {
        console.error('Audio play exception:', e)
      }
    }, 50)
  }

  // Fast forward should also play audio if it's not already playing
  const handleFastForward = () => {
    if (!audioRef.current) return

    setCurrentStep(DemoStep.TRANSCRIPT_FAST_FORWARDED)
    setIsActuallyFastForwarding(true)

    setVisibleTextBox1(true)
    setVisibleTextBox2(true)
    setVisibleTextBox3(true)

    // Make sure the audio is playing
    if (audioRef.current.paused) {
      audioRef.current.volume = isMuted ? 0 : 1
      audioRef.current.play().catch(error => {
        console.error('Error playing audio during fast forward:', error)
      })
    }

    // Reduce playback rate to 4x instead of 7x for smoother audio
    audioRef.current.playbackRate = 4.0
    if (!isMuted) {
      audioRef.current.volume = 0.5
    }

    setAnimatedProgress(0.15)

    // Clean up any existing animation frames
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current)
    }

    // 15 seconds from end is target end time
    const offsetFromEnd = 15

    const startTime = Date.now()
    const duration = 4500
    const startProgress = 0.15
    const endProgress = 0.95

    // Calculate total duration of audio and target end time
    const totalDuration = audioRef.current.duration
    const targetEndTime = totalDuration - offsetFromEnd
    const startAudioTime = totalDuration * startProgress

    const animateProgress = () => {
      const elapsed = Date.now() - startTime
      const progress = Math.min(elapsed / duration, 1)

      // Apply ease-in-out timing function
      const easedProgress =
        progress < 0.5
          ? 2 * progress * progress
          : -1 + (4 - 2 * progress) * progress

      const currentProgress =
        startProgress + (endProgress - startProgress) * easedProgress
      setAnimatedProgress(currentProgress)

      // Calculate and update the current time display
      const currentAudioTime =
        startAudioTime + (targetEndTime - startAudioTime) * easedProgress
      const minutes = Math.floor(currentAudioTime / 60)
      const seconds = Math.floor(currentAudioTime % 60)
      setCurrentTimeDisplay(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`)

      if (progress < 1) {
        animationFrameIdRef.current = requestAnimationFrame(animateProgress)
      }
    }

    animationFrameIdRef.current = requestAnimationFrame(animateProgress)

    // Fast forward for 4.5 seconds of user-perceived time
    fastForwardTimeoutRef.current = setTimeout(() => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current) // Cancel the animation frame
        animationFrameIdRef.current = null
      }

      // Jump to 15 seconds before the end and play at normal speed
      if (audioRef.current && audioRef.current.duration) {
        const skipToPosition = audioRef.current.duration - offsetFromEnd
        audioRef.current.currentTime = skipToPosition
        audioRef.current.playbackRate = 1.0
        audioRef.current.volume = isMuted ? 0 : 1.0
        setIsActuallyFastForwarding(false)

        // Update the time display
        const minutes = Math.floor(skipToPosition / 60)
        const seconds = Math.floor(skipToPosition % 60)
        setCurrentTimeDisplay(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`)

        // Animate the remaining 5% over 15 seconds
        const remainingDuration = 15000
        const remainingStartTime = Date.now()
        const remainingStartProgress = 0.95
        const remainingEndProgress = 1.0

        const animateRemainingProgress = () => {
          if (audioRef.current && !audioRef.current.paused) {
            const elapsed = Date.now() - remainingStartTime
            const progress = Math.min(elapsed / remainingDuration, 1)

            // Linear animation for the remaining progress
            const currentProgress =
              remainingStartProgress +
              (remainingEndProgress - remainingStartProgress) * progress

            setAnimatedProgress(currentProgress)

            if (progress < 1 && !audioRef.current.paused) {
              animationFrameIdRef.current = requestAnimationFrame(
                animateRemainingProgress
              )
            }
          }
        }

        animationFrameIdRef.current = requestAnimationFrame(
          animateRemainingProgress
        )

        // After 15 seconds of normal playback, collapse the transcript
        if (!transcriptAlreadyCollapsed) {
          collapseTimeoutIdRef.current = setTimeout(() => {
            handleCollapseTranscript()
          }, 16000)
        }
      }
    }, 4500)
  }

  const handleSkipToEnd = () => {
    if (collapseTimeoutIdRef.current) {
      clearTimeout(collapseTimeoutIdRef.current)
      collapseTimeoutIdRef.current = null
    }

    if (fastForwardTimeoutRef.current) {
      clearTimeout(fastForwardTimeoutRef.current)
      fastForwardTimeoutRef.current = null
    }

    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current)
      animationFrameIdRef.current = null
    }

    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.playbackRate = 1.0
      setIsActuallyFastForwarding(false)

      if (audioRef.current.duration) {
        audioRef.current.currentTime = audioRef.current.duration

        const minutes = Math.floor(audioRef.current.duration / 60)
        const seconds = Math.floor(audioRef.current.duration % 60)
        setCurrentTimeDisplay(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`)
      }
    }

    if (!transcriptAlreadyCollapsed) {
      setIsTranscriptCollapsing(true)
      setTranscriptAlreadyCollapsed(true)
      setControlsFadingOut(true)
      
      setVisibleTextBox1(false)
      setVisibleTextBox2(false)
      setVisibleTextBox3(false)

      setCurrentStep(DemoStep.SESSION_COMPLETE)
      setControlsFadingOut(false)
    }
  }

  const toggleMute = () => {
    const newMutedState = !isMuted
    setIsMuted(newMutedState)
    
    if (audioRef.current) {
      // Set volume directly based on the new muted state
      audioRef.current.muted = newMutedState
    }
  }

  const handleCollapseTranscript = () => {
    if (transcriptAlreadyCollapsed) return

    setIsTranscriptCollapsing(true)
    setTranscriptAlreadyCollapsed(true)
    
    setVisibleTextBox1(false)
    setVisibleTextBox2(false)
    setVisibleTextBox3(false)

    setCurrentStep(DemoStep.SESSION_COMPLETE)
    if (audioRef.current) {
      audioRef.current.pause()
    }
  }

  useEffect(() => {
    trackEvent('Onboarding Interactive Demo Viewed', {
      version: 3
    })
  }, [])

  // Define keyframes for animations
  const fadeOutKeyframes = keyframes`
    from { opacity: 1; }
    to { opacity: 0; }
  `

  const collapseKeyframes = keyframes`
    from { max-height: 338px; }
    to { max-height: 80px; }
  `

  const containerExpandKeyframes = keyframes`
    from { 
      max-height: 101px; 
      opacity: 0; 
    }
    to { 
      max-height: 338px; 
      opacity: 1; 
    }
  `

  const fadeOutAnimation = `${fadeOutKeyframes} 0.2s ease-out forwards`
  const collapseAnimation = `${collapseKeyframes} 0.7s ease-out forwards`
  const easeCurve = 'cubic-bezier(0.57, 0.1, 0, 1)'
  const containerExpandAnimation = `${containerExpandKeyframes} 800ms ${easeCurve} forwards`
  const bottomContextFadeIn = `fadeIn 800ms ${easeCurve} 100ms forwards`

  // Add a new keyframe for controls fade out
  const controlsFadeOutKeyframes = keyframes`
    from { opacity: 1; }
    to { opacity: 0; }
  `

  const controlsFadeOutAnimation = `${controlsFadeOutKeyframes} 0.9s ease-out forwards`

  const showTextBoxes = useBreakpointValue({ base: false, md: true })

  // Add this useEffect for cleanup
  useEffect(() => {
    return () => {
      if (collapseTimeoutIdRef.current) {
        clearTimeout(collapseTimeoutIdRef.current)
      }
      if (fastForwardTimeoutRef.current) {
        clearTimeout(fastForwardTimeoutRef.current)
      }
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current)
      }
    }
  }, [])

  return (
    <Step 
      mt={{ base: '40px', md: '20px', xl: '100px' }} 
      pb={4} 
      display="block"
    >
      {/* Hidden audio element */}
      <audio
        ref={audioRef}
        preload="auto"
        crossOrigin="anonymous"
        style={{ display: 'none' }}
      >
        <source
          src="https://res.cloudinary.com/hellojoy/video/upload/v1742423321/onboarding/Anna_x_Chris_Session_Audio-edit_down-v3-Balanced.mp3"
          type="audio/mpeg"
        />
      </audio>

      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Box>
          {currentStep === DemoStep.WELCOME && (
            <Text
              fontSize="19px"
              fontWeight="normal"
              mb={8}
              textAlign="center"
              maxW="343px"
              opacity={0}
              animation={`${
                currentStep === DemoStep.WELCOME ? 'fadeIn' : 'fadeOut'
              } ${initialFadeInDuration} ease-in forwards 0s`}
            >
              Welcome to an interactive{' '}
              <b>
                session
                <br />
                demo.{' '}
              </b>
              It'll show you how Blueprint
              <br />
              transcribes sessions, turns them into progress notes, and more.
            </Text>
          )}

          <Box display="flex" alignItems="center" flexDirection="column" mb={{
            base: 2,
            md: 8,
          }}>
            {currentStep !== DemoStep.WELCOME && (
              <Text
                data-test-id="demo-chip"
                fontSize="16px"
                fontWeight="normal"
                color="primary"
                backgroundColor="#E0E5FC"
                borderRadius="4px"
                textAlign="center"
                maxW="343px"
                opacity={0}
                px="8px"
                pt="4px"
                pb="4px"
                animation={`fadeIn ${initialFadeInDuration} ease-in forwards 0s`}
                mb={4}
              >
                Session Demo
              </Text>
            )}
            <Box>
              <AudioWaveform
                isPlaying={
                  currentStep !== DemoStep.WELCOME &&
                  currentStep !== DemoStep.SESSION_COMPLETE
                }
                isFastForward={isActuallyFastForwarding}
              />
            </Box>
            <Box width="100%" textAlign="center">
              <Text
                width="100%"
                color={'primary'}
                fontSize={{
                  base: '72px',
                  md: '88px',
                }}
                fontWeight="300"
                height={{
                  base: '73px',
                  md: '90px',
                }}
                lineHeight="90px"
                textAlign="center"
                mt={`0px !important`}
                display="block"
                mb={currentStep === DemoStep.WELCOME ? 4 : 4}
              >
                {currentTimeDisplay}
              </Text>
            </Box>
          </Box>
        </Box>

        {currentStep === DemoStep.WELCOME && (
          <>
            <Image
              mt={{
                base: 8,
                md: 0,
              }}
              src="https://res.cloudinary.com/hellojoy/image/upload/v1740775569/onboarding/mic-audio-lottie-for-gif.gif"
              height="37.5px"
              width="95px"
              opacity={0}
              filter="brightness(1.3)"
              animation={`fadeIn ${initialFadeInDuration} ease-in forwards ${fadeInDelay}`}
            />
            <Text
              mt={`0px !important`}
              pb={6}
              opacity={0}
              animation={`fadeIn ${initialFadeInDuration} ease-in forwards ${fadeInDelay}`}
            >
              Audio is enabled
            </Text>
          </>
        )}
      </Flex>

      {currentStep !== DemoStep.WELCOME && (
          <Box
            margin="0 auto"
            data-test-id="audio-transcript-container"
            bg="white"
            borderRadius="16px"
            boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
            width="100%"
            maxW="428px"
            position="relative"
            height={{
              base: '238px',
              md: '338px',
            }}
            maxHeight={transcriptAlreadyCollapsed ? '80px' : {
              base: '238px',
              md: '338px',
            }}
            transition={
              isTranscriptCollapsing ? 'none' : 'max-height 0.3s ease-out'
            }
            animation={
              isTranscriptCollapsing
                ? collapseAnimation
                : currentStep === DemoStep.TRANSCRIPT_PLAYING
                ? containerExpandAnimation
                : undefined
            }
            style={{
              opacity: currentStep === DemoStep.TRANSCRIPT_PLAYING ? 0 : 1
            }}
          >
            {showTextBoxes && (
              <VStack
                position="absolute"
                top="64px"
                right="-200px"
                spacing="30px"
                align="flex-start"
                width="max-content"
                zIndex="10"
              >
                <AnimatedTextExplainer
                  content="Have a session as normal"
                  isVisible={visibleTextBox1}
                />

                <AnimatedTextExplainer
                  content="We'll record and transcribe in the background"
                  isVisible={visibleTextBox2}
                />

                <AnimatedTextExplainer
                  content="And we'll generate your notes when you're done"
                  isVisible={visibleTextBox3}
                />
              </VStack>
            )}

            <Flex
              data-test-id="video-bottom-context"
              width="100%"
              p={4}
              position="absolute"
              bottom={
                isTranscriptCollapsing ||
                currentStep === DemoStep.SESSION_COMPLETE
                  ? '50%'
                  : '0'
              }
              transform={
                isTranscriptCollapsing ||
                currentStep === DemoStep.SESSION_COMPLETE
                  ? 'translateY(50%)'
                  : 'none'
              }
              left="0"
              zIndex="2"
              bg="white"
              height="64px"
              alignItems="center"
              borderRadius="16px"
              opacity={currentStep === DemoStep.TRANSCRIPT_PLAYING ? 0 : 1}
              animation={
                currentStep === DemoStep.TRANSCRIPT_PLAYING
                  ? bottomContextFadeIn
                  : undefined
              }
            >
              <Flex
                data-testid="client-clinician-profiles"
                width="84px"
                position="relative"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  src="https://res.cloudinary.com/hellojoy/image/upload/v1740168402/onboarding/demo-clinician.webp"
                  width="40px"
                  height="40px"
                  borderRadius="full"
                  border="2px solid #94A5DE"
                  outline="2px solid rgba(45, 84, 232, 0.4)"
                  position="absolute"
                  right="42px"
                />
                <Image
                  src="https://res.cloudinary.com/hellojoy/image/upload/v1742577385/onboarding/patient-avatar-compress.webp"
                  width="40px"
                  height="40px"
                  borderRadius="full"
                  border="2px solid #94A5DE"
                  outline="2px solid rgba(45, 84, 232, 0.4)"
                  position="absolute"
                  right="10px"
                  zIndex={1}
                />
              </Flex>
              <Text
                fontWeight="medium"
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text as="span" fontSize="12px" color="#A1A1A1" mr={1}>
                  In session with
                </Text>
                <Text as="span" fontSize="12px" color="black">
                  Chris A.
                </Text>
              </Text>
              <Flex width="84px"></Flex>
            </Flex>

            <Box
              data-test-id="scrolling-demo-session-text"
              flex="1"
              height="250px"
              minHeight="250px"
              animation={isTranscriptCollapsing ? fadeOutAnimation : undefined}
              opacity={currentStep === DemoStep.SESSION_COMPLETE ? 0 : 1}
              overflow="hidden"
              pl={4}
              pr={4}
              // pt={12}
            >
              <DemoTranscript
                audioRef={audioRef.current as HTMLAudioElement}
                transcript={transcript}
                isFastForwarding={isActuallyFastForwarding}
              />
            </Box>
          </Box>
        )}
      <Flex
        margin="0 auto"
        data-testid="audio-controls"
        pt={5}
        pb={4}
        width="100%"
        maxW="400px"
        opacity={0}
        animation={
          controlsFadingOut
            ? controlsFadeOutAnimation
            : `fadeIn ${initialFadeInDuration} ease-in forwards ${fadeInDelay}`
        }
      >
        {(currentStep === DemoStep.TRANSCRIPT_PLAYING ||
          currentStep === DemoStep.TRANSCRIPT_FAST_FORWARDED) && (
          <IconButton
            aria-label={isMuted ? 'Unmute' : 'Mute'}
            icon={
              isMuted ? (
                <Image
                  src="https://res.cloudinary.com/hellojoy/image/upload/v1740611535/onboarding/audio-speaker-unmute.svg"
                  width="16px"
                  height="16px"
                />
              ) : (
                <UnmuteIcon boxSize={4} />
              )
            }
            variant="solid"
            bg={isMuted ? 'white' : 'black'}
            color={isMuted ? 'black' : 'white'}
            borderRadius="full"
            height="48px"
            minWidth="48px"
            margin="0"
            marginRight={3.5}
            size="sm"
            _hover={{ bg: isMuted ? 'gray.100' : 'gray.800' }}
            onClick={toggleMute}
            opacity={currentStep === DemoStep.TRANSCRIPT_PLAYING ? 0 : 1}
            animation={
              currentStep === DemoStep.TRANSCRIPT_PLAYING
                ? 'fadeIn 800ms ease-in forwards'
                : undefined
            }
          />
        )}
        {currentStep === DemoStep.TRANSCRIPT_FAST_FORWARDED ? (
          <Flex width="100%">
            <Button
              flex="1"
              m={0}
              size="lg"
              type="button"
              aria-label="Skip to end"
              onClick={handleSkipToEnd}
              position="relative"
              height="48px"
              borderRadius="100px"
              bg="white"
              boxShadow="0px 2px 4px -1px #0000000F, 0px 4px 6px -1px #0000001A"
              _hover={{ bg: 'gray.50' }}
              mr={2}
            >
              <Flex width="100%" align="center" px={2}>
                <FastForwardIcon boxSize={4} color="primary" mr={2} />
                <Box
                  flex="1"
                  height="4px"
                  bg="#C9C9C9"
                  borderRadius="full"
                  overflow="hidden"
                >
                  <Box
                    height="100%"
                    width={`${(isActuallyFastForwarding ||
                    currentStep === DemoStep.TRANSCRIPT_FAST_FORWARDED
                      ? animatedProgress
                      : audioProgress) * 100}%`}
                    bg="primary"
                    transition={
                      isActuallyFastForwarding ? 'none' : 'width 0.3s'
                    }
                  />
                </Box>
              </Flex>
            </Button>
            <Button
              variant="ghost"
              height="48px"
              minWidth="48px"
              borderRadius="full"
              onClick={handleSkipToEnd}
              _hover={{ bg: 'gray.100' }}
              fontWeight="normal"
              fontSize="14px"
              color="primary"
            >
              Skip
            </Button>
          </Flex>
        ) : currentStep === DemoStep.SESSION_COMPLETE ? (
          <VStack 
            width="100%" 
            spacing={3}
            opacity={0}
            animation="fadeIn 0.4s ease-in-out forwards 0.1s"
          >
            <Text fontSize="14px" textAlign="center" maxW="300px" mx="auto">
              Now that the session is over, tap the end session button and
              we'll make your note
            </Text>
            <Button
              isFullWidth
              m={0}
              size="lg"
              type="button"
              aria-label="Continue"
              bg="#C6162C"
              color="white"
              _hover={{ bg: '#B01427' }}
              position="relative"
              onClick={() => history.push('/onboarding/select-note')}
            >
              <Box
                position="absolute"
                top="-12px"
                right="25%"
                transform="translateX(50%)"
                zIndex={1}
                height="28px"
                width="13px"
              >
                <svg
                  width="13"
                  height="28"
                  viewBox="0 0 13 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    d="M4.98242 1.23828C8.74921 2.84907 12.0123 8.59924 11.923 12.5329C11.8173 17.1862 8.40199 22.2394 4.33436 24.4306C3.74591 24.7476 0.602395 26.5159 1.06811 25.0642C1.54176 23.5877 3.19203 19.2369 2.66105 20.6937C2.14023 22.1227 1.61942 23.5516 1.0986 24.9805C1.00278 25.2434 0.347059 26.066 0.717511 26.0261C1.60222 25.9306 3.09362 25.966 4.10626 25.966C5.45643 25.966 6.61361 26.2017 7.81924 26.6411"
                    stroke="#282828"
                    strokeLinecap="round"
                  />
                </svg>
              </Box>
              End Session
            </Button>
          </VStack>
        ) : (
          <Button
            isFullWidth
            m={0}
            size="lg"
            type="button"
            aria-label="Start interactive demo"
            onClick={
              currentStep === DemoStep.WELCOME
                ? handleStartDemo
                : handleFastForward
            }
            position="relative"
          >
            <Text
              position="absolute"
              opacity={currentStep === DemoStep.WELCOME ? 1 : 0}
              transition="opacity 0.5s ease-in-out"
            >
              Start
            </Text>
            <Flex
              position="absolute"
              opacity={currentStep === DemoStep.TRANSCRIPT_PLAYING ? 1 : 0}
              transition="opacity 0.5s ease-in-out forwards 0.2s"
              align="center"
              justify="center"
              gap={2}
            >
              <FastForwardIcon boxSize={4} />
              Fast Forward
            </Flex>
          </Button>
        )}
      </Flex>
    </Step>
  )
}
