import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Flex,
  Text,
  Button,
  ButtonProps,
  VStack
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { FadeIn } from './animations'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'
import { useSampleSessions } from '@hooks'

const SampleNoteButton = ({
  label,
  identifier,
  subLabel,
  ...buttonProps
}: {
  label: string
  identifier: string
  subLabel?: string
} & ButtonProps) => {
  const { isLoading, createSampleSession } = useSampleSessions()

  return (
    <Button
      onClick={() => createSampleSession('welcome_demo', identifier)}
      isLoading={isLoading}
      isFullWidth
      m={0}
      h="42px"
      variant="outline"
      borderRadius="24px"
      bg="white"
      justifyContent="center"
      _hover={{
        bg: 'rgba(45, 84, 232, 0.04)'
      }}
      {...buttonProps}
    >
      {label}
      {subLabel && (
        <Text ml="4px" color="#757575">
          {subLabel}
        </Text>
      )}
    </Button>
  )
}

export const SelectNote = () => {
  const history = useHistory()
  const { isAuthenticated } = useStoreState((state: any) => state.auth)
  if (!isAuthenticated) {
    history.push('/')
  }

  const sampleNotes = [
    {
      label: 'SOAP',
      identifier: 'soap',
      subLabel: '(Most popular)'
    },
    {
      label: 'DAP',
      identifier: 'dap'
    },
    {
      label: 'BIRP',
      identifier: 'birp'
    },
    {
      label: 'GIRP',
      identifier: 'girp'
    },
    {
      label: 'SIRP',
      identifier: 'sirp'
    },
    {
      label: 'PIRP',
      identifier: 'pirp'
    },
    {
      label: 'PIE',
      identifier: 'pie'
    }
  ]

  useEffect(() => {
    trackEvent('Onboarding Select Note Viewed', {
      version: 3
    })
  }, [])

  return (
    <Step mt={{ base: '28px', md: 'xxlarge' }} alignItems="center">
      <FadeIn duration={0.7} maxWidth="360px" margin="0 auto">
        <Box textAlign="left" mb={{ base: 4, md: '34px' }}>
          <Text mb="xsmall" lineHeight="32px" fontSize="24px">
            Let's select a note type for this session
          </Text>
          <Text color="#757575">
            We also support formats like Intake, EMDR, and prescriber notes, though they may not be listed here.
          </Text>
        </Box>
        <VStack w="100%" gap="xsmall" mb={{ base: 4, md: '34px' }}>
          {sampleNotes.map((note, index) => (
            <SampleNoteButton key={index} {...note} />
          ))}
        </VStack>
      </FadeIn>
    </Step>
  )
}
