import React from 'react'
import { useBreakpointValue, Box, Button, Text, useToast } from '@chakra-ui/react'
import SuggestionStrength from '@handlers/sessions/components/assist-panel/suggestion-strength'
import { useSessionControllerPostContentSuggestions, getSessionControllerGetSuggestedContentQueryKey } from '~/clinician-api'
import { useQueryClient } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { StoreModel } from 'src/store/types'
import { useAssistPanelContext } from '../context'

const InSessionAssistSetup = ({ handleBack }: { handleBack: () => void }) => {
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const queryClient = useQueryClient()
  const toast = useToast()
  const { lastSession: session } = useAssistPanelContext()
  
  const {
    mutate: postContentSuggestions,
    isLoading: isLoadingPostContentSuggestions
  } = useSessionControllerPostContentSuggestions({
    mutation: {
      onSuccess: () => {
        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          session.id
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })  
            
        handleBack()
      },
      onError: (error: any) => {
        toast({
          title: 'Error',
          description: (
            <Text whiteSpace="pre-wrap">
              {error.error?.message || 'Failed to generate suggestions'}
            </Text>
          ),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  })

  const suggestionStrengthHeight = useBreakpointValue({
    base: 'calc(100vh - 175px)',
    md: 'calc(100vh - 200px)',
  })
      
  return (
      <>
        <Box h={suggestionStrengthHeight} overflowY="scroll">
          <SuggestionStrength account={user} session={session} />
        </Box>
        <Box p="24px">
          <Button
            colorScheme="primary"
            variant="solid"
            type="submit"
            isFullWidth
            size="lg"
            m={0}
            isLoading={isLoadingPostContentSuggestions}
            onClick={async () => {
              await postContentSuggestions({
                id: session.id
              })
            }}
          >
            Generate suggestions
          </Button>
        </Box>
      </>
  )
}

export default InSessionAssistSetup
