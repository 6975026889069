import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionContainer = motion(Box)

export const FadeIn = ({
  children,
  duration = 0.15,
  ...boxProps
}: { children: React.ReactNode, duration?: number } & BoxProps) => {
  return (
    <MotionContainer
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 0, opacity: 0 }}
      transition={{
        duration: duration,
        ease: [0.57, 0.1, 0, 1]
      }}
      {...boxProps}
    >
      {children}
    </MotionContainer>
  )
}
