import React, { useMemo } from 'react'
import { Stack, HStack, Text, Checkbox, Box } from '@chakra-ui/react'

interface TreatmentApproachOption {
  value: string
  label: string
}

interface TreatmentApproachPanelProps {
  options: TreatmentApproachOption[]
  selectedValues: string[]
  onChange: (value: string[]) => void
}

const SINGLE_SELECT_IDS = ['other', 'medication_management']

const TreatmentApproachPanel = ({
  options,
  selectedValues = [],
  onChange
}: TreatmentApproachPanelProps) => {
  const multiSelectOptions = useMemo(() => {
    return options.filter(opt => !SINGLE_SELECT_IDS.includes(opt.value))
  }, [options])

  const singleSelectOptions = useMemo(() => {
    return options.filter(opt => SINGLE_SELECT_IDS.includes(opt.value))
  }, [options])

  const handleChange = (optionId: string) => {
    if (singleSelectOptions.some(opt => opt.value === optionId)) {
      if (selectedValues?.includes(optionId)) {
        const newValues = selectedValues.filter(id => id !== optionId)
        return onChange(newValues.length === 0 ? [] : newValues)
      }
      return onChange([optionId])
    }

    if (selectedValues?.includes(optionId)) {
      const newValues = selectedValues.filter(id => id !== optionId)
      return onChange(newValues.length === 0 ? [] : newValues)
    }

    const currentMultiSelectCount =
      selectedValues?.filter(
        id => !singleSelectOptions.some(opt => opt.value === id)
      ).length ?? 0

    if (currentMultiSelectCount >= 3) {
      return
    }

    const newValues = [...(selectedValues || []), optionId]
    return onChange(
      newValues.filter(id => !singleSelectOptions.some(opt => opt.value === id))
    )
  }

  return (
    <Stack spacing="0" h="280px" overflowY="scroll" pt="8px">
      <Box w="100%" p="xsmall" backgroundColor="#F4F4F4" color="dark_gray">
        <Text fontSize="12px" lineHeight="16px" fontWeight="bold">
          SELECT UP TO 3
        </Text>
      </Box>
      {multiSelectOptions.map((item, index) => {
        const currentMultiSelectCount =
          selectedValues?.filter(
            id => !singleSelectOptions.some(sOpt => sOpt.value === id)
          ).length ?? 0
        const isDisabled =
          currentMultiSelectCount >= 3 && !selectedValues?.includes(item.value)

        return (
          <HStack
            key={index}
            justifyContent="space-between"
            spacing="0"
            gap="16px"
            style={{ marginRight: '24px' }}
          >
            <Text flex="1" display="flex" alignItems="center" h="40px">
              {item.label}
            </Text>
            <Checkbox
              isChecked={!!selectedValues?.includes(item.value)}
              onChange={() => handleChange(item.value)}
              isDisabled={isDisabled}
            />
          </HStack>
        )
      })}
      <Box w="100%" p="xsmall" backgroundColor="#F4F4F4" color="dark_gray">
        <Text fontSize="12px" lineHeight="16px" fontWeight="bold">
          SINGLE SELECTION
        </Text>
      </Box>
      {singleSelectOptions.map((item, index) => (
        <HStack
          key={index}
          justifyContent="space-between"
          spacing="0"
          gap="16px"
          style={{ marginRight: '24px' }}
        >
          <Text flex="1" display="flex" alignItems="center" h="40px">
            {item.label}
          </Text>
          <Checkbox
            isChecked={!!selectedValues?.includes(item.value)}
            onChange={() => handleChange(item.value)}
          />
        </HStack>
      ))}
    </Stack>
  )
}

export default TreatmentApproachPanel
