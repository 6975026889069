import React, { useState } from 'react'
import {
  formatDuration,
  formatDurationFromSeconds
} from '@handlers/sessions/util'
import {
  Flex,
  Text,
  Link,
  Button,
  Box,
  useToast,
  useDisclosure,
  Stack
} from '@chakra-ui/react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import { DateTime } from 'luxon'
import { SessionMenu } from './session-menu'
import { DeleteConfirmation } from '@components'
import {
  useSessionControllerDeleteSession,
  getSessionControllerGetProgressNotesForSessionQueryKey
} from '~/clinician-api'
import { Select } from '@blueprinthq/joy'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'

const clientPronounOptions = Object.entries(CLIENT_PRONOUNS).map(
  ([key, value]) => ({
    value: value,
    label: key
  })
)

// Add reverse mapping object
const PRONOUN_KEYS = Object.entries(CLIENT_PRONOUNS).reduce(
  (acc, [key, value]) => {
    acc[value] = key
    return acc
  },
  {} as { [key: string]: string }
)

const findPronounDisplay = (pronoun: string) => {
  return PRONOUN_KEYS[pronoun] || pronoun
}

function toInputDateTimeFormat(datetime: DateTime): string {
  return datetime.toFormat('yyyy-LL-dd') + 'T' + datetime.toFormat('HH:mm')
}

const Details = ({
  client,
  session,
  refresh,
  progressNote,
  sessionType
}: any) => {
  const toast = useToast()
  const history = useHistory()
  const { isExtension, isWidget } = useExperienceManager()
  const queryClient = useQueryClient()

  const [startedAt, setStartedAt] = useState(
    DateTime.fromISO(session.startedAt)
  )
  const [completedAt, setCompletedAt] = useState(
    DateTime.fromISO(session.completedAt || session.startedAt)
  )
  const [isEditingStartTime, setIsEditingStartTime] = useState(false)
  const [isEditingEndTime, setIsEditingEndTime] = useState(false)
  const [isEditingSessionDate, setIsEditingSessionDate] = useState(false)
  const [pronoun, setPronoun] = useState(client?.pronoun)
  const [isEditingPronouns, setIsEditingPronouns] = useState(false)

  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: onDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose
  } = useDisclosure()

  const {
    mutateAsync: deleteSession,
    isLoading: isDeleting
  } = useSessionControllerDeleteSession()

  const {
    mutateAsync: updatePronouns,
    isLoading: isUpdatingPronouns
  } = useMutation(async (data: { pronoun: string }) => {
    await endpoints.patchClinicianUserInfo.request({
      patientId: client.id,
      data: {
        pronoun: data.pronoun
      }
    })

    await endpoints.generateProgressNote.request({
      sessionId: session.id,
      noteType: progressNote.noteType
    })

    await queryClient.invalidateQueries(
      getSessionControllerGetProgressNotesForSessionQueryKey(session.id)
    )
  })

  const { mutate: updateTimestamps }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.patchSessionTimestampOverrides.request,
    {
      onSuccess: () => {
        refresh()
        setIsEditingStartTime(false)
        setIsEditingEndTime(false)
        setIsEditingSessionDate(false)
        setIsEditingPronouns(false)
      }
    }
  )

  const handleDeleteSession = () => {
    deleteSession(
      { id: session.id },
      {
        onSuccess: () => {
          toast({
            title: 'Session deleted',
            status: 'success',
            isClosable: true,
            duration: 3000
          })
          onDeleteConfirmationClose()
          if (isWidget) {
            history.replace('/widget/start-session')
          } else if (isExtension) {
            history.replace('/extension/start-session')
          } else {
            history.replace('/sessions')
          }
        }
      }
    )
  }

  const onUpdateTimestamps = () => {
    if (startedAt > completedAt) {
      toast({
        status: 'error',
        description: 'Time must be before end date.'
      })
    } else if (completedAt < startedAt) {
      toast({
        status: 'error',
        description: 'Time must be after start date.'
      })
    } else {
      updateTimestamps({
        sessionId: session.id,
        data: {
          startedAtOverride: startedAt,
          completedAtOverride: completedAt
        }
      })
    }
  }

  const handlePronounsUpdate = () => {
    updatePronouns(
      {
        pronoun: pronoun
      },
      {
        onSuccess: () => {
          toast({
            title: 'Pronouns updated',
            status: 'success',
            isClosable: true,
            duration: 3000
          })
        }
      }
    )
  }

  return (
    <>
      <Flex flexDirection="column" gap="16px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight="bold">Overview</Text>
          <SessionMenu onDelete={onDeleteConfirmationOpen} />
        </Flex>
        <Flex flexDirection="column" gap="2px">
          <Text>
            <b>Client:</b>&nbsp;
            {isWidget || isExtension ? (
              <Text as="span">
                {client.first_name} {client.last_name}
              </Text>
            ) : (
              <Link
                color="primary"
                as={RouterLink}
                to={`/patient/${client.id}`}
              >
                {client.first_name} {client.last_name}
              </Link>
            )}
          </Text>
          <Flex>
            <Text>
              <b>Pronouns:</b>&nbsp;
              {!isEditingPronouns && findPronounDisplay(pronoun)}
            </Text>
            {!isEditingPronouns && (
              <Link
                ml="xsmall"
                color="primary"
                onClick={() => setIsEditingPronouns(true)}
              >
                {pronoun ? 'Edit' : '+ Add pronouns'}
              </Link>
            )}
          </Flex>
          {isEditingPronouns && (
            <Stack gap="9px" spacing="0">
              <Select
                label={''}
                maxH="40px"
                valueKey="value"
                labelKey="label"
                placeholder="Select pronouns"
                options={clientPronounOptions}
                value={clientPronounOptions.find(
                  option => option.value === pronoun
                )}
                onChange={value => {
                  setPronoun(value.value)
                }}
              />
              <Flex>
                <Button
                  size="sm"
                  variant="outline"
                  ml="0"
                  onClick={() => {
                    setIsEditingPronouns(false)
                    setPronoun(client.pronoun)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    handlePronounsUpdate()
                    setIsEditingPronouns(false)
                  }}
                >
                  Save & regenerate note
                </Button>
              </Flex>
            </Stack>
          )}
        </Flex>

        <Flex flexDirection="column" gap="2px">
          <Flex>
            <Text>
              <b>Date:</b>&nbsp;
              {!isEditingSessionDate && startedAt.toFormat('EEE MMM d, yyyy')}
            </Text>
            {!isEditingSessionDate && (
              <Link
                ml="xsmall"
                color="primary"
                onClick={() => setIsEditingSessionDate(!isEditingSessionDate)}
              >
                Edit
              </Link>
            )}
          </Flex>
          {isEditingSessionDate && (
            <>
              <input
                type="date"
                style={{
                  border: '1px solid #E4E5E6',
                  borderRadius: '5px',
                  padding: '5px 5px',
                  maxWidth: '252px'
                }}
                value={startedAt.toFormat('yyyy-MM-dd')}
                onChange={e => {
                  const dateValues = e.target.value.split('-')
                  setStartedAt(
                    startedAt.set({
                      year: Number(dateValues[0]),
                      month: Number(dateValues[1]),
                      day: Number(dateValues[2])
                    })
                  )
                  setCompletedAt(
                    completedAt.set({
                      year: Number(dateValues[0]),
                      month: Number(dateValues[1]),
                      day: Number(dateValues[2])
                    })
                  )
                }}
              />
              <Flex>
                <Button
                  size="sm"
                  variant="outline"
                  ml="0"
                  onClick={() => {
                    setIsEditingSessionDate(false)
                    setStartedAt(DateTime.fromISO(session.startedAt).toLocal())
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={() =>
                    updateTimestamps({
                      sessionId: session.id,
                      data: {
                        startedAtOverride: startedAt,
                        completedAtOverride: completedAt
                      }
                    })
                  }
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
          {!session.fileUploadId && (
            <>
              <Flex>
                <Text>
                  <b>Start Time:</b>&nbsp;
                  {!isEditingStartTime && startedAt.toFormat('h:mma')}
                </Text>
                {!isEditingStartTime && (
                  <Link
                    ml="xsmall"
                    color="primary"
                    onClick={() => setIsEditingStartTime(!isEditingStartTime)}
                  >
                    Edit
                  </Link>
                )}
              </Flex>
              {isEditingStartTime && (
                <>
                  <input
                    type="datetime-local"
                    style={{
                      border: '1px solid #E4E5E6',
                      borderRadius: '5px',
                      padding: '5px 5px',
                      maxWidth: '252px'
                    }}
                    value={toInputDateTimeFormat(startedAt)}
                    max={toInputDateTimeFormat(completedAt)}
                    onChange={e => {
                      const nextStartedAt = DateTime.fromISO(e.target.value)
                      if (!nextStartedAt.isValid) return
                      setStartedAt(nextStartedAt)
                    }}
                  />
                  <Flex>
                    <Button
                      size="sm"
                      variant="outline"
                      ml="0"
                      onClick={() => {
                        setIsEditingStartTime(false)
                        setStartedAt(DateTime.fromISO(session.startedAt))
                      }}
                    >
                      Cancel
                    </Button>
                    <Button size="sm" onClick={onUpdateTimestamps}>
                      Save
                    </Button>
                  </Flex>
                </>
              )}
              <Flex>
                <Text>
                  <b>End Time:</b>&nbsp;
                  {!isEditingEndTime && completedAt.toFormat('h:mma')}
                </Text>
                {!isEditingEndTime && (
                  <Link
                    ml="xsmall"
                    color="primary"
                    onClick={() => setIsEditingEndTime(!isEditingEndTime)}
                  >
                    Edit
                  </Link>
                )}
              </Flex>
              {isEditingEndTime && (
                <>
                  <input
                    type="datetime-local"
                    style={{
                      border: '1px solid #E4E5E6',
                      borderRadius: '5px',
                      padding: '5px 5px',
                      maxWidth: '252px'
                    }}
                    min={toInputDateTimeFormat(startedAt)}
                    max={toInputDateTimeFormat(startedAt.plus({ days: 1 }))}
                    value={toInputDateTimeFormat(completedAt)}
                    onChange={e => {
                      const nextCompletedAt = DateTime.fromISO(e.target.value)
                      if (!nextCompletedAt.isValid) return
                      setCompletedAt(nextCompletedAt)
                    }}
                  />
                  <Flex>
                    <Button
                      size="sm"
                      variant="outline"
                      ml="0"
                      onClick={() => {
                        setIsEditingEndTime(false)
                        setCompletedAt(DateTime.fromISO(session.completedAt))
                      }}
                    >
                      Cancel
                    </Button>
                    <Button size="sm" onClick={onUpdateTimestamps}>
                      Save
                    </Button>
                  </Flex>
                </>
              )}
              <Flex flexDirection="column" gap="2px">
                {session.completedAt && (
                  <Text>
                    <b>Duration:</b>&nbsp;
                    {formatDuration(session.startedAt, session.completedAt)}
                  </Text>
                )}
              </Flex>
            </>
          )}
        </Flex>

        <Flex flexDirection="column" gap="2px">
          <Text>
            <b>Source:</b>&nbsp;
            {session.fileUploadId
              ? 'Uploaded'
              : !!session.recordingStartedAt || !!session.sampleType
              ? session.isDictation
                ? 'Dictated'
                : 'Recorded'
              : 'Assessments, Worksheets, Interventions.'}
          </Text>

          {session.transcriptDurationSeconds && (
            <Text>
              <b>Recording Time:</b>&nbsp;
              {formatDurationFromSeconds(session.transcriptDurationSeconds)}
            </Text>
          )}
          <Text>
            <b>Setting:</b>&nbsp;
            {session.isTelehealth ? 'Telehealth' : 'In-person'}
          </Text>
        </Flex>
        <Box>
          {progressNote?.createdAt && (
            <Text
              as="em"
              fontSize="xs"
              color="darkgray"
            >{`Note generated on ${DateTime.fromISO(progressNote.createdAt)
              .toLocal()
              .toFormat('EEE MMM d, yyyy')}`}</Text>
          )}
        </Box>
      </Flex>
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        onClose={onDeleteConfirmationClose}
        onConfirm={handleDeleteSession}
        isConfirming={isDeleting}
        header="Delete session?"
        text="Deleting a session will permanently remove all the notes, summaries, and transcript associated to this session."
        confirmText="Delete session"
      />
    </>
  )
}

export default Details
