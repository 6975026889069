import React from 'react'
import { Container, useToast } from '@chakra-ui/react'
import { useOrganizationControllerCreateCustomProgressNoteTemplate } from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { ProgressNoteTemplateForm, FormValues } from './form'

export const New = () => {
  const history = useHistory()
  const toast = useToast()
  const { organizationId } = useExperienceManager()
  const { mutate: createCustomProgressNoteTemplate } = useOrganizationControllerCreateCustomProgressNoteTemplate()
  const queryClient = useQueryClient()

  const initialValues: FormValues = {
    name: '',
    sections: [
      {
        name: '',
        id: crypto.randomUUID(),
        sampleOutputs: ['']
      }
    ]
  }

  const handleSubmit = (values: FormValues) => {
    createCustomProgressNoteTemplate({
      organizationId,
      data: { 
        name: values.name,
        sections: values.sections.map((section, index) => ({
          name: section.name,
          position: index,
          sampleOutputs: section.sampleOutputs
        }))
      }
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['organizationControllerGetCustomProgressNoteTemplates'] })
        toast({
          title: 'Template created',
          description: 'Your template has been created',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        history.replace('/settings/templates')
      }
    })
  }

  return (
    <Container mt="40px" maxWidth="1142px">
      <ProgressNoteTemplateForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={() => history.push('/settings/templates')}
      />
    </Container>
  )
}

export default New
