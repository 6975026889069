/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type CheckInHeadingNodeType = typeof CheckInHeadingNodeType[keyof typeof CheckInHeadingNodeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckInHeadingNodeType = {
  free_text: 'free_text',
  multiple_choice: 'multiple_choice',
  heading: 'heading',
  likert: 'likert',
  slider_vertical: 'slider_vertical',
} as const;
