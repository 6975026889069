import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Text,
  Button,
  Link,
  VStack,
  BoxProps,
  Flex
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import { TextField } from '@blueprinthq/joy'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'
import { SocialProofFooter } from './social-proof-footer'

export const Terms = (boxProps: BoxProps) => {
  return (
    <Box mt={4} {...boxProps}>
      <Text align="center" fontSize="10px">
        By signing up, you agree to our{' '}
        <Link
          href="https://www.blueprint.ai/privacy"
          color="#757575"
          isExternal
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          href="https://www.blueprint.ai/platform-services-agreement"
          color="#757575"
          isExternal
        >
          Terms of Service.
        </Link>
      </Text>
    </Box>
  )
}

export const Email = ({
  onNext,
  isLoading,
  onboardingVersion
}: {
  onNext: () => void
  isLoading: boolean
  onboardingVersion: number | null
}) => {
  useEffect(() => {
    if (onboardingVersion) {
      trackEvent('Onboarding Email Viewed', {
        version: onboardingVersion
      })
    }
  }, [onboardingVersion])

  return (
    <Step mt={{ base: '80px', md: 0 }} pb={4}>
      <VStack
        spacing={4}
        flex="1"
        position={{ base: 'static', md: 'relative' }}
      >
        <Flex
          flex={{ base: '2', md: '1' }}
          flexDir="column"
          w="100%"
          justifyContent={{ base: 'flex-start', md: 'center' }}
        >
          <Box textAlign="center">
            <Text fontSize="32px" fontWeight="bold" mb={{ base: 12, md: 0 }}>
              Get the AI Assistant <br />
              <Text as="span" color="primary" fontSize="32px" fontWeight="bold">
                for Therapists
              </Text>
            </Text>
          </Box>
          <Box mt={{ base: 0, md: 4 }}>
            <Field name="email">
              {({ field, meta }: FieldProps) => {
                const isInvalid = !!meta.error && !!meta.touched
                return (
                  <Box mb={isInvalid ? 6 : 4}>
                    <TextField
                      {...field}
                      type="email"
                      w="100%"
                      label="Enter your work email to begin"
                      isInvalid={isInvalid}
                      errorText={meta.error}
                      bg="white"
                      activeLabelStyle={{
                        bgGradient: 'linear(to-t, white, #F6F7FE)'
                      }}
                      autoFocus
                    />
                  </Box>
                )
              }}
            </Field>
            <Button
              size="lg"
              isFullWidth
              mb={6}
              onClick={onNext}
              isLoading={isLoading}
              type="submit"
            >
              Continue with email
            </Button>
            <Text align="center" color="#757575">
              Already have an account?{' '}
              <Link as={RouterLink} to="/" color="primary">
                Login
              </Link>
            </Text>
          </Box>
          <Terms display={{ base: 'none', md: 'block' }} />
        </Flex>
        <SocialProofFooter
          showTerms={true}
          termsComponent={<Terms display={{ base: 'block', md: 'none' }} />}
        />
      </VStack>
    </Step>
  )
}
