export const FlagsmithFeatures = Object.freeze({
  // KEEP
  ASSIST: 'assist',
  CLIENT_CONSENT: 'client_consent',
  DEMO_CLINICIAN: 'demo_clinician',
  EDIT_CHECK_INS: 'edit_check_ins',
  EHR_APPOINTMENTS: 'ehr_appointments',
  EHR_INTEGRATION_CLIENT_OPT_IN: 'ehr_integration_client_opt_in',
  EHR_INTEGRATION_ISSUES: 'ehr_integration_issues',
  SHOW_APPOINTMENT_WORKFLOWS: 'show_appointment_workflows',
  SHOW_IS_DEMO_CLIENTS_IN_START_SESSION:
    'show_is_demo_clients_in_start_session',
  SHOW_LIBRARY_NAV: 'show_library_nav',
  SYMPTOM_TRACKER_AND_WORKSHEETS_ENABLED:
    'symptom_tracker_and_worksheets_enabled',
  USE_DEMO_NOTES_WITH_EBC_CONTENT: 'use_demo_notes_with_ebc_content',
  RULA_UI_EXPERIENCE: 'rula_ui_experience',

  // investigate
  ALWAYS_SHOW_SAMPLE_SESSIONS: 'always_show_sample_sessions',
  AUDIT_REPORT: 'audit_report',
  DIAGNOSIS_SUGGESTIONS: 'has_diagnosis_suggestions',

  // features
  PLUS_PLAN_FREE_TRIAL_BANNER: 'plus_plan_free_trial_banner_release',
  AUDIO_CAPTURE_V2: 'audio_capture_v2',
  PRESIGNED_URL_V2: 'presigned_url_v2',
  CLINICIAN_MOBILE_PROMO: 'clinician_mobile_promo',
  REFERRAL_V2: 'referral_v2',
  CUSTOM_NOTE_TEMPLATES: 'custom_note_templates_release',
  FINISH_ASSIST_SETUP_V2: 'finish_assist_setup_v2',
  SHOW_CUSTOM_TEMPLATE_INSTRUCTIONS: 'show_custom_template_instructions',
  CUSTOM_NOTE_TEMPLATES_FULL_RELEASE: 'custom_note_templates_full_release',
  MEMORY: 'memory',
  CANCELLATION_REASONS_V2: 'cancellation_reasons_v2',
  ONBOARDING_V2: 'onboarding_v2'
})
