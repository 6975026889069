import React, { useEffect } from 'react'
import { Box, Flex, Text, Button } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import { TextField } from '@blueprinthq/joy'
import { FadeIn } from './animations'
import { Step } from './step'
import { PasswordRules } from '../../../components'
import { RedirectToBeginning } from './redirect-to-beginning'
import { trackEvent } from '@lib/clinician-tracking'

interface PasswordProps {
  onNext: () => void
  firstName: string
  onboardingVersion: number | null
}

export const Password: React.FC<PasswordProps> = ({
  onNext,
  firstName,
  onboardingVersion
}) => {
  useEffect(() => {
    if (onboardingVersion) {
      trackEvent('Onboarding Password Viewed', {
        version: onboardingVersion
      })
    }
  }, [onboardingVersion])

  return (
    <Step mt={{ base: '65px', md: 0 }}>
      <RedirectToBeginning />
      <FadeIn>
        <Flex
          flexDirection="column"
          justifyContent={{ base: 'space-between', md: 'center' }}
          h="100%"
        >
          <Box textAlign="left" mb="34px">
            <Text fontSize="24px" color="primary">
              Hi {firstName}! <br />
              <Text fontSize="24px" as="span" color="black">
                Now set your password
              </Text>
            </Text>
          </Box>
          <Box flex={{ base: 1, md: 0 }} mb={{ base: 0, md: '65px' }}>
            <Field name="password">
              {({ field }: FieldProps) => {
                return (
                  <Box mb={4}>
                    <TextField
                      {...field}
                      type="password"
                      w="100%"
                      isRequired
                      label="Password"
                      required
                      bg="white"
                      activeLabelStyle={{
                        bgGradient: 'linear(to-t, white, #F6F7FE)'
                      }}
                      autoFocus
                    />
                    <PasswordRules password={field.value} />
                  </Box>
                )
              }}
            </Field>
          </Box>
        </Flex>
      </FadeIn>
      <Box pt={4} pb={8}>
        <Button isFullWidth onClick={onNext} m={0} type="submit" size="lg">
          Continue
        </Button>
      </Box>
    </Step>
  )
}
