import React, { ReactChild, useState } from 'react'
import {
  Flex,
  Text,
  Button,
  VStack,
  useToast,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverTrigger
} from '@chakra-ui/react'
import { RadioButtonLoading } from '@components/icons'
import DischargeSummaryModal from './document-viewer/discharge-summary/Modal'
import {
  useDischargeSummariesControllerGenerateDischargeSummary,
  useDischargeSummariesControllerDeleteDischargeSummary,
  useDischargeSummariesControllerUpdateDischargeSummary,
  useDischargeSummariesControllerGetDischargeSummaries,
  getDischargeSummariesControllerGetDischargeSummariesQueryKey,
  usePatientControllerGetSessionList
} from '~/clinician-api'
import { useQueryClient, useQuery } from 'react-query'
import { endpoints } from '@api'
import { trackEvent } from '@lib/clinician-tracking'

import moment from 'moment'
interface DischargeSummaryProps {
  client: any
}

const GenerationDisabledPopover = ({
  children,
  canGenerate
}: {
  children: ReactChild
  canGenerate: boolean
}) => {
  return !canGenerate ? (
    <Popover trigger="hover" placement="bottom">
      <PopoverContent _focus={{ outline: 'none' }} borderColor="pale_gray">
        <PopoverArrow />
        <PopoverBody>
          <Text>Requires a Treatment Plan.</Text>
        </PopoverBody>
      </PopoverContent>
      <PopoverTrigger>{children}</PopoverTrigger>
    </Popover>
  ) : (
    <>{children}</>
  )
}

const DischargeSummary = ({ client }: DischargeSummaryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hasClickedEdit, setHasClickedEdit] = useState(false)
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: sessionList } = usePatientControllerGetSessionList(client.id, {
    query: {
      initialData: []
    }
  })

  const { data: treatmentPlanData } = useQuery(
    [endpoints.getClientTreatmentPlan.getCacheId(), client.id],
    () => endpoints.getClientTreatmentPlan.request({ clientId: client.id })
  )

  const hasTreatmentPlan =
    (treatmentPlanData as any)?.treatmentPlan &&
    !(treatmentPlanData as any)?.treatmentPlan?.isLoading
  const canGenerate = Boolean(hasTreatmentPlan)

  const {
    data: dischargeSummaries,
    refetch: refetchDischargeSummaries
  } = useDischargeSummariesControllerGetDischargeSummaries(client.id, {
    query: {
      refetchInterval: (data: any) => {
        if (data?.[0]?.isLoading) {
          return 5000
        }

        return false
      }
    }
  })

  const dischargeSummary = dischargeSummaries?.[0]

  const {
    mutateAsync: generateDischargeSummary,
    isLoading: isGenerating
  } = useDischargeSummariesControllerGenerateDischargeSummary({
    mutation: {
      onSuccess: () => {
        refetchDischargeSummaries()
      },
      onError: error => {
        toast({
          title: 'Error generating discharge summary',
          status: 'error',
          duration: 3000
        })
      }
    }
  })

  const {
    mutateAsync: updateDischargeSummary,
    isLoading: isUpdating,
    isError: isUpdatingError
  } = useDischargeSummariesControllerUpdateDischargeSummary({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getDischargeSummariesControllerGetDischargeSummariesQueryKey(
            client.id
          )
        )
      }
    }
  })

  const {
    mutateAsync: deleteDischargeSummary,
    isLoading: isDeleting
  } = useDischargeSummariesControllerDeleteDischargeSummary({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getDischargeSummariesControllerGetDischargeSummariesQueryKey(
            client.id
          )
        )
        trackEvent('Discharge Summary -> Deleted', { dischargeSummaryId: dischargeSummary?.id })
        toast({
          title: 'Discharge summary deleted',
          status: 'success',
          duration: 3000
        })
      }
    }
  })

  const closeModal = () => {
    setHasClickedEdit(false)
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  if (!sessionList || sessionList.length < 1) return null

  return (
    <VStack
      border="1px solid"
      flex="1"
      borderColor="pale_gray"
      borderRadius="8px"
      p="16px"
      boxShadow="0px 2px 8px 0px #00000014"
      alignItems="stretch"
      id="pendo-discharge-summary-generation-container"
    >
      <Flex justifyContent="space-between" gap="8px" w="100%">
        <Flex gap="8px" w="100%">
          <Flex>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"
                fill="#2D54E8"
              />
            </svg>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="bold">Discharge Summary</Text>
            {
              <Text
                lineHeight="17px"
                fontSize="13px"
                color="dark_gray"
                mb="xsmall"
              >
                {!dischargeSummary
                  ? 'Create a discharge document from recent sessions.'
                  : dischargeSummary.isLoading
                  ? 'Generating discharge summary...'
                  : `Last updated ${moment(dischargeSummary.updatedAt).format(
                      'MMMM D, YYYY'
                    )}`}
              </Text>
            }
          </Flex>
        </Flex>
        <Flex alignItems="center">
          {dischargeSummary?.isLoading && (
            <RadioButtonLoading stroke="#C9C9C9" />
          )}
        </Flex>
      </Flex>
      {dischargeSummary && !dischargeSummary.isLoading ? (
        <Button
          onClick={() => {
            trackEvent(
              'Discharge Summary -> Clicked View Summary',
              { dischargeSummaryId: dischargeSummary.id }
            )
            openModal()
          }}
          borderRadius="4px"
          style={{ textDecoration: 'none' }}
          _focus={{ outline: 'none' }}
          variant="outline"
          size="sm"
        >
          View Summary
        </Button>
      ) : (
        !dischargeSummary && (
          <GenerationDisabledPopover canGenerate={canGenerate}>
            <Button
              onClick={
                canGenerate
                  ? () => {
                      trackEvent(
                        'Discharge Summary -> Clicked Generate Summary'
                      )
                      
                      return generateDischargeSummary({ patientId: client.id })
                    }
                  : e => e.preventDefault()
              }
              isLoading={dischargeSummary?.isLoading}
              isDisabled={isGenerating}
              borderRadius="4px"
              style={{
                textDecoration: 'none',
                cursor: canGenerate ? 'pointer' : 'not-allowed',
                opacity: canGenerate ? 1 : 0.6
              }}
              _focus={{ outline: 'none' }}
              size="sm"
            >
              Generate
            </Button>
          </GenerationDisabledPopover>
        )
      )}
      <DischargeSummaryModal
        onDelete={deleteDischargeSummary}
        onUpdate={updateDischargeSummary}
        isUpdating={isUpdating}
        isUpdatingError={isUpdatingError}
        isRegenerating={dischargeSummary?.isLoading}
        isDeleting={isDeleting}
        isOpen={isModalOpen}
        onClose={closeModal}
        dischargeSummary={dischargeSummary}
        setHasClickedEdit={setHasClickedEdit}
        hasClickedEdit={hasClickedEdit}
        client={client}
        sessionList={sessionList}
      />
    </VStack>
  )
}

export default DischargeSummary
