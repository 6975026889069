import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { TrashIcon } from '@icons'
import { endpoints } from '@api'
import { SearchSelect } from '@components'
import {
  Text,
  Button,
  Box,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  FormControl,
  FormLabel,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Textarea,
  useBreakpointValue,
  Flex,
  keyframes,
  Divider,
  Alert,
  AlertTitle,
  AlertIcon,
  CloseButton,
  VStack,
  IconButton
} from '@chakra-ui/react'
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldProps,
  FieldArray,
  FormikProps,
  useFormikContext
} from 'formik'
import { copyToClipboard } from '@utilities'
import * as Yup from 'yup'
import moment from 'moment'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { datadogLogs } from '@datadog/browser-logs'
import { HighlightOffOutlined } from '@material-ui/icons'
import { isChrome, isMobile } from 'react-device-detect'

interface ModalProps {
  onDelete: () => void
  accept: (values: any) => Promise<void>
  onUpdate: (values: any) => Promise<void>
  onRegenerate: (values: any) => Promise<void>
  trackEvent: (values: any) => Promise<void>
  isOpen: boolean
  onClose: () => void
  treatmentPlan: any
  client: any
  isAccepting: boolean
  isUpdating: boolean
  isDeleting: boolean
  isRegenerating: boolean
  hasDiagnosisSuggestions: boolean
  regenerationError: Error | null
}

interface Diagnosis {
  id: string
  code: string
  name: string
}

interface FocusOfTreatment {
  id: string
  name: string
}

type OptionType = {
  value: string
  label: string
}

type SelectedFocus =
  | {
      value: string
      label: string
    }
  | undefined

type SelectedDiagnosis =
  | {
      value: string
      label: string
    }
  | undefined

type AlertMessageT = {
  status: 'success' | 'info' | 'warning' | 'error' | undefined
  title: string
}

interface FormValues {
  selectedDiagnosis?: OptionType
  selectedDiagnoses: SelectedDiagnosis[]
  selectedFocuses: SelectedFocus[]
  treatmentPlanText: string
}

const slideInRight = keyframes`
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInLeft = keyframes`
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const useAutosizeTextArea = (value: string) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }, [value])

  return textAreaRef
}
const RegenerateAlert = ({
  onGenerate,
  isGenerating
}: {
  onGenerate: any
  isGenerating: any
}) => {
  return (
    <Alert
      status="info"
      borderRadius="md"
      colorScheme="#F7F8FE"
      p="small"
      my="small"
    >
      <Stack>
        <Text fontWeight="bold">Regenerate Treatment Plan</Text>
        <Text>
          Do you want to regenerate a new treatment plan with your diagnosis and
          focus of treatment selections?
        </Text>
        <Flex gap="8px">
          <Button
            borderRadius="4px"
            size="sm"
            gap="8px"
            onClick={async () => {
              await onGenerate()
            }}
            isLoading={isGenerating}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9999 4.74667H7.47992L9.30659 2.86667C7.48659 1.06667 4.53992 1 2.71992 2.8C0.899921 4.60667 0.899921 7.52 2.71992 9.32667C4.53992 11.1333 7.48659 11.1333 9.30659 9.32667C10.2133 8.43333 10.6666 7.38667 10.6666 6.06667H11.9999C11.9999 7.38667 11.4133 9.1 10.2399 10.26C7.89992 12.58 4.09992 12.58 1.75992 10.26C-0.573412 7.94667 -0.593412 4.18667 1.74659 1.87333C4.08659 -0.44 7.83992 -0.44 10.1799 1.87333L11.9999 0V4.74667Z"
                fill="white"
              />
            </svg>
            Regenerate Treatment Plan
          </Button>
        </Flex>
      </Stack>
    </Alert>
  )
}

const DeleteConfirmation = ({ isOpen, onClose, onConfirm, isLoading }: any) => {
  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Are you sure?" onClose={onClose} />
      <DialogBody>
        <VStack spacing={6}>
          <Text textAlign="center">
            Are you sure you want to delete this Treatment Plan and remove
            golden thread documentation? This cannot be undone.
          </Text>
          <VStack w="100%">
            <Button
              bg="error"
              isFullWidth
              size="lg"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              Delete Treatment Plan
            </Button>
            <Button
              _focus={{ outline: 'none' }}
              variant="outline"
              isFullWidth
              size="lg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}

const RegenerationConfirmation = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  error
}: any) => {
  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Are you sure?" onClose={onClose} />
      <DialogBody>
        <VStack spacing={6}>
          {error && (
            <Text color="red">
              An error occurred while regenerating the treatment plan. Please
              try again.
            </Text>
          )}
          <Text textAlign="center">
            Are you sure you want to regenerate this Treatment Plan? This will
            overwrite the current treatment plan.
          </Text>
          <VStack w="100%">
            <Button
              bg="primary"
              isFullWidth
              size="lg"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              Regenerate Treatment plan
            </Button>
            <Button
              _focus={{ outline: 'none' }}
              variant="outline"
              isFullWidth
              size="lg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}

const TreatmentPlanText: React.FC = () => {
  const { values } = useFormikContext<any>()
  const textAreaRef = useAutosizeTextArea(values.treatmentPlanText)

  return (
    <FormControl>
      <FormLabel fontWeight="bold">Treatment Plan:</FormLabel>
      <Field name="treatmentPlanText">
        {({ field }: FieldProps<string>) => (
          <Textarea
            {...field}
            ref={textAreaRef}
            borderColor="pale_gray"
            overflow="hidden"
            pt={'16px !important'}
            pb={'16px !important'}
          />
        )}
      </Field>
    </FormControl>
  )
}

const TreatmentPlanModal: React.FC<ModalProps> = ({
  onDelete,
  accept,
  onUpdate,
  onRegenerate,
  isOpen,
  onClose,
  trackEvent,
  treatmentPlan,
  client,
  isAccepting,
  isRegenerating,
  isUpdating,
  isDeleting,
  hasDiagnosisSuggestions,
  regenerationError
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  )
  const [isRegenerateOpen, setIsRegenerateOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState<AlertMessageT | null>(null)
  const modalSize = useBreakpointValue({ base: 'full', sm: 'full', md: '4xl' })
  const contentHeight = useBreakpointValue({
    base: 'calc(100vh - 155px)',
    sm: 'calc(100vh - 155px)',
    md: 'calc(100vh - 220px)'
  })

  const { data: diagnoses, isLoading: isLoadingDiagnosis } = useQuery<
    Diagnosis[]
  >(
    [endpoints.getDiagnoses.getCacheId()],
    () => endpoints.getDiagnoses.request({}) as Promise<Diagnosis[]>
  )

  const { data: focusOfTreatments, isLoading: isLoadingFocuses } = useQuery<
    FocusOfTreatment[]
  >(
    [endpoints.getFocusOfTreatments.getCacheId()],
    () =>
      endpoints.getFocusOfTreatments.request({
        isTxPlanEnabled: true
      }) as Promise<FocusOfTreatment[]>
  )

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        setAlertMessage(null)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [alertMessage])

  if (isLoadingDiagnosis || isLoadingFocuses) return null

  const validationSchema = Yup.object().shape({
    selectedDiagnosis: Yup.mixed(),
    selectedFocuses: Yup.array().of(Yup.mixed()),
    treatmentPlanText: Yup.string()
  })

  const diagnosisOptions =
    diagnoses?.map(diagnosis => ({
      value: diagnosis.id,
      label: `${diagnosis.code} - ${diagnosis.name}`
    })) || []

  const focusOptions =
    focusOfTreatments?.map(focusOfTreatment => ({
      value: focusOfTreatment.id,
      label: focusOfTreatment.name
    })) || []

  const initialSelectedDiagnosis = diagnosisOptions?.find(
    diagnosis => diagnosis.value === treatmentPlan?.diagnosisId
  )

  const initialSelectedDiagnoses: OptionType[] = treatmentPlan.diagnosisIds
    ?.map((id: string) =>
      diagnosisOptions.find((option: OptionType) => option.value === id)
    )
    ?.filter((diagnosis: SelectedDiagnosis) => diagnosis) || [
    initialSelectedDiagnosis
  ]

  const initialSelectedFocuses: OptionType[] =
    treatmentPlan.focusOfTreatmentIds
      .map((id: string) =>
        focusOptions.find((option: OptionType) => option.value === id)
      )
      .filter((focus: SelectedFocus) => focus) || []

  const handleCopyAllToClipboard = () => {
    const values = []

    values.push(`Name: ${client.first_name} ${client.last_name}`)
    values.push(`DOB: ${moment.utc(client.date_of_birth).format('M/D/YYYY')}`)

    if (initialSelectedDiagnoses.length) {
      const diagnoses = initialSelectedDiagnoses
        .map(diagnosis => diagnosis.label)
        .join(', ')

      values.push(`Diagnosis: ${diagnoses}`)
    } else {
      values.push('Diagnosis: N/A')
    }

    if (initialSelectedFocuses.length) {
      const focuses = initialSelectedFocuses
        .map(focus => focus.label)
        .join(', ')
      values.push(`Focus of Treatment: ${focuses}`)
    } else {
      values.push('Focus of Treatment: N/A')
    }

    values.push(`Treatment Plan: ${treatmentPlan?.rawText || 'N/A'}`)

    const value = values.join('\n\n')

    trackEvent({
      eventType: 'copied_treatment_plan',
      payload: {
        copiedText: value
      }
    })

    copyToClipboard(value)

    setAlertMessage({
      title: 'Copied to clipboard',
      status: 'success'
    })
  }

  const focusSuggestions: OptionType[] =
    treatmentPlan?.extractedData?.focus_of_treatment
      ?.map((id: string) =>
        focusOptions?.find((option: OptionType) => option.value === id)
      )
      .filter((focus: OptionType) => focus) || []

  const diagnosisSuggestions: OptionType[] =
    treatmentPlan?.extractedData?.diagnoses
      ?.map((id: string) =>
        diagnosisOptions?.find((option: OptionType) => option.value === id)
      )
      .filter((diagnosis: OptionType) => diagnosis) || []

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setIsEditing(false)
      }}
      size={modalSize}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent maxH="none" position="relative">
        <ModalHeader
          textAlign="center"
          position="sticky"
          top="0"
          borderBottom="1px solid"
          borderColor="pale_gray"
          bg="white"
          zIndex="9"
          borderRadius="8px 8px 0 0px"
        >
          {treatmentPlan?.isDraft && (
            <Text>Review & Accept Treatment Plan</Text>
          )}
          {treatmentPlan?.acceptedAt && !isEditing && (
            <Text>Review Treatment Plan</Text>
          )}
          {isEditing && <Text>Edit Treatment Plan</Text>}
          <ModalCloseButton _focus={{ outline: 'none' }} />
        </ModalHeader>
        <Formik<FormValues>
          initialValues={{
            selectedDiagnosis: initialSelectedDiagnosis,
            selectedDiagnoses: initialSelectedDiagnoses,
            selectedFocuses: initialSelectedFocuses,
            treatmentPlanText: treatmentPlan?.rawText
          }}
          validationSchema={validationSchema}
          validate={values => {
            const errors: any = {}

            if (
              !values.selectedDiagnoses?.filter(d => d?.value)?.length &&
              !values.selectedFocuses?.filter(f => f?.value)?.length
            ) {
              errors.selectedDiagnoses =
                'Either diagnosis or focus of treatment must be selected'
              errors.selectedFocuses =
                'Either diagnosis or focus of treatment must be selected'
            }

            return errors
          }}
          onSubmit={async (values, { setTouched }) => {
            const {
              selectedDiagnosis,
              selectedDiagnoses,
              selectedFocuses,
              treatmentPlanText
            } = values

            const focusOfTreatmentIds = selectedFocuses
              ?.filter(focus => focus?.value)
              ?.map(focus => focus?.value)

            const diagnosisIds = selectedDiagnoses
              ?.filter(diagnosis => diagnosis?.value)
              ?.map(diagnosis => diagnosis?.value)

            const data = {
              diagnosisId: diagnosisIds?.[0],
              focusOfTreatmentId: focusOfTreatmentIds?.[0],
              focusOfTreatmentIds,
              diagnosisIds,
              treatmentPlanText
            }

            setTouched({})

            if (treatmentPlan?.isDraft) {
              await accept(data)

              setAlertMessage({
                title: 'Treatment plan accepted',
                status: 'success'
              })
            } else {
              await onUpdate(data)
              setIsEditing(false)

              setAlertMessage({
                title: 'Treatment plan updated',
                status: 'success'
              })
            }
          }}
        >
          {({
            setFieldValue,
            values,
            isValid,
            resetForm,
            setTouched,
            touched
          }: FormikProps<FormValues>) => (
            <Form>
              <ModalBody
                w="100%"
                overflowY="auto"
                py="0"
                maxH={`calc(${contentHeight} - 64px)`}
              >
                {alertMessage && (
                  <Flex
                    zIndex="9"
                    h="50px"
                    position="absolute"
                    minW="315px"
                    bottom="88px"
                    right="50%"
                    left="50%"
                    transform="translateX(-50%)"
                  >
                    <Alert
                      status={alertMessage.status}
                      bg="green.500"
                      borderRadius="md"
                    >
                      <AlertIcon color="white" />
                      <AlertTitle minW="150px" color="white">
                        {alertMessage.title}
                      </AlertTitle>
                      <CloseButton
                        color="white"
                        position="absolute"
                        right="8px"
                        top="8px"
                        onClick={() => setAlertMessage(null)}
                      />
                    </Alert>
                  </Flex>
                )}
                {treatmentPlan.url ? (
                  <Stack h={contentHeight}>
                    <iframe
                      src={treatmentPlan.url}
                      style={{ width: '100%', height: 'calc(100vh - 160px)' }}
                    />
                  </Stack>
                ) : (
                  <Stack h={contentHeight}>
                    <Stack
                      mt="24px"
                      pb="24px"
                      gap="16px"
                      maxW="900px"
                      mx="auto"
                      w="100%"
                    >
                      {isEditing || !treatmentPlan?.acceptedAt ? (
                        <>
                          <Flex
                            gap="4px"
                            mt={isMobile && isChrome ? '16px' : '0px'}
                            flexDirection="column"
                          >
                            <Text>
                              <b>Name: </b>
                              {`${client.first_name} ${client.last_name}`}
                            </Text>
                            <Text>
                              <b>DOB: </b>
                              {moment
                                .utc(client.date_of_birth)
                                .format('M/D/YYYY')}
                            </Text>
                            <Text>
                              <b>Last Updated: </b>
                              {moment(treatmentPlan?.updatedAt).format(
                                'M/D/YYYY'
                              )}
                            </Text>
                          </Flex>
                          <FormControl>
                            <FormLabel fontWeight="bold">Diagnosis:</FormLabel>
                            <FieldArray name="selectedDiagnoses">
                              {({ replace, push, remove }) => {
                                const selectedDiagnoses = values
                                  .selectedDiagnoses.length
                                  ? values.selectedDiagnoses
                                  : [undefined]

                                const unSelectedSuggestedDiagnoses = diagnosisSuggestions.filter(
                                  s =>
                                    !selectedDiagnoses.find(
                                      d => d?.value === s.value
                                    )
                                )

                                const filteredDiagnosisOptions = diagnosisOptions.filter(
                                  diagnosis =>
                                    !selectedDiagnoses.find(
                                      selectedDiagnosis =>
                                        selectedDiagnosis?.value ===
                                        diagnosis.value
                                    )
                                )

                                return (
                                  <Flex flexDirection="column">
                                    {selectedDiagnoses.map(
                                      (diagnosis, index) => {
                                        const isLast =
                                          selectedDiagnoses.length - 1 === index

                                        return (
                                          <Flex key={index} gap="16px">
                                            <SearchSelect
                                              key={`${index}-${diagnosis?.value}`}
                                              value={diagnosis}
                                              errors={null}
                                              placeholder="Choose diagnosis"
                                              onChange={(value: OptionType) => {
                                                setTouched({
                                                  selectedDiagnoses: true
                                                })
                                                replace(index, value)
                                              }}
                                              options={filteredDiagnosisOptions}
                                            />
                                            {(diagnosis || index > 0) && (
                                              <>
                                                <Button
                                                  variant="link"
                                                  margin="0"
                                                  minWidth="0"
                                                  style={{
                                                    textDecoration: 'none',
                                                    marginTop: '5px'
                                                  }}
                                                  _focus={{ outline: 'none' }}
                                                  onClick={() => {
                                                    if (
                                                      selectedDiagnoses.length ===
                                                      1
                                                    ) {
                                                      replace(0, undefined)
                                                    } else {
                                                      remove(index)
                                                    }
                                                    setTouched({
                                                      selectedDiagnoses: true
                                                    })
                                                  }}
                                                >
                                                  <IconButton
                                                    display="flex"
                                                    variant="unstyled"
                                                    _hover={{
                                                      bg: '#EDEDED'
                                                    }}
                                                    icon={
                                                      <HighlightOffOutlined
                                                        style={{
                                                          color: 'black'
                                                        }}
                                                      />
                                                    }
                                                    aria-label="Close"
                                                  />
                                                </Button>
                                              </>
                                            )}
                                          </Flex>
                                        )
                                      }
                                    )}

                                    <ErrorMessage
                                      name={'selectedDiagnoses'}
                                      component="div"
                                    >
                                      {msg => <Text color="red">{msg}</Text>}
                                    </ErrorMessage>
                                    {hasDiagnosisSuggestions &&
                                      !!unSelectedSuggestedDiagnoses.length &&
                                      treatmentPlan?.isDraft && (
                                        <Flex flexDirection="column">
                                          <Text
                                            py="8px"
                                            color="dark_gray"
                                            fontSize="12px"
                                          >
                                            Suggestions:
                                          </Text>
                                          <Flex
                                            flexDirection="column"
                                            border="1px solid"
                                            borderColor="pale_gray"
                                            px="16px"
                                            borderRadius="8px"
                                          >
                                            {unSelectedSuggestedDiagnoses.map(
                                              (diagnosis, index) => {
                                                const isLast =
                                                  unSelectedSuggestedDiagnoses.length -
                                                    1 ===
                                                  index

                                                return (
                                                  <Flex flexDirection="column">
                                                    <Text py="8px">
                                                      {diagnosis.label}
                                                      <Button
                                                        variant="link"
                                                        margin="0 4px"
                                                        style={{
                                                          textDecoration: 'none'
                                                        }}
                                                        _focus={{
                                                          outline: 'none'
                                                        }}
                                                        onClick={() => {
                                                          const undefinedIndex = selectedDiagnoses.findIndex(
                                                            d => d === undefined
                                                          )
                                                          if (
                                                            undefinedIndex !==
                                                            -1
                                                          ) {
                                                            replace(
                                                              undefinedIndex,
                                                              diagnosis
                                                            )
                                                          } else {
                                                            push(diagnosis)
                                                          }
                                                        }}
                                                      >
                                                        Add
                                                      </Button>
                                                    </Text>
                                                    {!isLast && <Divider />}
                                                  </Flex>
                                                )
                                              }
                                            )}
                                          </Flex>
                                        </Flex>
                                      )}
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        push(undefined)
                                      }}
                                      variant="link"
                                      textDecoration="none"
                                      style={{
                                        justifyContent: 'flex-start',
                                        marginTop: '16px'
                                      }}
                                      _focus={{ outline: 'none' }}
                                    >
                                      + Add diagnosis
                                    </Button>
                                  </Flex>
                                )
                              }}
                            </FieldArray>
                          </FormControl>
                          <FormControl>
                            <FormLabel fontWeight="bold">
                              Focus of Treatment:
                            </FormLabel>
                            <FieldArray name="selectedFocuses">
                              {({ replace, push, remove }) => {
                                const selectedFocuses = values.selectedFocuses
                                  .length
                                  ? values.selectedFocuses
                                  : [undefined]

                                const unSelectedSuggestedFocuses = focusSuggestions.filter(
                                  s =>
                                    !selectedFocuses.find(
                                      f => f?.value === s.value
                                    )
                                )

                                const filteredFocusOptions = focusOptions.filter(
                                  focus =>
                                    !selectedFocuses.find(
                                      selectedFocus =>
                                        selectedFocus?.value === focus.value
                                    )
                                )

                                return (
                                  <Flex flexDirection="column">
                                    {selectedFocuses.map((focus, index) => {
                                      const isLast =
                                        selectedFocuses.length - 1 === index

                                      return (
                                        <Flex key={index} gap="16px">
                                          <SearchSelect
                                            key={`${index}-${focus?.value}`}
                                            value={focus}
                                            errors={null}
                                            placeholder="Choose focus of treatment"
                                            onChange={(value: OptionType) => {
                                              setTouched({
                                                selectedFocuses: true
                                              })
                                              replace(index, value)
                                            }}
                                            options={filteredFocusOptions}
                                          />
                                          {(focus || index > 0) && (
                                            <>
                                              <Button
                                                variant="link"
                                                margin="0"
                                                minWidth="0"
                                                style={{
                                                  textDecoration: 'none',
                                                  marginTop: '5px'
                                                }}
                                                _focus={{ outline: 'none' }}
                                                onClick={() => {
                                                  if (
                                                    selectedFocuses.length === 1
                                                  ) {
                                                    replace(0, undefined)
                                                  } else {
                                                    remove(index)
                                                  }
                                                  setTouched({
                                                    selectedFocuses: true
                                                  })
                                                }}
                                              >
                                                <IconButton
                                                  display="flex"
                                                  variant="unstyled"
                                                  _hover={{
                                                    bg: '#EDEDED'
                                                  }}
                                                  icon={
                                                    <HighlightOffOutlined
                                                      style={{
                                                        color: 'black'
                                                      }}
                                                    />
                                                  }
                                                  aria-label="Close"
                                                />
                                              </Button>
                                            </>
                                          )}
                                        </Flex>
                                      )
                                    })}

                                    <ErrorMessage
                                      name={'selectedFocuses'}
                                      component="div"
                                    >
                                      {msg => <Text color="red">{msg}</Text>}
                                    </ErrorMessage>
                                    {!!unSelectedSuggestedFocuses.length &&
                                      treatmentPlan?.isDraft && (
                                        <Flex flexDirection="column">
                                          <Text
                                            py="8px"
                                            color="dark_gray"
                                            fontSize="12px"
                                          >
                                            Suggestions:
                                          </Text>
                                          <Flex
                                            flexDirection="column"
                                            border="1px solid"
                                            borderColor="pale_gray"
                                            px="16px"
                                            borderRadius="8px"
                                          >
                                            {unSelectedSuggestedFocuses.map(
                                              (focus, index) => {
                                                const isLast =
                                                  unSelectedSuggestedFocuses.length -
                                                    1 ===
                                                  index

                                                return (
                                                  <Flex flexDirection="column">
                                                    <Text py="8px">
                                                      {focus.label}
                                                      <Button
                                                        variant="link"
                                                        margin="0 4px"
                                                        style={{
                                                          textDecoration: 'none'
                                                        }}
                                                        _focus={{
                                                          outline: 'none'
                                                        }}
                                                        onClick={() => {
                                                          const undefinedIndex = selectedFocuses.findIndex(
                                                            f => f === undefined
                                                          )
                                                          if (
                                                            undefinedIndex !==
                                                            -1
                                                          ) {
                                                            replace(
                                                              undefinedIndex,
                                                              focus
                                                            )
                                                          } else {
                                                            push(focus)
                                                          }
                                                          setTouched({
                                                            selectedFocuses: true
                                                          })
                                                        }}
                                                      >
                                                        Add
                                                      </Button>
                                                    </Text>
                                                    {!isLast && <Divider />}
                                                  </Flex>
                                                )
                                              }
                                            )}
                                          </Flex>
                                        </Flex>
                                      )}
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        push(undefined)
                                      }}
                                      variant="link"
                                      textDecoration="none"
                                      style={{
                                        justifyContent: 'flex-start',
                                        marginTop: '16px'
                                      }}
                                      _focus={{ outline: 'none' }}
                                    >
                                      + Add focus of treatment
                                    </Button>
                                  </Flex>
                                )
                              }}
                            </FieldArray>
                          </FormControl>
                          {(!!touched.selectedDiagnoses ||
                            !!touched.selectedFocuses) &&
                            isValid && (
                              <RegenerateAlert
                                onGenerate={() => setIsRegenerateOpen(true)}
                                isGenerating={isRegenerating}
                              />
                            )}
                          <TreatmentPlanText />
                          {isChrome && <Box h="4px" />}
                        </>
                      ) : (
                        <Flex
                          gap="4px"
                          mt={isMobile && isChrome ? '16px' : '0px'}
                          flexDirection="column"
                        >
                          <Text>
                            <b>Name: </b>
                            {`${client.first_name} ${client.last_name}`}
                          </Text>
                          <Text>
                            <b>DOB: </b>
                            {moment
                              .utc(client.date_of_birth)
                              .format('M/D/YYYY')}
                          </Text>
                          <Text>
                            <b>Last Updated: </b>
                            {moment(treatmentPlan?.updatedAt).format(
                              'M/D/YYYY'
                            )}
                          </Text>
                          <br />
                          <Text>
                            <b>Diagnosis: </b>
                          </Text>
                          <Box h="16px" />
                          {initialSelectedDiagnoses.length ? (
                            <>
                              {initialSelectedDiagnoses.map(diagnosis => (
                                <Text>{diagnosis?.label}</Text>
                              ))}
                            </>
                          ) : (
                            <Text></Text>
                          )}
                          <br />
                          <Text>
                            <b>Focus of Treatment: </b>
                          </Text>
                          <Box h="16px" />
                          {initialSelectedFocuses.length ? (
                            <>
                              {initialSelectedFocuses.map(focus => (
                                <Text>{focus?.label}</Text>
                              ))}
                            </>
                          ) : (
                            <Text></Text>
                          )}
                          <br />
                          <Text fontWeight="bold">Treatment Plan:</Text>
                          <Box h="16px" />
                          <Text whiteSpace="pre-line">
                            {treatmentPlan?.rawText}
                          </Text>
                        </Flex>
                      )}
                    </Stack>
                  </Stack>
                )}
              </ModalBody>
              <ModalFooter
                position="sticky"
                bottom="0"
                bg="white"
                borderTop="1px solid"
                borderColor="pale_gray"
                borderRadius="0px 0px 8px 8px"
              >
                  {!treatmentPlan?.isDraft ? (
                    isEditing ? (
                       <Flex
                  animation={`${slideInLeft} 0.3s`}
                  w="100%"
                  justifyContent={{
                    base: 'space-between',
                    md: 'flex-start'
                  }}
                >
                      <Flex w="100%">
                        <Button
                          variant="outline"
                          gap="8px"
                          borderRadius="full"
                          w={{ base: '100%', md: 'auto' }}
                          onClick={() => {
                            setIsEditing(false)
                            resetForm({
                              values: {
                                selectedDiagnosis: initialSelectedDiagnosis,
                                selectedDiagnoses: initialSelectedDiagnoses,
                                selectedFocuses: initialSelectedFocuses,
                                treatmentPlanText: treatmentPlan?.rawText
                              }
                            })
                          }}
                        >
                          Discard Changes
                        </Button>
                        <Button
                          gap="8px"
                          borderRadius="full"
                          type="submit"
                          isLoading={isAccepting || isUpdating}
                          w={{ base: '100%', md: 'auto' }}
                        >
                          Save & Update
                        </Button>
                      </Flex>
                      </Flex>
                    ) : (
                      <Flex
                        animation={`${slideInRight} 0.3s`}
                        w="100%"
                        justifyContent={{
                          base: 'space-between',
                          md: 'flex-end'
                        }}
                      >
                        {!treatmentPlan?.url && (
                          <Button
                            colorScheme="primary"
                            gap="8px"
                            borderRadius="full"
                            onClick={handleCopyAllToClipboard}
                            _focus={{ outline: 'none' }}
                            w={{ base: '100%', md: 'auto' }}
                          >
                            Copy All
                          </Button>
                        )}

                        {!treatmentPlan?.url && (
                          <Button
                            variant="outline"
                            onClick={() => setIsEditing(true)}
                            borderRadius="full"
                            isLoading={isAccepting || isUpdating}
                            w={{ base: '100%', md: 'auto' }}
                          >
                            <Box mr="8px">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667L2 11.5ZM3.94667 12.6667H3.33333V12.0533L9.37333 6.01333L9.98667 6.62667L3.94667 12.6667ZM13.8067 3.75333L12.2467 2.19333C12.1133 2.06 11.9467 2 11.7733 2C11.6 2 11.4333 2.06667 11.3067 2.19333L10.0867 3.41333L12.5867 5.91333L13.8067 4.69333C14.0667 4.43333 14.0667 4.01333 13.8067 3.75333Z"
                                  fill="#282828"
                                />
                              </svg>
                            </Box>
                            Edit
                          </Button>
                        )}

                        <Button
                          variant="outline"
                          gap="8px"
                          borderRadius="full"
                          isLoading={isDeleting}
                          onClick={() => {
                            setIsDeleteConfirmationOpen(true)
                          }}
                          w={{ base: '100%', md: 'auto' }}
                        >
                          <TrashIcon fill="#EB5164" />
                          Delete
                        </Button>
                      </Flex>
                    )
                  ) : (
                    <Flex
                      w="100%"
                      justifyContent={{
                        base: 'space-between',
                        md: 'flex-start'
                      }}
                    >
                      <Button
                        variant="outline"
                        borderRadius="full"
                        gap="8px"
                        isLoading={isDeleting}
                        onClick={() => {
                          setIsDeleteConfirmationOpen(true)
                        }}
                        w={{ base: '100%', md: 'auto' }}
                      >
                        <TrashIcon fill="#EB5164" />
                        Delete
                      </Button>
                      <Button
                        isDisabled={isDeleting}
                        isLoading={isAccepting || isUpdating}
                        borderRadius="full"
                        type="submit"
                        w={{ base: '100%', md: 'auto' }}
                      >
                        Save & Accept
                      </Button>
                    </Flex>
                  )}
              </ModalFooter>
              <RegenerationConfirmation
                values={values}
                isOpen={isRegenerateOpen}
                onClose={() => setIsRegenerateOpen(false)}
                onConfirm={async () => {
                  try {
                    await onRegenerate({
                      diagnosisIds: values.selectedDiagnoses
                        .filter(d => d)
                        .map(d => d?.value),
                      focusOfTreatmentIds: values.selectedFocuses
                        .filter(f => f)
                        .map(f => f?.value)
                    })
                    setIsRegenerateOpen(false)
                    onClose()
                  } catch (error) {
                    datadogLogs.logger.error(
                      'Error regenerating treatment plan',
                      {},
                      error as Error
                    )
                  }
                }}
                isLoading={isRegenerating}
                error={regenerationError}
              />
            </Form>
          )}
        </Formik>
      </ModalContent>
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={onDelete}
        isLoading={isDeleting}
      />
    </Modal>
  )
}

export default TreatmentPlanModal
