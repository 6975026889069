import React from 'react'

interface ReminderClockProps extends React.SVGProps<SVGSVGElement> {
  fill?: string
  size?: string
}

export const ReminderClockIcon: React.FC<ReminderClockProps> = ({
  fill = '#2D54E8',
  size = 'md',
  style,
  ...props
}) => {
  let width,
    height = '24px'
  if (size === 'sm') {
    width = '16px'
    height = '16px'
  } else if (size === 'md') {
    width = '24px'
    height = '24px'
  } else if (size === 'lg') {
    width = '32px'
    height = '32px'
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      <path
        d="M14.47 15.08L11 13V7H12.5V12.25L15.58 14.08C15.17 14.36 14.79 14.7 14.47 15.08ZM13.08 19.92C12.72 19.97 12.37 20 12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 12.37 19.97 12.72 19.92 13.08C20.61 13.18 21.25 13.4 21.84 13.72C21.94 13.16 22 12.59 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.47 22 12 22C12.59 22 13.16 21.94 13.72 21.84C13.4 21.25 13.18 20.61 13.08 19.92ZM18 15V18H15V20H18V23H20V20H23V18H20V15H18Z"
        fill={fill}
      />
    </svg>
  )
}
