import React, { useState, useEffect, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface AudioWaveformProps {
  isPlaying?: boolean;
  isFastForward?: boolean;
}
export const AudioWaveform: React.FC<AudioWaveformProps> = ({ isPlaying = true, isFastForward = false }) => {
  const MIN_HEIGHT = 0.13;
  const NORMAL_SPEED = 100;
  const FAST_SPEED = 20;

  const [bars] = useState(Array(9).fill(0));
  const [levels, setLevels] = useState(() => {
    // Initial static pattern with max height of 10px, tapering to sides
    const maxHeight = 0.1; // 10px
    const staticPattern = [
      maxHeight * 0.4, // 4px
      maxHeight * 0.6, // 6px
      maxHeight * 0.7, // 7px
      maxHeight * 0.8, // 8px
      maxHeight,       // 10px
      maxHeight * 0.8, // 8px
      maxHeight * 0.7, // 7px
      maxHeight * 0.6, // 6px
      maxHeight * 0.4  // 4px
    ];
    return staticPattern;
  });

  const applyCenterEmphasis = (baseLevel: number, index: number): number => {
    const centerWeights = [0.8, 0.9, 0.95, 1.265, 1.38, 1.265, 0.95, 0.9, 0.8];
    return Math.max(MIN_HEIGHT, Math.min(baseLevel * centerWeights[index], 1));
  };

  const generateConversationSequence = useCallback(() => {
    const sequence = [];
    const sequenceLength = 75;

    let currentState = 'quiet';
    let stateCounter = 0;
    let silenceTimer = 15;

    for (let i = 0; i < sequenceLength; i++) {
      const frame = Array(9).fill(0);

      silenceTimer--;
      if (silenceTimer <= 0 && currentState !== 'silence' && Math.random() < 0.3) {
        currentState = 'silence';
        stateCounter = 3 + Math.floor(Math.random() * 3);
        silenceTimer = 20 + Math.floor(Math.random() * 10);
      }

      if (stateCounter <= 0) {
        const rand = Math.random();
        if (currentState === 'silence') {
          currentState = rand < 0.7 ? 'quiet' : 'normal';
        } else if (currentState === 'quiet') {
          currentState = rand < 0.7 ? 'normal' : 'quiet';
        } else if (currentState === 'normal') {
          if (rand < 0.15) currentState = 'energetic';
          else if (rand < 0.4) currentState = 'quiet';
        } else {
          currentState = rand < 0.7 ? 'normal' : 'energetic';
        }

        switch (currentState) {
          case 'silence':
            stateCounter = 3 + Math.floor(Math.random() * 3);
            break;
          case 'quiet':
            stateCounter = 3 + Math.floor(Math.random() * 4);
            break;
          case 'normal':
            stateCounter = 8 + Math.floor(Math.random() * 6);
            break;
          case 'energetic':
            stateCounter = 2 + Math.floor(Math.random() * 3);
            break;
        }
      }

      // Capture the current state in a constant to avoid the loop-func issue
      const frameCurrentState = currentState;

      frame.forEach((_, index) => {
        let baseLevel;

        switch (frameCurrentState) {
          case 'silence':
            baseLevel = MIN_HEIGHT + (Math.random() * 0.02);
            break;
          case 'quiet':
            baseLevel = Math.max(MIN_HEIGHT + 0.02, 0.05 + Math.random() * 0.1);
            break;
          case 'normal':
            const normalBase = 0.3 + Math.random() * 0.2;
            const wordEmphasis = Math.random() < 0.2 ? 0.15 : 0;
            baseLevel = normalBase + wordEmphasis;
            break;
          case 'energetic':
            const energeticBase = 0.6 + Math.random() * 0.3;
            const burstEmphasis = Math.random() < 0.3 ? 0.2 : 0;
            baseLevel = Math.min(energeticBase + burstEmphasis, 1);
            break;
          default:
            baseLevel = Math.max(MIN_HEIGHT, 0.1);
        }

        const microVariation = frameCurrentState === 'silence' ? 0 : Math.random() * 0.05;
        frame[index] = applyCenterEmphasis(baseLevel + microVariation, index);
      });

      sequence.push(frame);
      stateCounter--;
    }
    return sequence;
  }, [MIN_HEIGHT]);

  useEffect(() => {
    // Don't animate if not playing - keep static pattern
    if (!isPlaying) {
      // Static pattern with max height of 10px (0.1 = 10% of 100px), tapering to sides
      const staticPattern = [
        MIN_HEIGHT, 
        MIN_HEIGHT, 
        MIN_HEIGHT + 0.01, 
        MIN_HEIGHT + 0.02, 
        MIN_HEIGHT + 0.03, 
        MIN_HEIGHT + 0.02, 
        MIN_HEIGHT + 0.01, 
        MIN_HEIGHT, 
        MIN_HEIGHT
      ];
      setLevels(staticPattern);
      return;
    }

    const loopSequence = generateConversationSequence();
    let currentFrame = 0;

    const interval = setInterval(() => {
      setLevels(loopSequence[currentFrame]);
      currentFrame = (currentFrame + 1) % loopSequence.length;
    }, isFastForward ? FAST_SPEED : NORMAL_SPEED);

    return () => clearInterval(interval);
  }, [generateConversationSequence, isFastForward, isPlaying]);

  return (
    <Flex
      align="center"
      justify="center"
      h="52px"
      w="78px"
    >
      <Flex align="center" gap="2px">
        {bars.map((_, index) => (
          <Box key={index} position="relative" w="4px" h="52px" display="flex" alignItems="center">
            <Box position="absolute" w="100%" h="100%">
              {/* Background bar */}
              <Box
                position="absolute"
                w="100%"
                borderRadius="full"
                bg="#ABBBF6"
                opacity="0.7"
                left="0"
                bottom="50%"
                transform="translateY(50%)"
                transformOrigin="center"
                transition={isPlaying ? `height ${(isFastForward ? 0.02 : 0.1) + Math.random() * (isFastForward ? 0.04 : 0.2)}s ease-out` : "none"}
                transitionDelay={isPlaying ? `${(isFastForward ? 0.01 : 0.05) + Math.random() * (isFastForward ? 0.02 : 0.1)}s` : "0s"}
                height={`${Math.max(MIN_HEIGHT * 100, levels[index] * 100)}%`}
              />
              {/* Foreground bar */}
              <Box
                position="absolute"
                w="100%"
                borderRadius="full"
                bg="#2D54E8"
                left="0"
                bottom="50%"
                transform="translateY(50%)"
                transformOrigin="center"
                transition={isPlaying ? `height ${(isFastForward ? 0.016 : 0.08) + Math.random() * (isFastForward ? 0.024 : 0.12)}s ease-out` : "none"}
                height={`${Math.max(MIN_HEIGHT * 100, levels[index] * 100)}%`}
              />
            </Box>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};