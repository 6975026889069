import React from 'react'
import { Icon } from '@chakra-ui/react'

export const UnmuteIcon = (props: any) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_6505_244)">
      <path
        d="M8 2.66667L6.60667 4.06L8 5.45333M2.84667 2L2 2.84667L5.15333 6H2V10H4.66667L8 13.3333V8.84667L10.8333 11.6867C10.3867 12.0267 9.88667 12.3067 9.33333 12.4667V13.8467C10.2533 13.6333 11.0867 13.2133 11.7867 12.64L13.1533 14L14 13.1533L8 7.15333M12.6667 8C12.6667 8.62667 12.5333 9.21333 12.3067 9.76L13.3133 10.7667C13.7467 9.94 14 9 14 8C14 5.14667 12 2.76 9.33333 2.15333V3.52667C11.26 4.1 12.6667 5.88667 12.6667 8ZM11 8C11 6.82 10.3333 5.80667 9.33333 5.31333V6.78667L10.9667 8.42C11 8.28667 11 8.14 11 8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6505_244">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)
