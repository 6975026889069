import React, { useState, useEffect } from 'react'
import { endpoints } from '@api'
import { useQuery } from 'react-query'
import { chunk } from 'lodash'
import {
  VStack,
  Container,
  GridItem,
  Text,
  Image,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  HStack,
  useBreakpointValue
} from '@chakra-ui/react'
import { Button, LayoutGrid } from '@blueprinthq/joy'
import { copyToClipboard } from '../../utilities'
import { CopyNoteIcon } from '@icons'
import {
  REFERRAL_AMOUNT,
  SOCIAL_LINKS_COPY,
  FB_APP_ID,
  OG_TAG_TITLE,
  REFERRAL_IMAGE_BLURRED_EDGES_URL
} from '../../constants/referral'
import { ShareButtonDrawer } from './components'
import { SocialIcon } from 'react-social-icons'
import { trackEvent } from '@lib/clinician-tracking'

export const ReferralV2 = () => {
  const [isButtonDrawerOpen, setIsButtonDrawerOpen] = useState(false)
  const handleOpenButtonDrawer = () => setIsButtonDrawerOpen(true)
  const handleCloseButtonDrawer = () => setIsButtonDrawerOpen(false)
  const { data: referralData }: { data: any } = useQuery(
    endpoints.getReferralURL.getCacheId(),
    endpoints.getReferralURL.request
  )

  const getReferralURL = (referralData: any) => {
    if (referralData) {
      const splitURL = referralData.referralURL?.split('?referral=')

      if (splitURL.length === 2) {
        return `${splitURL[0]}/ref?referral=${splitURL[1]}`
      }
    }

    return ''
  }
  const referralURL: string = getReferralURL(referralData)

  const toast = useToast()
  const shouldCloseDrawer = useBreakpointValue({ base: false, sm: true })

  useEffect(() => {
    if (shouldCloseDrawer) {
      handleCloseButtonDrawer()
    }
  }, [shouldCloseDrawer])

  const handleCopyLink = (url: string) => {
    try {
      copyToClipboard(url)
      toast({
        title: 'Referral link copied to clipboard!',
        status: 'success',
        isClosable: true,
        duration: 2000
      })
    } catch (e) {
      toast({
        title:
          "We're sorry, something went wrong, please refresh and try again",
        status: 'error',
        isClosable: true,
        duration: 2000
      })
    }
  }

  const encodedReferralURL = encodeURIComponent(referralURL)

  const socialLinks = {
    email: `mailto:?subject=${encodeURIComponent(
      OG_TAG_TITLE
    )}&body=${encodeURIComponent(
      SOCIAL_LINKS_COPY + '\n\n'
    )}${encodeURIComponent(referralURL)}`,
    facebook: `https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${encodedReferralURL}&quote=${encodeURIComponent(
      SOCIAL_LINKS_COPY
    )}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      SOCIAL_LINKS_COPY
    )}&url=${encodedReferralURL}&via=blueprint_hq`,
    instagram: `https://www.instagram.com/direct/`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      referralURL
    )}&text=${encodeURIComponent(SOCIAL_LINKS_COPY)}`,
    messenger: `http://www.facebook.com/dialog/send?app_id=${FB_APP_ID}&link=${encodedReferralURL}&redirect_uri=https%3A%2F%2Fblueprint.ai&display=page`,
    whatsapp: `https://wa.me/?text=${encodeURIComponent(
      SOCIAL_LINKS_COPY + '\n'
    )}${encodedReferralURL}`,
    messages: `sms:?&body=${encodeURIComponent(
      SOCIAL_LINKS_COPY + '\n'
    )}${encodedReferralURL}`
  }

  const buttons = [
    {
      // @ts-ignore
      icon: (
        <SocialIcon
          bgColor="#2D54E8"
          style={{ width: '24px', height: '24px' }}
          network="mailto"
        />
      ),
      url: socialLinks.email,
      mobileTitle: 'Send in Email',
      mobileURL: '',
      mobileDisplayOrder: 0,
      isMobileOnly: false,
      title: 'Email',
      key: 'email'
    },
    {
      // @ts-ignore
      icon: (
        <SocialIcon
          style={{ width: '24px', height: '24px' }}
          network="wechat"
        />
      ),
      url: socialLinks.messages,
      mobileTitle: 'Send in Messages',
      mobileURL: '',
      mobileDisplayOrder: 1,
      isMobileOnly: true,
      title: '',
      key: 'messages'
    },
    {
      // @ts-ignore
      icon: (
        <SocialIcon
          style={{ width: '24px', height: '24px' }}
          network="whatsapp"
        />
      ),
      url: socialLinks.whatsapp,
      mobileTitle: 'Send in WhatsApp',
      mobileURL: '',
      mobileDisplayOrder: 4,
      isMobileOnly: true,
      title: '',
      key: 'whatsapp'
    },
    {
      // @ts-ignore
      icon: (
        <SocialIcon
          style={{ width: '24px', height: '24px' }}
          network="facebook"
        />
      ),
      url: socialLinks.facebook,
      mobileTitle: 'Share on Facebook',
      mobileURL: `fb://share?link=${encodeURIComponent(referralURL)}`,
      mobileDisplayOrder: 5,
      isMobileOnly: false,
      title: 'Facebook',
      key: 'facebook',
      onClick: handleCopyLink
    },
    {
      // @ts-ignore
      icon: (
        <SocialIcon
          style={{ width: '24px', height: '24px' }}
          network="linkedin"
        />
      ),
      url: socialLinks.linkedin,
      mobileTitle: 'Share on LinkedIn',
      mobileURL: `linkedin://share?url=${encodeURIComponent(
        referralURL
      )}&text=${encodeURIComponent(SOCIAL_LINKS_COPY)}`,
      mobileDisplayOrder: 7,
      isMobileOnly: false,
      title: 'LinkedIn',
      key: 'linkedin'
    },
    {
      // @ts-ignore
      icon: (
        <SocialIcon style={{ width: '24px', height: '24px' }} network="x" />
      ),
      url: socialLinks.twitter,
      mobileTitle: 'Share on X (Twitter)',
      mobileURL: '',
      mobileDisplayOrder: 8,
      isMobileOnly: false,
      title: '(Twitter)',
      key: 'twitter'
    },
    {
      // @ts-ignore
      icon: (
        <SocialIcon
          style={{ width: '24px', height: '24px' }}
          network="instagram"
        />
      ),
      url: socialLinks.instagram,
      mobileTitle: 'Share on Instagram',
      mobileURL: `instagram://direct/inbox`,
      mobileDisplayOrder: 6,
      isMobileOnly: false,
      title: 'Instagram',
      key: 'instagram',
      onClick: handleCopyLink
    },
    {
      // @ts-ignore
      icon: (
        <SocialIcon
          style={{ width: '24px', height: '24px' }}
          network="facebook"
        />
      ),
      url: socialLinks.messenger,
      mobileTitle: 'Send in Messenger',
      mobileURL: `fb-messenger://share?link=${encodedReferralURL}`,
      mobileDisplayOrder: 3,
      isMobileOnly: false,
      title: 'Messenger',
      key: 'fb_messanger'
    }
  ]

  return (
    <Container
      marginTop={{
        base: '24px',
        sm: '32px'
      }}
      paddingLeft={{
        base: '16px',
        sm: '0px'
      }}
      paddingRight={{
        base: '16px',
        sm: '0px'
      }}
      pb="medium"
    >
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 6,
            sm: 6,
            md: 6,
            lg: 6
          }}
          colStart={{
            sm: 2,
            md: 4,
            lg: 4
          }}
        >
          <VStack spacing="16px">
            <Image
              loading="eager"
              src={REFERRAL_IMAGE_BLURRED_EDGES_URL}
              width={{ base: '400px', sm: 'auto' }}
              height={{ base: '200px', sm: '250px' }}
            />
            <Heading sx={{ textAlign: 'center' }} size="xl">
              Share the love and earn {REFERRAL_AMOUNT} per referral!
            </Heading>
            <Text sx={{ textAlign: 'center' }}>
              Once your fellow clinician becomes a paid customer, you'll both
              get {REFERRAL_AMOUNT} account credit. Referrals are unlimited, so
              there's no cap on free credits for Blueprint!
            </Text>
            <Text fontWeight="bold" pb="xsmall">
              Copy and share your unique referral link:
            </Text>
            <InputGroup
              display={{ base: 'none', sm: 'unset' }}
              onClick={() => {
                handleCopyLink(referralURL)
                trackEvent('Referral V2 -> Clicked Copy Link Text', {
                  shareMethod: 'copy_link',
                  platform: 'desktop'
                })
              }}
              _hover={{
                cursor: 'pointer'
              }}
            >
              <Input
                _hover={{
                  cursor: 'pointer'
                }}
                _focus={{
                  outline: 'none'
                }}
                value={referralURL}
                placeholder={referralURL}
                type="text"
                readOnly
              />
              <InputRightElement>
                <CopyNoteIcon fill="black" />
              </InputRightElement>
            </InputGroup>
            <VStack
              pt="xsmall"
              display={{ base: 'none', sm: 'unset' }}
              w="100%"
              gap={1}
            >
              {chunk(
                buttons.filter(b => !b.isMobileOnly),
                2
              ).map((pairOfButtons, idx) => (
                <HStack key={idx} spacing={1} w="100%">
                  {pairOfButtons.map(({ url, icon, title, key }) => (
                    <ShareLinkButton
                      key={key}
                      url={url}
                      icon={icon}
                      title={title}
                      buttonType={key}
                    />
                  ))}
                </HStack>
              ))}
            </VStack>
            <HStack
              display={{ base: 'flex', sm: 'none' }}
              w="100%"
              alignItems="flex-start"
              justifyContent="center"
              gap={1}
            >
              <Button
                mt="0px"
                size="lg"
                w="45%"
                variant="outline"
                onClick={() => {
                  handleCopyLink(referralURL)
                  trackEvent('Referral V2 -> Clicked Copy Link Button', {
                    shareMethod: 'copy_link',
                    platform: 'mobile'
                  })
                }}
              >
                Copy link
              </Button>
              <Button size="lg" w="45%" onClick={handleOpenButtonDrawer}>
                Share
              </Button>
            </HStack>
            <ShareButtonDrawer
              referralURL={referralURL}
              buttons={buttons}
              isOpen={isButtonDrawerOpen}
              onClose={handleCloseButtonDrawer}
            />
          </VStack>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}

const ShareLinkButton = ({
  url,
  icon,
  title,
  buttonType
}: {
  url: string
  icon: any
  title: string
  buttonType: string
}) => (
  <Button
    margin="0px"
    variant="outline"
    color="primary"
    borderRadius="2px"
    isFullWidth
    borderColor="pale_gray"
    as="a"
    href={url}
    leftIcon={icon}
    colorScheme="gray"
    target="_blank"
    _hover={{
      bg: 'pale_gray'
    }}
    _focus={{
      outline: 'none'
    }}
    onClick={() =>
      trackEvent('Referral V2 -> Clicked Share Button', {
        shareMethod: buttonType,
        platform: 'desktop'
      })
    }
  >
    {title}
  </Button>
)
