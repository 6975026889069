import React, { createContext, useState, useEffect, FC, useMemo } from 'react'
import { useStoreActions } from 'easy-peasy'
import { datadogLogs } from '@datadog/browser-logs'
interface WidgetContextType {
  settings: {
    isDraggable?: boolean
    isAlwaysOpen?: boolean
    copyNoteButtonText?: string
    patientReference?: string
    hideTreatmentPlan?: string
    hidePreviousSessions?: string
  }
  selectedClientId: string | undefined
}

export const WidgetContext = createContext<WidgetContextType>(
  {} as WidgetContextType
)

interface WidgetProviderProps {
  children: React.ReactNode
}

export const WidgetProvider: FC<WidgetProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState({})
  const [selectedClientId, setSelectedClientId] = useState()
  const widgetLogin = useStoreActions(
    (actions: any) => actions.auth.widgetLogin
  )

  const contextValue = useMemo(() => ({ settings, selectedClientId }), [
    settings,
    selectedClientId
  ])

  const forceAuthenticate = async (credentials: {
    authTokens: object
    username: string
  }) => {
    await widgetLogin(credentials)
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type === 'BP_SETTINGS') {
        setSettings(event.data.settings)
      }

      if (event.data?.type === 'BP_AUTHENTICATE') {
        datadogLogs.logger.info(
          '[Auth Debug - Widget Context] Force Authenticate',
          {
            ...event.data
          }
        )

        forceAuthenticate({
          authTokens: event.data.authTokens,
          username: event.data.username
        })
      }

      if (event.data?.type === 'BP_SELECT_CLIENT') {
        const clientId = event.data.clientId
        setSelectedClientId(clientId)
      }
    }

    window.addEventListener('message', handleMessage)

    // eslint-disable-next-line
    parent.postMessage({ type: 'BP_EXTENSION_CONTEXT_LOADED' }, '*')
    // eslint-disable-next-line
    parent.postMessage({ type: 'BP_WIDGET_CONTEXT_LOADED' }, '*')

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <WidgetContext.Provider value={contextValue}>
      {children}
    </WidgetContext.Provider>
  )
}
