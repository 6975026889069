import React, { useEffect } from 'react'
import { Box, Text, VStack, Flex, Button } from '@chakra-ui/react'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'
import { SocialProofFooter } from './social-proof-footer'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const AffiliateProgram = ({
  onSoloClick,
  onGroupClick,
  onboardingVersion
}: {
  onSoloClick: () => void
  onGroupClick: () => void
  onboardingVersion: number | null
}) => {
  const location = useLocation()
  const params = queryString.parse(location.search)
  const affiliateName = params.affiliate

  useEffect(() => {
    if (onboardingVersion) {
      trackEvent('Onboarding Affiliate Program Viewed', {
        version: onboardingVersion,
        affiliate: affiliateName
      })
    }
  }, [onboardingVersion, affiliateName])

  const blueTitle = affiliateName
    ? `${affiliateName} Customers!`
    : 'To Blueprint!'

  return (
    <Step mt={{ base: '80px', md: 0 }} pb={4}>
      <VStack
        spacing={4}
        flex="1"
        position={{ base: 'static', md: 'relative' }}
      >
        <Flex
          flex={{ base: '2', md: '1' }}
          flexDir="column"
          w="100%"
          justifyContent={{ base: 'flex-start', md: 'center' }}
        >
          <Box textAlign="center">
            <Text fontSize="32px" fontWeight="bold" mb={{ base: 12, md: 0 }}>
              Welcome <br />
              <Text as="span" color="primary" fontSize="32px" fontWeight="bold">
                {blueTitle}
              </Text>
            </Text>
            <Text
              fontSize="md"
              textAlign="center"
              mt={4}
              mb={6}
              maxW="480px"
              mx="auto"
            >
              Ready for AI-generated documentation and real-time clinical
              support every session? Choose below to get started.
            </Text>
            <Box mt={{ base: 0, md: 4 }} maxW="480px" mx="auto">
              <Button
                size="lg"
                isFullWidth
                mb={3}
                onClick={onSoloClick}
                background="var(--BP-Blue-040-Alpha, #2D54E80A)"
                border="1px solid var(--Neutral-Pale-Gray-Alpha, #373C4123)"
                color="black"
                _hover={{ background: '#E8EDF9' }}
              >
                I'm a solo practitioner
              </Button>
              <Button
                size="lg"
                isFullWidth
                mb={6}
                onClick={onGroupClick}
                background="var(--BP-Blue-040-Alpha, #2D54E80A)"
                border="1px solid var(--Neutral-Pale-Gray-Alpha, #373C4123)"
                color="black"
                _hover={{ background: '#E8EDF9' }}
              >
                I'm part of a group (2+ clinicians)
              </Button>
            </Box>
          </Box>
        </Flex>
        <SocialProofFooter />
      </VStack>
    </Step>
  )
}
