import React, { useEffect } from 'react'
import { Button, VStack, Stack, Flex, Text, Image } from '@chakra-ui/react'
import { useExperienceManager, useQueryParams } from '@hooks'
import AssistUpgradePanel from '@handlers/sessions/components/shared/assist/upgrade-panel'
import {
  AssessmentSuggestionModule,
  WorksheetSuggestionModule,
  InterventionSuggestionModule
} from './suggestion-modules'
import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import Waitlist from '../in-session-assist-waitlist-panel'
import Setup from './setup'
import TreatmentPlanGate from './treatment-plan-gate'
import { useTreatmentPlan } from '@hooks'
import { SettingsOutlined } from '@components/icons'
import { DisclaimerBanner } from '../shared/disclaimer-banner'
import { ClientAccountResponse } from '@api'
import SubtleLoading from '../shared/assist/svgs/subtle-loader'

const SuggestionLoader = () => {
  return (
    <Stack spacing="0" gap="24px" flex="1">
      <VStack gap="16px" spacing="0" flex="1">
        <Flex justifyContent="center" width="170px" height="170px">
          <Image src="https://res.cloudinary.com/hellojoy/image/upload/v1739218692/coffee-cup-animation_1_l40krm.gif" />
        </Flex>
        <VStack spacing="0" gap="4px">
          <Text fontWeight="bold">Generating suggestions...</Text>
          <Text color="dark_gray">This could take a minute</Text>
        </VStack>
      </VStack>
    </Stack>
  )
}
interface AssistPanelProps {
  sessionId: string
  client: ClientAccountResponse
  toggleOverlay: () => void
  account: any
  session: any
}

const AssistPanel = ({
  sessionId,
  client,
  toggleOverlay,
  account,
  session
}: AssistPanelProps) => {
  const [showDemoLoading, setShowDemoLoading] = React.useState<boolean>(true)
  const queryParams = useQueryParams()
  const demoContext = queryParams.get('context')
  const isDemoQueryParam = queryParams.get('isDemo') === 'true'

  const {
    initialSelectedFocuses,
    treatmentPlan,
    canGenerateQuery,
    generateMutation
  } = useTreatmentPlan()

  const {
    data: suggestions,
    isLoading
  } = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      }
    }
  })

  const {
    isPlusPlanEnabled,
    isEvidenceBasedCareEnabled
  } = useExperienceManager()
  const showDemoExperience = client.is_demo

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId,
    { demoContext: demoContext || 'post-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  useEffect(() => {
    if (isDemoQueryParam) {
      setShowDemoLoading(true)
    }
  }, [isDemoQueryParam])

  useEffect(() => {
    if (showDemoExperience && !isDemoQueryParam) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 2000)
    } else if (!showDemoExperience && !isDemoQueryParam) {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const assessmentSuggestion = showDemoExperience
    ? demoData?.suggestedAssessments[0]
    : suggestions?.suggestedAssessments[0]
  const worksheetSuggestion = showDemoExperience
    ? demoData?.suggestedWorksheets[0]
    : suggestions?.suggestedWorksheets[0]
  const interventionSuggestion = showDemoExperience
    ? demoData?.suggestedInterventions[0]
    : suggestions?.suggestedInterventions[0]

  if (!isPlusPlanEnabled) {
    if (isEvidenceBasedCareEnabled) {
      return <Waitlist />
    }

    return <AssistUpgradePanel sessionId={session?.id} />
  }

  if (isLoading) {
    return (
      <Flex h="100%">
        <SubtleLoading />
      </Flex>
    )
  }

  if (suggestions?.isGenerationComplete === false || showDemoLoading) {
    return <SuggestionLoader />
  }

  if (!showDemoExperience && !!treatmentPlan?.fileKey) {
    return (
      <TreatmentPlanGate
        treatmentPlan={treatmentPlan}
        canGenerateQuery={canGenerateQuery}
        generateMutation={generateMutation}
      />
    )
  }

  const hasFocusOfTreatment = initialSelectedFocuses?.filter(f => f).length > 0
  const hasTreatmentApproach =
    session?.treatmentApproaches && session.treatmentApproaches.length > 0

  if (!showDemoExperience && !client?.isAssistSetupComplete) {
    return (
      <Setup
        toggleOverlay={toggleOverlay}
        client={client}
        hasFocusOfTreatment={hasFocusOfTreatment}
        hasAgeRange={!!client?.age_range}
        hasProviderType={!!account?.license_level}
        hasTreatmentApproach={hasTreatmentApproach}
        hasTreatmentSetting={!!account?.treatment_setting}
        hasHighRisk={!!client?.high_risk_timestamp}
      />
    )
  }

  const showDisclaimer =
    (assessmentSuggestion || worksheetSuggestion || interventionSuggestion) &&
    isPlusPlanEnabled

  return (
    <Stack spacing="0" gap="32px" flex="1">
      {showDisclaimer && <DisclaimerBanner />}
      <AssessmentSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <WorksheetSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <InterventionSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <VStack spacing="0">
        <Button
          variant="outline"
          h="35px"
          borderColor="pale_gray"
          borderRadius="4px"
          textDecoration="none"
          pl="8px"
          pr="8px"
          onClick={toggleOverlay}
        >
          <Flex align="center" justify="space-between" gap="8px">
            <SettingsOutlined />
            <Text>Assist setup for {client.first_name}</Text>
          </Flex>
        </Button>
      </VStack>
    </Stack>
  )
}

export default AssistPanel
