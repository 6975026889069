import React from 'react'
import { Box, Progress } from '@chakra-ui/react'

export const ProgressBar = ({ progress }: { progress: number | null }) => {
  if (progress === null) return null;

  return (
    <Box w="100%" px={4} position="relative">
      <Progress
        position={{ base: 'relative', md: 'absolute' }}
        bottom={0}
        left="50%"
        right="50%"
        transform="translate(-50%, 0)"
        value={progress}
        m={1}
        borderRadius="2px"
        size="xs"
        maxW="696px"
        w="100%"
        bg={progress === 0 ? 'transparent' : 'gray.100'}
        sx={{
          '& div[role=progressbar]': {
            transition: 'width 0.5s ease-in-out'
          }
        }}
      />
    </Box>
  );
}
