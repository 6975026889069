import React, { useMemo } from 'react'
import { VStack, Button, FormControl, FormLabel } from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { useExperienceManager } from '@hooks'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { AccountIcon, PeopleIcon, Refresh } from '@blueprinthq/joy'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { IconSelect } from './'
import { Group } from '@components/icons'
import _ from 'lodash'
import { NoteTypeDropdown } from './note-type-dropdown'
import { TreatmentApproachDropdown } from './treatment-approach-dropdown'

interface PropsT {
  isOpen: boolean
  onClose: () => void
  initialValue: string
  onSubmit: (
    noteType: string,
    treatmentApproaches: string[] | null
  ) => Promise<void>
  treatmentApproaches: string[] | null
}

type ProgressNoteTypeT = {
  id: string
  sessionType: string
  display: string
  noteGroup: string
}

const sessionTypes = [
  {
    value: 'individual',
    title: 'Individual',
    icon: <AccountIcon />
  },
  {
    value: 'couple',
    title: 'Couple',
    icon: <PeopleIcon />
  },
  {
    value: 'group',
    title: 'Group',
    icon: <Group />
  }
]

export const ChangeNoteTypeModalV2 = ({
  isOpen = true,
  onClose = () => {},
  initialValue,
  onSubmit,
  treatmentApproaches
}: PropsT) => {
  const { data: user } = useQuery(endpoints.getUserAccount.getCacheId(), () =>
    endpoints.getUserAccount.request()
  )

  const { isExtension, isWidget } = useExperienceManager()

  const {
    data: { progressNoteTypes },
    isLoading
  }: any = useQuery(
    [
      endpoints.getProgressNoteTypes.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user?.clinic?.organization_id
      }),
    {
      initialData: {
        progressNoteTypes: []
      },
      enabled: !!user
    }
  )

  const { data: settings, isLoading: isSettingsLoading } = useQuery(
    [
      endpoints.getProgressNoteSettings.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteSettings.request({
        organizationId: user?.clinic?.organization_id
      }),
    {
      enabled: !!user
    }
  )

  const treatmentApproachOptions =
    // @ts-ignore
    settings?.preferenceOptions?.treatmentApproach?.options?.map(
      (option: any) => {
        return {
          id: option.value,
          display: option.label
        }
      }
    ) || []

  if (isLoading) return null

  const groupedNoteTypes = _.groupBy(progressNoteTypes, 'sessionType')

  const sessionTypeOptions = sessionTypes.filter(t =>
    Object.keys(groupedNoteTypes).includes(t.value)
  )

  const defaultNoteType = progressNoteTypes.find(
    (t: ProgressNoteTypeT) => t.id === initialValue
  )

  if (!defaultNoteType) return null

  return (
    <DialogContainer isOpen={isOpen} onClose={onClose}>
      <DialogHeader text="Change Note Type" onClose={onClose} />
      <DialogBody>
        <Formik
          initialValues={{
            noteType: defaultNoteType,
            sessionType: defaultNoteType.sessionType,
            treatmentApproaches
          }}
          validationSchema={Yup.object({
            noteType: Yup.object().required('Progress note type is required')
          })}
          onSubmit={async values => {
            await onSubmit(values.noteType.id, values.treatmentApproaches)
          }}
        >
          {({ setFieldValue, values, isSubmitting, dirty, touched }) => {
            return (
              <Form>
                <VStack spacing={5}>
                  <Field name="sessionType">
                    {({ field }: any) => (
                      <FormControl>
                        <FormLabel fontWeight="bold">Session Type:</FormLabel>
                        <IconSelect
                          {...field}
                          onChange={(value: string) => {
                            setFieldValue(field.name, value)
                            setFieldValue(
                              'noteType',
                              groupedNoteTypes[value][0]
                            )
                          }}
                          selectedValue={field.value}
                          options={sessionTypeOptions}
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="noteType">
                    {({ field }: any) => (
                      <FormControl>
                        <FormLabel fontWeight="bold">Note Type:</FormLabel>
                        <NoteTypeDropdown
                          optionValue={field.value.id}
                          onChange={value => {
                            setFieldValue(
                              'noteType',
                              progressNoteTypes.find(
                                (note: ProgressNoteTypeT) => note.id === value
                              )
                            )
                          }}
                          progressNoteTypes={
                            groupedNoteTypes[values.sessionType]
                          }
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="treatmentApproaches">
                    {({ field }: any) => (
                      <FormControl>
                        <FormLabel fontWeight="bold" pointerEvents="none">
                          Treatment Approach Used:
                        </FormLabel>
                        <TreatmentApproachDropdown
                          selectedValues={field.value}
                          onChange={value => {
                            setFieldValue('treatmentApproaches', value)
                          }}
                          options={treatmentApproachOptions.filter(
                            (o: any) => o.id !== 'none'
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    type="submit"
                    isFullWidth
                    colorScheme="primary"
                    variant="solid"
                    size="lg"
                    w="100%"
                    m={0}
                    leftIcon={<Refresh fill="White" h="16px" w="16px" />}
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !dirty}
                  >
                    {isExtension || isWidget
                      ? 'Regenerate'
                      : 'Generate new note'}
                  </Button>
                </VStack>
              </Form>
            )
          }}
        </Formik>
      </DialogBody>
    </DialogContainer>
  )
}
