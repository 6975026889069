import React, { useCallback, useState } from 'react'
import {
  Box,
  Container,
  Flex,
  Image,
  Modal,
  ModalContent,
  Text,
  useToast,
  Divider
} from '@chakra-ui/react'
import { Button, IconButton } from '@blueprinthq/joy'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  SearchIcon,
  useScrollbarInfo,
  NewTabIcon
} from '@blueprinthq/joy'
import { useHistory, Link } from 'react-router-dom'

import WhiteLogo from '../../../images/brand/white_logo.svg'
import { usePermissions, useNavLinks } from '../../../hooks'
import { useStoreState, useStoreActions } from 'easy-peasy'
import NotificationBadge from './notification-badge'
import { useExperienceManager } from '@hooks'
import { Gift } from '../../../components/icons'
import { REFERRAL_AMOUNT } from '../../../constants/referral'
import { trackEvent } from '@lib/clinician-tracking'

export const MenuModal = ({
  onClose,
  open,
  onClickSearch,
  isClinical,
  paywallData
}) => {
  const toast = useToast()
  const { hasPayingSubscription, clientCount, isLegacyPlan } = paywallData
  const { hasPermission } = usePermissions()
  const { settingsLinkGroups } = useNavLinks(isLegacyPlan)
  const history = useHistory()
  const { hasYScrollbar, yScrollbarWidth } = useScrollbarInfo()
  const user = useStoreState(state => state.auth.user)
  const { selectedPatientTypeOption } = useStoreState(
    state => state.patientList
  )
  const {
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnabled,
    isAdmin,
    isPlusPlanEnabled,
    showReferralV2Experience
  } = useExperienceManager()

  const showScribeClientList =
    !isEvidenceBasedCareEnabled && isDocumentationAutomationEnabled
  const disableEnrollment = !hasPayingSubscription && clientCount >= 5

  const showBilling = user.organization.showBilling
  const canEnrollClients = user.organization.canEnrollClients

  const [showReportOptions, setShowReportOptions] = useState(false)
  const [showSettingOptions, setShowSettingOptions] = useState(false)
  const [showLibraryOptions, setShowLibraryOptions] = useState(false)

  const toggleShowLibraryOptions = useCallback(() => {
    setShowLibraryOptions(!showLibraryOptions)
  }, [showReportOptions])

  const toggleShowReportOptions = useCallback(() => {
    setShowReportOptions(!showReportOptions)
  }, [showReportOptions])

  const toggleShowSettingOptions = useCallback(() => {
    setShowSettingOptions(!showSettingOptions)
  }, [showSettingOptions])

  const openPlansModal = useStoreActions(
    actions => actions.modals.choosePlan.openModal
  )

  const navigate = useCallback(
    path => {
      onClose()
      history.push(path)
    },
    [onClose]
  )

  const backgroundColor = hasPermission('*:any:*:*') ? '#282828' : 'primary'

  return (
    <Modal preserveScrollBarGap size={'full'} onClose={onClose} isOpen={open}>
      <ModalContent
        background={backgroundColor}
        marginTop={'0px'}
        marginBottom={'0px'}
      >
        <Container>
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              flex: 1,
              '*': {
                fontFamily: 'TofinoPersonal',
                fontWeight: 'bold'
              },
              paddingRight: hasYScrollbar ? '0px' : `${yScrollbarWidth}px`
            }}
          >
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                height: {
                  base: '64px',
                  sm: '80px',
                  xl: '104px'
                }
              }}
            >
              <Image
                loading="eager"
                src={WhiteLogo}
                sx={{
                  objectFit: 'contain',
                  gridColumnStart: 2
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  right: 0
                }}
              >
                <IconButton
                  aria-label="Close"
                  onClick={onClose}
                  icon={<CloseIcon fill="white" />}
                  variant={'ghost'}
                />
              </Box>
            </Flex>
            <Flex
              sx={{
                mt: 'xsmall',
                flexDirection: 'column',
                alignItems: 'flex-start',
                mx: [0, 'xlarge']
              }}
            >
              {isClinical && (
                <>
                  <Flex
                    onClick={onClickSearch}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      width: '100%',
                      height: '48px',
                      borderRadius: '30px',
                      alignItems: 'center',
                      mb: ['small', 'medium']
                    }}
                  >
                    <Box sx={{ ml: 'medium' }} />
                    <SearchIcon fill={'white'} />
                    <Box
                      sx={{
                        ml: 'xsmall',
                        fontWeight: 'normal',
                        color: 'white',
                        opacity: 0.5
                      }}
                    >
                      Search Clients
                    </Box>
                  </Flex>
                  {isEvidenceBasedCareEnabled && (
                    <Button
                      onClick={() => {
                        if (window.location.pathname === '/dashboard') {
                          window.location.reload()
                        } else {
                          navigate('/dashboard')
                        }
                      }}
                      rightIcon={<NotificationBadge />}
                      mb={['small', 'medium']}
                      fontSize="lg"
                      fontWeight="bold"
                      size="md"
                      bg
                    >
                      Dashboard
                    </Button>
                  )}

                  <Button
                    onClick={() => navigate(`/sessions`)}
                    mb={['small', 'medium']}
                    fontSize="lg"
                    fontWeight="bold"
                    size="md"
                    bg
                  >
                    Sessions
                  </Button>
                  {isEvidenceBasedCareEnabled && (
                    <Button
                      onClick={() =>
                        navigate(`/patients/${selectedPatientTypeOption.value}`)
                      }
                      mb={['small', 'medium']}
                      fontSize="lg"
                      fontWeight="bold"
                      size="md"
                      bg
                    >
                      Clients
                    </Button>
                  )}
                  {showScribeClientList && (
                    <Button
                      onClick={() => navigate(`/scribe/client-list`)}
                      mb={['small', 'medium']}
                      fontSize="lg"
                      fontWeight="bold"
                      size="md"
                      bg
                      id="pendo-scribe-client-list"
                    >
                      Clients
                    </Button>
                  )}
                </>
              )}
              {canEnrollClients && isEvidenceBasedCareEnabled && (
                <Box
                  sx={{
                    mb: ['small', 'medium']
                  }}
                >
                  <Button
                    size="md"
                    aria-label="Add new client"
                    onClick={() => {
                      if (disableEnrollment) {
                        toast({
                          title:
                            'Please upgrade your plan to enroll additional clients.',
                          description:
                            "You've reached the 3 client limit on the Starter plan.",
                          status: 'error',
                          duration: 3000,
                          isClosable: true
                        })
                      } else {
                        navigate('/new-client')
                      }
                    }}
                    variant={'ghost'}
                    fontSize="lg"
                    color="white"
                    fontWeight="bold"
                  >
                    Add New Client
                  </Button>
                </Box>
              )}
              {isPlusPlanEnabled &&
                !isEvidenceBasedCareEnabled &&
                hasPermission('read:own:reports:outcomes') && (
                  <Box
                    sx={{
                      mb: ['small', 'medium']
                    }}
                  >
                    <Button
                      as={Link}
                      to="/insights/treatment-outcomes"
                      variant={'ghost'}
                      fontSize="lg"
                      color="white"
                      fontWeight="bold"
                      onClick={onClose}
                    >
                      Reports
                    </Button>
                  </Box>
                )}
              {isEvidenceBasedCareEnabled && (
                <Box
                  sx={{
                    mb: ['small', 'medium']
                  }}
                >
                  <Button
                    rightIcon={
                      showReportOptions ? (
                        <ArrowUpIcon fill={'white'} />
                      ) : (
                        <ArrowDownIcon fill={'white'} />
                      )
                    }
                    onClick={toggleShowReportOptions}
                    fontSize="lg"
                    fontWeight="bold"
                    size="md"
                    pr="xsmall"
                    bg
                  >
                    Reports
                  </Button>
                  <Box
                    sx={{
                      height: showReportOptions ? '100%' : 0,
                      opacity: showReportOptions ? 1 : 0,
                      overflow: 'hidden',
                      transition: 'all 200ms ease-out'
                    }}
                  >
                    <Flex
                      sx={{
                        ml: 'medium',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      {hasPermission('read:own:reports:outcomes') && (
                        <Button
                          as={Link}
                          to="/insights/treatment-outcomes"
                          fontSize="18px"
                          fontWeight="bold"
                          size="md"
                          bg
                          onClick={onClose}
                        >
                          Treatment Outcomes
                        </Button>
                      )}
                      {hasPermission('read:own:reports:outcomes') && (
                        <Button
                          as={Link}
                          to="/insights/client-satisfaction"
                          fontSize="18px"
                          fontWeight="bold"
                          size="md"
                          bg
                          onClick={onClose}
                        >
                          Client Satisfaction
                        </Button>
                      )}
                      {hasPermission('*:org:billing:*') && showBilling && (
                        <Button
                          as={Link}
                          to="/reports/claims"
                          fontSize="18px"
                          fontWeight="bold"
                          size="md"
                          bg
                          onClick={onClose}
                        >
                          Claims
                        </Button>
                      )}
                    </Flex>
                  </Box>
                </Box>
              )}
              {isEvidenceBasedCareEnabled && isAdmin && (
                <Box
                  sx={{
                    mb: ['small', 'medium']
                  }}
                >
                  <Button
                    rightIcon={
                      showLibraryOptions ? (
                        <ArrowUpIcon fill={'white'} />
                      ) : (
                        <ArrowDownIcon fill={'white'} />
                      )
                    }
                    onClick={toggleShowLibraryOptions}
                    fontSize="lg"
                    fontWeight="bold"
                    size="md"
                    pr="xsmall"
                    bg
                  >
                    Library
                  </Button>
                  <Box
                    sx={{
                      height: showLibraryOptions ? '100%' : 0,
                      opacity: showLibraryOptions ? 1 : 0,
                      overflow: 'hidden',
                      transition: 'all 200ms ease-out'
                    }}
                  >
                    <Flex
                      sx={{
                        ml: 'medium',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Button
                        as={Link}
                        to="/library/worksheets"
                        fontSize="18px"
                        fontWeight="bold"
                        size="md"
                        bg
                        onClick={onClose}
                      >
                        Worksheets
                      </Button>
                      <Button
                        as={Link}
                        to="/library/symptom-trackers"
                        fontSize="18px"
                        fontWeight="bold"
                        size="md"
                        bg
                        onClick={onClose}
                      >
                        Symptom Trackers
                      </Button>
                    </Flex>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  mb: ['small', 'medium']
                }}
              >
                <Button
                  rightIcon={
                    showSettingOptions ? (
                      <ArrowUpIcon fill={'white'} />
                    ) : (
                      <ArrowDownIcon fill={'white'} />
                    )
                  }
                  onClick={toggleShowSettingOptions}
                  fontSize="lg"
                  fontWeight="bold"
                  size="md"
                  pr="xsmall"
                  bg
                >
                  Settings
                </Button>
                <Box
                  sx={{
                    height: showSettingOptions ? '100%' : 0,
                    opacity: showSettingOptions ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'all 200ms ease-out'
                  }}
                >
                  <Flex
                    sx={{
                      ml: 'medium',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    {settingsLinkGroups
                      .flatMap(linkGroup => linkGroup.links)
                      .map(link => (
                        <Button
                          as={Link}
                          to={link.path}
                          fontSize="18px"
                          fontWeight="bold"
                          size="md"
                          key={link.key}
                          bg
                          onClick={() => {
                            if (link.key === 'plans') {
                              onClose()
                              openPlansModal()
                            } else {
                              onClose()
                            }
                          }}
                        >
                          {link.display}
                        </Button>
                      ))}
                  </Flex>
                </Box>
              </Box>
              <Flex width="100%" direction="column" align="flex-start">
                <Divider borderColor="rgba(255, 255, 255, 0.2)" />
                <Button
                  as={Link}
                  to={{ pathname: process.env.REACT_APP_HELP_URL }}
                  target="_blank"
                  rel="noopener noreferrer"
                  fontSize="18px"
                  fontWeight="bold"
                  size="md"
                  bg
                  onClick={onClose}
                >
                  <Flex direction="row" align="center">
                    <Text>Help Center</Text>
                    <NewTabIcon size="sm" fill="white" ml="xsmall" />
                  </Flex>
                </Button>
                <Divider borderColor="rgba(255, 255, 255, 0.2)" />
                <Button
                  as={Link}
                  to="/refer"
                  fontSize="md"
                  fontWeight="bold"
                  size="md"
                  w="auto"
                  bg
                  onClick={() => {
                    showReferralV2Experience &&
                      trackEvent('Referral V2 -> Clicked Refer A Friend', {
                        source: 'Mobile Nav Menu'
                      })
                    onClose()
                  }}
                >
                  {showReferralV2Experience ? (
                    <Flex align="center">
                      <Text mr="xsmall">
                        Refer a friend, get {REFERRAL_AMOUNT} in credits
                      </Text>
                      <Gift fill="white" />
                    </Flex>
                  ) : (
                    <Flex direction="row" align="center">
                      <Text>Get $100 off your next bill</Text>
                    </Flex>
                  )}
                </Button>
              </Flex>
              <Box sx={{ width: '100% ' }}>
                <Divider borderColor="rgba(255, 255, 255, 0.2)" />
                <Button
                  as={Link}
                  to="/logout"
                  fontSize="md"
                  fontWeight="bold"
                  size="md"
                  w="auto"
                  bg
                  onClick={onClose}
                >
                  Log out
                </Button>
              </Box>
              {user.clinic.contract_type &&
                user.roles.some(r => r.name === 'clinicadmin') &&
                (user.clinic.contract_type.toLowerCase().includes('trial') ||
                  user.clinic.contract_type
                    .toLowerCase()
                    .includes('self_serve')) && (
                  <Box sx={{ width: '100% ' }}>
                    <Divider borderColor="rgba(255, 255, 255, 0.2)" />
                  </Box>
                )}
            </Flex>
          </Flex>
        </Container>
      </ModalContent>
    </Modal>
  )
}
