import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { TestimonialCard } from './testimonial-card'

const MotionContainer = motion(Box)

export const TestimonialCarousel = ({ progress }: { progress: number }) => {
  const testimonials = [
    {
      num: 1,
      testimonial:
        "Blueprint has transformed my practice. Creating and editing progress notes is fun now! Often the first version of the note is perfect. I'll never go back.",
      attribution: '—Dr. Lynn Northrop, PhD, Owner',
      image:
        'https://res.cloudinary.com/hellojoy/image/upload/v1740515165/onboarding/testimonial-profile-01.webp',
      imageAlt: 'Dr. Lynn Northrop'
    },
    {
      num: 2,
      testimonial:
        'Blueprint has given me the opportunity to have my nights and weekends FREE of charting. Love it!',
      attribution: '—Kristie Blanchet, LCSW',
      attributionClinic: 'Blanchcounseling',
      image:
        'https://res.cloudinary.com/hellojoy/image/upload/v1740515165/onboarding/testimonial-profile-02.webp',
      imageAlt: 'Kristie Blanchet'
    },
    {
      num: 3,
      testimonial:
        'Blueprint AI has been instrumental in getting time back. I’ve used that time to see extra patients and to spend more time with my family.',
      attribution: '—Dr Alma Castaneda, PhD, LPC-S',
      image:
        'https://res.cloudinary.com/hellojoy/image/upload/v1740515165/onboarding/testimonial-profile-03.webp',
      imageAlt: 'Dr Alma Castaneda'
    },
    {
      num: 4,
      testimonial:
        'From assessments to homework to progress note writing, Blueprint has been instrumental in empowering our practice to do our best work!',
      attribution: '—Amy Green, CEO/Founder',
      attributionClinic: 'Mamaya Health',
      image:
        'https://res.cloudinary.com/hellojoy/image/upload/v1740515165/onboarding/testimonial-profile-04.webp',
      imageAlt: 'Amy Green'
    }
  ]

  const cardWidth = 380
  const spacing = 120
  const initialDelay = 0.7
  const defaultDelay = 0.2
  const initialDuration = 1
  const defaultDuration = 0.5

  const [transitionProps, setTransitionProps] = useState(() => {
    return progress === 1
      ? { duration: initialDuration, delay: initialDelay }
      : { duration: defaultDuration, delay: defaultDelay }
  })

  // We want to delay the transition on first render of the carousel
  // Can't just use the progress state because the user can go back and forth
  useEffect(() => {
    setTransitionProps({ duration: defaultDuration, delay: defaultDelay })
  }, [progress])

  return (
    <Box
      width="100%"
      overflow="hidden"
      position="relative"
      display="flex"
      justifyContent="center"
    >
      <MotionContainer
        display="flex"
        gap={`${spacing}px`}
        width="fit-content"
        direction="row"
        justify="space-between"
        w="100%"
        initial={{ x: 0 }}
        animate={{
          x: `calc(50% - ${cardWidth / 2}px - ${(progress - 1) *
            (cardWidth + spacing)}px)`
        }}
        transition={{ duration: 0.6, ease: [0.57, 0.1, 0, 1] }}
      >
        {testimonials.map(t => (
          <TestimonialCard
            key={t.num}
            testimonial={t.testimonial}
            attribution={t.attribution}
            attributionClinic={t.attributionClinic}
            image={t.image}
            imageAlt={t.imageAlt}
            inFocus={t.num === progress}
            transitionProps={transitionProps}
            childProps={{
              width: `${cardWidth}px`
            }}
          />
        ))}
      </MotionContainer>
    </Box>
  )
}
