import React from 'react'
import { Box, VStack, Flex, HStack, Text, CloseButton } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { Link } from 'react-router-dom'
import {
  REFERRAL_AMOUNT,
  REFERRAL_IMAGE_URL
} from '../../../../constants/referral'

import { Gift } from '@components/icons'
import { trackEvent } from '@lib/clinician-tracking'

interface ReferralBannerProps {
  handleClose: () => void
}

export const ReferralBanner = ({ handleClose }: ReferralBannerProps) => {
  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      bg="#F6F7FE"
      borderRadius="8px"
    >
      <CloseButton
        position="absolute"
        top={{ base: '5px', sm: '10px' }}
        right={{ base: '5px', sm: '10px' }}
        onClick={handleClose}
      />
      <Box flex="5" p={{ base: 6, md: 8 }}>
        <VStack spacing={4} align="stretch">
          <Text
            color="#2D54E8"
            fontSize={{ base: '16px', lg: '24px' }}
            fontWeight={{ base: 'bold', lg: 'normal' }}
          >
            Recommend Blueprint and get free notes!
          </Text>
          <Text>
            We are excited to have you onboard! We have a special offer for you.
            Refer a friend to Blueprint and get{' '}
            <Text as="span" fontWeight="bold">
              {REFERRAL_AMOUNT} in credits
            </Text>{' '}
            to use towards your own sessions.
          </Text>
          <Button
            as={Link}
            onClick={() =>
              trackEvent('Referral V2 -> Clicked Refer A Friend', {
                source: 'Welcome Banner'
              })
            }
            to="/refer"
            size="lg"
            variant="outline"
            _hover={{
              bg: 'rgba(45, 84, 232, 0.04)'
            }}
          >
            <HStack spacing={2}>
              <Gift fill="#2D54E8" />
              <Text>Refer a friend</Text>
            </HStack>
          </Button>
        </VStack>
      </Box>
      <Box
        flex="3"
        bgImage={`url(${REFERRAL_IMAGE_URL})`}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        pb={{ base: '200px', sm: '0' }}
        borderBottomRightRadius="8px"
        borderBottomLeftRadius={{ base: '8px', sm: '0' }}
        borderTopRightRadius={{ base: '0', sm: '8px' }}
      ></Box>
    </Flex>
  )
}
