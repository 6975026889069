import React from 'react'
import { Icon } from '@chakra-ui/react'

export const FastForwardIcon = (props: any) => (
  <Icon viewBox="0 0 17 16" {...props}>
    <path
      d="M8.5 4V12L14.1667 8M2.5 12L8.16667 8L2.5 4V12Z"
      fill="currentColor"
    />
  </Icon>
)
