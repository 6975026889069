import React from 'react'
import {
  Box,
  Text,
  Image as ChakraImage,
  useBreakpointValue,
  Flex,
  useMediaQuery,
  BoxProps
} from '@chakra-ui/react'

const FacePileImageDesktop =
  'https://res.cloudinary.com/hellojoy/image/upload/v1739997090/onboarding/blueprint-reviews-compress.webp'
const FacePileImageMobile =
  'https://res.cloudinary.com/hellojoy/image/upload/v1739997090/onboarding/blueprint-reviews-m-compress.webp'
const BadgeImage =
  'https://res.cloudinary.com/hellojoy/image/upload/v1742412846/onboarding/hipaa_soc_phipa.webp'

interface SocialProofFooterProps extends BoxProps {
  showTerms?: boolean
  termsComponent?: React.ReactNode
}

export const SocialProofFooter: React.FC<SocialProofFooterProps> = ({
  showTerms = false,
  termsComponent = null,
  ...boxProps
}) => {
  const facePileSrc = useBreakpointValue({
    base: FacePileImageMobile,
    md: FacePileImageDesktop
  })
  const badgeSrc = BadgeImage
  const [tallEnoughForFacepile] = useMediaQuery('(min-height: 700px)')

  return (
    <Flex
      flex={{ base: '1', md: '0' }}
      w="100%"
      flexDirection="column"
      textAlign="center"
      justifyContent="flex-end"
      position={{ base: 'static', md: 'absolute' }}
      bottom={{ base: 'auto', md: '0' }}
      {...boxProps}
    >
      <Box
        display={{
          base: 'block',
          md: tallEnoughForFacepile ? 'block' : 'none'
        }}
      >
        <ChakraImage
          w={{ base: '271px', md: '359px' }}
          h={{ base: '46px', md: '46px' }}
          mx="auto"
          src={facePileSrc}
          alt="Clinician testimonials"
        />
        <Text fontSize="12px" color="primary" pt={2} pb="30px">
          Trusted by 40,000+ mental health clinicians
        </Text>
      </Box>
      <ChakraImage
        w={{ base: '280px', md: '328px' }}
        h={{ base: '30px', md: '35px' }}
        mx="auto"
        src={badgeSrc}
        alt="HIPAA SOC PHIPA compliant"
      />
      {showTerms && termsComponent && <Box pt="14px">{termsComponent}</Box>}
    </Flex>
  )
}

export default SocialProofFooter
