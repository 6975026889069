import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { CompletedWorksheetList } from './completed-worksheets-list'
import { usePatientControllerGetCheckInScores } from '~/clinician-api'
import { useAssistPanelContext } from '../context'
import { useTreatmentPlan } from '@hooks'
import { AssistPanelOverlay } from '../overlay-panel'
export const AllWorksheetResults = () => {
  const { setOverlayPanel } = useAssistPanelContext()
  const { client } = useTreatmentPlan()

  const { data } = usePatientControllerGetCheckInScores(client?.id || '', {
    query: {
      initialData: {
        scores: [],
        versions: []
      },
      enabled: !!client?.id
    }
  })

  const scoresWithCheckIn = useMemo(() => {
    if (data) {
      return data.scores.map(score => ({
        ...score,
        checkIn: data.versions.find(v => v.versionId === score.versionId)
      }))
    } else {
      return []
    }
  }, [data])

  return (
    <Box 
      height={{ base: "calc(100vh - 72px)", md: "100%" }}
      maxHeight="100%"
      position="relative"
      overflow="hidden"
    >
      <Box 
        height="100%"
        p="medium" 
        overflowY="auto"
        pb={{base: '150px', md: '0'}}
      >
        <CompletedWorksheetList
          scoresWithCheckIn={scoresWithCheckIn}
          onClickWorksheet={s =>
            setOverlayPanel(AssistPanelOverlay.WORKSHEET_RESULT, {
              checkIn: s.checkIn,
              score: s
            })
          }
        />
      </Box>
    </Box>
  )
}
