import React from 'react'

interface PlayIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  width?: string | number
  height?: string | number
}

export const PlayIcon: React.FC<PlayIconProps> = ({
  color = '#2D54E8',
  width = '24px',
  height = '24px',
  style,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      <path d="M8 5.14001V19.14L19 12.14L8 5.14001Z" fill={color} />
    </svg>
  )
}
