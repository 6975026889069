import React from 'react'
import { Text, Box, Flex, VStack, HStack } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { MarkdownRenderer } from '@blueprinthq/joy-business'

import { Loading } from '@components'
import { snakeToCapital } from '@utilities'
import { useInterventionsControllerV1GetIntervention } from '~/clinician-api'
import { AssistPanelOverlay } from '../../../in-session-assist-panel/overlay-panel'
import { useAssistPanelContext } from '../../../in-session-assist-panel/context'
import { useExperienceManager } from '@hooks'

interface InterventionOverviewProps {
  interventionId: string
  contentSuggestionId?: string | null
}

type TriggerDisplayMap = {
  [key: string]: string
}

const TRIGGER_DISPLAY_MAP: TriggerDisplayMap = {
  high_risk: 'High-Risk'
}

export const InterventionOverview = ({
  interventionId,
  contentSuggestionId
}: InterventionOverviewProps) => {
  const { setOverlayPanel } = useAssistPanelContext()
  const { isSessionRecordingV2Enabled } = useExperienceManager()

  const {
    data: intervention,
    isLoading: isInterventionLoading
  } = useInterventionsControllerV1GetIntervention(interventionId)

  return (
    <VStack
      spacing={0}
      h={
        isSessionRecordingV2Enabled
          ? 'calc(100vh - 110px)'
          : 'calc(100vh - 168px)'
      }
    >
      {isInterventionLoading ? (
        <Loading />
      ) : (
        <Box
          p="24px"
          id="pendo-intervention-summary-tag"
          minH={
            isSessionRecordingV2Enabled
              ? 'calc(100vh - 200px)'
              : 'calc(100vh - 258px)'
          }
          maxH={{
            base: 'calc(100vh - 180px)',
            md: isSessionRecordingV2Enabled
              ? 'calc(100vh - 200px)'
              : 'calc(100vh - 258px)'
          }}
          overflowY="auto"
          pb={{ base: '96px', md: '24px' }}
        >
          <VStack align="left" spacing="xxsmall" pl="0" mb="medium">
            <HStack>
              <Text fontWeight="bold">Type</Text>
              <Text>{snakeToCapital(intervention?.type ?? '')}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Treatment Approach</Text>
              <Text>
                {snakeToCapital(intervention?.treatmentModality ?? '')}
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Population</Text>
              <Text>
                {intervention?.population
                  .map((p: string) => snakeToCapital(p))
                  .join(', ')}
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Trigger</Text>
              {intervention?.triggers?.length ? (
                intervention?.triggers.map((trigger: string, idx: number) => (
                  <Text key={idx}>
                    {TRIGGER_DISPLAY_MAP[trigger] || trigger}
                  </Text>
                ))
              ) : (
                <Text>None</Text>
              )}
            </HStack>
          </VStack>
          <MarkdownRenderer>
            {String(intervention?.instructions) ?? ''}
          </MarkdownRenderer>
        </Box>
      )}
      <Flex
        position={{ base: 'fixed', md: 'static' }}
        bottom={{ base: 0, md: 'auto' }}
        left={{ base: 0, md: 'auto' }}
        right={{ base: 0, md: 'auto' }}
        w="100%"
        p="18px"
        align="center"
        justify="center"
        borderTop="1px solid"
        borderColor="pale_gray"
        bg="white"
      >
        <Button
          id="pendo-intervention-start-activity-tag"
          size="lg"
          isFullWidth
          onClick={() => {
            setOverlayPanel(AssistPanelOverlay.INTERVENTION_ACTIVITY, {
              interventionId,
              isReadOnly: false,
              interventionName: intervention?.name,
              contentSuggestionId
            })
          }}
        >
          Start Activity
        </Button>
      </Flex>
    </VStack>
  )
}
