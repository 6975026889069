import React from 'react'

const Gift = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill || 'black'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3634 4.25015C12.7593 3.80786 13 3.22378 13 2.58349C13 1.20277 11.8807 0.0834859 10.5 0.0834859C9.11929 0.0834859 8 1.20277 8 2.58349C8 1.20277 6.88071 0.0834859 5.5 0.0834859C4.11929 0.0834859 3 1.20277 3 2.58349C3 3.22378 3.24071 3.80786 3.63658 4.25015H2.16667C1.24619 4.25015 0.5 4.99634 0.5 5.91682V7.58349C0.5 8.50396 1.24619 9.25015 2.16667 9.25015L2.16667 14.2502C2.16667 15.1706 2.91286 15.9168 3.83333 15.9168L7.16667 15.9168H8.83333L12.1667 15.9168C13.0871 15.9168 13.8333 15.1706 13.8333 14.2502L13.8333 9.25015C14.7538 9.25015 15.5 8.50396 15.5 7.58349V5.91682C15.5 4.99634 14.7538 4.25015 13.8333 4.25015H12.3634ZM8.83333 14.2502H12.1667V9.25015H8.83333V14.2502ZM8.83333 7.58349H12.1667L13.8333 7.58349V5.91682H8.83333V7.58349ZM7.16667 5.91682V7.58349H3.83333L2.16667 7.58349L2.16667 5.91682H7.16667ZM7.16667 9.25015V14.2502H3.83333L3.83333 9.25015H7.16667ZM6.33333 2.58349C6.33333 2.12325 5.96024 1.75015 5.5 1.75015C5.03976 1.75015 4.66667 2.12325 4.66667 2.58349C4.66667 3.04372 5.03976 3.41682 5.5 3.41682H6.33333V2.58349ZM9.66667 2.58349C9.66667 2.12325 10.0398 1.75015 10.5 1.75015C10.9602 1.75015 11.3333 2.12325 11.3333 2.58349C11.3333 3.04372 10.9602 3.41682 10.5 3.41682H9.66667V2.58349Z"
      />
    </svg>
  )
}

export default Gift
