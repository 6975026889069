import React, { useMemo } from 'react'
import { Button } from '@blueprinthq/joy'
import {
  VStack,
  Box,
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  RadioGroup,
  Radio,
  Select,
  Stack,
  useToast,
  useBreakpointValue
} from '@chakra-ui/react'
import { Formik, Field, Form } from 'formik'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

import { endpoints } from '../../../../api'

const DeleteProviderSchema = Yup.object().shape({
  transferProvider: Yup.object()
    .shape({
      id: Yup.string().required()
    })
    .required('Required')
    .nullable()
})

const TRANSFER = 'transfer'
const DISCHARGE = 'discharge'

export const DeleteClinicianModal = ({
  onClose,
  provider,
  providerList,
  selectedClinic,
  ...rest
}) => {
  const [handleClientOption, setHandleClientOption] = React.useState(TRANSFER)

  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: clinicians } = useQuery(
    [
      endpoints.getOrganizationProviders.getCacheId(),
      selectedClinic.organization_id,
      selectedClinic.id
    ],
    () =>
      endpoints.getOrganizationProviders.request({
        organizationId: selectedClinic.organization_id,
        clinicId: selectedClinic.id,
        cliniciansOnly: true,
        includeCounts: false
      }),
    {
      initialData: []
    }
  )

  const { mutateAsync: deleteProvider } = useMutation(
    endpoints.deleteOrganizationProvider.request,
    {
      onSuccess() {
        queryClient.invalidateQueries(
          endpoints.getOrganizationProviders.getCacheId()
        )
        onClose()
        toast({
          title: 'User Deleted',
          description: `Successfully deleted ${provider.first_name} ${provider.last_name}`,
          status: 'info',
          isClosable: true
        })
      },
      onError({ error }) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          isClosable: true
        })
      }
    }
  )

  const transferOptions = useMemo(() => {
    // Only include clinicians and do not include the provider that is being deleted
    return clinicians
      .filter(c => provider !== null && provider.id !== c.id)
      .map(c => {
        c.full_name = `${c.first_name} ${c.last_name}`
        return c
      })
  }, [clinicians, provider])

  const modalSize = useBreakpointValue({
    base: 'full',
    md: 'lg'
  })

  const handleArchiveWithDischarge = async ({ organizationId, providerId }) => {
    await deleteProvider({
      data: {
        dischargeClients: true
      },
      organizationId,
      providerId
    })
  }

  const handleArchiveWithTransfer = async ({
    newProviderId,
    organizationId,
    providerId
  }) => {
    await deleteProvider({
      data: {
        newProviderId
      },
      organizationId,
      providerId
    })
  }

  if (!provider) return null

  const isTransferSelected = handleClientOption === TRANSFER

  const validationSchema =
    isTransferSelected && provider && provider.total_client_count > 0
      ? DeleteProviderSchema
      : null

  return (
    <Modal onClose={onClose} size={modalSize} preserveScrollBarGap {...rest}>
      <ModalOverlay />
      <ModalContent p="small">
        <ModalHeader>Delete User</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            transferProvider: null
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            isTransferSelected
              ? await handleArchiveWithTransfer({
                  newProviderId: values.transferProvider?.id,
                  organizationId: selectedClinic.organization_id,
                  providerId: provider.id
                })
              : await handleArchiveWithDischarge({
                  organizationId: selectedClinic.organization_id,
                  providerId: provider.id
                })
            actions.setSubmitting(false)
          }}
        >
          {({ isSubmitting, errors, touched, isValid, dirty }) => (
            <Form>
              <ModalBody>
                <VStack spacing="small" alignItems="flex-start">
                  <Box>
                    <Text>
                      Are you sure you want to permanently delete{' '}
                      <b>
                        {provider.first_name} {provider.last_name} (
                        {provider.email})
                      </b>{' '}
                      and revoke their access to Blueprint? This action cannot
                      be undone.
                    </Text>
                  </Box>

                  {provider.total_client_count > 0 ? (
                    <>
                      <Box>
                        <Text mb="xsmall" fontWeight="bold">
                          What should we do with {provider.first_name}'s{' '}
                          {provider.total_client_count} clients?
                        </Text>
                        <RadioGroup
                          _selected={{ outline: 'none' }}
                          my="small"
                          value={handleClientOption}
                          onChange={val => {
                            setHandleClientOption(val)
                          }}
                        >
                          <Stack spacing={4} direction="row">
                            <Radio value={TRANSFER}>Transfer</Radio>
                            <Text>OR</Text>
                            <Radio value={DISCHARGE}>Discharge</Radio>
                          </Stack>
                        </RadioGroup>
                        {isTransferSelected && (
                          <Text fontWeight="bold" mt={5}>
                            Who should we transfer {provider.first_name}’s
                            clients to?
                          </Text>
                        )}
                      </Box>
                      {isTransferSelected && (
                        <Field name="transferProvider">
                          {({ field, form }) => (
                            <Select
                              {...field}
                              value={field.value?.id}
                              placeholder="Choose clinician"
                              onChange={evt => {
                                const value = transferOptions.find(
                                  c => c.id === evt.target.value
                                )
                                form.setFieldValue('transferProvider', value)
                              }}
                              borderColor="#C9C9C9"
                              minH="48px"
                            >
                              {transferOptions.map(option => (
                                <option key={option.id} value={option.id}>
                                  {option.full_name}
                                </option>
                              ))}
                            </Select>
                          )}
                        </Field>
                      )}
                    </>
                  ) : null}
                </VStack>
              </ModalBody>
              <ModalFooter display="block">
                <Button
                  type="submit"
                  size="lg"
                  backgroundColor="severe"
                  isFullWidth
                  isLoading={isSubmitting}
                  isDisabled={
                    isTransferSelected
                      ? !isValid || (provider.total_client_count > 0 && !dirty)
                      : false
                  }
                >
                  Permanently Delete User
                </Button>
                <Button
                  mt="xsmall"
                  type="button"
                  size="lg"
                  isFullWidth
                  backgroundColor="pale_gray"
                  color="black"
                  onClick={onClose}
                >
                  I want to keep this User
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
