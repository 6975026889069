import React from 'react'
import { endpoints } from '@api'
import { useQuery } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import {
  VStack,
  Stack,
  Container,
  GridItem,
  Box,
  Text,
  Image,
  Heading,
  Input,
  Spacer
} from '@chakra-ui/react'
import { Button, LayoutGrid } from '@blueprinthq/joy'
import { copyToClipboard } from '../../utilities'
import { useExperienceManager } from '@hooks'
import { ReferralV2 } from './referral-v2'

export const Referral = () => {
  const { showReferralV2Experience } = useExperienceManager()

  const { data: referralData } = useQuery(
    endpoints.getReferralURL.getCacheId(),
    endpoints.getReferralURL.request
  )
  const referralURL = referralData
    ? referralData.referralURL
    : process.env.REACT_APP_WWW_URL

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const handleCopyLink = url => {
    try {
      copyToClipboard(url)
      setSnackbarMessage({
        variant: 'success',
        message: 'Referral link copied to clipboard!'
      })
    } catch (e) {
      setSnackbarMessage({
        variant: 'error',
        message:
          "We're sorry, something went wrong, please refresh and try again"
      })
    }
  }

  if (showReferralV2Experience) {
    return <ReferralV2 />
  }

  return (
    <Container
      marginTop={{
        base: '24px',
        sm: '32px',
        md: '48px',
        lg: '64px',
        xl: '64px'
      }}
      paddingLeft={{
        base: '16px',
        sm: '0px'
      }}
      paddingRight={{
        base: '16px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 6,
            sm: 6,
            md: 6,
            lg: 6
          }}
          colStart={{
            sm: 2,
            md: 4,
            lg: 4
          }}
        >
          <VStack spacing="24px">
            <Image
              loading="eager"
              src="https://res.cloudinary.com/hellojoy/image/upload/v1660248820/refer_hoh6mi.png"
              sx={{
                width: 'auto',
                height: '250px'
              }}
            />
            <Spacer />
            <Heading sx={{ textAlign: 'center' }} size="xl">
              Refer a colleague and you both get $100
            </Heading>
            <Text sx={{ textAlign: 'center' }}>
              Refer an unlimited number of colleagues to Blueprint. After your
              colleague becomes a paid customer, you’ll both get a $100 account
              credit on your next bill.
            </Text>
            <Text sx={{ fontWeight: 'bold' }}>
              Share your unique referral link via email or on social media
              accounts:
            </Text>
            <Stack
              direction={['column', 'row']}
              alignItems="stretch"
              spacing="24px"
              w="100%"
            >
              <Box flex="1">
                <Input
                  value={referralURL}
                  placeholder={referralURL}
                  type="text"
                  readOnly
                />
              </Box>
              <Button
                id="pendo-copy-referral-link-button"
                minW="150px"
                onClick={() => handleCopyLink(referralURL)}
              >
                Copy Link
              </Button>
            </Stack>
          </VStack>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
