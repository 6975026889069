import React from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  VStack,
  Button
} from '@chakra-ui/react'
import { REFERRAL_AMOUNT, SOCIAL_LINKS_COPY } from '../../../constants/referral'
import { trackEvent } from '@lib/clinician-tracking'

interface ShareButtonDrawerProps {
  isOpen: boolean
  onClose: () => void
  buttons: any[]
  referralURL: string
}

export const ShareButtonDrawer = ({
  isOpen,
  onClose,
  buttons,
  referralURL
}: ShareButtonDrawerProps) => {
  const shareContent = () => {
    trackEvent('Referral V2 -> Clicked Share Button', {
      shareMethod: 'other',
      platform: 'mobile'
    })
    if (navigator.share) {
      navigator
        .share({
          title: `Blueprint For Therapists: Referral Link (Claim ${REFERRAL_AMOUNT} Credit!)`,
          text: SOCIAL_LINKS_COPY,
          url: referralURL
        })
        .catch(error => console.error('Error sharing:', error))
    } else {
      console.log('Web Share API not supported')
    }
  }

  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent borderTopRadius="16px">
        <DrawerHeader
          borderBottomWidth="1px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize="16px" fontWeight={300}>
            Share
          </Text>
          <Text
            onClick={onClose}
            color="primary"
            fontWeight={300}
            fontSize="16px"
            _hover={{ cursor: 'pointer' }}
          >
            Close
          </Text>
        </DrawerHeader>
        <DrawerBody pb="large" pt="0">
          <VStack w="100%" gap={2}>
            {buttons
              .sort((a, b) => a.mobileDisplayOrder - b.mobileDisplayOrder)
              .map(({ url, icon, mobileTitle, mobileURL, key }) => (
                <ShareLinkButton
                  key={key}
                  url={mobileURL || url}
                  icon={icon}
                  title={mobileTitle}
                  buttonType={key}
                />
              ))}
            <Button
              margin="0px"
              variant="outline"
              isFullWidth
              borderColor="pale_gray"
              colorScheme="gray"
              size="lg"
              _hover={{
                bg: 'pale_gray'
              }}
              _focus={{
                outline: 'none'
              }}
              onClick={shareContent}
            >
              Other
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const ShareLinkButton = ({
  url,
  icon,
  title,
  buttonType,
  onClick
}: {
  url: string
  icon: any
  title: string
  buttonType: string
  onClick?: () => void
}) => (
  <Button
    margin="0px"
    variant="outline"
    isFullWidth
    borderColor="pale_gray"
    as="a"
    href={url}
    leftIcon={icon}
    colorScheme="gray"
    size="lg"
    target="_blank"
    _hover={{
      bg: 'pale_gray'
    }}
    _focus={{
      outline: 'none'
    }}
    onClick={() => {
      trackEvent('Referral V2 -> Clicked Share Button', {
        shareMethod: buttonType,
        platform: 'mobile'
      })
      if (onClick) {
        onClick()
      }
    }}
  >
    {title}
  </Button>
)
