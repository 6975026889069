import React, { useState } from 'react'
import {
  Text,
  Box,
  VStack,
  Button,
  Flex,
  IconButton,
  Stack,
  useToast
} from '@chakra-ui/react'
import { TrashIcon } from '@icons'
import {
  useProgressNoteTemplateMemoryControllerFindByNoteType,
  useProgressNoteTemplateMemoryControllerDelete,
  useProgressNoteTemplateMemoryControllerClear
} from '~/clinician-api'
import DeleteMemoryConfirmation from './delete-memory-confirmation'
import ClearMemoryConfirmation from './clear-memory-confirmation'

const Memories = ({ noteType }: { noteType: string }) => {
  const [memoryToDelete, setMemoryToDelete] = useState<string | null>(null)
  const [showClearConfirmation, setShowClearConfirmation] = useState(false)
  const toast = useToast()

  const {
    data: memories = [],
    isLoading: isMemoryLoading,
    refetch
  } = useProgressNoteTemplateMemoryControllerFindByNoteType({
    noteType
  })

  const {
    mutateAsync: deleteMemory,
    isLoading: isDeleteMemoryLoading
  } = useProgressNoteTemplateMemoryControllerDelete()

  const {
    mutateAsync: clearMemory,
    isLoading: isClearMemoryLoading
  } = useProgressNoteTemplateMemoryControllerClear()

  const handleDeleteMemory = async () => {
    if (memoryToDelete) {
      try {
        await deleteMemory({ id: memoryToDelete })
        await refetch()
        setMemoryToDelete(null)
        toast({
          title: 'Memory deleted',
          description: 'The memory has been successfully deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      } catch (error) {
        toast({
          title: 'Error deleting memory',
          description:
            'There was an error deleting the memory. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  const handleClearMemory = async () => {
    try {
      await clearMemory({ data: { noteType } })
      await refetch()
      setShowClearConfirmation(false)
      toast({
        title: 'Memory cleared',
        description: 'All memories have been successfully cleared.',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    } catch (error) {
      toast({
        title: 'Error clearing memory',
        description:
          'There was an error clearing the memories. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Box p="small" overflowY="scroll" height="100%">
      {isMemoryLoading ? (
        <Flex justify="center" align="center" height="200px">
          <Text>Loading memories...</Text>
        </Flex>
      ) : (
        <Stack spacing={0} flex="1" gap="16px">
          <VStack
            spacing={0}
            alignItems="stretch"
            flex="1"
            border="1px solid"
            borderColor="pale_gray"
            borderRadius="8px"
          >
            {memories.length > 0 ? (
              memories.map((memory, index) => (
                <Flex
                  key={memory.id}
                  p="12px"
                  borderTop={index !== 0 ? '1px solid' : 'none'}
                  borderColor="pale_gray"
                  justify="space-between"
                  align="center"
                >
                  <Text>{memory.content}</Text>
                  <IconButton
                    aria-label="Delete memory"
                    icon={<TrashIcon />}
                    h="16px"
                    w="16px"
                    minW="16px"
                    m="0"
                    variant="ghost"
                    color="gray.500"
                    onClick={() => setMemoryToDelete(memory.id)}
                    _focus={{ outline: 'none' }}
                  />
                </Flex>
              ))
            ) : (
              <Flex justify="center" align="center" height="200px">
                <Text color="gray.500" fontStyle="italic">
                  No memories found
                </Text>
              </Flex>
            )}
          </VStack>

          <Button
            variant="link"
            color="red.500"
            mt={6}
            onClick={() => setShowClearConfirmation(true)}
            textDecoration="none"
            hidden={!memories.length}
          >
            Clear Blueprint's memory
          </Button>
        </Stack>
      )}

      {memoryToDelete && (
        <DeleteMemoryConfirmation
          onClose={() => setMemoryToDelete(null)}
          onDelete={handleDeleteMemory}
          isLoading={isDeleteMemoryLoading}
        />
      )}

      {showClearConfirmation && (
        <ClearMemoryConfirmation
          onClose={() => setShowClearConfirmation(false)}
          onClear={handleClearMemory}
          isLoading={isClearMemoryLoading}
        />
      )}
    </Box>
  )
}

export default Memories
