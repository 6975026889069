export interface TierOption {
  display: string
  value: string
}

export interface Plan {
  value: string
  title: string
  features: string[]
  tierOptions: TierOption[]
  price: string
}

export const StardardFeatures = [
  'Automated progress notes',
  'Smart treatment plans',
  'Discharge summaries'
]

export const PlusFeatures = [
  ...StardardFeatures,
  'Pre-session insights',
  'In-session guidance',
  'Post-session suggestions'
]

export const EnterpriseFeatures = [
  ...PlusFeatures,
  'EHR integration',
  'API access',
  'Automated user provisioning',
  'Dedicated success manager'
]

export const PlanTypeToFeatures: Record<string, string[]> = {
  standard: StardardFeatures,
  plus: PlusFeatures,
  enterprise: EnterpriseFeatures
}

const plans: Plan[] = [
  {
    value: 'standard',
    title: 'Standard',
    features: [
      'Automated progress notes',
      'Smart treatment plans',
      'Discharge summaries'
    ],
    price: '$29',
    tierOptions: [
      { display: '30 sessions per month - $29.00 USD', value: '30' },
      { display: '60 sessions per month - $49.00 USD', value: '60' },
      { display: '100 sessions per month - $99.00 USD', value: '100' }
    ]
  },
  {
    value: 'plus',
    title: 'Plus',
    price: '$39',
    features: [
      'Automated progress notes',
      'Smart treatment plans',
      'Discharge summaries',
      'Pre-session insights',
      'In-session guidance',
      'Post-session suggestions'
    ],
    tierOptions: [
      { display: '30 sessions per month - $39.00 USD', value: '30' },
      { display: '60 sessions per month - $89.00 USD', value: '60' },
      { display: '100 sessions per month - $129.00 USD', value: '100' }
    ]
  }
]

export const EnterprisePricingPlan: Plan = {
  value: 'enterprise',
  title: 'Enterprise',
  features: [
    'Automated progress notes',
    'Smart treatment plans',
    'Pre-session insights',
    'In-session guidance',
    'Post-session suggestions',
    'EHR integration',
    'API access',
    'Automated user provisioning',
    'Dedicated success manager'
  ],
  price: 'Custom pricing',
  tierOptions: []
}

export default plans
