import React, { useEffect } from 'react'
import { Dialog } from '@material-ui/core'
import {
  Box,
  Text,
  Stack,
  Flex,
  VStack,
  UnorderedList,
  ListItem,
  Container,
  Button,
  IconButton,
  Textarea,
  useToast,
  Heading,
  Divider
} from '@chakra-ui/react'
import { Select } from '@blueprinthq/joy'
import { ArrowLeft } from '@components/icons'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import * as clinicianTracking from '@lib/clinician-tracking'
import { standardErrorDescription } from '@constants/Copy'
import { useHistory, Redirect } from 'react-router-dom'
import {
  useClinicControllerGetPlanOptions,
  useClinicControllerGetCurrentPlan
} from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
import { useExperienceManager } from '@hooks'
import moment from 'moment'

const CancelSubscription = ({ selectedClinic }: { selectedClinic: any }) => {
  const history = useHistory()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { user } = useStoreState((state: any) => state.auth)
  const { isAdmin } = useExperienceManager()

  const { data: billing, isFetching: isBillingLoading } = useQuery(
    [
      endpoints.getClinicBilling.getCacheId(),
      selectedClinic && selectedClinic.id
    ],
    () =>
      endpoints.getClinicBilling.request({
        clinicId: selectedClinic.id
      }),
    {
      initialData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        return data
      }
    }
  )

  const {
    data: currentPlan,
    isLoading: isLoadingCurrentPlan
  } = useClinicControllerGetCurrentPlan(user.clinic.id)

  const planType = currentPlan?.planType
  const clinicId = user.clinic_id
  const subscriptionId = currentPlan?.subscriptionId
  const planName = currentPlan?.planName

  useEffect(() => {
    clinicianTracking.trackEvent(`Viewed Cancel Subscription Page`)
  }, [])

  const { data: cancellationReasons }: any = useQuery(
    [endpoints.getAccountDeletionReasons.getCacheId()],
    endpoints.getAccountDeletionReasons.request
  )

  const { mutateAsync: cancelPlan } = useMutation(
    endpoints.patchIndividualBillingPlan.request,
    {
      onSuccess() {
        history.replace('/')
        toast({
          title: `Your subscription has been canceled`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
      },
      onError(err: any) {
        toast({
          title: `We ran into an issue canceling your subscription`,
          description: standardErrorDescription(),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const handleSubmit = async (values: any) => {
    clinicianTracking.trackEvent(
      `Cancel ${planType?.toUpperCase()} Dialog -> Clicked confirm cancel`
    )
    try {
      const data = {
        action: 'cancel',
        clinicId,
        subscriptionId,
        planType,
        planName,
        cancellationReason: values.cancellationReason,
        cancellationFeedback: values.cancellationFeedback
      }

      await cancelPlan({ data })
    } catch (err) {
      console.log(err)
    }
  }

  if (!isAdmin) {
    return <Redirect to="/sessions" />
  }

  return (
    <Container
      maxWidth="672px"
      overflowY="clip"
      height="100%"
      marginTop="40px"
      centerContent
      paddingLeft={{
        base: 'medium'
      }}
      paddingRight={{
        base: 'medium'
      }}
    >
      <Flex flexDirection="column" flex="1" gap="24px">
        <Flex flexDirection="column" gap="32px">
          <Heading fontSize="24px">Cancel your Blueprint subscription</Heading>
          <Divider />
          <Flex flexDirection="column" gap="8px">
            <Text as="b">
              Here's what will happen when your subscription ends on{' '}
              {moment(currentPlan?.billingEnd).format('MMM D, YYYY')}:
            </Text>
            <UnorderedList>
              <ListItem>
                All activities assigned to clients will be unassigned.
              </ListItem>
              <ListItem>
                You will no longer be billed (unless you have
                outstanding/overdue invoices).
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
        <Formik
          initialValues={{
            cancellationReason: null
          }}
          onSubmit={async (values: any) => {
            await handleSubmit(values)
          }}
          validationSchema={Yup.object().shape({
            cancellationReason: Yup.object()
              .shape({
                id: Yup.string().required('Cancellation Reason is required')
              })
              .required('Cancellation Reason is required')
          })}
        >
          {({ errors, touched, isSubmitting }: any) => (
            <Form style={{ width: '100%' }}>
              <VStack align="left" spacing="0" gap="24px">
                <VStack align="left" spacing="small" w="100%">
                  <Text as="b">Why are you canceling this plan?</Text>
                  <Field name="cancellationReason">
                    {({ field, form }: any) => (
                      <Select
                        matchWidth
                        borderColor="blue"
                        {...field}
                        onChange={(value: any) =>
                          form.setFieldValue('cancellationReason', value)
                        }
                        options={cancellationReasons?.filter(
                          (r: { id: string; display: string; type: string }) =>
                            ['any', 'scribe'].includes(r.type)
                        )}
                        valueKey="id"
                        labelKey="display"
                        label="Select a reason"
                        isRequired
                        isInvalid={
                          errors.cancellationReason &&
                          touched.cancellationReason
                        }
                        errorText={'Cancellation reason is required'}
                      />
                    )}
                  </Field>
                </VStack>
                <VStack align="left" spacing="small" w="100%">
                  <Text as="b">
                    Do you have any other feedback for our team on how we can
                    improve Blueprint?
                  </Text>
                  <Field name="cancellationFeedback">
                    {({ form }: any) => (
                      <Textarea
                        placeholder="Leave feedback..."
                        _placeholder={{ color: 'medium_gray' }}
                        borderColor="light_gray"
                        _focus={{
                          outline: 'none'
                        }}
                        onChange={(e: any) =>
                          form.setFieldValue(
                            'cancellationFeedback',
                            e.target.value
                          )
                        }
                      ></Textarea>
                    )}
                  </Field>
                </VStack>
                <Flex gap="16px">
                  <Button
                    variant="outline"
                    size="lg"
                    m="0"
                    borderColor="pale_gray"
                    onClick={() => history.push('/')}
                  >
                    Cancel
                  </Button>
                  <Button
                    bg="#EB5164"
                    size="lg"
                    m="0"
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    type="submit"
                  >
                    Cancel subscription
                  </Button>
                </Flex>
              </VStack>
            </Form>
          )}
        </Formik>
      </Flex>
    </Container>
  )
}

export default CancelSubscription
