import React from 'react'
import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  Input,
  Textarea,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon } from '@blueprinthq/joy'
import { TrashIcon } from '@icons'
import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import { FormikErrors, FormikTouched } from 'formik'
import { useExperienceManager } from '@hooks'

const SubtractIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6666 8.66665H3.33325V7.33331H12.6666V8.66665Z"
      fill="#282828"
    />
  </svg>
)

const PlusIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66659 12.6667V8.66669H12.6666V7.33335H8.66659V3.33335H7.33325V7.33335H3.33325V8.66669H7.33325V12.6667H8.66659Z"
      fill="#282828"
    />
  </svg>
)

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Template name is required'),
  sections: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Section name is required'),
        sampleOutputs: Yup.array().of(Yup.string().required('Sample output is required'))
      })
    )
    .min(1, 'At least one section is required')
})

export interface SectionType {
  name: string
  id: string
  sampleOutputs: string[]
  instructions?: string
}

export interface FormValues {
  name: string
  sections: SectionType[]
}

interface ProgressNoteTemplateFormProps {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  submitButtonText?: string;
}

export const ProgressNoteTemplateForm: React.FC<ProgressNoteTemplateFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  submitButtonText = 'Save template'
}) => {
  const [minimizedSections, setMinimizedSections] = React.useState<Set<string>>(new Set())
  const { showCustomTemplateInstructions } = useExperienceManager()
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => {
        const formErrors = errors as FormikErrors<FormValues>
        const formTouched = touched as FormikTouched<FormValues>

        return (
          <Form style={{ width: '100%' }}>
            <Stack flex="1" gap="32px" spacing="0px">
              <HStack
                flex="1"
                justifyContent="space-between"
                spacing="0"
                gap="32px"
              >
                <Stack flex="1" gap="16px">
                  <FormControl
                    isInvalid={Boolean(formTouched.name && formErrors.name)}
                  >
                    <FormLabel>
                      Template Name
                    </FormLabel>
                    <Input
                      placeholder="Template name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      width="100%"
                    />
                  </FormControl>
                  <Text color="dark_gray">Add template description</Text>
                </Stack>
                <HStack spacing="0px" gap="8px" minW="352px" justifyContent="flex-end">
                  <Button 
                    m="0px" 
                    w="100px" 
                    variant="outline" 
                    type="button"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    m="0px"
                    w="155px"
                    colorScheme="primary"
                    type="submit"
                  >
                    {submitButtonText}
                  </Button>
                </HStack>
              </HStack>
              <Flex
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
                justifyContent="space-between"
                alignItems="flex-start"
                gap="32px"
              >
                <Stack flex="1" spacing="24px" w="100%">
                  <FieldArray name="sections">
                    {({ push, remove, move }) => (
                      <>
                        {values.sections.map((section, index) => (
                          <Stack
                            key={section.id}
                            spacing="0px"
                            border="1px solid"
                            borderColor="pale_gray"
                            borderRadius="8px"
                            bg="rgba(249, 249, 249, 1)"
                            p="16px"
                            gap="16px"
                          >
                            <HStack
                              spacing="0"
                              justifyContent="space-between"
                              flex="1"
                            >
                              <Text>
                                {minimizedSections.has(section.id) &&
                                section.name
                                  ? section.name
                                  : 'Section'}
                              </Text>
                              <Button
                                variant="ghost"
                                p="0"
                                minW="0"
                                height="auto"
                                onClick={() => {
                                  setMinimizedSections(prev => {
                                    const next = new Set(prev)
                                    if (next.has(section.id)) {
                                      next.delete(section.id)
                                    } else {
                                      next.add(section.id)
                                    }
                                    return next
                                  })
                                }}
                                _focus={{
                                  outline: 'none'
                                }}
                              >
                                {minimizedSections.has(section.id) ? (
                                  <PlusIcon />
                                ) : (
                                  <SubtractIcon />
                                )}
                              </Button>
                            </HStack>
                            {!minimizedSections.has(section.id) && (
                              <>
                                <FormControl
                                  isInvalid={Boolean(
                                    // @ts-ignore
                                    formTouched.sections?.[index]?.name &&
                                      // @ts-ignore
                                      formErrors.sections?.[index]?.name
                                  )}
                                >
                                  <Input
                                    bg="white"
                                    placeholder="Add section name"
                                    name={`sections.${index}.name`}
                                    onChange={handleChange}
                                    value={section.name}
                                  />
                                </FormControl>
                                {section.instructions && showCustomTemplateInstructions && (
                                  <>
                                    <Text fontWeight="bold">Instructions</Text>
                                    <Box
                                      bg="white"
                                      p="12px"
                                      borderRadius="8px"
                                      border="1px solid"
                                      borderColor="light_gray"
                                    >
                                      <Text fontSize="sm" whiteSpace="pre-wrap">{section.instructions}</Text>
                                    </Box>
                                  </>
                                )}
                                <FormControl
                                  isInvalid={Boolean(
                                    // @ts-ignore
                                    formTouched.sections?.[index]?.sampleOutputs?.[0] &&
                                      // @ts-ignore
                                      formErrors.sections?.[index]?.sampleOutputs?.[0]
                                  )}
                                >
                                  <Textarea
                                    bg="white"
                                    placeholder="Add content"
                                    borderColor="light_gray"
                                    name={`sections.${index}.sampleOutputs[0]`}
                                    onChange={handleChange}
                                    value={section.sampleOutputs[0]}
                                  />
                                </FormControl>
                              </>
                            )}
                            {!minimizedSections.has(section.id) && (
                              <HStack justifyContent="space-between">
                                <HStack>
                                  <Button
                                    variant="ghost"
                                    p="0"
                                    minW="0"
                                    height="auto"
                                    onClick={() =>
                                      index > 0 && move(index, index - 1)
                                    }
                                    _focus={{ outline: 'none' }}
                                    isDisabled={
                                      index === 0 ||
                                      values.sections.length <= 1
                                    }
                                  >
                                    <ArrowUpIcon />
                                  </Button>
                                  <Button
                                    variant="ghost"
                                    p="0"
                                    minW="0"
                                    height="auto"
                                    onClick={() =>
                                      index < values.sections.length - 1 &&
                                      move(index, index + 1)
                                    }
                                    _focus={{ outline: 'none' }}
                                    isDisabled={
                                      index === values.sections.length - 1 ||
                                      values.sections.length <= 1
                                    }
                                  >
                                    <ArrowDownIcon />
                                  </Button>
                                </HStack>
                                <Button
                                  variant="ghost"
                                  p="0"
                                  minW="0"
                                  height="auto"
                                  onClick={() => {
                                    if (values.sections.length > 1) {
                                      remove(index)
                                    }
                                  }}
                                  _focus={{ outline: 'none' }}
                                >
                                  <TrashIcon />
                                </Button>
                              </HStack>
                            )}
                          </Stack>
                        ))}
                        <Button
                          m="0"
                          colorScheme="primary"
                          onClick={() =>
                            push({
                              name: '',
                              id: crypto.randomUUID(),
                              sampleOutputs: ['']
                            })
                          }
                          maxW={{
                            base: 'auto',
                            sm: 'auto',
                            md: '162px'
                          }}
                        >
                          + Add Section
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </Stack>
                <Stack w="352px" alignItems="flex-start">
                  <Stack
                    w="100%"
                    spacing="0"
                    border="1px solid"
                    borderColor="pale_gray"
                    borderRadius="8px"
                    bg="rgba(249, 249, 249, 1)"
                    p="16px"
                    gap="16px"
                  >
                    <Text fontWeight="bold">Memory</Text>
                    <Text color="dark_gray" textAlign="center">
                      Will learn your style and preferences as you use this
                      template.
                    </Text>
                    <Button
                      textDecoration="none"
                      color="primary"
                      variant="link"
                    >
                      Learn more
                    </Button>
                  </Stack>
                </Stack>
              </Flex>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ProgressNoteTemplateForm