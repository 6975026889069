import React, { useEffect } from 'react'
import {
  Text,
  Flex,
  Stack,
  Container,
  useToast,
  useDisclosure,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Divider
} from '@chakra-ui/react'
import {
  PlanSelection,
  Step,
  Summary,
  CreditCardForm,
  SavedPaymentMethod,
  BeforeYouDowngradeModal
} from './components'
import {
  useClinicControllerGetCurrentPlan,
  useClinicControllerGetPaymentMethods,
  useClinicControllerGetPlanOptions,
  useClinicControllerChangePlan,
  useSessionControllerInitiateSessionPrepGeneration,
  getClinicControllerGetCurrentPlanQueryKey
} from '~/clinician-api'
import { CurrentPlanResponseDto, PlanOptionDto } from '~/clinician-api/models'
import { groupBy } from 'lodash'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement
} from '@stripe/react-stripe-js'
import { UnassignOption, Plan } from './types'
import { loadStripe } from '@stripe/stripe-js'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { endpoints } from '@api'
import { useQueryClient } from 'react-query'
import { formatPrice } from './utils'
import { useExperienceManager } from '@hooks'
import { ChevronDown } from '@components/icons'

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

interface Props {
  selectedClinic: {
    id: string
  }
}

interface UpdateYourPlanProps extends Props {
  planOptions: PlanOptionDto[]
  currentPlan: CurrentPlanResponseDto
  initialPlan: Plan | undefined
  initialPriceId: string | undefined
  redirectPath?: string | undefined | null
  prepGenerationSessionId?: string | undefined | null
}

const UpdateYourPlan = ({
  selectedClinic,
  currentPlan,
  planOptions,
  initialPlan,
  initialPriceId,
  redirectPath,
  prepGenerationSessionId
}: UpdateYourPlanProps) => {
  const queryClient = useQueryClient()
  const groupedTiers = groupBy(planOptions, 'planType')
  const [selectedPlan, setSelectedPlan] = React.useState<Plan | undefined>(
    initialPlan || (currentPlan.planType as Plan)
  )
  const [selectedTier, setSelectedTier] = React.useState<string | null>(
    initialPriceId || currentPlan.priceId
  )

  const [selectedPaymentMethodId, setPaymentMethodId] = React.useState<
    string | null
  >(null)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isPaymentMethodComplete, setIsPaymentMethodComplete] = React.useState<
    boolean
  >(false)
  const [
    downgradeOption,
    setDowngradeOption
  ] = React.useState<UnassignOption | null>(null)

  const [showAllTiers, setShowAllTiers] = React.useState<boolean>(false)

  const history = useHistory()

  const {
    isOpen: isDowngradeModalOpen,
    onOpen: openDowngradeModal,
    onClose: closeDowngradeModal
  } = useDisclosure()

  const stripe = useStripe()
  const elements = useElements()

  const { mutate: changePlan } = useClinicControllerChangePlan()

  const toast = useToast()

  const clinicId = selectedClinic.id

  const {
    data: paymentMethods = [],
    isLoading: isPaymentMethodsLoading
  } = useClinicControllerGetPaymentMethods(clinicId)

  const {
    mutateAsync: initiateSessionPrepGeneration
  } = useSessionControllerInitiateSessionPrepGeneration()

  const defaultPaymentMethod = paymentMethods.find(p => p.isDefault)

  useEffect(() => {
    if (defaultPaymentMethod) {
      setPaymentMethodId(defaultPaymentMethod.id)
    }
  }, [defaultPaymentMethod])

  useEffect(() => {
    if (initialPriceId) {
      setSelectedTier(initialPriceId)
    }
  }, [initialPriceId])

  useEffect(() => {
    if (initialPlan) {
      setSelectedPlan(initialPlan)
    }
  }, [initialPlan])

  const handleSelectPlan = (plan: Plan) => {
    setSelectedPlan(plan)
    const currentSessionCount = planOptions.find(
      p => p.priceId === selectedTier
    )?.sessionCount

    const matchingTier = planOptions.find(
      p => p.planType === plan && p.sessionCount === currentSessionCount
    )?.priceId

    setSelectedTier(matchingTier || null)
  }

  const handleSubmit = async () => {
    if (selectedTier && currentPlan) {
      if (
        currentPlan.assignedPatientCount > 0 &&
        currentPlan.planType === 'plus' &&
        selectedPlan === 'standard' &&
        !downgradeOption
      ) {
        openDowngradeModal()
        return
      }

      setIsSubmitting(true)

      let paymentMethodId = selectedPaymentMethodId

      if (!paymentMethodId) {
        paymentMethodId = await createStripePaymentMethod()
      }

      if (paymentMethodId) {
        changePlan(
          {
            clinicId: clinicId,
            // @ts-ignore
            data: {
              priceId: selectedTier,
              paymentMethodId,
              unassignMeasures: downgradeOption === UnassignOption.UnassignAll
            }
          },
          {
            onSuccess: async () => {
              toast({
                title: 'Plan Updated',
                status: 'success',
                isClosable: true,
                duration: 1200
              })

              queryClient.invalidateQueries(
                getClinicControllerGetCurrentPlanQueryKey(clinicId)
              )

              queryClient.invalidateQueries(
                endpoints.getPaywallValidation.getCacheId()
              )

              if (prepGenerationSessionId && selectedPlan === 'plus') {
                await initiateSessionPrepGeneration({
                  id: prepGenerationSessionId
                })
              }

              setIsSubmitting(false)

              if (redirectPath) {
                history.replace(redirectPath)
              } else {
                history.replace('/settings/billing')
              }
            },
            onError: (error: any) => {
              setIsSubmitting(false)
              toast({
                title: error?.error?.message || 'Error updating plan',
                status: 'error',
                isClosable: true,
                duration: 3000
              })
            }
          }
        )
      }
    }
  }

  const tierOptions = selectedPlan
    ? (groupedTiers[selectedPlan] || []).map(plan => ({
        value: plan.priceId,
        display: `${plan.sessionCount} sessions per month - ${formatPrice(
          plan.priceCents
        )}`,
        sessionCount: plan.sessionCount
      }))
    : []

  const createStripePaymentMethod = async (): Promise<string | null> => {
    const cardElement = elements?.getElement(CardNumberElement)

    if (!cardElement) {
      console.error('Card Element not available')
    }

    const {
      // @ts-ignore
      paymentMethod,
      // @ts-ignore
      paymentMethodError
      // @ts-ignore
    } = await stripe?.createPaymentMethod({ type: 'card', card: cardElement })

    if (paymentMethodError) {
      toast({
        title: 'Error saving credit card. Please Try again.',
        status: 'error',
        isClosable: true,
        duration: 3000
      })
      setIsSubmitting(false)
      return null
    }
    return paymentMethod.id as string
  }

  const handleCloseDowngradeModal = () => {
    setDowngradeOption(null)
    closeDowngradeModal()
  }

  const handleConfirmDowngrade = () => {
    handleSubmit()
    closeDowngradeModal()
  }

  if (isPaymentMethodsLoading) return null

  return (
    <Container maxWidth="none" overflowY="hidden">
      <Flex
        flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
        flex="1"
        gap={{
          base: '32px',
          sm: '32px'
        }}
      >
        <Flex
          flex="1"
          justifyContent={{
            md: 'center'
          }}
          h={{ base: 'auto', sm: 'auto', md: 'calc(100vh - 80px)' }}
          overflowY={{ base: 'auto', sm: 'auto', md: 'scroll' }}
          overflow={{ base: 'visible', sm: 'visible', md: 'auto' }}
        >
          <Stack
            gap="32px"
            w="100%"
            mr={{ md: '32px' }}
            maxW={{ base: 'auto', sm: 'auto', md: '672px' }}
            spacing="0"
            flex={{ base: '1', sm: '1', md: '1 1 672px' }}
            mt="32px"
          >
            <Text
              fontSize="24px"
              fontWeight="bold"
              display="flex"
              h="32px"
              alignItems="center"
            >
              Update Your Plan
            </Text>
            <Step number={1} title="Choose plan">
              <PlanSelection
                selectedPlan={selectedPlan}
                setSelectedPlan={handleSelectPlan}
                currentPlan={currentPlan}
              />
            </Step>
            <Step number={2} title="Choose session tier">
              <Box position="relative">
                <Menu placement="bottom" matchWidth onClose={() => setShowAllTiers(false)}>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDown />}
                    width="100%"
                    h="56px"
                    borderWidth="1px"
                    borderColor="pale_gray"
                    borderRadius="4px !important"
                    bg="white"
                    color="#282828"
                    fontWeight="normal"
                    textAlign="left"
                    justifyContent="space-between"
                    _hover={{ bg: "gray.50" }}
                  > 
                    {selectedTier
                      ? tierOptions.find(option => option.value === selectedTier)?.display
                      : "Choose session tier"}
                  </MenuButton>
                  <MenuList width="100%" maxH="300px" overflowY="auto">
                    {tierOptions
                      .filter(option => {
                        if (!showAllTiers && option.sessionCount > 300 && option.value !== selectedTier) { 
                          return false
                        }
                        return true
                      })
                      .map(option => (
                        <MenuItem
                          key={option.value}
                          onClick={() => setSelectedTier(option.value)}
                          isDisabled={option.value === currentPlan?.priceId}
                          bg={selectedTier === option.value ? "gray.100" : "white"}
                          _hover={{ bg: "gray.50" }}
                        >
                          {option.display}
                        </MenuItem>
                      ))}
                    
                    {!showAllTiers && (
                      <Box
                        as="button"
                        width="100%"
                        height="40px"
                        px="12px"
                        display="flex"
                        alignItems="center"
                        textAlign="left"
                        color="primary"
                        fontWeight="500"
                        _hover={{ bg: "gray.50" }}
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          setShowAllTiers(true);
                        }}
                      >
                        Show all tiers
                      </Box>
                    )}
                  </MenuList>
                </Menu>
              </Box>
            </Step>
            <Step
              number={3}
              title="Enter payment details"
              paddingBottom={{ base: 0, sm: 0, md: '160px' }}
            >
              {selectedPaymentMethodId && defaultPaymentMethod ? (
                <SavedPaymentMethod
                  paymentMethod={defaultPaymentMethod}
                  onChange={() => setPaymentMethodId(null)}
                />
              ) : (
                <CreditCardForm
                  onComplete={(isComplete: boolean) =>
                    setIsPaymentMethodComplete(isComplete)
                  }
                  onRevertToSaved={
                    defaultPaymentMethod
                      ? () => setPaymentMethodId(defaultPaymentMethod.id)
                      : undefined
                  }
                />
              )}
            </Step>
          </Stack>
        </Flex>
        <Flex justifyContent="flex-end">
          <Summary
            clinicId={clinicId}
            priceId={selectedTier}
            currentPlan={currentPlan}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isValid={
              !!(
                selectedTier &&
                (!!selectedPaymentMethodId || isPaymentMethodComplete) &&
                selectedTier !== currentPlan.priceId
              )
            }
          />
        </Flex>
      </Flex>
      {currentPlan && (
        <BeforeYouDowngradeModal
          isOpen={isDowngradeModalOpen}
          value={downgradeOption}
          onChange={setDowngradeOption}
          onClose={handleCloseDowngradeModal}
          onConfirm={handleConfirmDowngrade}
          isSubmitting={false}
        />
      )}
    </Container>
  )
}

const UpdateYourPlanContainer = (props: Props) => {
  const { search } = useLocation()
  const query = queryString.parse(search)

  const { data: currentPlan } = useClinicControllerGetCurrentPlan(
    props.selectedClinic.id
  )

  const { data: planOptions } = useClinicControllerGetPlanOptions(
    props.selectedClinic.id
  )

  const { isAdmin } = useExperienceManager()

  if (!isAdmin) {
    return <Redirect to="/sessions" />
  }

  const options = {
    fonts: [
      {
        family: 'TofinoPersonal',
        src:
          'url(https://res.cloudinary.com/hellojoy/raw/upload/v1631114007/Fonts/tofino-personal/TofinoPersonal-Regular.otf)'
      }
    ]
  }

  const redirectPath = query.redirectPath
  const prepGenerationSessionId = query.prepGenerationSessionId

  if (!planOptions || !currentPlan) return null

  const initialPlan = Object.values(Plan).includes(query.plan as Plan)
    ? (query.plan as Plan)
    : currentPlan.isFree
    ? undefined
    : (currentPlan?.planType as Plan) || undefined

  const initialPriceId = currentPlan.isFree
    ? undefined
    : query.sessionCount
    ? planOptions.find(
        o =>
          o.sessionCount === Number(query.sessionCount) &&
          o.planType === initialPlan
      )?.priceId
    : currentPlan.priceId

  return (
    <Elements stripe={stripePromise} options={options}>
      <UpdateYourPlan
        {...props}
        currentPlan={currentPlan}
        planOptions={planOptions}
        initialPlan={initialPlan}
        initialPriceId={initialPriceId}
        redirectPath={redirectPath as string}
        prepGenerationSessionId={prepGenerationSessionId as string}
      />
    </Elements>
  )
}

export default UpdateYourPlanContainer
