/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  AssignCheckInBodyDto,
  AssignCheckInDto,
  AssignedCheckInDto,
  AssistEnrollDto,
  CancelPlusPlanFreeTrialDto,
  ChangePlanBodyDto,
  ChangePlanPreviewResponseDto,
  CheckInBodyDto,
  CheckInDtoV2,
  CheckinsControllerV1GetAllCheckInsParams,
  ClearTemplateMemoryDto,
  ClientListDto,
  ClinicControllerChangePlanPreviewParams,
  ClinicControllerGetAssignedCheckInsParams,
  ClinicControllerGetCheckInsParams,
  ClinicControllerGetClientListParams,
  ClinicControllerSearchClinicsParams,
  ClinicSearchResultListDto,
  CompleteUploadDto,
  CompletedSessionDto,
  CompletedSessionDtoV2,
  ConsentSettingDTO,
  CreateCustomProgressNoteTemplateDTO,
  CreateSampleSessionDto,
  CurrentPlanResponseDto,
  CustomProgressNoteTemplateDTO,
  DischargeSummaryDto,
  GetExperimentDto,
  Intervention,
  InterventionsControllerV1GetAllInterventionsParams,
  MarkSessionCompleteBodyDto,
  MarkSessionCompleteBodyDtoV2,
  OnboardingControllerExperimentParams,
  OnboardingSurveyDto,
  OutboundEhrSettingsDTO,
  PatchConsentSettingDTO,
  PatchDischargeSummaryDto,
  PatchNoteDto,
  PatientCheckInScoresDto,
  PatientConsentDto,
  PatientControllerGetConsentParams,
  PatientControllerGetPatientsParams,
  PatientDto,
  PatientInterventionDto,
  PatientInterventionWithInterventionDto,
  PatientInterventionWithResponsesDto,
  PatientInterventionsControllerGetAllPatientInterventionsParams,
  PaymentMethodDto,
  PlanOptionDto,
  PostPatientInterventionDto,
  ProgressNoteTemplateMemoryControllerFindByNoteTypeParams,
  ProgressNoteTemplateMemoryResponseDto,
  PutPatientInterventionDto,
  SampleSessionDto,
  SaveForLaterBodyDto,
  SavedForLaterInterventionDto,
  SessionControllerGetDemoSuggestedContentParams,
  SessionControllerGetSessionPrepParams,
  SessionControllerGetSessionsListParams,
  SessionCreateBodyDto,
  SessionDraftDto,
  SessionDto,
  SessionFromUploadedAudioBodyDto,
  SessionFromUploadedAudioBodyDtoV2,
  SessionListResponseDto,
  SessionPatchDto,
  SessionPatchDtoV2,
  SessionPlannedInterventionsBodyDto,
  SessionPrepDto,
  SessionProgressNotesDto,
  SessionPsychotherapyNoteBodyDto,
  SessionRecapResponse,
  SetReminderDto,
  StartInterventionsBodyDto,
  StartUploadDto,
  SubmitFeedbackDto,
  SuggestedContentDTO,
  TrackEventDto,
  UpdateCheckInNotificationDto,
  ValidateEmailDto
} from './models'
import { hellojoyApiInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';



export const clinicControllerGetClientList = (
    clinicId: string,
    params?: ClinicControllerGetClientListParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<ClientListDto>(
      {url: `/v1/clinician-api/clinic/${clinicId}/client-list`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getClinicControllerGetClientListQueryKey = (clinicId: string,
    params?: ClinicControllerGetClientListParams,) => {
    return [`/v1/clinician-api/clinic/${clinicId}/client-list`, ...(params ? [params]: [])] as const;
    }

    
export const getClinicControllerGetClientListQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError = ErrorType<void>>(clinicId: string,
    params?: ClinicControllerGetClientListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerGetClientListQueryKey(clinicId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetClientList>>> = ({ signal }) => clinicControllerGetClientList(clinicId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerGetClientListQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetClientList>>>
export type ClinicControllerGetClientListQueryError = ErrorType<void>

export const useClinicControllerGetClientList = <TData = Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetClientListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerGetClientListQueryOptions(clinicId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const clinicControllerGetCheckIns = (
    clinicId: string,
    params?: ClinicControllerGetCheckInsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getClinicControllerGetCheckInsQueryKey = (clinicId: string,
    params?: ClinicControllerGetCheckInsParams,) => {
    return [`/v1/clinician-api/clinic/${clinicId}/library/check-ins`, ...(params ? [params]: [])] as const;
    }

    
export const getClinicControllerGetCheckInsQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError = ErrorType<void>>(clinicId: string,
    params?: ClinicControllerGetCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerGetCheckInsQueryKey(clinicId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>> = ({ signal }) => clinicControllerGetCheckIns(clinicId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerGetCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>>
export type ClinicControllerGetCheckInsQueryError = ErrorType<void>

export const useClinicControllerGetCheckIns = <TData = Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerGetCheckInsQueryOptions(clinicId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const clinicControllerGetAssignedCheckIns = (
    clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins/assigned`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getClinicControllerGetAssignedCheckInsQueryKey = (clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams,) => {
    return [`/v1/clinician-api/clinic/${clinicId}/library/check-ins/assigned`, ...(params ? [params]: [])] as const;
    }

    
export const getClinicControllerGetAssignedCheckInsQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError = ErrorType<void>>(clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerGetAssignedCheckInsQueryKey(clinicId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>> = ({ signal }) => clinicControllerGetAssignedCheckIns(clinicId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerGetAssignedCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>>
export type ClinicControllerGetAssignedCheckInsQueryError = ErrorType<void>

export const useClinicControllerGetAssignedCheckIns = <TData = Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerGetAssignedCheckInsQueryOptions(clinicId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const clinicControllerToggleCheckInDefault = (
    clinicId: string,
    checkInId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins/${checkInId}/toggle-default`, method: 'PUT'
    },
      );
    }
  


export const getClinicControllerToggleCheckInDefaultMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, TError,{clinicId: string;checkInId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, TError,{clinicId: string;checkInId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, {clinicId: string;checkInId: string}> = (props) => {
          const {clinicId,checkInId} = props ?? {};

          return  clinicControllerToggleCheckInDefault(clinicId,checkInId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ClinicControllerToggleCheckInDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>>
    
    export type ClinicControllerToggleCheckInDefaultMutationError = ErrorType<void>

    export const useClinicControllerToggleCheckInDefault = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, TError,{clinicId: string;checkInId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>,
        TError,
        {clinicId: string;checkInId: string},
        TContext
      > => {

      const mutationOptions = getClinicControllerToggleCheckInDefaultMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const clinicControllerAssignCheckIn = (
    clinicId: string,
    assignCheckInBodyDto: AssignCheckInBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/assign`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assignCheckInBodyDto
    },
      );
    }
  


export const getClinicControllerAssignCheckInMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, {clinicId: string;data: AssignCheckInBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerAssignCheckIn(clinicId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ClinicControllerAssignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>>
    export type ClinicControllerAssignCheckInMutationBody = AssignCheckInBodyDto
    export type ClinicControllerAssignCheckInMutationError = ErrorType<void>

    export const useClinicControllerAssignCheckIn = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>,
        TError,
        {clinicId: string;data: AssignCheckInBodyDto},
        TContext
      > => {

      const mutationOptions = getClinicControllerAssignCheckInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const clinicControllerUnassignCheckIn = (
    clinicId: string,
    assignCheckInBodyDto: AssignCheckInBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/unassign`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assignCheckInBodyDto
    },
      );
    }
  


export const getClinicControllerUnassignCheckInMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, {clinicId: string;data: AssignCheckInBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerUnassignCheckIn(clinicId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ClinicControllerUnassignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>>
    export type ClinicControllerUnassignCheckInMutationBody = AssignCheckInBodyDto
    export type ClinicControllerUnassignCheckInMutationError = ErrorType<void>

    export const useClinicControllerUnassignCheckIn = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>,
        TError,
        {clinicId: string;data: AssignCheckInBodyDto},
        TContext
      > => {

      const mutationOptions = getClinicControllerUnassignCheckInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const clinicControllerGetPaymentMethods = (
    clinicId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PaymentMethodDto[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/payment-methods`, method: 'GET', signal
    },
      );
    }
  

export const getClinicControllerGetPaymentMethodsQueryKey = (clinicId: string,) => {
    return [`/v1/clinician-api/clinic/${clinicId}/payment-methods`] as const;
    }

    
export const getClinicControllerGetPaymentMethodsQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError = ErrorType<void>>(clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerGetPaymentMethodsQueryKey(clinicId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>> = ({ signal }) => clinicControllerGetPaymentMethods(clinicId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerGetPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>>
export type ClinicControllerGetPaymentMethodsQueryError = ErrorType<void>

export const useClinicControllerGetPaymentMethods = <TData = Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError = ErrorType<void>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerGetPaymentMethodsQueryOptions(clinicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const clinicControllerGetPlanOptions = (
    clinicId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PlanOptionDto[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/plan-options`, method: 'GET', signal
    },
      );
    }
  

export const getClinicControllerGetPlanOptionsQueryKey = (clinicId: string,) => {
    return [`/v1/clinician-api/clinic/${clinicId}/plan-options`] as const;
    }

    
export const getClinicControllerGetPlanOptionsQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError = ErrorType<void>>(clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerGetPlanOptionsQueryKey(clinicId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>> = ({ signal }) => clinicControllerGetPlanOptions(clinicId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerGetPlanOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>>
export type ClinicControllerGetPlanOptionsQueryError = ErrorType<void>

export const useClinicControllerGetPlanOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError = ErrorType<void>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerGetPlanOptionsQueryOptions(clinicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const clinicControllerGetCurrentPlan = (
    clinicId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CurrentPlanResponseDto>(
      {url: `/v1/clinician-api/clinic/${clinicId}/current-plan`, method: 'GET', signal
    },
      );
    }
  

export const getClinicControllerGetCurrentPlanQueryKey = (clinicId: string,) => {
    return [`/v1/clinician-api/clinic/${clinicId}/current-plan`] as const;
    }

    
export const getClinicControllerGetCurrentPlanQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError = ErrorType<void>>(clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerGetCurrentPlanQueryKey(clinicId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>> = ({ signal }) => clinicControllerGetCurrentPlan(clinicId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerGetCurrentPlanQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>>
export type ClinicControllerGetCurrentPlanQueryError = ErrorType<void>

export const useClinicControllerGetCurrentPlan = <TData = Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError = ErrorType<void>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerGetCurrentPlanQueryOptions(clinicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const clinicControllerChangePlanPreview = (
    clinicId: string,
    params: ClinicControllerChangePlanPreviewParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<ChangePlanPreviewResponseDto>(
      {url: `/v1/clinician-api/clinic/${clinicId}/change-plan-preview`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getClinicControllerChangePlanPreviewQueryKey = (clinicId: string,
    params: ClinicControllerChangePlanPreviewParams,) => {
    return [`/v1/clinician-api/clinic/${clinicId}/change-plan-preview`, ...(params ? [params]: [])] as const;
    }

    
export const getClinicControllerChangePlanPreviewQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError = ErrorType<void>>(clinicId: string,
    params: ClinicControllerChangePlanPreviewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerChangePlanPreviewQueryKey(clinicId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>> = ({ signal }) => clinicControllerChangePlanPreview(clinicId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerChangePlanPreviewQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>>
export type ClinicControllerChangePlanPreviewQueryError = ErrorType<void>

export const useClinicControllerChangePlanPreview = <TData = Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError = ErrorType<void>>(
 clinicId: string,
    params: ClinicControllerChangePlanPreviewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerChangePlanPreviewQueryOptions(clinicId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const clinicControllerChangePlan = (
    clinicId: string,
    changePlanBodyDto: ChangePlanBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/change-plan`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePlanBodyDto
    },
      );
    }
  


export const getClinicControllerChangePlanMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerChangePlan>>, TError,{clinicId: string;data: ChangePlanBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof clinicControllerChangePlan>>, TError,{clinicId: string;data: ChangePlanBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerChangePlan>>, {clinicId: string;data: ChangePlanBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerChangePlan(clinicId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ClinicControllerChangePlanMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerChangePlan>>>
    export type ClinicControllerChangePlanMutationBody = ChangePlanBodyDto
    export type ClinicControllerChangePlanMutationError = ErrorType<void>

    export const useClinicControllerChangePlan = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerChangePlan>>, TError,{clinicId: string;data: ChangePlanBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof clinicControllerChangePlan>>,
        TError,
        {clinicId: string;data: ChangePlanBodyDto},
        TContext
      > => {

      const mutationOptions = getClinicControllerChangePlanMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const clinicControllerCancelPlusPlanFreeTrial = (
    clinicId: string,
    cancelPlusPlanFreeTrialDto: CancelPlusPlanFreeTrialDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/cancel-plus-plan-free-trial`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cancelPlusPlanFreeTrialDto
    },
      );
    }
  


export const getClinicControllerCancelPlusPlanFreeTrialMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>, {clinicId: string;data: CancelPlusPlanFreeTrialDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerCancelPlusPlanFreeTrial(clinicId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ClinicControllerCancelPlusPlanFreeTrialMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>>
    export type ClinicControllerCancelPlusPlanFreeTrialMutationBody = CancelPlusPlanFreeTrialDto
    export type ClinicControllerCancelPlusPlanFreeTrialMutationError = ErrorType<void>

    export const useClinicControllerCancelPlusPlanFreeTrial = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>,
        TError,
        {clinicId: string;data: CancelPlusPlanFreeTrialDto},
        TContext
      > => {

      const mutationOptions = getClinicControllerCancelPlusPlanFreeTrialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const clinicControllerStartPlusPlanFreeTrial = (
    clinicId: string,
    cancelPlusPlanFreeTrialDto: CancelPlusPlanFreeTrialDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/start-plus-plan-free-trial`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cancelPlusPlanFreeTrialDto
    },
      );
    }
  


export const getClinicControllerStartPlusPlanFreeTrialMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>, {clinicId: string;data: CancelPlusPlanFreeTrialDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerStartPlusPlanFreeTrial(clinicId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ClinicControllerStartPlusPlanFreeTrialMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>>
    export type ClinicControllerStartPlusPlanFreeTrialMutationBody = CancelPlusPlanFreeTrialDto
    export type ClinicControllerStartPlusPlanFreeTrialMutationError = ErrorType<void>

    export const useClinicControllerStartPlusPlanFreeTrial = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>,
        TError,
        {clinicId: string;data: CancelPlusPlanFreeTrialDto},
        TContext
      > => {

      const mutationOptions = getClinicControllerStartPlusPlanFreeTrialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const clinicControllerSearchClinics = (
    params: ClinicControllerSearchClinicsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<ClinicSearchResultListDto>(
      {url: `/v1/clinician-api/clinic/search`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getClinicControllerSearchClinicsQueryKey = (params: ClinicControllerSearchClinicsParams,) => {
    return [`/v1/clinician-api/clinic/search`, ...(params ? [params]: [])] as const;
    }

    
export const getClinicControllerSearchClinicsQueryOptions = <TData = Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError = ErrorType<void>>(params: ClinicControllerSearchClinicsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClinicControllerSearchClinicsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerSearchClinics>>> = ({ signal }) => clinicControllerSearchClinics(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError, TData> & { queryKey: QueryKey }
}

export type ClinicControllerSearchClinicsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerSearchClinics>>>
export type ClinicControllerSearchClinicsQueryError = ErrorType<void>

export const useClinicControllerSearchClinics = <TData = Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError = ErrorType<void>>(
 params: ClinicControllerSearchClinicsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClinicControllerSearchClinicsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerGetSessionsList = (
    params?: SessionControllerGetSessionsListParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SessionListResponseDto>(
      {url: `/v1/clinician-api/session`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSessionControllerGetSessionsListQueryKey = (params?: SessionControllerGetSessionsListParams,) => {
    return [`/v1/clinician-api/session`, ...(params ? [params]: [])] as const;
    }

    
export const getSessionControllerGetSessionsListQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError = ErrorType<void>>(params?: SessionControllerGetSessionsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>> = ({ signal }) => sessionControllerGetSessionsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionsListQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>>
export type SessionControllerGetSessionsListQueryError = ErrorType<void>

export const useSessionControllerGetSessionsList = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError = ErrorType<void>>(
 params?: SessionControllerGetSessionsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetSessionsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerPostSession = (
    sessionCreateBodyDto: SessionCreateBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<SessionDraftDto>(
      {url: `/v1/clinician-api/session`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sessionCreateBodyDto
    },
      );
    }
  


export const getSessionControllerPostSessionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostSession>>, TError,{data: SessionCreateBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostSession>>, TError,{data: SessionCreateBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostSession>>, {data: SessionCreateBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerPostSession(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPostSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostSession>>>
    export type SessionControllerPostSessionMutationBody = SessionCreateBodyDto
    export type SessionControllerPostSessionMutationError = ErrorType<void>

    export const useSessionControllerPostSession = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostSession>>, TError,{data: SessionCreateBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPostSession>>,
        TError,
        {data: SessionCreateBodyDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerPostSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetCompletedSessionDetails = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CompletedSessionDto>(
      {url: `/v1/clinician-api/session/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getSessionControllerGetCompletedSessionDetailsQueryKey = (id: string,) => {
    return [`/v1/clinician-api/session/${id}`] as const;
    }

    
export const getSessionControllerGetCompletedSessionDetailsQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError = ErrorType<void>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetCompletedSessionDetailsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>> = ({ signal }) => sessionControllerGetCompletedSessionDetails(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetCompletedSessionDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>>
export type SessionControllerGetCompletedSessionDetailsQueryError = ErrorType<void>

export const useSessionControllerGetCompletedSessionDetails = <TData = Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetCompletedSessionDetailsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerDeleteSession = (
    id: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getSessionControllerDeleteSessionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerDeleteSession(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerDeleteSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerDeleteSession>>>
    
    export type SessionControllerDeleteSessionMutationError = ErrorType<void>

    export const useSessionControllerDeleteSession = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerDeleteSession>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerDeleteSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerPatchSession = (
    id: string,
    sessionPatchDto: SessionPatchDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sessionPatchDto
    },
      );
    }
  


export const getSessionControllerPatchSessionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSession>>, TError,{id: string;data: SessionPatchDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSession>>, TError,{id: string;data: SessionPatchDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPatchSession>>, {id: string;data: SessionPatchDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPatchSession(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPatchSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPatchSession>>>
    export type SessionControllerPatchSessionMutationBody = SessionPatchDto
    export type SessionControllerPatchSessionMutationError = ErrorType<void>

    export const useSessionControllerPatchSession = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSession>>, TError,{id: string;data: SessionPatchDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPatchSession>>,
        TError,
        {id: string;data: SessionPatchDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerPatchSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetCompletedSessionDetailsV2 = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CompletedSessionDtoV2>(
      {url: `/v2/clinician-api/session/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getSessionControllerGetCompletedSessionDetailsV2QueryKey = (id: string,) => {
    return [`/v2/clinician-api/session/${id}`] as const;
    }

    
export const getSessionControllerGetCompletedSessionDetailsV2QueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetailsV2>>, TError = ErrorType<void>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetailsV2>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetCompletedSessionDetailsV2QueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetailsV2>>> = ({ signal }) => sessionControllerGetCompletedSessionDetailsV2(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetailsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetCompletedSessionDetailsV2QueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetailsV2>>>
export type SessionControllerGetCompletedSessionDetailsV2QueryError = ErrorType<void>

export const useSessionControllerGetCompletedSessionDetailsV2 = <TData = Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetailsV2>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetailsV2>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetCompletedSessionDetailsV2QueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerPatchSessionV2 = (
    id: string,
    sessionPatchDtoV2: SessionPatchDtoV2,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v2/clinician-api/session/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sessionPatchDtoV2
    },
      );
    }
  


export const getSessionControllerPatchSessionV2MutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSessionV2>>, TError,{id: string;data: SessionPatchDtoV2}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSessionV2>>, TError,{id: string;data: SessionPatchDtoV2}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPatchSessionV2>>, {id: string;data: SessionPatchDtoV2}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPatchSessionV2(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPatchSessionV2MutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPatchSessionV2>>>
    export type SessionControllerPatchSessionV2MutationBody = SessionPatchDtoV2
    export type SessionControllerPatchSessionV2MutationError = ErrorType<void>

    export const useSessionControllerPatchSessionV2 = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSessionV2>>, TError,{id: string;data: SessionPatchDtoV2}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPatchSessionV2>>,
        TError,
        {id: string;data: SessionPatchDtoV2},
        TContext
      > => {

      const mutationOptions = getSessionControllerPatchSessionV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetProgressNotesForSession = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SessionProgressNotesDto>(
      {url: `/v1/clinician-api/session/${id}/progress-notes`, method: 'GET', signal
    },
      );
    }
  

export const getSessionControllerGetProgressNotesForSessionQueryKey = (id: string,) => {
    return [`/v1/clinician-api/session/${id}/progress-notes`] as const;
    }

    
export const getSessionControllerGetProgressNotesForSessionQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError = ErrorType<void>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetProgressNotesForSessionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>> = ({ signal }) => sessionControllerGetProgressNotesForSession(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetProgressNotesForSessionQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>>
export type SessionControllerGetProgressNotesForSessionQueryError = ErrorType<void>

export const useSessionControllerGetProgressNotesForSession = <TData = Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetProgressNotesForSessionQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerPatchProgressNote = (
    id: string,
    noteId: string,
    patchNoteDto: PatchNoteDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/progress-notes/${noteId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchNoteDto
    },
      );
    }
  


export const getSessionControllerPatchProgressNoteMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, TError,{id: string;noteId: string;data: PatchNoteDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, TError,{id: string;noteId: string;data: PatchNoteDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, {id: string;noteId: string;data: PatchNoteDto}> = (props) => {
          const {id,noteId,data} = props ?? {};

          return  sessionControllerPatchProgressNote(id,noteId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPatchProgressNoteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>>
    export type SessionControllerPatchProgressNoteMutationBody = PatchNoteDto
    export type SessionControllerPatchProgressNoteMutationError = ErrorType<void>

    export const useSessionControllerPatchProgressNote = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, TError,{id: string;noteId: string;data: PatchNoteDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>,
        TError,
        {id: string;noteId: string;data: PatchNoteDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerPatchProgressNoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerStartInterventions = (
    id: string,
    startInterventionsBodyDto: StartInterventionsBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/start-interventions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: startInterventionsBodyDto
    },
      );
    }
  


export const getSessionControllerStartInterventionsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, TError,{id: string;data: StartInterventionsBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, TError,{id: string;data: StartInterventionsBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, {id: string;data: StartInterventionsBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerStartInterventions(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerStartInterventionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerStartInterventions>>>
    export type SessionControllerStartInterventionsMutationBody = StartInterventionsBodyDto
    export type SessionControllerStartInterventionsMutationError = ErrorType<void>

    export const useSessionControllerStartInterventions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, TError,{id: string;data: StartInterventionsBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerStartInterventions>>,
        TError,
        {id: string;data: StartInterventionsBodyDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerStartInterventionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetStartedInterventions = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PatientInterventionWithInterventionDto[]>(
      {url: `/v1/clinician-api/session/${id}/started-interventions`, method: 'GET', signal
    },
      );
    }
  

export const getSessionControllerGetStartedInterventionsQueryKey = (id: string,) => {
    return [`/v1/clinician-api/session/${id}/started-interventions`] as const;
    }

    
export const getSessionControllerGetStartedInterventionsQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError = ErrorType<void>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetStartedInterventionsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>> = ({ signal }) => sessionControllerGetStartedInterventions(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetStartedInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>>
export type SessionControllerGetStartedInterventionsQueryError = ErrorType<void>

export const useSessionControllerGetStartedInterventions = <TData = Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetStartedInterventionsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerDeleteIntervention = (
    id: string,
    patientInterventionId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/interventions/${patientInterventionId}`, method: 'DELETE'
    },
      );
    }
  


export const getSessionControllerDeleteInterventionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, TError,{id: string;patientInterventionId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, TError,{id: string;patientInterventionId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, {id: string;patientInterventionId: string}> = (props) => {
          const {id,patientInterventionId} = props ?? {};

          return  sessionControllerDeleteIntervention(id,patientInterventionId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerDeleteInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>>
    
    export type SessionControllerDeleteInterventionMutationError = ErrorType<void>

    export const useSessionControllerDeleteIntervention = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, TError,{id: string;patientInterventionId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>,
        TError,
        {id: string;patientInterventionId: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerDeleteInterventionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerUpdatePsychotherapyNote = (
    id: string,
    sessionPsychotherapyNoteBodyDto: SessionPsychotherapyNoteBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/psychotherapy-note`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sessionPsychotherapyNoteBodyDto
    },
      );
    }
  


export const getSessionControllerUpdatePsychotherapyNoteMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, TError,{id: string;data: SessionPsychotherapyNoteBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, TError,{id: string;data: SessionPsychotherapyNoteBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, {id: string;data: SessionPsychotherapyNoteBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerUpdatePsychotherapyNote(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerUpdatePsychotherapyNoteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>>
    export type SessionControllerUpdatePsychotherapyNoteMutationBody = SessionPsychotherapyNoteBodyDto
    export type SessionControllerUpdatePsychotherapyNoteMutationError = ErrorType<void>

    export const useSessionControllerUpdatePsychotherapyNote = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, TError,{id: string;data: SessionPsychotherapyNoteBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>,
        TError,
        {id: string;data: SessionPsychotherapyNoteBodyDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerUpdatePsychotherapyNoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetSessionRecap = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SessionRecapResponse>(
      {url: `/v1/clinician-api/session/${id}/recap`, method: 'GET', signal
    },
      );
    }
  

export const getSessionControllerGetSessionRecapQueryKey = (id: string,) => {
    return [`/v1/clinician-api/session/${id}/recap`] as const;
    }

    
export const getSessionControllerGetSessionRecapQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError = ErrorType<void>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionRecapQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>> = ({ signal }) => sessionControllerGetSessionRecap(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionRecapQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>>
export type SessionControllerGetSessionRecapQueryError = ErrorType<void>

export const useSessionControllerGetSessionRecap = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetSessionRecapQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerTrackEvent = (
    id: string,
    trackEventDto: TrackEventDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/track-event`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: trackEventDto
    },
      );
    }
  


export const getSessionControllerTrackEventMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, TError,{id: string;data: TrackEventDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, TError,{id: string;data: TrackEventDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, {id: string;data: TrackEventDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerTrackEvent(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerTrackEventMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerTrackEvent>>>
    export type SessionControllerTrackEventMutationBody = TrackEventDto
    export type SessionControllerTrackEventMutationError = ErrorType<void>

    export const useSessionControllerTrackEvent = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, TError,{id: string;data: TrackEventDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerTrackEvent>>,
        TError,
        {id: string;data: TrackEventDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerTrackEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerPostPlannedInterventions = (
    id: string,
    sessionPlannedInterventionsBodyDto: SessionPlannedInterventionsBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/planned-interventions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sessionPlannedInterventionsBodyDto
    },
      );
    }
  


export const getSessionControllerPostPlannedInterventionsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, TError,{id: string;data: SessionPlannedInterventionsBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, TError,{id: string;data: SessionPlannedInterventionsBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, {id: string;data: SessionPlannedInterventionsBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPostPlannedInterventions(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPostPlannedInterventionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>>
    export type SessionControllerPostPlannedInterventionsMutationBody = SessionPlannedInterventionsBodyDto
    export type SessionControllerPostPlannedInterventionsMutationError = ErrorType<void>

    export const useSessionControllerPostPlannedInterventions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, TError,{id: string;data: SessionPlannedInterventionsBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>,
        TError,
        {id: string;data: SessionPlannedInterventionsBodyDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerPostPlannedInterventionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerCreateSampleSession = (
    createSampleSessionDto: CreateSampleSessionDto,
 ) => {
      
      
      return hellojoyApiInstance<SampleSessionDto>(
      {url: `/v1/clinician-api/session/sample`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createSampleSessionDto
    },
      );
    }
  


export const getSessionControllerCreateSampleSessionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, TError,{data: CreateSampleSessionDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, TError,{data: CreateSampleSessionDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, {data: CreateSampleSessionDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCreateSampleSession(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerCreateSampleSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>>
    export type SessionControllerCreateSampleSessionMutationBody = CreateSampleSessionDto
    export type SessionControllerCreateSampleSessionMutationError = ErrorType<void>

    export const useSessionControllerCreateSampleSession = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, TError,{data: CreateSampleSessionDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>,
        TError,
        {data: CreateSampleSessionDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerCreateSampleSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerStartUpload = (
    startUploadDto: StartUploadDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/start-upload`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: startUploadDto
    },
      );
    }
  


export const getSessionControllerStartUploadMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartUpload>>, TError,{data: StartUploadDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartUpload>>, TError,{data: StartUploadDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerStartUpload>>, {data: StartUploadDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerStartUpload(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerStartUploadMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerStartUpload>>>
    export type SessionControllerStartUploadMutationBody = StartUploadDto
    export type SessionControllerStartUploadMutationError = ErrorType<void>

    export const useSessionControllerStartUpload = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartUpload>>, TError,{data: StartUploadDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerStartUpload>>,
        TError,
        {data: StartUploadDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerStartUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerCompleteUpload = (
    completeUploadDto: CompleteUploadDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/complete-upload`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: completeUploadDto
    },
      );
    }
  


export const getSessionControllerCompleteUploadMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, TError,{data: CompleteUploadDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, TError,{data: CompleteUploadDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, {data: CompleteUploadDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCompleteUpload(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerCompleteUploadMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>>
    export type SessionControllerCompleteUploadMutationBody = CompleteUploadDto
    export type SessionControllerCompleteUploadMutationError = ErrorType<void>

    export const useSessionControllerCompleteUpload = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, TError,{data: CompleteUploadDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerCompleteUpload>>,
        TError,
        {data: CompleteUploadDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerCompleteUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerPostDiscardSession = (
    id: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/discard-session`, method: 'POST'
    },
      );
    }
  


export const getSessionControllerPostDiscardSessionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerPostDiscardSession(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPostDiscardSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>>
    
    export type SessionControllerPostDiscardSessionMutationError = ErrorType<void>

    export const useSessionControllerPostDiscardSession = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerPostDiscardSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerPostMarkComplete = (
    id: string,
    markSessionCompleteBodyDto: MarkSessionCompleteBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/mark-complete`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: markSessionCompleteBodyDto
    },
      );
    }
  


export const getSessionControllerPostMarkCompleteMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, TError,{id: string;data: MarkSessionCompleteBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, TError,{id: string;data: MarkSessionCompleteBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, {id: string;data: MarkSessionCompleteBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPostMarkComplete(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPostMarkCompleteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>>
    export type SessionControllerPostMarkCompleteMutationBody = MarkSessionCompleteBodyDto
    export type SessionControllerPostMarkCompleteMutationError = ErrorType<void>

    export const useSessionControllerPostMarkComplete = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, TError,{id: string;data: MarkSessionCompleteBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>,
        TError,
        {id: string;data: MarkSessionCompleteBodyDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerPostMarkCompleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerPostMarkCompleteV2 = (
    id: string,
    markSessionCompleteBodyDtoV2: MarkSessionCompleteBodyDtoV2,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v2/clinician-api/session/${id}/mark-complete`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: markSessionCompleteBodyDtoV2
    },
      );
    }
  


export const getSessionControllerPostMarkCompleteV2MutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkCompleteV2>>, TError,{id: string;data: MarkSessionCompleteBodyDtoV2}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkCompleteV2>>, TError,{id: string;data: MarkSessionCompleteBodyDtoV2}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostMarkCompleteV2>>, {id: string;data: MarkSessionCompleteBodyDtoV2}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPostMarkCompleteV2(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPostMarkCompleteV2MutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostMarkCompleteV2>>>
    export type SessionControllerPostMarkCompleteV2MutationBody = MarkSessionCompleteBodyDtoV2
    export type SessionControllerPostMarkCompleteV2MutationError = ErrorType<void>

    export const useSessionControllerPostMarkCompleteV2 = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkCompleteV2>>, TError,{id: string;data: MarkSessionCompleteBodyDtoV2}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPostMarkCompleteV2>>,
        TError,
        {id: string;data: MarkSessionCompleteBodyDtoV2},
        TContext
      > => {

      const mutationOptions = getSessionControllerPostMarkCompleteV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerCreateFromUploadedAudio = (
    sessionFromUploadedAudioBodyDto: SessionFromUploadedAudioBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<CompletedSessionDto>(
      {url: `/v1/clinician-api/session/from-uploaded-audio`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sessionFromUploadedAudioBodyDto
    },
      );
    }
  


export const getSessionControllerCreateFromUploadedAudioMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, TError,{data: SessionFromUploadedAudioBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, TError,{data: SessionFromUploadedAudioBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, {data: SessionFromUploadedAudioBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCreateFromUploadedAudio(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerCreateFromUploadedAudioMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>>
    export type SessionControllerCreateFromUploadedAudioMutationBody = SessionFromUploadedAudioBodyDto
    export type SessionControllerCreateFromUploadedAudioMutationError = ErrorType<void>

    export const useSessionControllerCreateFromUploadedAudio = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, TError,{data: SessionFromUploadedAudioBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>,
        TError,
        {data: SessionFromUploadedAudioBodyDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerCreateFromUploadedAudioMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerCreateFromUploadedAudioV2 = (
    sessionFromUploadedAudioBodyDtoV2: SessionFromUploadedAudioBodyDtoV2,
 ) => {
      
      
      return hellojoyApiInstance<CompletedSessionDtoV2>(
      {url: `/v2/clinician-api/session/from-uploaded-audio`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sessionFromUploadedAudioBodyDtoV2
    },
      );
    }
  


export const getSessionControllerCreateFromUploadedAudioV2MutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudioV2>>, TError,{data: SessionFromUploadedAudioBodyDtoV2}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudioV2>>, TError,{data: SessionFromUploadedAudioBodyDtoV2}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudioV2>>, {data: SessionFromUploadedAudioBodyDtoV2}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCreateFromUploadedAudioV2(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerCreateFromUploadedAudioV2MutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudioV2>>>
    export type SessionControllerCreateFromUploadedAudioV2MutationBody = SessionFromUploadedAudioBodyDtoV2
    export type SessionControllerCreateFromUploadedAudioV2MutationError = ErrorType<void>

    export const useSessionControllerCreateFromUploadedAudioV2 = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudioV2>>, TError,{data: SessionFromUploadedAudioBodyDtoV2}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudioV2>>,
        TError,
        {data: SessionFromUploadedAudioBodyDtoV2},
        TContext
      > => {

      const mutationOptions = getSessionControllerCreateFromUploadedAudioV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerPostContentSuggestions = (
    id: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/generate-suggestions`, method: 'POST'
    },
      );
    }
  


export const getSessionControllerPostContentSuggestionsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerPostContentSuggestions(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerPostContentSuggestionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>>
    
    export type SessionControllerPostContentSuggestionsMutationError = ErrorType<void>

    export const useSessionControllerPostContentSuggestions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerPostContentSuggestionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetContentExclusionReasons = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/content-exclusion-reasons`, method: 'GET', signal
    },
      );
    }
  

export const getSessionControllerGetContentExclusionReasonsQueryKey = (id: string,) => {
    return [`/v1/clinician-api/session/${id}/content-exclusion-reasons`] as const;
    }

    
export const getSessionControllerGetContentExclusionReasonsQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError = ErrorType<void>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetContentExclusionReasonsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>> = ({ signal }) => sessionControllerGetContentExclusionReasons(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetContentExclusionReasonsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>>
export type SessionControllerGetContentExclusionReasonsQueryError = ErrorType<void>

export const useSessionControllerGetContentExclusionReasons = <TData = Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetContentExclusionReasonsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerGetSuggestedContent = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SuggestedContentDTO>(
      {url: `/v1/clinician-api/session/${id}/content-suggestions`, method: 'GET', signal
    },
      );
    }
  

export const getSessionControllerGetSuggestedContentQueryKey = (id: string,) => {
    return [`/v1/clinician-api/session/${id}/content-suggestions`] as const;
    }

    
export const getSessionControllerGetSuggestedContentQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError = ErrorType<void>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSuggestedContentQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>> = ({ signal }) => sessionControllerGetSuggestedContent(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSuggestedContentQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>>
export type SessionControllerGetSuggestedContentQueryError = ErrorType<void>

export const useSessionControllerGetSuggestedContent = <TData = Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetSuggestedContentQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerGetDemoSuggestedContent = (
    id: string,
    params: SessionControllerGetDemoSuggestedContentParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SuggestedContentDTO>(
      {url: `/v1/clinician-api/session/${id}/demo-content-suggestions`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSessionControllerGetDemoSuggestedContentQueryKey = (id: string,
    params: SessionControllerGetDemoSuggestedContentParams,) => {
    return [`/v1/clinician-api/session/${id}/demo-content-suggestions`, ...(params ? [params]: [])] as const;
    }

    
export const getSessionControllerGetDemoSuggestedContentQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError = ErrorType<void>>(id: string,
    params: SessionControllerGetDemoSuggestedContentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetDemoSuggestedContentQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>> = ({ signal }) => sessionControllerGetDemoSuggestedContent(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetDemoSuggestedContentQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>>
export type SessionControllerGetDemoSuggestedContentQueryError = ErrorType<void>

export const useSessionControllerGetDemoSuggestedContent = <TData = Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError = ErrorType<void>>(
 id: string,
    params: SessionControllerGetDemoSuggestedContentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetDemoSuggestedContentQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerGetSessionPrep = (
    id: string,
    params?: SessionControllerGetSessionPrepParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SessionPrepDto>(
      {url: `/v1/clinician-api/session/${id}/prep`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSessionControllerGetSessionPrepQueryKey = (id: string,
    params?: SessionControllerGetSessionPrepParams,) => {
    return [`/v1/clinician-api/session/${id}/prep`, ...(params ? [params]: [])] as const;
    }

    
export const getSessionControllerGetSessionPrepQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError = ErrorType<void>>(id: string,
    params?: SessionControllerGetSessionPrepParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionPrepQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>> = ({ signal }) => sessionControllerGetSessionPrep(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionPrepQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>>
export type SessionControllerGetSessionPrepQueryError = ErrorType<void>

export const useSessionControllerGetSessionPrep = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError = ErrorType<void>>(
 id: string,
    params?: SessionControllerGetSessionPrepParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSessionControllerGetSessionPrepQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerInitiateSessionPrepGeneration = (
    id: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/initiate-prep-generation`, method: 'POST'
    },
      );
    }
  


export const getSessionControllerInitiateSessionPrepGenerationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerInitiateSessionPrepGeneration(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerInitiateSessionPrepGenerationMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>>
    
    export type SessionControllerInitiateSessionPrepGenerationMutationError = ErrorType<void>

    export const useSessionControllerInitiateSessionPrepGeneration = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerInitiateSessionPrepGenerationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerUploadNoteToEhr = (
    id: string,
    noteId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/progress-notes/${noteId}/upload-to-ehr`, method: 'POST'
    },
      );
    }
  


export const getSessionControllerUploadNoteToEhrMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>, TError,{id: string;noteId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>, TError,{id: string;noteId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>, {id: string;noteId: string}> = (props) => {
          const {id,noteId} = props ?? {};

          return  sessionControllerUploadNoteToEhr(id,noteId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerUploadNoteToEhrMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>>
    
    export type SessionControllerUploadNoteToEhrMutationError = ErrorType<void>

    export const useSessionControllerUploadNoteToEhr = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>, TError,{id: string;noteId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>,
        TError,
        {id: string;noteId: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerUploadNoteToEhrMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerUpgradeNowEmail = (
    
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/upgrade-now-email`, method: 'POST'
    },
      );
    }
  


export const getSessionControllerUpgradeNowEmailMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>, void> = () => {
          

          return  sessionControllerUpgradeNowEmail()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerUpgradeNowEmailMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>>
    
    export type SessionControllerUpgradeNowEmailMutationError = ErrorType<void>

    export const useSessionControllerUpgradeNowEmail = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getSessionControllerUpgradeNowEmailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientControllerGetPatients = (
    params: PatientControllerGetPatientsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PatientDto[]>(
      {url: `/v1/clinician-api/patient/patients`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getPatientControllerGetPatientsQueryKey = (params: PatientControllerGetPatientsParams,) => {
    return [`/v1/clinician-api/patient/patients`, ...(params ? [params]: [])] as const;
    }

    
export const getPatientControllerGetPatientsQueryOptions = <TData = Awaited<ReturnType<typeof patientControllerGetPatients>>, TError = ErrorType<void>>(params: PatientControllerGetPatientsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetPatients>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientControllerGetPatientsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetPatients>>> = ({ signal }) => patientControllerGetPatients(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetPatients>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientControllerGetPatientsQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetPatients>>>
export type PatientControllerGetPatientsQueryError = ErrorType<void>

export const usePatientControllerGetPatients = <TData = Awaited<ReturnType<typeof patientControllerGetPatients>>, TError = ErrorType<void>>(
 params: PatientControllerGetPatientsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetPatients>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientControllerGetPatientsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const patientControllerAssistEnroll = (
    patientId: string,
    assistEnrollDto: AssistEnrollDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/assist-enroll`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assistEnrollDto
    },
      );
    }
  


export const getPatientControllerAssistEnrollMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, TError,{patientId: string;data: AssistEnrollDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, TError,{patientId: string;data: AssistEnrollDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, {patientId: string;data: AssistEnrollDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  patientControllerAssistEnroll(patientId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientControllerAssistEnrollMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerAssistEnroll>>>
    export type PatientControllerAssistEnrollMutationBody = AssistEnrollDto
    export type PatientControllerAssistEnrollMutationError = ErrorType<void>

    export const usePatientControllerAssistEnroll = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, TError,{patientId: string;data: AssistEnrollDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientControllerAssistEnroll>>,
        TError,
        {patientId: string;data: AssistEnrollDto},
        TContext
      > => {

      const mutationOptions = getPatientControllerAssistEnrollMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientControllerDeleteClient = (
    patientId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}`, method: 'DELETE'
    },
      );
    }
  


export const getPatientControllerDeleteClientMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerDeleteClient>>, TError,{patientId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientControllerDeleteClient>>, TError,{patientId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerDeleteClient>>, {patientId: string}> = (props) => {
          const {patientId} = props ?? {};

          return  patientControllerDeleteClient(patientId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientControllerDeleteClientMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerDeleteClient>>>
    
    export type PatientControllerDeleteClientMutationError = ErrorType<void>

    export const usePatientControllerDeleteClient = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerDeleteClient>>, TError,{patientId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientControllerDeleteClient>>,
        TError,
        {patientId: string},
        TContext
      > => {

      const mutationOptions = getPatientControllerDeleteClientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientControllerGetSessionList = (
    patientId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SessionDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/session-list`, method: 'GET', signal
    },
      );
    }
  

export const getPatientControllerGetSessionListQueryKey = (patientId: string,) => {
    return [`/v1/clinician-api/patient/${patientId}/session-list`] as const;
    }

    
export const getPatientControllerGetSessionListQueryOptions = <TData = Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError = ErrorType<void>>(patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientControllerGetSessionListQueryKey(patientId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetSessionList>>> = ({ signal }) => patientControllerGetSessionList(patientId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientControllerGetSessionListQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetSessionList>>>
export type PatientControllerGetSessionListQueryError = ErrorType<void>

export const usePatientControllerGetSessionList = <TData = Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientControllerGetSessionListQueryOptions(patientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const patientControllerGetCheckInScores = (
    patientId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PatientCheckInScoresDto>(
      {url: `/v1/clinician-api/patient/${patientId}/check-ins/scores`, method: 'GET', signal
    },
      );
    }
  

export const getPatientControllerGetCheckInScoresQueryKey = (patientId: string,) => {
    return [`/v1/clinician-api/patient/${patientId}/check-ins/scores`] as const;
    }

    
export const getPatientControllerGetCheckInScoresQueryOptions = <TData = Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError = ErrorType<void>>(patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientControllerGetCheckInScoresQueryKey(patientId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>> = ({ signal }) => patientControllerGetCheckInScores(patientId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientControllerGetCheckInScoresQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>>
export type PatientControllerGetCheckInScoresQueryError = ErrorType<void>

export const usePatientControllerGetCheckInScores = <TData = Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientControllerGetCheckInScoresQueryOptions(patientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const patientControllerGetConsent = (
    patientId: string,
    params: PatientControllerGetConsentParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PatientConsentDto>(
      {url: `/v1/clinician-api/patient/${patientId}/consent`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getPatientControllerGetConsentQueryKey = (patientId: string,
    params: PatientControllerGetConsentParams,) => {
    return [`/v1/clinician-api/patient/${patientId}/consent`, ...(params ? [params]: [])] as const;
    }

    
export const getPatientControllerGetConsentQueryOptions = <TData = Awaited<ReturnType<typeof patientControllerGetConsent>>, TError = ErrorType<void>>(patientId: string,
    params: PatientControllerGetConsentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetConsent>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientControllerGetConsentQueryKey(patientId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetConsent>>> = ({ signal }) => patientControllerGetConsent(patientId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetConsent>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientControllerGetConsentQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetConsent>>>
export type PatientControllerGetConsentQueryError = ErrorType<void>

export const usePatientControllerGetConsent = <TData = Awaited<ReturnType<typeof patientControllerGetConsent>>, TError = ErrorType<void>>(
 patientId: string,
    params: PatientControllerGetConsentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetConsent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientControllerGetConsentQueryOptions(patientId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const patientControllerAssignCheckIns = (
    patientId: string,
    assignCheckInDto: AssignCheckInDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/checkin/assign`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assignCheckInDto
    },
      );
    }
  


export const getPatientControllerAssignCheckInsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>, TError,{patientId: string;data: AssignCheckInDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>, TError,{patientId: string;data: AssignCheckInDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>, {patientId: string;data: AssignCheckInDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  patientControllerAssignCheckIns(patientId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientControllerAssignCheckInsMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>>
    export type PatientControllerAssignCheckInsMutationBody = AssignCheckInDto
    export type PatientControllerAssignCheckInsMutationError = ErrorType<void>

    export const usePatientControllerAssignCheckIns = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>, TError,{patientId: string;data: AssignCheckInDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientControllerAssignCheckIns>>,
        TError,
        {patientId: string;data: AssignCheckInDto},
        TContext
      > => {

      const mutationOptions = getPatientControllerAssignCheckInsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientControllerUnassignCheckIn = (
    patientId: string,
    checkInId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/checkin/${checkInId}/unassign`, method: 'POST'
    },
      );
    }
  


export const getPatientControllerUnassignCheckInMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>, TError,{patientId: string;checkInId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>, TError,{patientId: string;checkInId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>, {patientId: string;checkInId: string}> = (props) => {
          const {patientId,checkInId} = props ?? {};

          return  patientControllerUnassignCheckIn(patientId,checkInId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientControllerUnassignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>>
    
    export type PatientControllerUnassignCheckInMutationError = ErrorType<void>

    export const usePatientControllerUnassignCheckIn = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>, TError,{patientId: string;checkInId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>,
        TError,
        {patientId: string;checkInId: string},
        TContext
      > => {

      const mutationOptions = getPatientControllerUnassignCheckInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientControllerUpdateCheckInNotification = (
    patientId: string,
    checkInId: string,
    updateCheckInNotificationDto: UpdateCheckInNotificationDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/checkin/${checkInId}/update-notification`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateCheckInNotificationDto
    },
      );
    }
  


export const getPatientControllerUpdateCheckInNotificationMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>, TError,{patientId: string;checkInId: string;data: UpdateCheckInNotificationDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>, TError,{patientId: string;checkInId: string;data: UpdateCheckInNotificationDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>, {patientId: string;checkInId: string;data: UpdateCheckInNotificationDto}> = (props) => {
          const {patientId,checkInId,data} = props ?? {};

          return  patientControllerUpdateCheckInNotification(patientId,checkInId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientControllerUpdateCheckInNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>>
    export type PatientControllerUpdateCheckInNotificationMutationBody = UpdateCheckInNotificationDto
    export type PatientControllerUpdateCheckInNotificationMutationError = ErrorType<void>

    export const usePatientControllerUpdateCheckInNotification = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>, TError,{patientId: string;checkInId: string;data: UpdateCheckInNotificationDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>,
        TError,
        {patientId: string;checkInId: string;data: UpdateCheckInNotificationDto},
        TContext
      > => {

      const mutationOptions = getPatientControllerUpdateCheckInNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientControllerGetAssignedCheckIns = (
    patientId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<AssignedCheckInDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/assigned-checkins`, method: 'GET', signal
    },
      );
    }
  

export const getPatientControllerGetAssignedCheckInsQueryKey = (patientId: string,) => {
    return [`/v1/clinician-api/patient/${patientId}/assigned-checkins`] as const;
    }

    
export const getPatientControllerGetAssignedCheckInsQueryOptions = <TData = Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError = ErrorType<void>>(patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientControllerGetAssignedCheckInsQueryKey(patientId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>> = ({ signal }) => patientControllerGetAssignedCheckIns(patientId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientControllerGetAssignedCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>>
export type PatientControllerGetAssignedCheckInsQueryError = ErrorType<void>

export const usePatientControllerGetAssignedCheckIns = <TData = Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientControllerGetAssignedCheckInsQueryOptions(patientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const interventionsControllerSaveInterventionForLater = (
    patientId: string,
    interventionId: string,
    saveForLaterBodyDto: SaveForLaterBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/interventions/${interventionId}/save-for-later`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveForLaterBodyDto
    },
      );
    }
  


export const getInterventionsControllerSaveInterventionForLaterMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>, TError,{patientId: string;interventionId: string;data: SaveForLaterBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>, TError,{patientId: string;interventionId: string;data: SaveForLaterBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>, {patientId: string;interventionId: string;data: SaveForLaterBodyDto}> = (props) => {
          const {patientId,interventionId,data} = props ?? {};

          return  interventionsControllerSaveInterventionForLater(patientId,interventionId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InterventionsControllerSaveInterventionForLaterMutationResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>>
    export type InterventionsControllerSaveInterventionForLaterMutationBody = SaveForLaterBodyDto
    export type InterventionsControllerSaveInterventionForLaterMutationError = ErrorType<void>

    export const useInterventionsControllerSaveInterventionForLater = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>, TError,{patientId: string;interventionId: string;data: SaveForLaterBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>,
        TError,
        {patientId: string;interventionId: string;data: SaveForLaterBodyDto},
        TContext
      > => {

      const mutationOptions = getInterventionsControllerSaveInterventionForLaterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const interventionsControllerGetSavedForLaterInterventions = (
    patientId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<SavedForLaterInterventionDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/interventions/saved-for-later`, method: 'GET', signal
    },
      );
    }
  

export const getInterventionsControllerGetSavedForLaterInterventionsQueryKey = (patientId: string,) => {
    return [`/v1/clinician-api/patient/${patientId}/interventions/saved-for-later`] as const;
    }

    
export const getInterventionsControllerGetSavedForLaterInterventionsQueryOptions = <TData = Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError = ErrorType<void>>(patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInterventionsControllerGetSavedForLaterInterventionsQueryKey(patientId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>> = ({ signal }) => interventionsControllerGetSavedForLaterInterventions(patientId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError, TData> & { queryKey: QueryKey }
}

export type InterventionsControllerGetSavedForLaterInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>>
export type InterventionsControllerGetSavedForLaterInterventionsQueryError = ErrorType<void>

export const useInterventionsControllerGetSavedForLaterInterventions = <TData = Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInterventionsControllerGetSavedForLaterInterventionsQueryOptions(patientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const interventionsControllerRemoveInterventionFromSavedForLater = (
    patientId: string,
    interventionId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/interventions/${interventionId}/remove-from-saved`, method: 'DELETE'
    },
      );
    }
  


export const getInterventionsControllerRemoveInterventionFromSavedForLaterMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>, TError,{patientId: string;interventionId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>, TError,{patientId: string;interventionId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>, {patientId: string;interventionId: string}> = (props) => {
          const {patientId,interventionId} = props ?? {};

          return  interventionsControllerRemoveInterventionFromSavedForLater(patientId,interventionId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InterventionsControllerRemoveInterventionFromSavedForLaterMutationResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>>
    
    export type InterventionsControllerRemoveInterventionFromSavedForLaterMutationError = ErrorType<void>

    export const useInterventionsControllerRemoveInterventionFromSavedForLater = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>, TError,{patientId: string;interventionId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>,
        TError,
        {patientId: string;interventionId: string},
        TContext
      > => {

      const mutationOptions = getInterventionsControllerRemoveInterventionFromSavedForLaterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientInterventionsControllerGetAllPatientInterventions = (
    patientId: string,
    params?: PatientInterventionsControllerGetAllPatientInterventionsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PatientInterventionDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getPatientInterventionsControllerGetAllPatientInterventionsQueryKey = (patientId: string,
    params?: PatientInterventionsControllerGetAllPatientInterventionsParams,) => {
    return [`/v1/clinician-api/patient/${patientId}/patient-interventions`, ...(params ? [params]: [])] as const;
    }

    
export const getPatientInterventionsControllerGetAllPatientInterventionsQueryOptions = <TData = Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError = ErrorType<void>>(patientId: string,
    params?: PatientInterventionsControllerGetAllPatientInterventionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(patientId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>> = ({ signal }) => patientInterventionsControllerGetAllPatientInterventions(patientId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientInterventionsControllerGetAllPatientInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>>
export type PatientInterventionsControllerGetAllPatientInterventionsQueryError = ErrorType<void>

export const usePatientInterventionsControllerGetAllPatientInterventions = <TData = Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError = ErrorType<void>>(
 patientId: string,
    params?: PatientInterventionsControllerGetAllPatientInterventionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientInterventionsControllerGetAllPatientInterventionsQueryOptions(patientId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const patientInterventionsControllerCreatePatientIntervention = (
    patientId: string,
    postPatientInterventionDto: PostPatientInterventionDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postPatientInterventionDto
    },
      );
    }
  


export const getPatientInterventionsControllerCreatePatientInterventionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>, TError,{patientId: string;data: PostPatientInterventionDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>, TError,{patientId: string;data: PostPatientInterventionDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>, {patientId: string;data: PostPatientInterventionDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  patientInterventionsControllerCreatePatientIntervention(patientId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientInterventionsControllerCreatePatientInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>>
    export type PatientInterventionsControllerCreatePatientInterventionMutationBody = PostPatientInterventionDto
    export type PatientInterventionsControllerCreatePatientInterventionMutationError = ErrorType<void>

    export const usePatientInterventionsControllerCreatePatientIntervention = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>, TError,{patientId: string;data: PostPatientInterventionDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>,
        TError,
        {patientId: string;data: PostPatientInterventionDto},
        TContext
      > => {

      const mutationOptions = getPatientInterventionsControllerCreatePatientInterventionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientInterventionsControllerGetPatientInterventionById = (
    patientId: string,
    patientInterventionId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<PatientInterventionWithResponsesDto>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}/responses`, method: 'GET', signal
    },
      );
    }
  

export const getPatientInterventionsControllerGetPatientInterventionByIdQueryKey = (patientId: string,
    patientInterventionId: string,) => {
    return [`/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}/responses`] as const;
    }

    
export const getPatientInterventionsControllerGetPatientInterventionByIdQueryOptions = <TData = Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError = ErrorType<void>>(patientId: string,
    patientInterventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPatientInterventionsControllerGetPatientInterventionByIdQueryKey(patientId,patientInterventionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>> = ({ signal }) => patientInterventionsControllerGetPatientInterventionById(patientId,patientInterventionId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId && patientInterventionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError, TData> & { queryKey: QueryKey }
}

export type PatientInterventionsControllerGetPatientInterventionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>>
export type PatientInterventionsControllerGetPatientInterventionByIdQueryError = ErrorType<void>

export const usePatientInterventionsControllerGetPatientInterventionById = <TData = Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError = ErrorType<void>>(
 patientId: string,
    patientInterventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPatientInterventionsControllerGetPatientInterventionByIdQueryOptions(patientId,patientInterventionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const patientInterventionsControllerUpdatePatientIntervention = (
    patientId: string,
    patientInterventionId: string,
    putPatientInterventionDto: PutPatientInterventionDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putPatientInterventionDto
    },
      );
    }
  


export const getPatientInterventionsControllerUpdatePatientInterventionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>, TError,{patientId: string;patientInterventionId: string;data: PutPatientInterventionDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>, TError,{patientId: string;patientInterventionId: string;data: PutPatientInterventionDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>, {patientId: string;patientInterventionId: string;data: PutPatientInterventionDto}> = (props) => {
          const {patientId,patientInterventionId,data} = props ?? {};

          return  patientInterventionsControllerUpdatePatientIntervention(patientId,patientInterventionId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientInterventionsControllerUpdatePatientInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>>
    export type PatientInterventionsControllerUpdatePatientInterventionMutationBody = PutPatientInterventionDto
    export type PatientInterventionsControllerUpdatePatientInterventionMutationError = ErrorType<void>

    export const usePatientInterventionsControllerUpdatePatientIntervention = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>, TError,{patientId: string;patientInterventionId: string;data: PutPatientInterventionDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>,
        TError,
        {patientId: string;patientInterventionId: string;data: PutPatientInterventionDto},
        TContext
      > => {

      const mutationOptions = getPatientInterventionsControllerUpdatePatientInterventionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const patientInterventionsControllerDeletePatientIntervention = (
    patientId: string,
    patientInterventionId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}`, method: 'DELETE'
    },
      );
    }
  


export const getPatientInterventionsControllerDeletePatientInterventionMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>, TError,{patientId: string;patientInterventionId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>, TError,{patientId: string;patientInterventionId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>, {patientId: string;patientInterventionId: string}> = (props) => {
          const {patientId,patientInterventionId} = props ?? {};

          return  patientInterventionsControllerDeletePatientIntervention(patientId,patientInterventionId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatientInterventionsControllerDeletePatientInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>>
    
    export type PatientInterventionsControllerDeletePatientInterventionMutationError = ErrorType<void>

    export const usePatientInterventionsControllerDeletePatientIntervention = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>, TError,{patientId: string;patientInterventionId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>,
        TError,
        {patientId: string;patientInterventionId: string},
        TContext
      > => {

      const mutationOptions = getPatientInterventionsControllerDeletePatientInterventionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const dischargeSummariesControllerGenerateDischargeSummary = (
    patientId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries`, method: 'POST'
    },
      );
    }
  


export const getDischargeSummariesControllerGenerateDischargeSummaryMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>, TError,{patientId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>, TError,{patientId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>, {patientId: string}> = (props) => {
          const {patientId} = props ?? {};

          return  dischargeSummariesControllerGenerateDischargeSummary(patientId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DischargeSummariesControllerGenerateDischargeSummaryMutationResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>>
    
    export type DischargeSummariesControllerGenerateDischargeSummaryMutationError = ErrorType<void>

    export const useDischargeSummariesControllerGenerateDischargeSummary = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>, TError,{patientId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>,
        TError,
        {patientId: string},
        TContext
      > => {

      const mutationOptions = getDischargeSummariesControllerGenerateDischargeSummaryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const dischargeSummariesControllerGetDischargeSummaries = (
    patientId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<DischargeSummaryDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries`, method: 'GET', signal
    },
      );
    }
  

export const getDischargeSummariesControllerGetDischargeSummariesQueryKey = (patientId: string,) => {
    return [`/v1/clinician-api/patient/${patientId}/discharge-summaries`] as const;
    }

    
export const getDischargeSummariesControllerGetDischargeSummariesQueryOptions = <TData = Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError = ErrorType<void>>(patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDischargeSummariesControllerGetDischargeSummariesQueryKey(patientId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>> = ({ signal }) => dischargeSummariesControllerGetDischargeSummaries(patientId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError, TData> & { queryKey: QueryKey }
}

export type DischargeSummariesControllerGetDischargeSummariesQueryResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>>
export type DischargeSummariesControllerGetDischargeSummariesQueryError = ErrorType<void>

export const useDischargeSummariesControllerGetDischargeSummaries = <TData = Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDischargeSummariesControllerGetDischargeSummariesQueryOptions(patientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const dischargeSummariesControllerUpdateDischargeSummary = (
    patientId: string,
    dischargeSummaryId: string,
    patchDischargeSummaryDto: PatchDischargeSummaryDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries/${dischargeSummaryId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchDischargeSummaryDto
    },
      );
    }
  


export const getDischargeSummariesControllerUpdateDischargeSummaryMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>, {patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto}> = (props) => {
          const {patientId,dischargeSummaryId,data} = props ?? {};

          return  dischargeSummariesControllerUpdateDischargeSummary(patientId,dischargeSummaryId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DischargeSummariesControllerUpdateDischargeSummaryMutationResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>>
    export type DischargeSummariesControllerUpdateDischargeSummaryMutationBody = PatchDischargeSummaryDto
    export type DischargeSummariesControllerUpdateDischargeSummaryMutationError = ErrorType<void>

    export const useDischargeSummariesControllerUpdateDischargeSummary = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>,
        TError,
        {patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto},
        TContext
      > => {

      const mutationOptions = getDischargeSummariesControllerUpdateDischargeSummaryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const dischargeSummariesControllerDeleteDischargeSummary = (
    patientId: string,
    dischargeSummaryId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries/${dischargeSummaryId}`, method: 'DELETE'
    },
      );
    }
  


export const getDischargeSummariesControllerDeleteDischargeSummaryMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>, {patientId: string;dischargeSummaryId: string}> = (props) => {
          const {patientId,dischargeSummaryId} = props ?? {};

          return  dischargeSummariesControllerDeleteDischargeSummary(patientId,dischargeSummaryId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DischargeSummariesControllerDeleteDischargeSummaryMutationResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>>
    
    export type DischargeSummariesControllerDeleteDischargeSummaryMutationError = ErrorType<void>

    export const useDischargeSummariesControllerDeleteDischargeSummary = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>,
        TError,
        {patientId: string;dischargeSummaryId: string},
        TContext
      > => {

      const mutationOptions = getDischargeSummariesControllerDeleteDischargeSummaryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const checkinsControllerV1GetAllCheckIns = (
    params?: CheckinsControllerV1GetAllCheckInsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/library/check-ins/all`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getCheckinsControllerV1GetAllCheckInsQueryKey = (params?: CheckinsControllerV1GetAllCheckInsParams,) => {
    return [`/v1/clinician-api/library/check-ins/all`, ...(params ? [params]: [])] as const;
    }

    
export const getCheckinsControllerV1GetAllCheckInsQueryOptions = <TData = Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError = ErrorType<void>>(params?: CheckinsControllerV1GetAllCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckinsControllerV1GetAllCheckInsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>> = ({ signal }) => checkinsControllerV1GetAllCheckIns(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckinsControllerV1GetAllCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>>
export type CheckinsControllerV1GetAllCheckInsQueryError = ErrorType<void>

export const useCheckinsControllerV1GetAllCheckIns = <TData = Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError = ErrorType<void>>(
 params?: CheckinsControllerV1GetAllCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCheckinsControllerV1GetAllCheckInsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const checkinsControllerV1GetDraftCheckIn = (
    checkInId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/draft`, method: 'GET', signal
    },
      );
    }
  

export const getCheckinsControllerV1GetDraftCheckInQueryKey = (checkInId: string,) => {
    return [`/v1/clinician-api/library/check-ins/${checkInId}/draft`] as const;
    }

    
export const getCheckinsControllerV1GetDraftCheckInQueryOptions = <TData = Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError = ErrorType<void>>(checkInId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckinsControllerV1GetDraftCheckInQueryKey(checkInId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>> = ({ signal }) => checkinsControllerV1GetDraftCheckIn(checkInId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(checkInId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckinsControllerV1GetDraftCheckInQueryResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>>
export type CheckinsControllerV1GetDraftCheckInQueryError = ErrorType<void>

export const useCheckinsControllerV1GetDraftCheckIn = <TData = Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError = ErrorType<void>>(
 checkInId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCheckinsControllerV1GetDraftCheckInQueryOptions(checkInId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const checkinsControllerV1SaveDraftCheckIn = (
    checkInId: string,
    checkInBodyDto: CheckInBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/draft`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: checkInBodyDto
    },
      );
    }
  


export const getCheckinsControllerV1SaveDraftCheckInMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>, TError,{checkInId: string;data: CheckInBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>, TError,{checkInId: string;data: CheckInBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>, {checkInId: string;data: CheckInBodyDto}> = (props) => {
          const {checkInId,data} = props ?? {};

          return  checkinsControllerV1SaveDraftCheckIn(checkInId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CheckinsControllerV1SaveDraftCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>>
    export type CheckinsControllerV1SaveDraftCheckInMutationBody = CheckInBodyDto
    export type CheckinsControllerV1SaveDraftCheckInMutationError = ErrorType<void>

    export const useCheckinsControllerV1SaveDraftCheckIn = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>, TError,{checkInId: string;data: CheckInBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>,
        TError,
        {checkInId: string;data: CheckInBodyDto},
        TContext
      > => {

      const mutationOptions = getCheckinsControllerV1SaveDraftCheckInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const checkinsControllerV1CreateCheckIn = (
    checkInBodyDto: CheckInBodyDto,
 ) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2>(
      {url: `/v1/clinician-api/library/check-ins/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: checkInBodyDto
    },
      );
    }
  


export const getCheckinsControllerV1CreateCheckInMutationOptions = <TError = ErrorType<void | CheckInDtoV2>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>, TError,{data: CheckInBodyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>, TError,{data: CheckInBodyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>, {data: CheckInBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  checkinsControllerV1CreateCheckIn(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CheckinsControllerV1CreateCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>>
    export type CheckinsControllerV1CreateCheckInMutationBody = CheckInBodyDto
    export type CheckinsControllerV1CreateCheckInMutationError = ErrorType<void | CheckInDtoV2>

    export const useCheckinsControllerV1CreateCheckIn = <TError = ErrorType<void | CheckInDtoV2>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>, TError,{data: CheckInBodyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>,
        TError,
        {data: CheckInBodyDto},
        TContext
      > => {

      const mutationOptions = getCheckinsControllerV1CreateCheckInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const checkinsControllerV1PublishDraftCheckIn = (
    checkInId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/publish-draft`, method: 'POST'
    },
      );
    }
  


export const getCheckinsControllerV1PublishDraftCheckInMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>, TError,{checkInId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>, TError,{checkInId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  checkinsControllerV1PublishDraftCheckIn(checkInId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CheckinsControllerV1PublishDraftCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>>
    
    export type CheckinsControllerV1PublishDraftCheckInMutationError = ErrorType<void>

    export const useCheckinsControllerV1PublishDraftCheckIn = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>, TError,{checkInId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>,
        TError,
        {checkInId: string},
        TContext
      > => {

      const mutationOptions = getCheckinsControllerV1PublishDraftCheckInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const checkinsControllerV1ToggleCheckInIsCore = (
    checkInId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/toggle-core`, method: 'PUT'
    },
      );
    }
  


export const getCheckinsControllerV1ToggleCheckInIsCoreMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>, TError,{checkInId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>, TError,{checkInId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  checkinsControllerV1ToggleCheckInIsCore(checkInId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CheckinsControllerV1ToggleCheckInIsCoreMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>>
    
    export type CheckinsControllerV1ToggleCheckInIsCoreMutationError = ErrorType<void>

    export const useCheckinsControllerV1ToggleCheckInIsCore = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>, TError,{checkInId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>,
        TError,
        {checkInId: string},
        TContext
      > => {

      const mutationOptions = getCheckinsControllerV1ToggleCheckInIsCoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const checkinsControllerV1ToggleCheckinIsAssist = (
    checkInId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/toggle-assist`, method: 'PUT'
    },
      );
    }
  


export const getCheckinsControllerV1ToggleCheckinIsAssistMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>, TError,{checkInId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>, TError,{checkInId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  checkinsControllerV1ToggleCheckinIsAssist(checkInId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CheckinsControllerV1ToggleCheckinIsAssistMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>>
    
    export type CheckinsControllerV1ToggleCheckinIsAssistMutationError = ErrorType<void>

    export const useCheckinsControllerV1ToggleCheckinIsAssist = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>, TError,{checkInId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>,
        TError,
        {checkInId: string},
        TContext
      > => {

      const mutationOptions = getCheckinsControllerV1ToggleCheckinIsAssistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const interventionsControllerV1GetAllInterventions = (
    params?: InterventionsControllerV1GetAllInterventionsParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<Intervention[]>(
      {url: `/v1/clinician-api/library/interventions/all`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getInterventionsControllerV1GetAllInterventionsQueryKey = (params?: InterventionsControllerV1GetAllInterventionsParams,) => {
    return [`/v1/clinician-api/library/interventions/all`, ...(params ? [params]: [])] as const;
    }

    
export const getInterventionsControllerV1GetAllInterventionsQueryOptions = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError = ErrorType<void>>(params?: InterventionsControllerV1GetAllInterventionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInterventionsControllerV1GetAllInterventionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>> = ({ signal }) => interventionsControllerV1GetAllInterventions(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError, TData> & { queryKey: QueryKey }
}

export type InterventionsControllerV1GetAllInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>>
export type InterventionsControllerV1GetAllInterventionsQueryError = ErrorType<void>

export const useInterventionsControllerV1GetAllInterventions = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError = ErrorType<void>>(
 params?: InterventionsControllerV1GetAllInterventionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInterventionsControllerV1GetAllInterventionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const interventionsControllerV1GetIntervention = (
    interventionId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<Intervention>(
      {url: `/v1/clinician-api/library/interventions/${interventionId}`, method: 'GET', signal
    },
      );
    }
  

export const getInterventionsControllerV1GetInterventionQueryKey = (interventionId: string,) => {
    return [`/v1/clinician-api/library/interventions/${interventionId}`] as const;
    }

    
export const getInterventionsControllerV1GetInterventionQueryOptions = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError = ErrorType<void>>(interventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInterventionsControllerV1GetInterventionQueryKey(interventionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>> = ({ signal }) => interventionsControllerV1GetIntervention(interventionId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(interventionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError, TData> & { queryKey: QueryKey }
}

export type InterventionsControllerV1GetInterventionQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>>
export type InterventionsControllerV1GetInterventionQueryError = ErrorType<void>

export const useInterventionsControllerV1GetIntervention = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError = ErrorType<void>>(
 interventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInterventionsControllerV1GetInterventionQueryOptions(interventionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const interventionsControllerV1GetInterventionActivity = (
    interventionId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<Intervention>(
      {url: `/v1/clinician-api/library/interventions/${interventionId}/items`, method: 'GET', signal
    },
      );
    }
  

export const getInterventionsControllerV1GetInterventionActivityQueryKey = (interventionId: string,) => {
    return [`/v1/clinician-api/library/interventions/${interventionId}/items`] as const;
    }

    
export const getInterventionsControllerV1GetInterventionActivityQueryOptions = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError = ErrorType<void>>(interventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInterventionsControllerV1GetInterventionActivityQueryKey(interventionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>> = ({ signal }) => interventionsControllerV1GetInterventionActivity(interventionId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(interventionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError, TData> & { queryKey: QueryKey }
}

export type InterventionsControllerV1GetInterventionActivityQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>>
export type InterventionsControllerV1GetInterventionActivityQueryError = ErrorType<void>

export const useInterventionsControllerV1GetInterventionActivity = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError = ErrorType<void>>(
 interventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInterventionsControllerV1GetInterventionActivityQueryOptions(interventionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const contentSuggestionControllerDismiss = (
    contentSuggestionId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/dismiss`, method: 'PATCH'
    },
      );
    }
  


export const getContentSuggestionControllerDismissMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, TError,{contentSuggestionId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, TError,{contentSuggestionId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, {contentSuggestionId: string}> = (props) => {
          const {contentSuggestionId} = props ?? {};

          return  contentSuggestionControllerDismiss(contentSuggestionId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ContentSuggestionControllerDismissMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>>
    
    export type ContentSuggestionControllerDismissMutationError = ErrorType<void>

    export const useContentSuggestionControllerDismiss = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, TError,{contentSuggestionId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>,
        TError,
        {contentSuggestionId: string},
        TContext
      > => {

      const mutationOptions = getContentSuggestionControllerDismissMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const contentSuggestionControllerAccept = (
    contentSuggestionId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/accept`, method: 'PATCH'
    },
      );
    }
  


export const getContentSuggestionControllerAcceptMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, TError,{contentSuggestionId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, TError,{contentSuggestionId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, {contentSuggestionId: string}> = (props) => {
          const {contentSuggestionId} = props ?? {};

          return  contentSuggestionControllerAccept(contentSuggestionId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ContentSuggestionControllerAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>>
    
    export type ContentSuggestionControllerAcceptMutationError = ErrorType<void>

    export const useContentSuggestionControllerAccept = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, TError,{contentSuggestionId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof contentSuggestionControllerAccept>>,
        TError,
        {contentSuggestionId: string},
        TContext
      > => {

      const mutationOptions = getContentSuggestionControllerAcceptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const contentSuggestionControllerSubmitFeedback = (
    contentSuggestionId: string,
    submitFeedbackDto: SubmitFeedbackDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/feedback`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: submitFeedbackDto
    },
      );
    }
  


export const getContentSuggestionControllerSubmitFeedbackMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, TError,{contentSuggestionId: string;data: SubmitFeedbackDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, TError,{contentSuggestionId: string;data: SubmitFeedbackDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, {contentSuggestionId: string;data: SubmitFeedbackDto}> = (props) => {
          const {contentSuggestionId,data} = props ?? {};

          return  contentSuggestionControllerSubmitFeedback(contentSuggestionId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ContentSuggestionControllerSubmitFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>>
    export type ContentSuggestionControllerSubmitFeedbackMutationBody = SubmitFeedbackDto
    export type ContentSuggestionControllerSubmitFeedbackMutationError = ErrorType<void>

    export const useContentSuggestionControllerSubmitFeedback = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, TError,{contentSuggestionId: string;data: SubmitFeedbackDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>,
        TError,
        {contentSuggestionId: string;data: SubmitFeedbackDto},
        TContext
      > => {

      const mutationOptions = getContentSuggestionControllerSubmitFeedbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const organizationControllerGetOrganizationConsentSetting = (
    organizationId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<ConsentSettingDTO>(
      {url: `/v1/clinician-api/organization/${organizationId}/consent-setting`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerGetOrganizationConsentSettingQueryKey = (organizationId: string,) => {
    return [`/v1/clinician-api/organization/${organizationId}/consent-setting`] as const;
    }

    
export const getOrganizationControllerGetOrganizationConsentSettingQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError = ErrorType<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerGetOrganizationConsentSettingQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>> = ({ signal }) => organizationControllerGetOrganizationConsentSetting(organizationId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerGetOrganizationConsentSettingQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>>
export type OrganizationControllerGetOrganizationConsentSettingQueryError = ErrorType<unknown>

export const useOrganizationControllerGetOrganizationConsentSetting = <TData = Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError = ErrorType<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerGetOrganizationConsentSettingQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const organizationControllerUpdateOrganizationConsentSetting = (
    organizationId: string,
    patchConsentSettingDTO: PatchConsentSettingDTO,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/organization/${organizationId}/consent-setting`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchConsentSettingDTO
    },
      );
    }
  


export const getOrganizationControllerUpdateOrganizationConsentSettingMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, TError,{organizationId: string;data: PatchConsentSettingDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, TError,{organizationId: string;data: PatchConsentSettingDTO}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, {organizationId: string;data: PatchConsentSettingDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  organizationControllerUpdateOrganizationConsentSetting(organizationId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerUpdateOrganizationConsentSettingMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>>
    export type OrganizationControllerUpdateOrganizationConsentSettingMutationBody = PatchConsentSettingDTO
    export type OrganizationControllerUpdateOrganizationConsentSettingMutationError = ErrorType<unknown>

    export const useOrganizationControllerUpdateOrganizationConsentSetting = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, TError,{organizationId: string;data: PatchConsentSettingDTO}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>,
        TError,
        {organizationId: string;data: PatchConsentSettingDTO},
        TContext
      > => {

      const mutationOptions = getOrganizationControllerUpdateOrganizationConsentSettingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const organizationControllerCreateCustomProgressNoteTemplate = (
    organizationId: string,
    createCustomProgressNoteTemplateDTO: CreateCustomProgressNoteTemplateDTO,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/organization/${organizationId}/custom-progress-note-templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCustomProgressNoteTemplateDTO
    },
      );
    }
  


export const getOrganizationControllerCreateCustomProgressNoteTemplateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreateCustomProgressNoteTemplate>>, TError,{organizationId: string;data: CreateCustomProgressNoteTemplateDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreateCustomProgressNoteTemplate>>, TError,{organizationId: string;data: CreateCustomProgressNoteTemplateDTO}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerCreateCustomProgressNoteTemplate>>, {organizationId: string;data: CreateCustomProgressNoteTemplateDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  organizationControllerCreateCustomProgressNoteTemplate(organizationId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerCreateCustomProgressNoteTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerCreateCustomProgressNoteTemplate>>>
    export type OrganizationControllerCreateCustomProgressNoteTemplateMutationBody = CreateCustomProgressNoteTemplateDTO
    export type OrganizationControllerCreateCustomProgressNoteTemplateMutationError = ErrorType<unknown>

    export const useOrganizationControllerCreateCustomProgressNoteTemplate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreateCustomProgressNoteTemplate>>, TError,{organizationId: string;data: CreateCustomProgressNoteTemplateDTO}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof organizationControllerCreateCustomProgressNoteTemplate>>,
        TError,
        {organizationId: string;data: CreateCustomProgressNoteTemplateDTO},
        TContext
      > => {

      const mutationOptions = getOrganizationControllerCreateCustomProgressNoteTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const organizationControllerGetCustomProgressNoteTemplates = (
    organizationId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<unknown>(
      {url: `/v1/clinician-api/organization/${organizationId}/custom-progress-note-templates`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerGetCustomProgressNoteTemplatesQueryKey = (organizationId: string,) => {
    return [`/v1/clinician-api/organization/${organizationId}/custom-progress-note-templates`] as const;
    }

    
export const getOrganizationControllerGetCustomProgressNoteTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplates>>, TError = ErrorType<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplates>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerGetCustomProgressNoteTemplatesQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplates>>> = ({ signal }) => organizationControllerGetCustomProgressNoteTemplates(organizationId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerGetCustomProgressNoteTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplates>>>
export type OrganizationControllerGetCustomProgressNoteTemplatesQueryError = ErrorType<unknown>

export const useOrganizationControllerGetCustomProgressNoteTemplates = <TData = Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplates>>, TError = ErrorType<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerGetCustomProgressNoteTemplatesQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const organizationControllerGetOutboundEhrSettings = (
    organizationId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<OutboundEhrSettingsDTO>(
      {url: `/v1/clinician-api/organization/${organizationId}/outbound-ehr-settings`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerGetOutboundEhrSettingsQueryKey = (organizationId: string,) => {
    return [`/v1/clinician-api/organization/${organizationId}/outbound-ehr-settings`] as const;
    }

    
export const getOrganizationControllerGetOutboundEhrSettingsQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError = ErrorType<unknown>>(organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerGetOutboundEhrSettingsQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>> = ({ signal }) => organizationControllerGetOutboundEhrSettings(organizationId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerGetOutboundEhrSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>>
export type OrganizationControllerGetOutboundEhrSettingsQueryError = ErrorType<unknown>

export const useOrganizationControllerGetOutboundEhrSettings = <TData = Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError = ErrorType<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerGetOutboundEhrSettingsQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const organizationControllerGetCustomProgressNoteTemplate = (
    organizationId: string,
    templateId: string,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CustomProgressNoteTemplateDTO>(
      {url: `/v1/clinician-api/organization/${organizationId}/custom-progress-note-templates/${templateId}`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerGetCustomProgressNoteTemplateQueryKey = (organizationId: string,
    templateId: string,) => {
    return [`/v1/clinician-api/organization/${organizationId}/custom-progress-note-templates/${templateId}`] as const;
    }

    
export const getOrganizationControllerGetCustomProgressNoteTemplateQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplate>>, TError = ErrorType<unknown>>(organizationId: string,
    templateId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplate>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerGetCustomProgressNoteTemplateQueryKey(organizationId,templateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplate>>> = ({ signal }) => organizationControllerGetCustomProgressNoteTemplate(organizationId,templateId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && templateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplate>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerGetCustomProgressNoteTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplate>>>
export type OrganizationControllerGetCustomProgressNoteTemplateQueryError = ErrorType<unknown>

export const useOrganizationControllerGetCustomProgressNoteTemplate = <TData = Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplate>>, TError = ErrorType<unknown>>(
 organizationId: string,
    templateId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetCustomProgressNoteTemplate>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerGetCustomProgressNoteTemplateQueryOptions(organizationId,templateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const checkInControllerGetCheckInsForPlusCustomer = (
    
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/check-in`, method: 'GET', signal
    },
      );
    }
  

export const getCheckInControllerGetCheckInsForPlusCustomerQueryKey = () => {
    return [`/v1/clinician-api/check-in`] as const;
    }

    
export const getCheckInControllerGetCheckInsForPlusCustomerQueryOptions = <TData = Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError = ErrorType<void>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckInControllerGetCheckInsForPlusCustomerQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>> = ({ signal }) => checkInControllerGetCheckInsForPlusCustomer(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckInControllerGetCheckInsForPlusCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>>
export type CheckInControllerGetCheckInsForPlusCustomerQueryError = ErrorType<void>

export const useCheckInControllerGetCheckInsForPlusCustomer = <TData = Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCheckInControllerGetCheckInsForPlusCustomerQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const onboardingControllerExperiment = (
    params: OnboardingControllerExperimentParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<GetExperimentDto>(
      {url: `/v1/clinician-api/onboarding/experiment`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getOnboardingControllerExperimentQueryKey = (params: OnboardingControllerExperimentParams,) => {
    return [`/v1/clinician-api/onboarding/experiment`, ...(params ? [params]: [])] as const;
    }

    
export const getOnboardingControllerExperimentQueryOptions = <TData = Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError = ErrorType<unknown>>(params: OnboardingControllerExperimentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOnboardingControllerExperimentQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof onboardingControllerExperiment>>> = ({ signal }) => onboardingControllerExperiment(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError, TData> & { queryKey: QueryKey }
}

export type OnboardingControllerExperimentQueryResult = NonNullable<Awaited<ReturnType<typeof onboardingControllerExperiment>>>
export type OnboardingControllerExperimentQueryError = ErrorType<unknown>

export const useOnboardingControllerExperiment = <TData = Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError = ErrorType<unknown>>(
 params: OnboardingControllerExperimentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOnboardingControllerExperimentQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const onboardingControllerValidateEmail = (
    validateEmailDto: ValidateEmailDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/onboarding/validate-email`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: validateEmailDto
    },
      );
    }
  


export const getOnboardingControllerValidateEmailMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>, TError,{data: ValidateEmailDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>, TError,{data: ValidateEmailDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>, {data: ValidateEmailDto}> = (props) => {
          const {data} = props ?? {};

          return  onboardingControllerValidateEmail(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type OnboardingControllerValidateEmailMutationResult = NonNullable<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>>
    export type OnboardingControllerValidateEmailMutationBody = ValidateEmailDto
    export type OnboardingControllerValidateEmailMutationError = ErrorType<unknown>

    export const useOnboardingControllerValidateEmail = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>, TError,{data: ValidateEmailDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof onboardingControllerValidateEmail>>,
        TError,
        {data: ValidateEmailDto},
        TContext
      > => {

      const mutationOptions = getOnboardingControllerValidateEmailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const onboardingControllerSubmitSurveyResponse = (
    onboardingSurveyDto: OnboardingSurveyDto,
 ) => {
      
      
      return hellojoyApiInstance<unknown>(
      {url: `/v1/clinician-api/onboarding/survey`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: onboardingSurveyDto
    },
      );
    }
  


export const getOnboardingControllerSubmitSurveyResponseMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>, TError,{data: OnboardingSurveyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>, TError,{data: OnboardingSurveyDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>, {data: OnboardingSurveyDto}> = (props) => {
          const {data} = props ?? {};

          return  onboardingControllerSubmitSurveyResponse(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type OnboardingControllerSubmitSurveyResponseMutationResult = NonNullable<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>>
    export type OnboardingControllerSubmitSurveyResponseMutationBody = OnboardingSurveyDto
    export type OnboardingControllerSubmitSurveyResponseMutationError = ErrorType<void>

    export const useOnboardingControllerSubmitSurveyResponse = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>, TError,{data: OnboardingSurveyDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>,
        TError,
        {data: OnboardingSurveyDto},
        TContext
      > => {

      const mutationOptions = getOnboardingControllerSubmitSurveyResponseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const stayOnBlueprintOfferControllerAccept = (
    offerId: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/stay-on-blueprint-offer/${offerId}/accept`, method: 'POST'
    },
      );
    }
  


export const getStayOnBlueprintOfferControllerAcceptMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>, TError,{offerId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>, TError,{offerId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>, {offerId: string}> = (props) => {
          const {offerId} = props ?? {};

          return  stayOnBlueprintOfferControllerAccept(offerId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StayOnBlueprintOfferControllerAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>>
    
    export type StayOnBlueprintOfferControllerAcceptMutationError = ErrorType<unknown>

    export const useStayOnBlueprintOfferControllerAccept = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>, TError,{offerId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>,
        TError,
        {offerId: string},
        TContext
      > => {

      const mutationOptions = getStayOnBlueprintOfferControllerAcceptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const reminderControllerSetReminder = (
    setReminderDto: SetReminderDto,
 ) => {
      
      
      return hellojoyApiInstance<unknown>(
      {url: `/v1/clinician-api/reminder`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: setReminderDto
    },
      );
    }
  


export const getReminderControllerSetReminderMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reminderControllerSetReminder>>, TError,{data: SetReminderDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof reminderControllerSetReminder>>, TError,{data: SetReminderDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reminderControllerSetReminder>>, {data: SetReminderDto}> = (props) => {
          const {data} = props ?? {};

          return  reminderControllerSetReminder(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReminderControllerSetReminderMutationResult = NonNullable<Awaited<ReturnType<typeof reminderControllerSetReminder>>>
    export type ReminderControllerSetReminderMutationBody = SetReminderDto
    export type ReminderControllerSetReminderMutationError = ErrorType<void>

    export const useReminderControllerSetReminder = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reminderControllerSetReminder>>, TError,{data: SetReminderDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof reminderControllerSetReminder>>,
        TError,
        {data: SetReminderDto},
        TContext
      > => {

      const mutationOptions = getReminderControllerSetReminderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const progressNoteTemplateMemoryControllerDelete = (
    id: string,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/progress-note-template-memory/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getProgressNoteTemplateMemoryControllerDeleteMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerDelete>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  progressNoteTemplateMemoryControllerDelete(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProgressNoteTemplateMemoryControllerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerDelete>>>
    
    export type ProgressNoteTemplateMemoryControllerDeleteMutationError = ErrorType<void>

    export const useProgressNoteTemplateMemoryControllerDelete = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerDelete>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getProgressNoteTemplateMemoryControllerDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const progressNoteTemplateMemoryControllerClear = (
    clearTemplateMemoryDto: ClearTemplateMemoryDto,
 ) => {
      
      
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/progress-note-template-memory/clear`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: clearTemplateMemoryDto
    },
      );
    }
  


export const getProgressNoteTemplateMemoryControllerClearMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerClear>>, TError,{data: ClearTemplateMemoryDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerClear>>, TError,{data: ClearTemplateMemoryDto}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerClear>>, {data: ClearTemplateMemoryDto}> = (props) => {
          const {data} = props ?? {};

          return  progressNoteTemplateMemoryControllerClear(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProgressNoteTemplateMemoryControllerClearMutationResult = NonNullable<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerClear>>>
    export type ProgressNoteTemplateMemoryControllerClearMutationBody = ClearTemplateMemoryDto
    export type ProgressNoteTemplateMemoryControllerClearMutationError = ErrorType<void>

    export const useProgressNoteTemplateMemoryControllerClear = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerClear>>, TError,{data: ClearTemplateMemoryDto}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerClear>>,
        TError,
        {data: ClearTemplateMemoryDto},
        TContext
      > => {

      const mutationOptions = getProgressNoteTemplateMemoryControllerClearMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const progressNoteTemplateMemoryControllerFindByNoteType = (
    params: ProgressNoteTemplateMemoryControllerFindByNoteTypeParams,
 signal?: AbortSignal
) => {
      
      
      return hellojoyApiInstance<ProgressNoteTemplateMemoryResponseDto[]>(
      {url: `/v1/clinician-api/progress-note-template-memory`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getProgressNoteTemplateMemoryControllerFindByNoteTypeQueryKey = (params: ProgressNoteTemplateMemoryControllerFindByNoteTypeParams,) => {
    return [`/v1/clinician-api/progress-note-template-memory`, ...(params ? [params]: [])] as const;
    }

    
export const getProgressNoteTemplateMemoryControllerFindByNoteTypeQueryOptions = <TData = Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerFindByNoteType>>, TError = ErrorType<void>>(params: ProgressNoteTemplateMemoryControllerFindByNoteTypeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerFindByNoteType>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProgressNoteTemplateMemoryControllerFindByNoteTypeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerFindByNoteType>>> = ({ signal }) => progressNoteTemplateMemoryControllerFindByNoteType(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerFindByNoteType>>, TError, TData> & { queryKey: QueryKey }
}

export type ProgressNoteTemplateMemoryControllerFindByNoteTypeQueryResult = NonNullable<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerFindByNoteType>>>
export type ProgressNoteTemplateMemoryControllerFindByNoteTypeQueryError = ErrorType<void>

export const useProgressNoteTemplateMemoryControllerFindByNoteType = <TData = Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerFindByNoteType>>, TError = ErrorType<void>>(
 params: ProgressNoteTemplateMemoryControllerFindByNoteTypeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof progressNoteTemplateMemoryControllerFindByNoteType>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getProgressNoteTemplateMemoryControllerFindByNoteTypeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




