import React from 'react'
import { Stack, HStack, Text, VStack, Button } from '@chakra-ui/react'
import CoolKidsStudy from '../../shared/assist/svgs/cool-kids-study'
import { useAssistPanelContext } from '../context'
import { AssistPanelOverlay } from '../overlay-panel'
import { trackEvent } from '@lib/clinician-tracking'

export const FinishAssistSetup = ({tabName}: {tabName: string}) => {
  const { setOverlayPanel } = useAssistPanelContext()
  
  return (
    <Stack spacing="0" gap="8px">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Suggestions</Text>
      </HStack>
      <VStack
        spacing="0"
        pb="24px"
        pt="16px"
        gap="12px"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="8px"
        h="240px"
      >
        <CoolKidsStudy />
        <Text w="280px" textAlign="center" fontSize="14px">We need more information before we can generate suggestions.</Text>
        <Button
          colorScheme="primary"
          size="lg"
          m={0}
          onClick={() => {
            trackEvent('Clicked Finish Assist Setup', {
              source: tabName,
            })
            setOverlayPanel(AssistPanelOverlay.FINISH_ASSIST_SETUP)
          }}
        >
          Finish setup
        </Button>
      </VStack>
    </Stack>
  )
}