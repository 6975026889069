import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Tabs,
  TabList,
  Tab,
  Box,
  Text
} from '@chakra-ui/react'
import { ChevronDown } from '@components/icons'
import { groupBy, keyBy } from 'lodash'
import { useExperienceManager } from '@hooks'

type Props = {
  optionValue: string
  onChange: (value: string | string[]) => void
  progressNoteTypes: any
}

export const NoteTypeDropdown = ({
  optionValue,
  onChange,
  progressNoteTypes
}: Props) => {
  const [selectedNoteOptions, setSelectedNoteOptions] = useState('therapists')
  const [isOpen, setIsOpen] = useState(false)
  const selectedRef = useRef<HTMLDivElement | null>(null)
  const { prescriberNoteTypeOptionsLabel } = useExperienceManager()

  useEffect(() => {
    setTimeout(() => {
      if (selectedRef.current) {
        selectedRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        })
      }
    }, 300)
  }, [isOpen])

  const progressNoteTypesWithSubGroups = progressNoteTypes.map((note: any) => {
    return {
      ...note,
      noteSubGroup: note.noteSubGroup || 'progress'
    }
  })

  const noteTypesById = keyBy(progressNoteTypesWithSubGroups, 'id')
  const therapistNotes = progressNoteTypesWithSubGroups.filter(
    (o: any) => !o.hideFromDefaultOptions && o.noteGroup === 'therapists'
  )
  const therapistNotesBySubGroup = groupBy(therapistNotes, 'noteSubGroup')

  const prescriberNotes = progressNoteTypesWithSubGroups.filter(
    (o: any) => !o.hideFromDefaultOptions && o.noteGroup === 'prescribers'
  )
  const prescriberNotesBySubGroup = groupBy(prescriberNotes, 'noteSubGroup')

  const menuOptions = useMemo(() => {
    return Object.keys(selectedNoteOptions === 'therapists' ? therapistNotesBySubGroup : prescriberNotesBySubGroup)
      .sort()
      .map((subGroup: string) => (
        <Box key={`${selectedNoteOptions}-${subGroup}`}>
          <Text fontWeight="bold" fontSize="14px" bg="#F4F4F4" py="4px" pl="32px" color="gray">{subGroup.toUpperCase()}</Text>
          {selectedNoteOptions === 'therapists' ? therapistNotesBySubGroup[subGroup].map((n: any, index: number) => (
            <MenuItemOption
              key={`therapist-${n.id}-${index}`}
              value={n.id}
              paddingLeft="20px"
              onClick={() => onChange(n.id)}
            >
              <div ref={n.id === optionValue ? selectedRef : null} style={{ pointerEvents: 'none' }}>
                {n.display}
              </div>
            </MenuItemOption>
          )) : prescriberNotesBySubGroup[subGroup].map((n: any, index: number) => (
            <MenuItemOption
              key={`prescriber-${n.id}-${index}`}
              value={n.id}
              paddingLeft="20px"
              onClick={() => onChange(n.id)}
            >
              <div ref={n.id === optionValue ? selectedRef : null} style={{ pointerEvents: 'none' }}>
                {n.display}
              </div>
            </MenuItemOption>
          ))}
        </Box>
      ))
  }, [therapistNotesBySubGroup, prescriberNotesBySubGroup, optionValue, selectedRef, onChange, selectedNoteOptions])

  useEffect(() => {
    const noteOptions = therapistNotes.find((n: any) => n.id === optionValue)
      ? 'therapists'
      : 'prescribers'
    setSelectedNoteOptions(noteOptions)
  }, [])

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)

  useEffect(() => {
    const updateWidth = () => {
      if (buttonRef.current) {
        setMenuWidth(buttonRef.current.offsetWidth)
      }
    }
    updateWidth()

    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <Menu 
      closeOnSelect
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <MenuButton
        ref={buttonRef}
        w="100%"
        as={Button}
        value={optionValue}
        border="1px solid rgb(228, 229, 230)"
        borderColor="#C9C9C9"
        m={0}
        color="black"
        background="white"
        borderRadius="6px"
        height="48px"
        paddingInlineStart={4}
        rightIcon={<ChevronDown />}
        textAlign="start"
      >
        {noteTypesById[optionValue as keyof typeof noteTypesById]?.display}
      </MenuButton>
      <MenuList
        paddingTop="0"
        maxHeight="240px"
        width={`${menuWidth}px`}
        overflowY="scroll"
        borderRadius="8px"
        zIndex={1000}
      >
        <Tabs
          isFitted
          index={selectedNoteOptions === 'therapists' ? 0 : 1}
          onChange={i =>{
            setSelectedNoteOptions(i === 0 ? 'therapists' : 'prescribers')
          }}
        >
          <TabList>
            <Tab
              height="50px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Box as="span" overflow="hidden" textOverflow="ellipsis">
                Therapy Notes
              </Box>
            </Tab>
            <Tab
              height="50px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Box as="span" overflow="hidden" textOverflow="ellipsis">
                {prescriberNoteTypeOptionsLabel || 'Prescriber Notes'}
              </Box>
            </Tab>
          </TabList>
        </Tabs>
        <MenuOptionGroup
          defaultValue={optionValue}
          backgroundColor="primary"
          margin="0"
          color="white"
          height="50px"
          type="radio"
          paddingLeft="20px"
          alignItems="center"
          display="flex"
        >
          {menuOptions}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
