import React, { useState, useCallback } from 'react'
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  Text,
  Box,
  DrawerCloseButton,
  DrawerFooter,
  VStack,
  Textarea,
  List,
  ListItem,
  Button,
  useToast,
  Flex
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { MagicWand, Refresh, ArrowRightIcon } from '@blueprinthq/joy'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { NoteSettings } from '@components/icons'
import { getSessionControllerGetProgressNotesForSessionQueryKey } from '~/clinician-api'
import { endpoints } from '@api'
import { useStoreState } from 'easy-peasy'
import { capitalize } from 'lodash'
import { StoreModel } from 'src/store/types'
import { useExperienceManager } from '@hooks'
import {
  MemoryManagementDrawer,
  ReadOnlyMemories
} from './progress-note-template-memory'

const getPreferenceLabel = (value: string, options: any) => {
  return options.find((o: any) => o.value === value)?.label
}

export const MagicEditDrawer = ({
  isOpen,
  closeDrawer,
  noteType,
  noteTypeLabel,
  progressNoteId,
  preferences = {},
  openSettings
}: {
  isOpen: boolean
  closeDrawer: () => void
  progressNoteId: string
  preferences: Record<string, any>
  openSettings: () => void
  noteType: string,
  noteTypeLabel?: string
}) => {
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const { notePreferencesEnabled, memoryEnabled } = useExperienceManager()

  const { sessionId }: { sessionId: string } = useParams()
  const queryClient = useQueryClient()
  const toast = useToast()

  const [userInstructions, setUserInstructions] = useState('')
  const [saveToMemory, setSaveToMemory] = useState(false)
  const [isManagingMemory, setIsManagingMemory] = useState(false)

  const organizationId = user?.clinic?.organization?.id

  const { data, isLoading: isSettingsLoading } = useQuery(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () => endpoints.getProgressNoteSettings.request({ organizationId })
  )
  const { mutateAsync: executeRegenerateProgressNote, isLoading } = useMutation(
    endpoints.postRegenerateProgressNote.request,
    {
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error regenerating this progress note',
          status: 'error',
          isClosable: true,
          duration: 2000,
          position: 'bottom-left'
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
        )
      }
    }
  )

  const submitRegenerateProgressNote = useCallback(async () => {
    const data = {
      progressNoteId,
      userInstructions,
      saveToMemory
    }
    await executeRegenerateProgressNote(data)

    closeDrawer()
  }, [progressNoteId, userInstructions, saveToMemory])

  const handleGoToSettings = () => {
    closeDrawer()
    openSettings()
  }

  const handleCloseMemoryManagement = () => {
    setIsManagingMemory(false)
  }

  if (isSettingsLoading) return null

  // @ts-ignore
  const { preferenceOptions }: { preferenceOptions: any } = data

  return (
    <>
      <ChakraDrawer isOpen={isOpen} onClose={closeDrawer} size="md">
        <DrawerOverlay />
        <DrawerContent>
          {isManagingMemory ? (
            <MemoryManagementDrawer
              onClose={handleCloseMemoryManagement}
              noteType={noteType}
            />
          ) : (
            <>
              <Box
                height="95px"
                backgroundColor="primary"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="small"
              >
                <Flex w="150px" height="80px" align="center">
                  <MagicWand fill="white" />
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="white"
                    paddingLeft="xsmall"
                  >
                    Magic edit
                  </Text>
                </Flex>
                <DrawerCloseButton
                  color="white"
                  position="static"
                  _focus={{ outline: 'none' }}
                />
              </Box>
              <Box
                p="small"
                overflowY="scroll"
                height="100%"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <VStack spacing="small" alignItems="flex-start">
                  <Text>
                    Something missing or wrong in this note? Tell our AI what to
                    fix:
                  </Text>
                  <Textarea
                    h="140px"
                    placeholder="Enter your instructions here..."
                    borderColor={'lightgray'}
                    onChange={e => setUserInstructions(e.target.value)}
                  />

                  {memoryEnabled && (
                    <Box width="100%" py={2} style={{ marginTop: '0px' }}>
                      <Flex align="center">
                        <input
                          type="checkbox"
                          id="saveToMemory"
                          checked={saveToMemory}
                          onChange={e => setSaveToMemory(e.target.checked)}
                          style={{ marginRight: '8px' }}
                        />
                        <Text as="label" htmlFor="saveToMemory">
                          Save to memory for future <strong>{noteTypeLabel}</strong> notes
                        </Text>
                      </Flex>
                    </Box>
                  )}

                  {memoryEnabled ? (
                    <ReadOnlyMemories
                      noteType={noteType}
                      onManageClick={() => setIsManagingMemory(true)}
                    />
                  ) : (
                    <Box px={4}>
                      <Text align="center" fontWeight="bold" mb={2}>
                        Example instructions
                      </Text>
                      <List styleType="disc">
                        <ListItem fontStyle="italic">
                          Include [specific topics discussed not yet in note]
                        </ListItem>
                        <ListItem fontStyle="italic">
                          Include [specific client quote not yet in note]
                        </ListItem>
                        <ListItem fontStyle="italic">
                          Detail [observations you have of the client]
                        </ListItem>
                        <ListItem fontStyle="italic">
                          Document [specifics not discussed in-session]
                        </ListItem>
                        <ListItem fontStyle="italic">
                          Include [client actions outside of session]
                        </ListItem>
                      </List>
                    </Box>
                  )}
                </VStack>
                {notePreferencesEnabled && !memoryEnabled && (
                  <Box bg="#2D54E80A" borderRadius="8px" py={4} px={5} w="100%">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <NoteSettings fill="black" />
                        <Text fontWeight="bold" ml={2}>
                          Your note preferences
                        </Text>
                      </Box>
                      <Box>
                        <Button
                          color="primary"
                          onClick={handleGoToSettings}
                          ml={2}
                          alignSelf="flex-end"
                          variant="link"
                          textDecoration="none"
                          rightIcon={<ArrowRightIcon fill="primary" />}
                          sx={{
                            '.chakra-button__icon': {
                              marginLeft: 0,
                              position: 'relative',
                              top: '1px'
                            }
                          }}
                          _focus={{ outline: 'none' }}
                        >
                          Go to settings
                        </Button>
                      </Box>
                    </Box>
                    <Flex gap="16px" mt={4} lineHeight="18px">
                      <Box flex="1">
                        <Box flex="1">
                          <Text fontSize="13px" as="span" fontWeight="bold">
                            Terminology:
                          </Text>
                          <Text fontSize="13px" as="span" ml={1}>
                            {getPreferenceLabel(
                              preferences.selfReference,
                              preferenceOptions?.selfReference?.options
                            )}
                            {', '}
                            {getPreferenceLabel(
                              preferences.patientReference,
                              preferenceOptions?.patientReference?.options
                            )}
                          </Text>
                        </Box>
                        <Box flex="1">
                          <Text fontSize="13px" as="span" fontWeight="bold">
                            Length of note:
                          </Text>
                          <Text fontSize="13px" as="span" ml={1}>
                            {getPreferenceLabel(
                              preferences.noteLength,
                              preferenceOptions?.noteLength?.options
                            )}
                          </Text>
                        </Box>
                        <Box flex="1">
                          <Text fontSize="13px" as="span" fontWeight="bold">
                            Medical Necessity:
                          </Text>
                          <Text fontSize="13px" as="span" ml={1}>
                            {getPreferenceLabel(
                              preferences.medicalNecessity,
                              preferenceOptions?.medicalNecessity?.options
                            )}
                          </Text>
                        </Box>
                        <Box flex="1">
                          <Text fontSize="13px" as="span" fontWeight="bold">
                            Diagnostic Symptoms:
                          </Text>
                          <Text fontSize="13px" as="span" ml={1}>
                            {getPreferenceLabel(
                              preferences.diagnosticSymptoms,
                              preferenceOptions?.diagnosticSymptoms?.options
                            )}
                          </Text>
                        </Box>
                      </Box>
                      <Box flex="1">
                        <Box flex="1">
                          <Text fontSize="13px" as="span" fontWeight="bold">
                            Action Items:
                          </Text>
                          <Text fontSize="13px" as="span" ml={1}>
                            {getPreferenceLabel(
                              preferences.actionItems,
                              preferenceOptions?.actionItems?.options
                            )}
                          </Text>
                        </Box>
                        <Box flex="1">
                          <Text fontSize="13px" as="span" fontWeight="bold">
                            Direct Quotes:
                          </Text>
                          <Text fontSize="13px" as="span" ml={1}>
                            {getPreferenceLabel(
                              preferences.directQuotes,
                              preferenceOptions?.directQuotes?.options
                            )}
                          </Text>
                        </Box>
                        {/* <Box flex="1">
                        <Text fontSize="13px" as="span" fontWeight="bold">
                          Formatting:
                        </Text>
                        <Text fontSize="13px" as="span" ml={1}>
                          {getPreferenceLabel(
                            preferences.formatting,
                            preferenceOptions?.formatting?.options
                          )}
                        </Text>
                      </Box> */}
                        <Box flex="1">
                          <Text fontSize="13px" as="span" fontWeight="bold">
                            Mental Status Exam:
                          </Text>
                          <Text fontSize="13px" as="span" ml={1}>
                            {preferences.mentalStatusExam.length === 0
                              ? 'None'
                              : preferences.mentalStatusExam
                                  .map((i: string) => capitalize(i))
                                  .join(', ')}
                          </Text>
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                )}
              </Box>
              <DrawerFooter width="100%" px="small" py={0}>
                <Box w="100%">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    py={6}
                  >
                    <Button
                      colorScheme="primary"
                      variant="solid"
                      type="submit"
                      onClick={submitRegenerateProgressNote}
                      isFullWidth
                      size="lg"
                      m={0}
                      leftIcon={<Refresh fill="White" h="16px" w="16px" />}
                      isLoading={isLoading}
                      isDisabled={!userInstructions.trim()}
                    >
                      Generate new note
                    </Button>
                  </Box>
                </Box>
              </DrawerFooter>
            </>
          )}
        </DrawerContent>
      </ChakraDrawer>
    </>
  )
}
