import React, { useState } from 'react'
import { Box, useDisclosure} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { useStoreState } from 'easy-peasy'
import { StoreModel } from 'src/store/types'
import { ReferralBanner } from './referral-banner'
import { WelcomeAndSurveyBanner } from './welcome-banner'
import { WelcomeVideoModal } from '@handlers/signup-v2/components/welcome-video-modal'

interface InfoBannerProps {
  hasLessThanNumberRecordedSessions: boolean
}

const InfoBanner = ({ hasLessThanNumberRecordedSessions }: InfoBannerProps) => {
  const [isVisible, setIsVisible] = useState(true)
  
  const user = useStoreState((state: StoreModel) => state.auth.user)
  
  const {
    isDocumentationAutomationFreePlan,
    showReferralV2Experience
  } = useExperienceManager()

  const { 
    isOpen: isOpenPreviewVideo,
    onOpen: onOpenPreviewVideo,
    onClose: onClosePreviewVideo
  } = useDisclosure()

  const localStorageDismissedKey = 'welcomeBannerDismissed'
  const localStorageDismissedAtKey = 'welcomeBannerDismissedAt'

  const showReferralBanner =
    showReferralV2Experience && !isDocumentationAutomationFreePlan

  const handleClose = () => {
    localStorage.setItem(localStorageDismissedKey, 'true')
    localStorage.setItem(localStorageDismissedAtKey, Date.now().toString())
    setIsVisible(false)
  }

  if (!isVisible || !user) return null

  return (
    <Box position="relative">
      {showReferralBanner ? (
        <ReferralBanner handleClose={handleClose} />
      ) : (
        hasLessThanNumberRecordedSessions && (
          <WelcomeAndSurveyBanner
            user={user}
            handleClose={handleClose}
            setIsVisible={setIsVisible}
            localStorageDismissedAtKey={localStorageDismissedAtKey}
            localStorageDismissedKey={localStorageDismissedKey}
            onOpenPreviewVideo={onOpenPreviewVideo}
          />
        )
      )}
      <WelcomeVideoModal
        isOpenPreviewVideo={isOpenPreviewVideo}
        onClosePreviewVideo={onClosePreviewVideo} />
    </Box>
  )
}

export default InfoBanner
