import React from 'react'
import {
  Flex,
  Text,
  Box,
  Button,
  HStack,
  useBreakpointValue,
  useToast,
  useDisclosure,
  LinkBox,
  LinkOverlay
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { useSessionControllerDeleteSession } from '~/clinician-api'
import { SessionMenu } from '@handlers/sessions/components/session-menu'
import { DeleteConfirmation } from '@components'
import { ArrowRightIcon } from '@blueprinthq/joy'
import { formatDuration } from '@handlers/sessions/util'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { RadioButtonLoading } from '@components/icons'

interface SessionListItemProps {
  patientId: string
  sessionId: string
  header: string
  startedAt: string
  completedAt: string
  isUpload: boolean
  clinicianName: string
  inProgress: boolean
  isDictation: boolean
  showMenu?: boolean
  onDelete?: () => void
  isLoading?: boolean
}

export const SessionListItem = ({
  patientId,
  sessionId,
  header,
  startedAt,
  completedAt,
  isUpload,
  clinicianName,
  inProgress,
  isDictation,
  showMenu = true,
  onDelete,
  isLoading = false
}: SessionListItemProps) => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  const toast = useToast()

  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onCloseMenu
  } = useDisclosure()

  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: onDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose
  } = useDisclosure()

  const history = useHistory()
  const {
    mutateAsync: deleteSession,
    isLoading: isDeleting
  } = useSessionControllerDeleteSession()

  const handleDeleteClick = () => {
    onDeleteConfirmationOpen()
    setIsHovered(false)
  }

  const handleDeleteSession = () => {
    deleteSession(
      { id: sessionId },
      {
        onSuccess: () => {
          toast({
            title: 'Session deleted',
            status: 'success',
            isClosable: true,
            duration: 3000
          })
          onDeleteConfirmationClose()
          if (onDelete) {
            onDelete()
          }
        }
      }
    )
  }

  const buttonSize = useBreakpointValue({
    base: 'sm',
    sm: 'md'
  })

  const handleHover = (isHovered: boolean) => {
    setIsHovered(isHovered)
  }

  const sessionUrl =
    patientId && sessionId
      ? !inProgress
        ? `/patient/${patientId}/completed-session/${sessionId}`
        : isDictation
        ? `/patient/${patientId}/dictate/session/${sessionId}`
        : `/patient/${patientId}/session/${sessionId}`
      : '#'

  return (
    <LinkBox
      display="flex"
      w="100%"
      background="transparent"
      cursor="pointer"
      p="7px 12px"
      _hover={{ bg: '#F9F9F9' }}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <LinkOverlay as={RouterLink} to={sessionUrl} />
      <Flex py={1} w="100%" alignItems="center">
        <Box _hover={{ cursor: 'pointer' }}>
          <Box
            display="flex"
            mr="small"
            background="#EAEEFD"
            width="48px"
            height="48px"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
          >
            {isLoading ? (
              <RadioButtonLoading />
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"
                  fill="#2D54E8"
                />
              </svg>
            )}
          </Box>
        </Box>
        <Flex flex="1" wrap="wrap" justifyContent="space-between">
          <Flex w="100%">
            <Box>
              <Text
                sx={{
                  fontSize: 'md',
                  fontWeight: 'bold',
                  display: 'inline',
                  wordBreak: 'break-word'
                }}
              >
                {header}
              </Text>
              <Box fontSize="xsm">
                <Flex flexDirection="row">
                  <Text as="span" fontSize="inherit">
                    {clinicianName}
                  </Text>
                  {!isUpload && (
                    <>
                      <Text
                        as="span"
                        fontSize="inherit"
                        mx="10px"
                        color="light_gray"
                      >
                        |
                      </Text>
                      <Text as="span" fontSize="inherit">
                        {moment(startedAt).format('h:mma')}
                      </Text>
                      {!inProgress && (
                        <>
                          <Text
                            as="span"
                            fontSize="inherit"
                            mx="10px"
                            color="light_gray"
                          >
                            |
                          </Text>
                          <Text as="span" fontSize="inherit">
                            {formatDuration(
                              startedAt,
                              completedAt || moment.utc().format()
                            )}
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Box>
          {inProgress ? (
            <Button as={RouterLink} to={sessionUrl} size={buttonSize} m={0}>
              {isDictation ? 'Resume Dictation' : 'Resume Session'}
            </Button>
          ) : (
            <HStack alignItems="center" spacing={2}>
              <Box onClick={e => e.stopPropagation()}>
                {(isHovered || isMenuOpen) && showMenu && (
                  <SessionMenu
                    isOpen={isMenuOpen}
                    onOpen={onMenuOpen}
                    onClose={onCloseMenu}
                    onDelete={handleDeleteClick}
                  />
                )}
              </Box>
              {(isHovered || isMenuOpen) && <ArrowRightIcon fill="#A1A1A1" />}
            </HStack>
          )}
        </Box>
      </Flex>
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        onClose={onDeleteConfirmationClose}
        onConfirm={handleDeleteSession}
        isConfirming={isDeleting}
        header="Delete session?"
        text="Deleting a session will permanently remove all the notes, summaries, and transcript associated to this session."
        confirmText="Delete session"
      />
    </LinkBox>
  )
}
