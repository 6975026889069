import React, { useState, useEffect } from 'react'
import { HEX_CODES } from '@constants'
import styles from './chart-info-styles'
import { makeStyles } from '@material-ui/core'
import _, { find } from 'lodash'

import { Text, SimpleGrid, Box, Grid, Spinner, Link } from '@chakra-ui/react'
import { formatScoreToInterpretation } from '@utilities'

const useStyles = makeStyles(styles)

export const ChartInfo = props => {
  const { data, baseline_scores, client } = props
  const classes = useStyles()
  const [positiveResponses, setPositiveResponses] = useState([])
  const [negativeResponses, setNegativeResponses] = useState([])
  const [otherResponses, setOtherResponses] = useState([])
  useEffect(() => {
    sortAssessments()
  }, [data])
  const sortAssessments = () => {
    let positiveResponses = []
    let negativeResponses = []
    let otherResponses = []
    if (!data || !data.length) {
      return null
    } else {
      data.forEach((assessment_group, i) => {
        const scores = assessment_group.scores
        const last_score = scores[scores.length - 1].total_score
        const is_reverse_valence = assessment_group.is_reverse_valence

        let first_score =
          baseline_scores.length > 0
            ? baseline_scores[i].total_score
            : scores[0].total_score
        if (first_score === 0 && last_score !== 0) {
          first_score = 0.1
        }

        let change
        if (scores.length > 1) {
          change = Math.round(((last_score - first_score) / first_score) * 100)
        }

        if (Number.isNaN(change) || scores.length < 2) {
          otherResponses.push(
            <Box maxW={'210px'} w={'210px'} height="100%">
              <AssessmentSection
                client={client}
                assessment_group={assessment_group}
                index={i}
                key={i}
                updateChart={(e, id) => props.changeChecked(e, id)}
                checked={props.checked}
                change={change}
                scores={scores}
                last_score={last_score}
                is_reverse_valence={is_reverse_valence}
              />
            </Box>
          )
        }
        if (
          (change >= 0 && !is_reverse_valence) ||
          (change <= 0 && is_reverse_valence)
        ) {
          negativeResponses.push(
            <Box maxW={'210px'} w={'210px'} height="100%">
              <AssessmentSection
                client={client}
                assessment_group={assessment_group}
                index={i}
                key={i}
                updateChart={(e, id) => props.changeChecked(e, id)}
                checked={props.checked}
                change={change}
                scores={scores}
                last_score={last_score}
                is_reverse_valence={is_reverse_valence}
              />
            </Box>
          )
        }
        if (
          (change < 0 && !is_reverse_valence) ||
          (change > 0 && is_reverse_valence)
        ) {
          positiveResponses.push(
            <Box maxW={'210px'} w={'210px'} height="100%">
              <AssessmentSection
                client={client}
                assessment_group={assessment_group}
                index={i}
                key={i}
                updateChart={(e, id) => props.changeChecked(e, id)}
                checked={props.checked}
                change={change}
                scores={scores}
                last_score={last_score}
                is_reverse_valence={is_reverse_valence}
              />
            </Box>
          )
        }
        setPositiveResponses(positiveResponses)
        setNegativeResponses(negativeResponses)
        setOtherResponses(otherResponses)
      })
    }
  }

  const allResponses = [].concat(
    positiveResponses,
    negativeResponses,
    otherResponses
  )
  return (
    <>
      <SimpleGrid columns={4}>
        {allResponses.map((assessmentSection, i) => {
          return (
            <React.Fragment key={`assessment-section-${i}`}>
              {assessmentSection}
            </React.Fragment>
          )
        })}
      </SimpleGrid>
    </>
  )
}

const AssessmentSection = props => {
  const { assessment_group, index, scores, client } = props
  const classes = useStyles()

  return (
    <div className={classes.individualAssessment} key={index}>
      <Subheader
        checked={props.checked}
        updateChart={(e, id) => props.updateChart(e, id)}
        assessment_group={assessment_group}
        index={index}
      />
    </div>
  )
}

function formatScoreToInterpretationWrapper(assessments, assessment_id, score) {
  const assessment = find(assessments, { assessment_id: assessment_id })
  if (assessment) return formatScoreToInterpretation(assessment, score)
  else return ''
}

function findMostRecentScore(array) {
  if (array.length === 0) {
    return null // Return null if the array is empty
  }

  let mostRecentObject = array[0]

  for (let i = 1; i < array.length; i++) {
    if (new Date(array[i].created_at) > new Date(mostRecentObject.created_at)) {
      mostRecentObject = array[i]
    }
  }

  return mostRecentObject
}

const Subheader = props => {
  const { assessment_group, index } = props
  const mostRecentScore = findMostRecentScore(assessment_group.scores)
  const score = formatScoreToInterpretationWrapper(
    props.checked,
    assessment_group.assessment_id,
    mostRecentScore.total_score
  )
  const classes = useStyles()
  const [checked, setChecked] = useState(props.checked)
  const [load, setLoad] = useState(false)
  const handleCheck = (e, id) => {
    setLoad(true)
    props.updateChart(e, assessment_group.assessment_id)
    if (e) {
      const filter = props.checked.filter(e => e.assessment_id === id)
      setChecked(filter)
      setLoad(false)
    } else {
      const filter = checked.filter(e => e.assessment_id !== id)
      setChecked(filter)
      setLoad(false)
    }
  }
  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])
  const isChecked =
    checked.includes(assessment_group) && !assessment_group.removed
  return (
    <div key={index} className={classes.subheader}>
      {!load && (
        <input
          type="checkbox"
          onChange={e =>
            handleCheck(e.target.checked, assessment_group.assessment_id)
          }
          checked={isChecked}
          style={{
            accentColor: HEX_CODES[index],
            marginRight: '2px',
            height: '18px',
            maxWidth: '18px',
            width: '100%',
            marginTop: '3px',
            marginLeft: '3px',
            border: `2px solid ${HEX_CODES[index]}`,
            MozAppearance: 'none',
            WebkitAppearance: isChecked ? '' : 'none',
            borderRadius: '2px'
          }}
        />
      )}
      {load && <Spinner color={'#2D54E8'} />}
      <Grid ml={'4px'} h={'100%'} xs={12}>
        <Grid xs={12}>
          <Text
            fontWeight={'bold'}
            fontFamily={'TofinoPersonal'}
            fontSize={'12px'}
            marginTop={'4px'}
          >
            {assessment_group.name}
          </Text>
        </Grid>
        <Grid mt={1} xs={12}>
          <Text
            fontWeight={'430'}
            fontFamily={'TofinoPersonal'}
            fontSize={'12px'}
          >
            {capitalizeFirstLetter(assessment_group.disorder)}
          </Text>
        </Grid>
      </Grid>
    </div>
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
