/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type CheckinsControllerV1GetAllCheckInsType = typeof CheckinsControllerV1GetAllCheckInsType[keyof typeof CheckinsControllerV1GetAllCheckInsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckinsControllerV1GetAllCheckInsType = {
  treatment_activity: 'treatment_activity',
  symptom: 'symptom',
} as const;
