import { timeToRelativeOrDate } from '@utilities'
import React from 'react'
import { Box, Text, VStack, Divider, HStack } from '@chakra-ui/react'
import { ArrowRightIcon } from '@blueprinthq/joy'

import { CheckInDtoV2, CheckInScoreDtoV1 } from '~/clinician-api/models'

type ScoreWithCheckIn = CheckInScoreDtoV1 & {
  checkIn?: CheckInDtoV2
}

export interface CompletedWorksheetListProps {
  scoresWithCheckIn: ScoreWithCheckIn[]
  onClickWorksheet: (score: ScoreWithCheckIn) => void
}

export const CompletedWorksheetList = ({
  scoresWithCheckIn,
  onClickWorksheet
}: CompletedWorksheetListProps) => {
  return (
    <Box border="1px solid" borderColor="pale_gray" rounded="md">
      {scoresWithCheckIn?.length === 0 ? (
        <Text p="small">No worksheets completed.</Text>
      ) : (
        <VStack
          flex={1}
          alignItems="flex-start"
          divider={
            <Divider
              color="pale_gray"
              mt="0px !important"
              mb="0px !important"
            />
          }
        >
          {scoresWithCheckIn.map(s => (
            <HStack
              key={s.id}
              p="small"
              alignItems="center"
              justify="space-between"
              w="100%"
              _hover={{
                bg: 'gray.200',
                cursor: 'pointer'
              }}
              onClick={() => {
                onClickWorksheet(s)
              }}
            >
              <Box maxW="215px" w="100%">
                <Text>{s.checkIn?.title}</Text>
              </Box>
              <HStack gap="small">
                <Text fontSize="small" color="gray">
                  {timeToRelativeOrDate(s.createdAt)}
                </Text>
                <Box minW="24px" minH="24px" w="24px" h="24px" flexShrink={0} display="flex" alignItems="center" justifyContent="center">
                  <ArrowRightIcon boxSize="24px" />
                </Box>
              </HStack>
            </HStack>
          ))}
        </VStack>
      )}
    </Box>
  )
}
