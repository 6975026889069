import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  Image,
  Icon,
  Button,
  Link as CLink
} from '@chakra-ui/react'
import {
  RadioButtonLoading,
  RadioButtonNotLoading,
  RadioButtonLoadingComplete
} from '@components/icons'
import { Link } from 'react-router-dom'
import { AlertBox } from '@components'
import InfoIcon from '@material-ui/icons/Info'
import { TimerOutlined } from '@material-ui/icons'
import { motion, AnimatePresence } from 'framer-motion'
import { datadogLogs } from '@datadog/browser-logs'
import { useExperienceManager, useWidget } from '@hooks'

const Status = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR'
}

type Step =
  | 'PROCESS_AUDIO'
  | 'TRANSCRIBING_AUDIO'
  | 'SUMMARIZE_CONVERSATION'
  | 'REQUEST_NOTE_GENERATION'
  | 'GENERATE_NOTE'
  | 'DELETE_AUDIO'
  | 'GENERATE_SUMMARY'
  | 'GENERATE_TREATMENT_PLAN'

type StepHandlerProps = {
  handleCompletedTranscriptClick?: () => void;
  handleCompletedSummaryClick?: () => void;
  handleCompletedTreatmentPlanClick?: () => void;
}

type ProgressNoteGenerationStepStatus = {
  step: Step;
  status: string;
}

type ProgressNoteGenerationStepStatusesProps = {
  progressNoteGenerationStepStatuses: ProgressNoteGenerationStepStatus[];
  isSlowLoading: boolean;
  sessionId: string;
  client?: {
    first_name: string;
  };
  isAssistSetupComplete: boolean;
  handleCompletedSummaryClick?: () => void;
  handleCompletedTranscriptClick?: () => void;
  handleCompletedTreatmentPlanClick?: () => void;
  handleFinishAssistSetup?: () => void;
}

const stepConfig = {
  PROCESS_AUDIO: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Process audio',
    [Status.STARTED]: (props: StepHandlerProps) => 'Processing audio',
    [Status.COMPLETED]: (props: StepHandlerProps) => 'Processed audio'
  },
  TRANSCRIBING_AUDIO: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Transcribe audio',
    [Status.STARTED]: (props: StepHandlerProps) => 'Transcribing audio',
    [Status.COMPLETED]: (props: StepHandlerProps) => (
      <Text as="span" cursor="pointer" color="primary" _hover={{ textDecoration: 'underline' }} onClick={props.handleCompletedTranscriptClick}>View transcript</Text>
    ),
    [Status.ERROR]: (props: StepHandlerProps) => 'Transcribing audio'
  },
  SUMMARIZE_CONVERSATION: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Summarize conversation',
    [Status.STARTED]: (props: StepHandlerProps) => 'Summarizing conversation',
    [Status.COMPLETED]: (props: StepHandlerProps) => (
      <Text as="span" cursor="pointer" color="primary" _hover={{ textDecoration: 'underline' }} onClick={props.handleCompletedSummaryClick}>Summarized conversation</Text>
    )
  },
  REQUEST_NOTE_GENERATION: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Request progress note',
    [Status.STARTED]: (props: StepHandlerProps) => 'Requesting progress note',
    [Status.COMPLETED]: (props: StepHandlerProps) => 'Requested progress note'
  },
  GENERATE_SUMMARY: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Generate summary',
    [Status.STARTED]: (props: StepHandlerProps) => 'Generating summary',
    [Status.COMPLETED]: (props: StepHandlerProps) => (
      <Text as="span" cursor="pointer" color="primary" _hover={{ textDecoration: 'underline' }} onClick={props.handleCompletedSummaryClick}>View summary</Text>
    )
  },
  GENERATE_TREATMENT_PLAN: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Generate treatment plan',
    [Status.STARTED]: (props: StepHandlerProps) => 'Generating treatment plan',
    [Status.COMPLETED]: (props: StepHandlerProps) => (
      <Text as="span" cursor="pointer" color="primary" _hover={{ textDecoration: 'underline' }} onClick={props.handleCompletedTreatmentPlanClick}>View treatment plan</Text>
    )
  },
  GENERATE_NOTE: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Generate note',
    [Status.STARTED]: (props: StepHandlerProps) => 'Generating note',
    [Status.COMPLETED]: (props: StepHandlerProps) => 'Generated note'
  },
  DELETE_AUDIO: {
    [Status.NOT_STARTED]: (props: StepHandlerProps) => 'Delete audio',
    [Status.STARTED]: (props: StepHandlerProps) => 'Deleting audio',
    [Status.COMPLETED]: (props: StepHandlerProps) => 'Deleted audio'
  }
}

const RadioButton = ({ status }: { status: any }) => {
  switch (status) {
    case Status.STARTED:
      return <RadioButtonLoading />
    case Status.COMPLETED:
      return <RadioButtonLoadingComplete />
    case Status.ERROR:
      return <InfoIcon style={{ color: '#EB5164' }} />
    default:
      return <RadioButtonNotLoading />
  }
}

const TimerIcon = (
  <Icon
    as={TimerOutlined}
    color="primary"
    mr="12px"
    boxSize={5}
    alignSelf="center"
  />
)

const MotionBox = motion(Box)

const ProgressNoteGenerationSteps = ({
  progressNoteGenerationStepStatuses,
  isSlowLoading,
  sessionId,
  client,
  isAssistSetupComplete,
  handleFinishAssistSetup,
  handleCompletedSummaryClick,
  handleCompletedTranscriptClick,
  handleCompletedTreatmentPlanClick
}: ProgressNoteGenerationStepStatusesProps) => {
  useEffect(() => {
    if (isSlowLoading) {
      datadogLogs.logger.info('[Slow Generation] - Showing info banner', {
        sessionId
      })
    }
  }, [isSlowLoading])

  const { isPlusPlanEnabled, isIntegratedUI  } = useExperienceManager()
  const { settings } = useWidget()

  const generationStepStatuses = settings?.hideTreatmentPlan
    ? progressNoteGenerationStepStatuses.filter(
        (step: ProgressNoteGenerationStepStatus) =>
          step.step !== 'GENERATE_TREATMENT_PLAN'
      )
    : progressNoteGenerationStepStatuses

  return (
    <Flex
      pb="32px"
      flexDirection="column"
      backgroundColor="white"
      flex="1"
      alignContent="center"
      alignItems="center"
    >
      <AnimatePresence>
        {isSlowLoading && (
          <MotionBox
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            overflow="hidden"
            transition={{ duration: 0.5 }}
            bg="white"
          >
            <AlertBox bg="white" icon={TimerIcon}>
              <Text mb="12px">
                Apologies for the delay—this is taking a bit longer than usual.
                Rest assured, we have everything we need and your note is still
                on track.
              </Text>
              {!isIntegratedUI && (
                <Button
                  m={0}
                  as={Link}
                  to="/sessions"
                  size="sm"
                  variant="outline"
                  borderRadius="4px"
                  _hover={{
                    bg: 'rgba(45, 84, 232, 0.04)'
                  }}
                >
                  Start a new session
                </Button>
              )}
            </AlertBox>
          </MotionBox>
        )}
      </AnimatePresence>
      <Image
        height="260"
        width="260"
        src="https://res.cloudinary.com/hellojoy/image/upload/v1734112184/smdn2p6niu3rjkwbrd6g.gif"
        alt="Generating Note"
      />
      <Text textAlign="center" fontWeight="bold" mb="xxsmall">
        Generating note...
      </Text>
      <Text textAlign="center" color="#757575">
        This process could take a few minutes.
        <br />
        {!isIntegratedUI &&
          (isPlusPlanEnabled ? (
            <>
              You can close this tab
              {!isAssistSetupComplete && (
                <>
                  ,{' '}
                  <CLink onClick={handleFinishAssistSetup} color="primary">
                    finish assist setup for {client?.first_name}
                  </CLink>
                </>
              )}{' '}
              or{' '}
              <CLink as={Link} to="/sessions" color="primary">
                start a new session
              </CLink>{' '}
              while we finish up.
            </>
          ) : (
            <>
              You can close this tab or{' '}
              <CLink as={Link} to="/sessions" color="primary">
                start a new session
              </CLink>{' '}
              while we finish up.
            </>
          ))}
      </Text>
      <Box h="40px" />
      <Flex flexDirection="column" alignSelf="center" gap="16px">
        {generationStepStatuses.map(
          (step: ProgressNoteGenerationStepStatus, index: number) => (
            <Flex key={index}>
              <RadioButton status={step.status} />
              <Box w="8px" />
              {stepConfig[step.step]?.[step.status]?.({
                handleCompletedTranscriptClick,
                handleCompletedSummaryClick,
                handleCompletedTreatmentPlanClick
              }) || ''}
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  )
}

export default ProgressNoteGenerationSteps
