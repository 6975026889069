import React from 'react'
import { Container, Stack, Flex, Text, useToast } from '@chakra-ui/react'
import { useAssistPanelContext } from '../context'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import { CompletedInterventions } from './completed-intervention-list'
import { SavedForLater } from './saved-for-later'
import { Suggestions } from './suggestions'
import { InProgress } from './in-progress'
import { endpoints } from '@api'

import { useQuery, useQueryClient } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import AvailableSoonSVG from './available-soon-svg'

import {
  useInterventionsControllerGetSavedForLaterInterventions,
  usePatientInterventionsControllerGetAllPatientInterventions,
  usePatientInterventionsControllerDeletePatientIntervention,
  getPatientInterventionsControllerGetAllPatientInterventionsQueryKey,
  useInterventionsControllerRemoveInterventionFromSavedForLater,
  getInterventionsControllerGetSavedForLaterInterventionsQueryKey,
  getSessionControllerGetDemoSuggestedContentQueryKey,
  getSessionControllerGetSuggestedContentQueryKey
} from '~/clinician-api'
import { AssistPanelOverlay } from '../overlay-panel'

export const Interventions = () => {
  const { client } = useTreatmentPlan()
  const { setOverlayPanel } = useAssistPanelContext()
  const { isSessionRecordingV2Enabled } = useExperienceManager()

  const queryClient = useQueryClient()
  const toast = useToast()

  let match: any = useRouteMatch('/patient/:patientId/session/:sessionId')

  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId(), client?.id],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client?.id
      })
    },
    {
      enabled: !!client?.id
    }
  )

  const sessionId = lastSessionQuery?.data?.id || match?.params.sessionId

  const {
    data: allPatientInterventions
  } = usePatientInterventionsControllerGetAllPatientInterventions(
    client?.id ?? '',
    {},
    {
      query: {
        initialData: [],
        enabled: !!client?.id
      }
    }
  )

  const {
    data: savedForLaterInterventions
  } = useInterventionsControllerGetSavedForLaterInterventions(
    client?.id ?? '',
    {
      query: {
        initialData: [],
        enabled: !!client?.id
      }
    }
  )

  const {
    mutateAsync: deleteClientIntervention
  } = usePatientInterventionsControllerDeletePatientIntervention()

  const handleDiscard = async (patientInterventionId: string) => {
    deleteClientIntervention(
      { patientId: client?.id ?? '', patientInterventionId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(
              client?.id ?? ''
            )
          )
          queryClient.invalidateQueries(
            getSessionControllerGetDemoSuggestedContentQueryKey(sessionId, {
              demoContext: 'in-session'
            })
          )
          queryClient.invalidateQueries(
            getSessionControllerGetSuggestedContentQueryKey(sessionId)
          )
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error discarding this activity',
            status: 'error',
            isClosable: true,
            duration: 2000
          })
        }
      }
    )
  }

  const {
    mutateAsync: removeInterventionSavedForLater
  } = useInterventionsControllerRemoveInterventionFromSavedForLater({
    mutation: {
      onSuccess: async () => {
        toast({
          title: 'Intervention removed from Saved for Later',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        queryClient.invalidateQueries(
          getInterventionsControllerGetSavedForLaterInterventionsQueryKey(
            client?.id ?? ''
          )
        )
        queryClient.invalidateQueries(
          getSessionControllerGetDemoSuggestedContentQueryKey(sessionId, {
            demoContext: 'in-session'
          })
        )
        queryClient.invalidateQueries(
          getSessionControllerGetSuggestedContentQueryKey(sessionId)
        )
      }
    }
  })

  const handleRemoveFromSaved = async (interventionId: string) => {
    await removeInterventionSavedForLater({
      patientId: client?.id ?? '',
      interventionId
    })
  }

  const handleBrowseLibraryClick = () => {
    setOverlayPanel(AssistPanelOverlay.INTERVENTION_LIBRARY)
  }

  const handleViewInterventionOverview = ({
    interventionId,
    interventionName,
    contentSuggestionId
  }: {
    interventionId: string
    interventionName: string
    contentSuggestionId?: string | null
  }) => {
    setOverlayPanel(AssistPanelOverlay.INTERVENTION_OVERVIEW, {
      interventionId,
      interventionName,
      contentSuggestionId
    })
  }

  const handleResumeInterventionClick = (patientIntervention: any) => {
    setOverlayPanel(AssistPanelOverlay.INTERVENTION_ACTIVITY, {
      interventionName: patientIntervention.name,
      interventionId: patientIntervention.interventionId,
      patientInterventionId: patientIntervention.id,
      isReadOnly: false
    })
  }

  const handleCompletedInterventionClick = (patientIntervention: any) => {
    setOverlayPanel(AssistPanelOverlay.INTERVENTION_ACTIVITY, {
      interventionName: patientIntervention.name,
      interventionId: patientIntervention.interventionId,
      patientInterventionId: patientIntervention.id,
      isReadOnly: true
    })
  }

  const inProgressInterventions =
    allPatientInterventions?.filter(pi => !pi.isComplete) || []
  // @ts-ignore
  const completedInterventions =
    allPatientInterventions
      ?.filter(pi => pi.isComplete)
      .sort(
        (a, b) =>
          moment(b.completedAt).valueOf() - moment(a.completedAt).valueOf()
      ) || []

  return (
    <Container
      h={
        isSessionRecordingV2Enabled
          ? 'calc(100vh - 150px)'
          : 'calc(100vh - 208px)'
      }
      pt="24px"
      overflowY="scroll"
    >
      <Stack flex="1" gap="24px" spacing="0">
        <Flex
          flexDirection="column"
          pb={{ base: '140px', md: '40px' }}
          gap="24px"
        >
          <InProgress
            interventions={inProgressInterventions}
            onResume={handleResumeInterventionClick}
            onDiscard={handleDiscard}
          />
          <SavedForLater
            onInterventionClick={handleViewInterventionOverview}
            onRemoveFromSavedClick={handleRemoveFromSaved}
            interventions={savedForLaterInterventions || []}
            onBrowseLibraryClick={handleBrowseLibraryClick}
          />
          <Suggestions sessionId={sessionId} />
          <CompletedInterventions
            interventions={completedInterventions}
            onClick={handleCompletedInterventionClick}
          />
        </Flex>
      </Stack>
    </Container>
  )
}
