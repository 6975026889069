import React, { useCallback } from 'react'
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@blueprinthq/joy'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Clipboard } from '@components/icons'
import { isMobile } from 'react-device-detect'


interface SavedForLaterProps {
  onInterventionClick: ({
    interventionId,
    interventionName,
    contentSuggestionId,
  }: {
    interventionId: string
    interventionName: string
    contentSuggestionId?: string
  }) => void
  onRemoveFromSavedClick: (interventionId: string) => void
  onBrowseLibraryClick?: () => void
  interventions: any[]
}

export const SavedForLater = ({
  onInterventionClick,
  onRemoveFromSavedClick,
  interventions,
  onBrowseLibraryClick
}: SavedForLaterProps) => {
  
  const handleInterventionClick = useCallback((s: any, e: React.MouseEvent | React.TouchEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    onInterventionClick({
      interventionId: s.interventionId,
      interventionName: s.interventionName,
      contentSuggestionId: s.contentSuggestionId
    });
  }, [onInterventionClick]);
  
  const handleRemoveClick = useCallback((e: React.MouseEvent | React.TouchEvent, interventionId: string) => {
    // Ensure the event doesn't propagate to parent elements
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    // Add a small delay to ensure the event doesn't propagate
    setTimeout(() => {
      onRemoveFromSavedClick(interventionId);
    }, 10);
    
    return false;
  }, [onRemoveFromSavedClick]);


  return (
    <Box>
      <Flex justifyContent="space-between">
        <Text fontWeight="bold" mb="xsmall">
          Saved for Later
        </Text>
        <Text
          color="primary"
          _hover={{ cursor: 'pointer' }}
          onClick={onBrowseLibraryClick}
        >
          Browse library
        </Text>
      </Flex>
      <Box border="1px solid" borderColor="pale_gray" rounded="md">
        {interventions?.length === 0 ? (
          <Text p="16px">No saved interventions.</Text>
        ) : (
          <VStack
            flex={1}
            alignItems="flex-start"
            divider={
              <Divider
                color="pale_gray"
                mt="0px !important"
                mb="0px !important"
              />
            }
          >
            {interventions &&
              interventions.map(s => (
                <div
                  key={s.interventionId}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    position: 'relative',
                    touchAction: 'manipulation'
                  }}
                  onClick={(e) => handleInterventionClick(s, e)}
                  onTouchStart={(e) => e.preventDefault()}
                  onTouchEnd={(e) => handleInterventionClick(s, e)}
                  role="button"
                  tabIndex={0}
                  aria-label={`Open ${s.interventionName} intervention`}
                >
                  <Flex
                    pl="16px"
                    pr="16px"
                    pb="12px"
                    pt="12px"
                    alignItems="center"
                    justify="space-between"
                    w="100%"
                    _hover={{
                      bg: 'gray.200'
                    }}
                    role="group"
                  >
                    <HStack spacing="8px">
                      <Box minW="24px" minH="24px" w="24px" h="24px" flexShrink={0} display="flex" alignItems="center" justifyContent="center">
                        <Clipboard fill="black" width={16} height={16} />
                      </Box>
                      <Text>{s.interventionName}</Text>
                    </HStack>
                    <HStack spacing={2}>
                      <Menu isLazy placement="bottom-end" closeOnSelect>
                        <Box 
                          position="relative" 
                          zIndex={2}
                          onClick={(e) => e.stopPropagation()}
                          onTouchStart={(e) => e.stopPropagation()}
                          onTouchEnd={(e) => e.stopPropagation()}
                        >
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<MoreHorizIcon />}
                            variant="ghost"
                            height="auto"
                            size="sm"
                            _focus={{
                              outline: 'none',
                              outlineStyle: 'none'
                            }}
                            opacity={isMobile ? "1" : "0"}
                            transition="opacity 0.2s"
                            _groupHover={{ opacity: '1' }}
                          />
                        </Box>
                        <MenuList zIndex={10}>
                          <MenuItem
                            onClick={(e: any) => handleRemoveClick(e, s.interventionId)}
                            onTouchStart={(e: any) => e.stopPropagation()}
                            onTouchEnd={(e: any) => handleRemoveClick(e, s.interventionId)}
                          >
                            Remove from Saved
                          </MenuItem>
                        </MenuList>
                      </Menu>
                      <Box minW="24px" minH="24px" w="24px" h="24px" flexShrink={0} display="flex" alignItems="center" justifyContent="center">
                        <ArrowRightIcon />
                      </Box>
                    </HStack>
                  </Flex>
                </div>
              ))}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
