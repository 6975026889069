import React, { useEffect, useRef } from 'react'
import {
  Flex,
  Text,
  VStack,
  Stack,
  Box,
  Divider,
  Button,
  useToast,
  Link
} from '@chakra-ui/react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { useQueryClient } from 'react-query'
import { useAssistPanelContext } from '../../../in-session-assist-panel/context'
import { useExperienceManager } from '@hooks'
import { endpoints } from '@api'
import { useQuery } from 'react-query'
import {
  useInterventionsControllerSaveInterventionForLater,
  getInterventionsControllerGetSavedForLaterInterventionsQueryKey,
  getInterventionsControllerV1GetAllInterventionsQueryKey,
  useSessionControllerGetDemoSuggestedContent,
  useSessionControllerGetSuggestedContent
} from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
import { isMobile } from 'react-device-detect'
import { AssistPanelOverlay } from '../../../in-session-assist-panel/overlay-panel'

interface InterventionListProps {
  interventionList: any[] | undefined,
  clientId: string,
  client?: any
}

export const InterventionList = ({
  clientId,
  interventionList,
  client
}: InterventionListProps) => {
  const {
    setOverlayPanel,
    interventionLibraryData,
    saveInterventionLibraryData
  } = useAssistPanelContext()
  const queryClient = useQueryClient()
  const { isSessionRecordingV2Enabled } = useExperienceManager()
  const toast = useToast()
  const scrollRef = useRef<HTMLDivElement>(null)


  const { isDemoClinician } = useExperienceManager()


  const showDemoExperience = client?.is_demo && isDemoClinician
  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId(), client?.id],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client?.id
      })
    },
    {
      enabled: !!client?.id
    }
  )

  const sessionId = lastSessionQuery?.data?.id

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId,
    { demoContext: 'in-session' },
    {
      query: {
        enabled: showDemoExperience,
      }
    }
  )

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId && !showDemoExperience,
    }
  })

  const suggestedInterventions = showDemoExperience
  ? demoData?.suggestedInterventions?.filter(intervention => !intervention.isActive)
  : suggestionsQuery?.data?.suggestedInterventions?.filter(intervention => !intervention.isActive)


  useEffect(() => {
    if (scrollRef.current && interventionLibraryData.scrollTop) {
      scrollRef.current.scrollTop = interventionLibraryData.scrollTop
    }
  }, [scrollRef.current, interventionLibraryData.scrollTop])

  const { isOpen: isSaveInterventionsModalOpen } = useStoreState(
    // @ts-ignore
    state => state.modals.saveInterventions
  )

  const location = useLocation()
  const [baseUrl] = location.pathname.split('/patient')

  const {
    mutateAsync: saveInterventionForLater
  } = useInterventionsControllerSaveInterventionForLater({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Intervention Saved for Later',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        queryClient.invalidateQueries(
          getInterventionsControllerGetSavedForLaterInterventionsQueryKey(
            clientId
          )
        )
        queryClient.invalidateQueries(
          getInterventionsControllerV1GetAllInterventionsQueryKey()
        )
      }
    }
  })

  if (!Boolean(interventionList?.length)) return null

  return (
    <Box
      h={
        isSaveInterventionsModalOpen
          ? 'calc(100vh - 380px)'
          : isSessionRecordingV2Enabled
          ? 'calc(100vh - 235px)'
          : 'calc(100vh - 293px)'
      }
    >
         <VStack
        ref={scrollRef}
        overflowY="scroll"
        h="100%"
 
      >
      {suggestedInterventions && suggestedInterventions?.length > 0 && (
        <>
        <Box p="4px 24px" w="100%" mt="8px" bg="#F4F4F4">
        <Text fontWeight="bold" fontSize="12px" color="#757575" textTransform="uppercase">
          Suggestions
        </Text>
      </Box>
      <VStack
        px="24px"
   
        h="100%"
 
        divider={<Divider color="pale_gray" />}
      >
        {suggestedInterventions?.map((intervention: any) => (
          <Flex
          pt={'4px'}
          pb={'32px'}
            justify="space-between"
            key={intervention.interventionId}
            w="100%"
          >
            <Flex align="center" flex="2" minW="0">
              <Box flex="0.9" gap="4px" minW="0">
                <Text fontSize={isMobile ? "14px" : "16px"}>{intervention.interventionName}</Text>
                <Text
                  color="dark_gray"
                  sx={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word'
                  }}
                  fontSize={isMobile ? "12px" : "14px"}
                >
                  {intervention.rationale}
                </Text>

              </Box>
            </Flex>

            <Stack
              direction={{ base: 'column', sm: 'row' }}
              align={{ base: 'center', sm: 'center' }}
              spacing={{ base: '12px', sm: '12px' }}
              gap="12px"
            >
              {isSaveInterventionsModalOpen ? (
                <Link
                  id={`pendo-intervention-view-link-tag-${intervention.interventionId}`}
                  as={RouterLink}
                  color="primary"
                  isExternal={true}
                  target="_blank"
                  rel="noopener noreferrer"
                  flexShrink={0}
                  _focus={{
                    outline: 'none'
                  }}
                  order={{ base: 2, sm: 1 }}
                  to={{
                    pathname: `${baseUrl}/patient/${clientId}/interventions/${intervention.interventionId}/summary?isPlus=true`,
                    state: { prevLocation: location.pathname }
                  }}
                >
                  <Text fontSize={isMobile ? "14px" : "16px"}>Learn more</Text>
                </Link>
              ) : (
                <Text
                  fontSize={isMobile ? "14px" : "16px"}
                  color="primary"
                  _hover={{ cursor: 'pointer' }}
                  order={{ base: 2, sm: 1 }}
                  onClick={() => {
                    saveInterventionLibraryData({ scrollTop: scrollRef.current?.scrollTop })
                    setOverlayPanel(AssistPanelOverlay.INTERVENTION_OVERVIEW, {
                      interventionId: intervention.interventionId,
                      interventionName: intervention.interventionName
                    })
                  }}
                >
                  Learn more
                </Text>
              )}
              <Button
                isDisabled={intervention.isActive}
                paddingLeft={'6'}
                paddingRight={'6'}
                borderRadius="8px"
                size="sm"
                w="125px"
                order={{ base: 1, sm: 2 }}
                onClick={() => {
                  saveInterventionForLater({
                    patientId: clientId,
                    interventionId: intervention.interventionId,
                    data: {}
                  })
                }}
                _focus={{
                  outline: 'none'
                }}
              >
                <Text fontSize={isMobile ? "14px" : "16px"}>
                  {intervention.isActive
                    ? 'Saved for later'
                    : 'Save for later'}
                </Text>
              </Button>
            </Stack>
          </Flex>
        ))}
      </VStack>
      </>
      )}

      <Box p="4px 24px" w="100%" mt="8px" bg="#F4F4F4">
        <Text fontWeight="bold" fontSize="12px" color="#757575" textTransform="uppercase">
          A to Z
        </Text>
      </Box>
      <VStack
        pt={'4px'}
        pb={'32px'}
        pl={'24px'}
        pr={'24px'}
        h="100%"
 
        divider={<Divider color="pale_gray" />}
      >
        {interventionList?.map((intervention: any) => (
          <Flex
            mb="12px"
            justify="space-between"
            key={intervention.id}
            w="100%"
            gap="24px"
          >
            <Flex align="center" flex="2" minW="0">
              <Box flex="0.9" gap="4px" minW="0">
                <Text fontSize={isMobile ? "14px" : "16px"}>{intervention.name}</Text>
                <Text
                  color="dark_gray"
                  sx={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    marginTop: '4px'
                  }}
                  fontSize={isMobile ? "12px" : "14px"}
                >
                  {intervention.shortDescription}
                </Text>

                <Box
                  p="2px 4px"
                  bg="#F4F4F4"
                  mt="4px"
                  width="fit-content"
                  borderRadius="3px"
                >
                  <Text
                    fontSize="12px"
                    fontWeight="bold"
                    color="#757575"
                    textTransform="uppercase"
                  >
                    {intervention.treatmentModality}
                  </Text>
                </Box>
              </Box>
            </Flex>

            <Stack
              direction={{ base: 'column', sm: 'row' }}
              align={{ base: 'center', sm: 'center' }}
              spacing={{ base: '12px', sm: '12px' }}
              gap="12px"
            >
              {isSaveInterventionsModalOpen ? (
                <Link
                  id={`pendo-intervention-view-link-tag-${intervention.id}`}
                  as={RouterLink}
                  color="primary"
                  isExternal={true}
                  target="_blank"
                  rel="noopener noreferrer"
                  flexShrink={0}
                  _focus={{
                    outline: 'none'
                  }}
                  order={{ base: 2, sm: 1 }}
                  to={{
                    pathname: `${baseUrl}/patient/${clientId}/interventions/${intervention.id}/summary?isPlus=true`,
                    state: { prevLocation: location.pathname }
                  }}
                >
                  <Text fontSize={isMobile ? "14px" : "16px"}>Learn more</Text>
                </Link>
              ) : (
                <Text
                  fontSize={isMobile ? "14px" : "16px"}
                  color="primary"
                  _hover={{ cursor: 'pointer' }}
                  order={{ base: 2, sm: 1 }}
                  onClick={() => {
                    saveInterventionLibraryData({
                      scrollTop: scrollRef.current?.scrollTop
                    })
                    setOverlayPanel(AssistPanelOverlay.INTERVENTION_OVERVIEW, {
                      interventionId: intervention.id,
                      interventionName: intervention.name
                    })
                  }}
                >
                  Learn more
                </Text>
              )}
              <Button
                isDisabled={intervention.isAlreadySaved}
                paddingLeft={'6'}
                paddingRight={'6'}
                borderRadius="8px"
                size="sm"
                 w="125px"
                order={{ base: 1, sm: 2 }}
                onClick={() => {
                  saveInterventionForLater({
                    patientId: clientId,
                    interventionId: intervention.id,
                    data: {}
                  })
                }}
                _focus={{
                  outline: 'none'
                }}
              >
                <Text fontSize={isMobile ? "14px" : "16px"}>
                  {intervention.isAlreadySaved
                    ? 'Saved for later'
                    : 'Save for later'}
                </Text>
              </Button>
            </Stack>
          </Flex>
        ))}
        </VStack>
      </VStack>
    </Box>
  )
}
