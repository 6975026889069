import React, { useState, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Text,
  Box,
  Button,
  Flex,
  useToast,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import {
  getSessionControllerGetProgressNotesForSessionQueryKey,
  useSessionControllerPatchProgressNote
} from '~/clinician-api'
import ContentCopyIconOutline from '@material-ui/icons/FileCopyOutlined'
import { copyToClipboard } from '@utilities'
import { useQueryClient } from 'react-query'
import * as clinicianTracking from '../../../lib/clinician-tracking'
import { debounce } from 'lodash'
import { CopyNoteIcon } from '@icons'

const EventTypes = {
  EDIT_CLICKED: 'progress_note_edit_button_clicked',
  COPY_CLICKED: 'progress_note_copy_button_clicked',
  TEXT_COPIED: 'progress_note_text_copied'
}

const NoteBox = ({
  noteId,
  type,
  field,
  isEditable,
  label,
  value,
  isHighlight,
  emptyMessage,
  trackEvent,
  staticHeight
}: any) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value || '')
  const queryClient = useQueryClient()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const showCopyText = useBreakpointValue({ base: false, md: true })

  const toast = useToast()

  const { sessionId }: { sessionId: string } = useParams()

  const {
    mutate: patchNote,
    isLoading: isPatchNoteLoading
  } = useSessionControllerPatchProgressNote({
    mutation: {
      onSuccess: (data, variables) => {
        const queryDatSetter = (oldData: any) => {
          return {
            ...oldData,
            sessionProgressNotes: (oldData?.sessionProgressNotes || []).map(
              (spn: any) => {
                if (spn.id === noteId) {
                  return {
                    ...spn,
                    note: {
                      ...spn.note,
                      [field]: editedValue
                    }
                  }
                } else {
                  return spn
                }
              }
            )
          }
        }
        queryClient.setQueryData(
          getSessionControllerGetProgressNotesForSessionQueryKey(sessionId),
          queryDatSetter
        )
        toast({
          title: 'Note updated!',
          id: 'progress_note_updated_toast',
          status: 'success',
          duration: 1000
        })
        setIsEditing(false)
      },
      onError: () => {
        toast({
          title: 'Error updating note',
          status: 'error',
          duration: 1500
        })
      }
    }
  })

  const resizeTextArea = () => {
    if (textAreaRef.current && !staticHeight) {
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
    }
  }

  React.useEffect(resizeTextArea, [editedValue, value])

  const _throttledUpdateNote = useMemo(
    () => debounce(patchNote, 500, { trailing: true }),
    []
  )

  const handleCopy = (value: string) => {
    copyToClipboard(value)
    toast({
      title: 'Copied to clipboard!',
      status: 'success',
      duration: 1500
    })
    if (trackEvent) {
      clinicianTracking.trackEvent('Copied Note Section', {
        noteId,
        noteType: type,
        noteField: field
      })
      trackEvent(EventTypes.COPY_CLICKED, {
        noteType: type,
        field
      })
    }
  }

  const handleChange = (e: any) => {
    setEditedValue(e.target.value)

    _throttledUpdateNote({
      data: {
        [field]: e.target.value
      },
      noteId,
      id: sessionId
    })
  }

  return (
    <Box w="100%" mb="5">
      <Flex marginBottom="8px" alignItems="center" minHeight={10}>
        <Text fontSize="16px" fontWeight="bold">
          {label}
        </Text>
        <Flex>
          <Button
            id={`pendo-copy-${type}-${field}-tag`}
            isDisabled={value === null}
            borderRadius="24px"
            variant="ghost"
            mr={0}
            margin="0px !important"
            pr={0}
            pl={showCopyText ? '8px' : '0px'}
            onClick={() => handleCopy(value)}
            _focus={{ outline: 'none' }}
            _hover={{ opacity: '0.7' }}
          >
            <Flex align="center" justify="space-between" gap="4px">
              <CopyNoteIcon fill="#282828" />
              {showCopyText && <Text>Copy</Text>}
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {value === null && !isEditable ? (
        <Box w="100%" p="small" bg="pale_gray" borderRadius="8px">
          <Text color="dark_gray">{emptyMessage}</Text>
        </Box>
      ) : (
        <Flex
          w="100%"
          borderColor="pale_gray"
          borderRadius="md"
          direction="column"
          bg={isHighlight ? '#FEFAF3' : 'inherit'}
        >
          <Textarea
            readOnly={!isEditable}
            ref={textAreaRef}
            value={editedValue}
            overflow={staticHeight ? 'auto' : 'hidden'}
            placeholder={`Add ${label} note here...`}
            onChange={handleChange}
            whiteSpace="pre-line"
            lineHeight="26px"
            p="small"
            h={staticHeight ? '250px' : 'auto'}
            onCopy={() => {
              if (trackEvent) {
                trackEvent(EventTypes.TEXT_COPIED, {
                  noteType: type,
                  field
                })
              }
            }}
          />
        </Flex>
      )}
    </Box>
  )
}

export default NoteBox
