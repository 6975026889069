import React from 'react'
import { Box } from '@chakra-ui/react'

interface AnimatedTextExplainerProps {
  content: string
  isVisible?: boolean
}

export const AnimatedTextExplainer: React.FC<AnimatedTextExplainerProps> = ({
  content,
  isVisible = false
}) => {
  return (
    <Box
      width="max-content"
      maxWidth="240px"
      padding="12px 48px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      gap="10px"
      borderRadius="24px"
      background="rgba(45, 84, 232, 0.10)"
      backdropFilter="blur(10px)"
      color="#2D54E8"
      textAlign="center"
      fontSize="16px"
      zIndex="10"
      opacity="0"
      transform="translateY(35px)"
      transition="opacity 1200ms cubic-bezier(0.57, 0.1, 0, 1), transform 1200ms cubic-bezier(0.57, 0.1, 0, 1)"
      transitionDelay="0s"
      sx={{
        ...(isVisible && {
          opacity: 1,
          transform: "translateY(0)"
        })
      }}
    >
      {content}
    </Box>
  )
}